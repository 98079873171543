import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import {setDefaultAppConf} from "./login/configurationLoader";

if(typeof window.AppConf === "undefined"){
    setDefaultAppConf();
}

if(window.AppConf.sendSentryLogs !== false){
    Sentry.init({
        dsn: "https://59446e0b5f6a46999003d04e5c4838a0@sentry.nimi24.com/42",
        release: "SCO@" + process.env.REACT_APP_VERSION,
        beforeSend(event, hint) {
            console.log('Sentry: beforeSend', {event, hint});
            const error = hint.originalException;
            if (
                error &&
                error.message &&
                error.message.match(/Cannot read property 'scrollTop' of null/i)
            ) {
                return null;
            }
            return event;
        },
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();