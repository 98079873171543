import React, {Component} from 'react';
import "../main.css"
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import Keyboard from "../../keyboard/Keyboard";
import {addErrorAlert} from "../../redux/actions";
import {displayPrice, round005} from "../../util/helperFunctions";
import Button from "../../buttons/Button";

const mapStateToProps = state => {
    return {
        currency: state.rootReducer.currency
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addErrorAlert: (message) => {
            dispatch(addErrorAlert(message));
        }
    }
};

class PaymentInput extends Component{
    constructor(props) {
        super(props);
        this.state = {
            change: 0,
            paymentSum: ''
        };

        this.calculateChange = this.calculateChange.bind(this);
    }

    calculateChange(total, paymentSum, canGiveChange) {
        let change = parseFloat((paymentSum - total));
        if(isNaN(change)) change = 0;

        if(total >= 0){
            if(canGiveChange){
                this.setState({
                    paymentSum: paymentSum,
                    change: parseFloat(displayPrice(change))
                });
            }else{
                let newPaymentSum = paymentSum;
                if(change > 0){
                    newPaymentSum = total;
                }
                this.setState({
                    paymentSum: newPaymentSum,
                    change: 0
                });
            }
        }else{
            if(canGiveChange){
                this.setState({
                    paymentSum: paymentSum,
                    change: parseFloat(displayPrice(change))
                });
            }else{
                let newPaymentSum = paymentSum;
                if(change <= 0){
                    newPaymentSum = total;
                }
                this.setState({
                    paymentSum: newPaymentSum,
                    change: 0
                });
            }
        }
    }

    render() {
        let onConfirm = this.props.onConfirm;
        let calculateChange = this.calculateChange;
        let totalSum = this.props.totalSum;
        let sumChange = this.state.change;
        let canGiveChange = this.props.canGiveChange;
        let addErrorAlert = this.props.addErrorAlert;
        let roundToNearestFiveCents = this.props.roundToNearestFiveCents || false;

        let fullAmountButton = this.props.fullAmountButton || false;

        return (
            <div>
                <div className={"dialogueTitle"}><Translate id={this.props.title}/></div>
                    <table className={"paymentInputTable"}>
                        <tbody>
                            <tr>
                                <td><h2><Translate id={"Total"}/></h2></td>
                                <td className={"textRight bold"}>{ displayPrice(totalSum) }</td>
                            </tr>
                            <tr className={"coloredPaymentRow"}>
                                <td><Translate id={this.props.paymentType}/></td>
                                <td>
                                    <input name={"paymentInput"} className={"textRight"} type={"text"} value={this.state.paymentSum} readOnly={"readonly"}/>
                                </td>
                            </tr>
                            <tr>
                                <td><h2><Translate id={"changeMoneySum"}/></h2></td>
                                <td className={"textRight bold"}>{ this.state.change }</td>
                            </tr>
                        </tbody>
                    </table>
                    {
                        fullAmountButton ? (
                            <div onClick={e => {
                                onConfirm(totalSum, 0);
                            }} style={{marginBottom: '10px'}}>
                                <Button type="button" className={"largeButton blueButton"} name={<Translate id="100%"/>}/>
                            </div>
                        ) : null
                    }
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: 'numbers',
                        inputFieldName: 'paymentInput',
                        onChangeToLetters: () => {},
                        onChangeToNumbers: () => {},
                        onDone: function () {
                            let inputValue = document.getElementsByName("paymentInput")[0].value;
                            let lastChar = inputValue.substr(-1);
                            inputValue = parseFloat(inputValue);
                            if(isNaN(inputValue) || inputValue === 0 || isNaN(lastChar)){
                                addErrorAlert('please insert correct amount');
                            }else{
                                if(typeof onConfirm !== "undefined"){
                                    onConfirm(inputValue, sumChange);
                                }
                            }
                        },
                        currentValue: this.state.paymentSum,
                        updateValue: (value, key, oldValue) => {
                            let paymentSum = value;
                            let lastChar = value.substr(-1);
                            if(isNaN(key) && key !== ',' && key !== '.' && key !== 'delete'){
                                paymentSum = paymentSum.slice(0, -1);
                            }
                            paymentSum = paymentSum.replace(',', '.');
                            if(key === ',' || key === '.'){
                                if(paymentSum.slice(0,-1).indexOf('.') !== -1){
                                    paymentSum = paymentSum.slice(0, -1);
                                }
                            }
                            if(paymentSum[0] === '.'){
                                paymentSum = '0' + paymentSum;
                            }
                            if(totalSum < 0 && parseFloat(paymentSum) > 0){
                                paymentSum *= -1;
                            }

                            if(lastChar !== '.' && paymentSum !== ''){
                                let parts = paymentSum.toString().split('.');
                                let decimals = parts?.[1] || '';
                                if(parts[0].length > 12){
                                    paymentSum = parts[0].slice(0,-1) + (decimals !== ''? '.' + decimals : '');
                                }
                                if(decimals.length > 2){
                                    paymentSum = paymentSum.slice(0, -1 * (decimals.length - 2));
                                }
                                if(roundToNearestFiveCents){
                                    paymentSum = round005(paymentSum, decimals.length);
                                }
                                calculateChange(totalSum, paymentSum, canGiveChange);
                            }else{
                                this.setState({
                                    paymentSum
                                });
                            }
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(PaymentInput));