import React from 'react';
import './formElements.css';

export default function ({checked, name, onChange}) {
    let checkIcon;
    if(checked){
        checkIcon = <i className={"icoFont"}>&#xeed6;</i>;
    }
    return (
        <label className={'checkBox'}>
            <input type={'hidden'} value={checked || ''} name={name}/>
            <span className={'checkMark'} onClick={e => {
                onChange(!checked);
            }}>
                {checkIcon}
            </span>
        </label>
    );
}