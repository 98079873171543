import React, {Component} from 'react';
import "../main.css"
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import Keyboard from "../../keyboard/Keyboard";
import Button from "../../buttons/Button";
import {
    addErrorAlert,
    addInfoAlert,
    closeAdministration,
    getUserRights,
    openVerifyPinModal,
    POSCashInOut,
    showSelectDialogue,
} from "../../redux/actions";
import {getReasonCodesByPurpose} from "../../redux/selectors";
import {CASH_IN_OUT_SUCCESS} from "../../redux/actionTypes";
import Header from "./components/Header";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        reasonCodes: state.rootReducer.reasonCodes,
        pointOfSaleID: state.rootReducer.posID,
        employeeID: state.rootReducer.user.employeeID,
        erplyConf: state.rootReducer.erplyConf,
        clientCode: state.rootReducer.user.clientCode,
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        handleCashInOut: (type, amount, reasonCodes, posID, employeeID, erplyConf, clientCode, adminUser) => {
            if(amount === ''){
                dispatch(addErrorAlert('Please enter amount'));
                return false;
            }

            if(amount.indexOf('-') !== -1){
                dispatch(addErrorAlert('Negative amount is not allowed!'));
                return false;
            }

            function checkUserRightsByUserID(userID, callback) {
                dispatch(getUserRights(userID, posID, (records) => {
                    callback(records[0].rightMakeDiscountInPOS == 1);
                    return {
                        type: 'USER_RIGHTS_SUCCESS'
                    }
                }, () => {
                    return addErrorAlert('Could not get user right records, please try again!');
                }));
            }

            function checkUserRights(callback){
                if(type === 'cashIn' || parseFloat(erplyConf.DKTCashOutSupervisorLimit) >= parseFloat(amount)){
                    callback(true);
                    return true;
                }
                checkUserRightsByUserID(adminUser.userID, (hasRight) => {
                    if(hasRight){
                        callback(true);
                        return true;
                    }

                    dispatch(openVerifyPinModal(dispatch, clientCode, (user) => {
                        checkUserRightsByUserID(user.userID, callback);
                    }, () => {
                        callback(false);
                    }));
                });
            }

            checkUserRights(function (userHasRight) {
                if(!userHasRight){
                    dispatch(addErrorAlert('User has no right to perform this action!'));
                    return false;
                }

                let purpose = type === 'cashIn' ? 'CASH_IN' : 'CASH_OUT';

                let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, purpose).reduce((acc, reasonCode) => {
                    acc.push({
                        name: reasonCode.name,
                        value: reasonCode.reasonID
                    });
                    return acc;
                }, []);

                let dialogueTitle = type === 'cashIn' ? 'Cash in reason' : 'Cash out reason';
                dispatch(showSelectDialogue(dialogueTitle, reasonCodeOptions, (reasonID, reason) => {
                    let requestName = type === 'cashIn' ? 'POSCashIN' : 'POSCashOUT';
                    dispatch(POSCashInOut(requestName, posID, employeeID, amount, reason, reasonID, data => {
                        dispatch(addInfoAlert('Transaction completed'));
                        dispatch(closeAdministration());
                        return {
                            type: CASH_IN_OUT_SUCCESS,
                            payload: {
                                transactionID: data[0].transactionID,
                                addedUnixTime: data[0].addedUnixTime,
                                type,
                                amount
                            }
                        }
                    }, (error) => {
                        return addErrorAlert('Could not save cash in');
                    }))
                }, e => {}));
            });
        }
    }
};

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            selectedUser: false,
            errorMessage: false
        };

        this.clickButton = this.clickButton.bind(this);
        this.getInputAmount = this.getInputAmount.bind(this);
    }

    getInputAmount(){
        return document.getElementsByName("cashInOutAmount")[0].value;
    }

    clickButton(onClick, name) {
        return (
            <div onClick={e => {
                if(typeof onClick !== "undefined" && onClick !== null){
                    onClick();
                }
            }} style={{
                float: 'left',
                marginRight: '10px'
            }}>
                {
                    this.props.theme === 'Erply' ?
                        <Button type={"button"}
                                className={"largeButton darkYellowButton roundButton uppercaseText"}
                                arrow={"right"}
                                name={name}
                        />
                        :
                        <Button type={"button"}
                                className={"largeButton yellowButton"}
                                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                                arrow={"right"}
                                name={name}
                        />
                }

            </div>
        )
    }

    render() {
        if(this.props.theme === 'Erply'){
            return (
                <div>
                    <Header title={"Cash in/out"}/>
                    <div style={{
                        marginTop: '100px'
                    }}>
                        <label style={{
                            color: '#808080',
                            marginLeft: '15px'
                        }}>
                            <Translate id={"Amount"}/>
                        </label>
                        <Translate>
                            {({ translate }) =>
                                <input
                                    type="text"
                                    name={'cashInOutAmount'}
                                    className={'underlineInput'}
                                    style={{
                                        marginBottom: '20px'
                                    }}
                                    autoComplete={'off'}
                                />
                            }
                        </Translate>
                    </div>

                    {this.clickButton(e => {
                        this.props.handleCashInOut(
                            'cashIn',
                            this.getInputAmount(),
                            this.props.reasonCodes,
                            this.props.pointOfSaleID,
                            this.props.employeeID,
                            this.props.erplyConf,
                            this.props.clientCode
                        );
                    }, <Translate id={"Cash in"}/>)}

                    {this.clickButton(e => {
                        this.props.handleCashInOut(
                            'cashOut',
                            this.getInputAmount(),
                            this.props.reasonCodes,
                            this.props.pointOfSaleID,
                            this.props.employeeID,
                            this.props.erplyConf,
                            this.props.clientCode,
                            this.props.administration.user
                        );
                    }, <Translate id={"Cash out"}/>)}

                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: this.state.keyboardLayout,
                            inputFieldName: "cashInOutAmount",
                            onChangeToLetters: () => {}
                        }}/>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Cash in/out"}/></h1>
                <Translate>
                    {({ translate }) => <input type="text" name={'cashInOutAmount'} className={'underlineInput'} style={{
                        marginBottom: '20px'
                    }} placeholder={translate('Amount')} autoComplete={'off'}/>}
                </Translate>

                {this.clickButton(e => {
                    this.props.handleCashInOut(
                        'cashIn',
                        this.getInputAmount(),
                        this.props.reasonCodes,
                        this.props.pointOfSaleID,
                        this.props.employeeID,
                        this.props.erplyConf,
                        this.props.clientCode
                    );
                }, <Translate id={"Cash in"}/>)}

                {this.clickButton(e => {
                    this.props.handleCashInOut(
                        'cashOut',
                        this.getInputAmount(),
                        this.props.reasonCodes,
                        this.props.pointOfSaleID,
                        this.props.employeeID,
                        this.props.erplyConf,
                        this.props.clientCode,
                        this.props.administration.user
                    );
                }, <Translate id={"Cash out"}/>)}

                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: this.state.keyboardLayout,
                        inputFieldName: "cashInOutAmount",
                        onChangeToLetters: () => {}
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(AddItem));
