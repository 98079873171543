import React, {Component} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import ProductList from "./ProductList";
import Button from "../buttons/Button";
import {connect} from "react-redux";
import {
    changeMode,
    cancelTransaction as cancelTransactionAction,
    showRightPanelDialogue,
    closeRightPanelDialogue,
    changeTransactionMode,
    changeView,
    setCustomer
} from "../redux/actions";
import {changePaymentMode} from "../redux/actions";
import {APP_MODE_BEFORE_PAYMENT, APP_MODE_PAYMENT, APP_MODE_SCAN, PAYMENT_MODE_OPTIONS} from "../redux/appConstants";
import RemoveItems from "./RemoveItems";
import Customer from "./Customer";
import BasketHeader from "./BasketHeader";
import CancelButton from "./leftPanel/cancelButton";
import {mixedTransactions} from "../redux/selectors";


const mapStateToProps = state => {
    return {
        hasItems: state.rootReducer.productsInBasket.length > 0,
        mixedTransaction: mixedTransactions(),
        mode: state.rootReducer.mode,
        cashierMode: state.rootReducer.cashierMode,
        transactionMode: state.rootReducer.transactionMode,
        paymentMode: state.rootReducer.paymentMode,
        erplyMode: process.env.REACT_APP_ERPLY_MODE === "1",
        customer: state.rootReducer.customer,
        theme: state.rootReducer.theme,
        leftPanelFullScreen: state.rootReducer.leftPanelFullScreen
    }
};

const mapDispatchToProps = dispatch => {
    return {
        cancelTransaction: (hasItems) => {
            let confirm = () => {
                dispatch(cancelTransactionAction());
            };
            if(hasItems){
                dispatch(showRightPanelDialogue(
                    <RemoveItems
                        title={<Translate id={"removeAllItemsFromBin"}/>}
                        confirmText={<Translate id={"I confirm"}/>}
                        cancelText={<Translate id={"backToBasket"}/>}
                        onConfirm={confirm}
                        onCancel={e => {
                            dispatch(closeRightPanelDialogue());
                        }}
                    />
                ));
            }else{
                confirm();
            }
        },
        modifyBasket: (customer) => {
            dispatch(changeMode(APP_MODE_SCAN));
            dispatch(changePaymentMode(PAYMENT_MODE_OPTIONS));
            if(customer !== false && customer.default){
                dispatch(setCustomer(false));
            }
        },
        changeToSaleMode: () => {
            dispatch(changeTransactionMode('SALE'));
        },
        changeToReturnMode: () => {
            dispatch(changeTransactionMode('RETURN'));
        },
        changeView: (view) => {
            dispatch(changeView(view));
        }
    }
};

class LeftPanel extends Component{
    constructor(props) {
        super(props);
        this.state = {
            customerSearchOpen: false
        }
    }
    render() {
        let cancelTransaction;
        let returnMode;
        let customerSearch;

        if(this.props.transactionMode === 'RETURN' && (this.props.mixedTransaction || !this.props.hasItems)){
            let changeToSaleButton;
            if(this.props.theme === 'Erply'){
                changeToSaleButton =
                    <Button type="button"
                             className={"mediumEqualButton lightBlueButton roundButton uppercaseText"}
                             name={<Translate id="Change to sale"/>}
                             arrow="left"
                    />
            }else{
                changeToSaleButton =
                    <Button type="button"
                            className={"mediumButton blueButton"}
                            name={<Translate id="Change to sale"/>}
                            arrow="left"
                            flencheClass={"flencheSmallLeftBottom blueFlenche"}
                    />
            }
            returnMode = (
                <div style={{
                    float: 'right',
                    marginRight: '10px',
                    marginBottom: '10px',
                }}>
                    <div onClick={() => {
                        this.props.changeToSaleMode();
                    }} style={{
                        float: 'left',
                        marginTop: '10px'
                    }}>
                        {changeToSaleButton}
                    </div>
                </div>
            );
        }else if(this.props.transactionMode === 'SALE' && this.props.cashierMode && this.props.mode === APP_MODE_SCAN){
            returnMode = (
                <div style={{
                    float: 'right',
                    marginRight: '10px',
                    marginBottom: '10px',
                }}>
                    <div onClick={this.props.changeToReturnMode} style={{
                        float: 'left',
                        marginTop: '10px'
                    }}>
                        <Button
                            type="button"
                            className={"mediumButton orangeButton"}
                            name={<Translate id="Return mode"/>}
                            arrow="left"
                        />
                    </div>
                </div>
            );
        }

        if( this.props.mode === APP_MODE_SCAN ||
            this.props.mode === APP_MODE_BEFORE_PAYMENT ||
            this.props.mode === APP_MODE_PAYMENT
        ){
            let disabled = this.props.paymentMode !== PAYMENT_MODE_OPTIONS;
            cancelTransaction = <CancelButton
                onClick={(e) => {
                    if(this.props.mode === APP_MODE_SCAN){
                        this.props.cancelTransaction(this.props.hasItems)
                    }else{
                        if(!disabled) this.props.modifyBasket(this.props.customer)
                    }
                }}
                cancelTransaction={this.props.cancelTransaction}
                theme={(this.props.theme)}
                title={this.props.mode === APP_MODE_SCAN ? "cancelTransaction" : "modifyBasket"}
                appMode={this.props.mode}
            />;
        }

        if(this.props.erplyMode && window.AppConf.enableManualCustomerSearch !== false && !this.state.customerSearchOpen){
            customerSearch = (
                <div onClick={e => this.setState({customerSearchOpen: true})} style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    float: 'right'
                }}>
                    <Button
                        type="button"
                        className={"mediumEqualButton lightBlueButton"}
                        name={<Translate id="Customer"/>}
                    />
                </div>
            );
        }

        let header;
        let content;

        if(this.state.customerSearchOpen){
            header = (
                <BasketHeader>
                    <span className={"boldUppercaseText"} style={{
                        paddingLeft: '30px',
                        verticalAlign: 'middle',
                        lineHeight: '3',
                        fontSize: '20px'
                    }}>
                        <Translate id={"Customer"} />
                        <div onClick={e => this.setState({customerSearchOpen: false})} style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            float: 'right'
                        }}>
                            <Button
                                type="button"
                                className={"mediumButton grayButton"}
                                name={<Translate id="Cancel"/>}
                                flencheClass={"flencheSmallRightBottom grayFlenche"}
                            />
                        </div>
                    </span>

                </BasketHeader>
            );
            content = (
                <Customer
                    onSelect={(customer) => {
                        this.setState({
                            customerSearchOpen: false
                        });
                    }}
                />
            );
        }else{
            let basketTextStyle;
            let basketTextClass;
            if(this.props.theme === 'Erply'){
                basketTextStyle = {
                    paddingLeft: '20px',
                    verticalAlign: 'middle',
                    lineHeight: 2.5,
                    fontSize: '24px'
                }
                basketTextClass = 'boldText';
            }else{
                basketTextStyle = {
                    paddingLeft: '30px',
                    verticalAlign: 'middle',
                    lineHeight: '3',
                    fontSize: '20px'
                }
                basketTextClass = 'boldUppercaseText';
            }
            header = (
                <BasketHeader>
                    <span className={basketTextClass} style={basketTextStyle}>
                        <Translate id={"basket"} />
                    </span>
                    { cancelTransaction }
                    { returnMode }
                    { customerSearch }
                </BasketHeader>
            );
            content = <ProductList/>;
        }

        let backgroundColor = this.props.cashierMode && this.props.transactionMode === "RETURN" ? "#B9E4FAFF" : "#FFF";
        return (
            <div id={this.props.leftPanelFullScreen ? "leftPanelFullScreen" : "leftPanel"} style={{
                backgroundColor
            }}>
                { header }
                { content }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LeftPanel))