import {removePayment, updatePayment, voidAllPayments, voidGiftCardActivations} from "../../actions";
import {
    addBeforeCompleteTransactionAction,
    changeMode,
    changePaymentMode,
    closeModal,
    openLogonModal,
    openModal,
    removeBeforeCompleteTransactionAction,
    setLoading,
    showDialogue,
    showInputDialogue,
    updateProductGiftCardNumber
} from "../../../actions";
import {getCheckedGiftCardPayments, getFirstStartedGiftCardPayment, giftCardIsInTransaction} from "../../selectors";
import RGFD from "./main";
import * as uuid from "uuid";
import React, {useState} from "react";
import CreditResults from "./CreditResults";
import {getTranslate} from "react-localize-redux";
import {APP_DOCUMENT_SAVE_STATUS_START, APP_MODE_SCAN} from "../../../appConstants";
import {CHANGE_TRANSACTION_SAVE_STATUS} from "../../../actionTypes";

export const checkGiftCard = function (dispatch, state, code, scannedCode, onDone = ()=>{}) {
    const reject = function (title, errorMessage) {
        function after(){
            dispatch(removePayment(payment));
            dispatch(changePaymentMode('options'));
        }
        dispatch(showDialogue(title, errorMessage, 'Ok', after, after));
        onDone(false);
    };
    const payment = Object.assign({}, getFirstStartedGiftCardPayment(state.payment.payments));
    RGFD.init(state.rootReducer.warehouse, state.rootReducer.pos, state.rootReducer.erplyConf, state.rootReducer.user.clientCode, getTranslate(state.localize));

    let number = code;

    if(giftCardIsInTransaction(number, state.payment.payments)){
        reject("Could not use gift card", 'It is added to this transaction already!');
        return false;
    }

    RGFD.payment.searchGiftCard(number, function (giftCard) {
        console.log('payment: ', payment);
        console.log('giftCard: ', giftCard);
        const giftCardAmount = parseFloat(giftCard.chargedAmount);
        if(giftCardAmount > 0){
            payment.sum = payment.sum > giftCardAmount ? giftCardAmount : payment.sum;
            payment.status = "checked";
            payment.giftCard = giftCard;
            dispatch(changePaymentMode('options'));
            dispatch(updatePayment(payment));
            onDone(true);
        }else{
            reject("Could not use gift card", 'Total amount used already!')
        }

    }, function (errorMessage) {
        reject("Could not use gift card", errorMessage);
    });
};

export const giftCard = async function (dispatch, state, payload) {
    RGFD.init(state.rootReducer.warehouse, state.rootReducer.pos, state.rootReducer.erplyConf, state.rootReducer.user.clientCode, getTranslate(state.localize), dispatch);

    if(payload.data.request === 'checkGiftCard'){
        checkGiftCard(dispatch, state, payload.data.number, false, payload.data.onDone);
    } else if(payload.data.request === 'captureFirst'){
        const payments = getCheckedGiftCardPayments(state.payment.payments);
        let payment;
        if(payments.length > 0){
            payment = payments[0];
        }else{
            return true;
        }
        let failed = false;
        const setFailed = function () {
            failed = true;
        };

        let balance = payment.giftCard.chargedAmount - payment.sum;
        let result = await RGFD.API.doCapture(payment.giftCard.cardNumber, balance, state.rootReducer.user.employeeID).catch(setFailed);
        if(!failed){
            console.log('RGFD: payment confirm doCapture result', result);
                if(result.length > 0){
                    payment.status = 'success';
                    payment.sumPaid = payment.sum;
                    payment.giftCard.giftCardID = result[0].giftCardID;
                    dispatch(payload.onSuccess(payment));
                }else{
                    failed = true;
                }
        }
        if(failed){
            let after = function(){
                dispatch(removePayment(payment));
                dispatch(changePaymentMode('options'));
            };
            dispatch(showDialogue("Could not use gift card", '', 'Ok', after, after));
            payload.onFailure();
        }
    } else if(payload.data.request === 'giftCardAvailable'){
        RGFD.API.doCheck(payload.data.number).then(function (result) {
            const response = JSON.parse(result);
            let canUse = false;
            if(response.status.responseStatus === 'ok') {
                if (response.data.action_status === 'CHECK_ERROR') {
                    if(response.data.generic_parameter.message === "Error code: 4 - Inactive account"){
                        canUse = true;
                    }
                }
            }
            if(canUse) {
                payload.onSuccess(true, response.data.generic_parameter);
            }else{
                let errorMessage = 'Can\'t use this gift card!';
                if(response.data.action_status === 'CHECK_SUCCESS'){
                    errorMessage = 'This gift card is already activated!';
                }
                payload.onSuccess(false, errorMessage);
            }
        }, function () {
            payload.onFailure('Could not perform the API call to check the number, try again!');
        });
    } else if(payload.data.request === 'voidGiftCardActivations'){
        let handleVoidFailed = (creditResult) => {
            creditResult.status = 'VoidFailed';
            if(RGFD.creditResults.allVoided()){
                let voidFailed = RGFD.creditResults.data.filter(el => el.status === 'VoidFailed');
                payload.onFailure(voidFailed);
            }
        };

        let activatedGiftCards = RGFD.creditResults.data.filter(el => el.status === 'Success');
        if(activatedGiftCards.length === 0){
            payload.onSuccess();
        }else{
            for(let creditResult of activatedGiftCards){
                RGFD.API.doVoid(creditResult.transactionID).then((result) => {
                    const response = JSON.parse(result);
                    console.log('RGFD: doVoid', response);
                    if(typeof response.data !== "undefined" && response.data.action_status === 'VOID_SUCCESS'){
                        creditResult.status = 'Voided';
                        if(RGFD.creditResults.allVoided()){
                            let voidFailed = RGFD.creditResults.data.filter(el => el.status === 'VoidFailed');
                            if(voidFailed.length > 0){
                                payload.onFailure(voidFailed);
                            }else{
                                payload.onSuccess();
                            }
                        }
                    }else{
                        handleVoidFailed(creditResult);
                    }
                }, () => {
                    handleVoidFailed(creditResult);
                });
            }
        }
    } else if(payload.data.request === 'voidGiftCardPayment'){
        RGFD.API.doVoid(payload.data.transactionID).then((result) => {
            const response = JSON.parse(result);
            console.log('RGFD: doVoid', response);
            if(typeof response.data !== "undefined" && response.data.action_status === 'VOID_SUCCESS'){
                payload.onSuccess();
            }else{
                payload.onFailure();
            }
        }, payload.onFailure);
    }
};

export const posEvents = {
    activateGiftCards: (store, onDone, onFail, beforeCompleteTransactionID) => {
        console.log('RGFD: activate Gift Cards');
        let state = store.getState();
        let hasGiftCardSales = false;
        let giftCardResultsModalID = uuid.v4();
        let lineNo = 1;
        for(let product of state.rootReducer.productsInBasket) {
            if(typeof product.giftCardCode !== 'undefined'){
                hasGiftCardSales = true;
                if(typeof RGFD.creditResults.data.find(el => el.cardNumber === product.giftCardCode) !== "undefined"){
                    continue;
                }

                let creditResult = new RGFD.giftCard(lineNo, product.giftCardCode, product.rowTotal, 'Pending', product.lineNumber);
                RGFD.creditResults.data.push(creditResult);

                RGFD.API.doCredit(product.giftCardCode, product.rowTotal, state.rootReducer.user.employeeID, (response) => {
                    if(RGFD.creditResults.allSuccessful()){
                        store.dispatch(closeModal(giftCardResultsModalID));
                        RGFD.creditResults.init();
                        onDone();
                    }
                }, function (response) {});
            }
            lineNo++;
        }

        let openCancelActivationOptions = (dispatch) => {
            dispatch(closeModal(giftCardResultsModalID));
            dispatch(showDialogue('Gift card activation failed!', 'Payments and gift card activations will be voided!', 'Ok', function () {
                dispatch(setLoading(true));
                dispatch(changeMode(APP_MODE_SCAN));
                dispatch(voidAllPayments());
                dispatch(voidGiftCardActivations(() => {
                    dispatch(setLoading(false));
                    RGFD.creditResults.init();
                }, (giftCards) => {
                    dispatch(setLoading(false));
                    RGFD.creditResults.init();
                    for(let giftCard of giftCards){
                        giftCard.status = 'Success';
                        RGFD.creditResults.data.push(giftCard);
                        dispatch(showDialogue('Failed to void gift card activation', 'no: ' + giftCard.cardNumber, 'Ok',
                            () => {}, undefined, undefined, false, undefined, undefined, true));
                    }
                }));
                dispatch(removeBeforeCompleteTransactionAction(beforeCompleteTransactionID));
                dispatch({
                    type: CHANGE_TRANSACTION_SAVE_STATUS,
                    payload: APP_DOCUMENT_SAVE_STATUS_START
                });
            }, undefined, undefined, false, undefined, undefined, true));
        };

        let openCreditResultsModal = () => {
            let CreditResultsWrapper = () => {
                const [creditResults, setCreditResults] = useState(RGFD.creditResults.data);
                RGFD.creditResults.update = () => {
                    setCreditResults([...RGFD.creditResults.data]);
                };

                return (
                    <CreditResults
                        data={creditResults}
                        onAskAssistance={() => {
                            store.dispatch(openLogonModal('Logon to handle failed gift card activation', (data) => {
                                openCancelActivationOptions(store.dispatch);
                            }));
                        }}
                        onEdit={(creditResult) => {
                            store.dispatch(showInputDialogue('enter gift card code', '', 'card number', (number) => {
                                creditResult.cardNumber = number;
                                creditResult.retry = 0;
                                RGFD.creditResults.update();
                                store.dispatch(updateProductGiftCardNumber(creditResult.productLineNumber, number));
                                RGFD.API.resendCredit(creditResult.cardNumber, creditResult.chargedAmount);
                            }, undefined, undefined, true, 'zIndex6', undefined, creditResult.cardNumber));
                        }}
                    />
                );
            };

            store.dispatch(openModal({
                content:(
                    <CreditResultsWrapper/>
                ),
                id: giftCardResultsModalID,
                className: "Administration",
                onClose: function(){},
                canClose: false
            }));
        };

        console.log('RGFD: creditResults.data', RGFD.creditResults.data);
        if(hasGiftCardSales){
            openCreditResultsModal();
        }
    },
    beforeSaveTransaction: (store) => {
        let state = store.getState();
        let giftCardInRows = RGFD.giftCardInRows(state.rootReducer.productsInBasket);
        let alreadyAdded = typeof state.rootReducer.beforeCompleteTransactionActions.find(el => el.type === 'activateGiftCards') !== "undefined";
        if(giftCardInRows && !alreadyAdded){
            let id = uuid.v4();
            store.dispatch(addBeforeCompleteTransactionAction({
                id,
                type: 'activateGiftCards',
                run: (onDone, onFail) => {
                    posEvents.activateGiftCards(store, onDone, onFail, id);
                }
            }));
        }
    },
    initTransaction: () => {
        RGFD.creditResults.init();
    }
};