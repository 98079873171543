import React, { Component } from 'react';
import languageChoose from './images/LanguageChoose.png'
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from "react-redux";
import ClickAwayListener from "react-advanced-click-away";

let allLanguages = {};

const setAllLanguages = async () => {
    for (let language of window.AppConf.languages) {
        let img = (await import('../flags/' + language + '.png')).default;
        allLanguages[language] = {
            title: <Translate id={language}/>,
            img: img,
            locale: language
        }
    }
    console.log('allLanguages set', allLanguages);
};

const mapStateToProps = state => {
    let locale = state.localize.languages.find(lang => lang.active).code;

    return {
        locale,
        languages: window.AppConf.languages,
        language: allLanguages[locale] ?? false
    }
};

class LanguageSelect extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };
    }

    async componentDidMount() {
        if (window.AppConf.languages !== undefined) {
            await setAllLanguages();
            this.props.setActiveLanguage(window.AppConf.languages[0]);
        }
    }

    toggleSelect(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    changeLocale(e, locale){
        this.props.setActiveLanguage(locale);
    }

    render() {
        let languages = this.props.languages.map((languageCode) => {
            let language = allLanguages[languageCode];
            if(typeof language === "undefined") return <li className={"languageItem"} />;
            return(
                <li className={"languageItem"} key={language.locale} onClick={e => this.changeLocale.bind(this)(e, language.locale, language.img)}>
                    <img src={language.img} alt={language.locale} style={{width: "35px"}}/>
                    <span>{language.title}</span>
                </li>
            );
        });

        if(this.props.language === false){
            return <div className={"languageSelectContainerErply"} />;
        }

        return (
            <div onClick={this.toggleSelect.bind(this)} className={"statusBarItemLeft"}>
                <img src={languageChoose} alt={"languageChoose"}/>
                {this.state.isOpen &&
                    <ClickAwayListener onClickAway={this.toggleSelect.bind(this)}>
                        <div className={"languageListContainer"}>
                            <div className={"triangle-with-shadow"}/>
                            <div className={""}>
                               <ul>{languages}</ul>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(withLocalize(LanguageSelect));
