import React from 'react';
import "./modal.css"
import * as uuid from "uuid";
import {useSelector} from "react-redux";

export const createModalID = () => uuid.v4();
/**
 * @param props
 * - className
 * - content
 * - onClose
 */
export default function (props) {
    const theme = useSelector(state => state.rootReducer.theme);
    let closeButton;
    const canClose = typeof props.canClose === "undefined" ? true : props.canClose;
    console.log('can close modal', canClose);
    if(canClose){
        if(theme === 'Erply'){
            closeButton = (
                <div onClick={ e => {props.onClose(e);}} className={"modalCloseButtonErply"}>
                    <i className="icoFont">&#xeee1;</i>
                </div>
            );
        }else{
            closeButton = (
                <div onClick={ e => {props.onClose(e);}} className={"modalCloseButton blueCircleBackground"}>
                    <i className="icoFont">&#xeee1;</i>
                </div>
            );
        }
    }
    return (
        <div>
            <div className={"overlay"}/>
            <div className={"modal " + (props.className || "")}>
                {closeButton}
                <div className={"modalContent"}>{props.content}</div>
            </div>
        </div>
    );
}