import React from 'react';
import './button.css';

export default function ({className = '', image, title}) {
    return (
        <div className={"buttonContainer " + className} style={{
            overflow: 'hidden',
            margin: '24px 90px'
        }}>
            <div style={{
                margin: '0 auto',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                height: '120px'
            }}>
                <img src={image} style={{
                    width: '54px'
                }} alt={"MOP"}/>
                <span className={"uppercaseText"} style={{
                    marginLeft: '15px',
                    fontSize: '18px'
                }}>
                    { title }
                </span>
            </div>
        </div>
    );
}