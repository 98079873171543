import React, {useState} from "react";
import {Translate} from "react-localize-redux";
import {validateEmail, validatePhone} from "../redux/dto/customer";
import Customer from "../redux/dto/customer";
import Button from "../buttons/Button";
import InputWithKeyboard from "../formElements/InputWithKeyboard";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../redux/actions";
import decathlonActiveImg from "./images/decathlonActive.png";

const determineValueType = (value, customerCodeStartsWith) => {
    if(value.startsWith(customerCodeStartsWith)){
        return "card";
    }
    if(value.startsWith('+')){
        return "phone";
    }
    if(value.indexOf("@") !== -1){
        return "email";
    }
    return false;
}

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());

    }

    const [inputValue, setInputValue] = useState('');
    const [validationError, setValidationError] = useState(false);

    const onInputDone = (value) => {
        let valueType = determineValueType(value, props.customerCodeStartsWith);
        let customer = new Customer();
        if(valueType === "card"){
            customer.card_number = value;
            props.onDone(customer);
        }else if(valueType === "email" && validateEmail(value)){
            customer.email = value;
            props.onDone(customer);
        }else if(valueType === "phone" && validatePhone(value)) {
            customer.phone = value.replace(/\D/g,'');
            props.onDone(customer);
        } else {
            setValidationError(<Translate id={"Not a proper value"}/>)
        }
    }

    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <div className={'errorMessage'} style={{
                fontSize: "14px"
            }}>{validationError}</div>
        );
    }

    return (
        <div style={{
            textAlign: "left"
        }}>
            <div style={{
                padding: "0 60px"
            }}>
                <img src={decathlonActiveImg} alt={"Decathlon active"} style={{
                    width: "220px"
                }}/>
            </div>
            <div style={{
                padding: "10px",
                paddingTop: "100px",
                width: "450px",
                margin: "0 auto"
            }}>
                <InputWithKeyboard
                    inputLabel={"Enter phone number, email or card number"}
                    inputLabelStyle={{
                        fontSize: "20px"
                    }}
                    inputClass={"shadowBoxInput"}
                    inputStyle={{
                        width: "100%",
                        marginTop: "10px"
                    }}
                    placeholder={`+${props.countryDialCode} 123 123 123 / ${props.translate("name@email.com")}`}
                    layout={"numbers"}
                    onInputChange={setInputValue}
                    onDone={onInputDone}
                />

                { validationErrorDisplay }
                <div style={{
                    display: "flex"
                }}>
                    <div onClick={props.onBack} style={{
                        margin: "10px 30px 10px 0"
                    }}>
                        <Button type="button" className={"wideButton grayButton"} name={<Translate id="cancel"/>}/>
                    </div>
                    <div onClick={() => {
                        onInputDone(inputValue);
                    }} style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        flexGrow: 1
                    }}>
                        <Button type="button" className={"wideButton darkerBlueButton"} name={<Translate id="confirm"/>} style={{
                            width: "100%"
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );
}