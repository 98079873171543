import React, {Component} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import addProductImg from "./images/pictureAddArticleRFID.png";
import addProductAnimationImg from "./images/pictureAddArticleRFIDAnimation.gif";
import addProductErplyImg from "./images/Erply/addProducts.png";
import scanErplyImg from "./images/Erply/scan.png";
import Button from "../buttons/Button";
import {changeMode, setEditQuickProducts} from "../redux/actions";
import Payment from "./payment/Payment";
import {APP_MODE_PAYMENT, APP_MODE_BEFORE_PAYMENT} from "../redux/appConstants";
import ProductSearch from "./ProductSearch";
import ProductGroups from "./ProductGroups";
import BasketHeader from "./BasketHeader";
import BeforePayment from "./payment/BeforePayment";
import PaymentButton from "./PaymentButton";

const mapStateToProps = state => {
    return {
        mode: state.rootReducer.mode,
        hasItems: state.rootReducer.productsInBasket.length > 0,
        rightPanelDialogue: state.rootReducer.rightPanelDialogue,
        rightPanelFullScreen: state.rootReducer.rightPanelFullScreen,
        editQuickPosProducts: state.rootReducer.editQuickPosProducts,
        quickPosProducts: state.rootReducer.quickPosProducts,
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startPayment: () => {
            dispatch(changeMode(APP_MODE_BEFORE_PAYMENT));
        },
        setEditQuickProducts: (edit) => {
            dispatch(setEditQuickProducts(edit));
        }
    }
};

class RightPanel extends Component{
    constructor(props) {
        super(props);
        this.state = {
            productSearchOpen: false
        }
    }

    placeItems() {
        let content;
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            let Header = ({children, buttons}) => (
                <BasketHeader>
                        <span className={"boldUppercaseText"} style={{
                            paddingLeft: '30px',
                            verticalAlign: 'middle',
                            lineHeight: '3',
                            fontSize: '20px'
                        }}>
                            {children}
                            {buttons.map((button, index) => {
                                return (
                                    <div key={index} style={{
                                        marginTop: '10px',
                                        marginRight: '10px',
                                        float: 'right',
                                        lineHeight: 0
                                    }}>
                                        {button}
                                    </div>
                                );
                            })}
                        </span>
                </BasketHeader>
            );
            if(this.state.productSearchOpen){
                content = (
                    <ProductSearch
                        onSelect={product => this.setState({productSearchOpen: false})}
                        onClose={e => this.setState({productSearchOpen: false})}
                    />
                );
            }else{
                let closeEditQuickSelectButton;
                let cancelEditQuickSelectButton;
                if(this.props.editQuickPosProducts){
                    closeEditQuickSelectButton = (
                        <Button
                            type="button"
                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                            name={<Translate id="Edit done"/>}
                            onClick={e => {
                                this.props.setEditQuickProducts(false);
                            }}
                        />
                    );
                    cancelEditQuickSelectButton = (
                        <Button
                            type="button"
                            className={"mediumButton grayButton roundButton uppercaseText"}
                            name={<Translate id="cancel"/>}
                            onClick={e => {
                                this.props.setEditQuickProducts('cancel');
                            }}
                        />
                    );
                }

                let ScanProducts = () => (
                    <div className={"boldText"} style={{
                        marginTop: '40px'
                    }}>
                        <div style={{
                            marginBottom: '27px'
                        }}>
                            <img src={scanErplyImg} alt={'Scan'}/>
                        </div>
                        <span style={{fontSize: "24px"}}>
                            <Translate id={"Bring the item to the scanner"}/>
                        </span>
                        <div style={{
                            position: 'absolute',
                            top: '250px',
                            bottom: '150px',
                            left: 0,
                            right:0,
                            textAlign: 'center',
                            maxWidth: '450px',
                            margin: 'auto'
                        }}>
                            <img src={addProductErplyImg} alt="Scan product" style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}/>
                        </div>
                    </div>
                );
                let headerButtons = [];
                if(window.AppConf.showProductSearch !== false){
                    headerButtons.push([
                        (
                            <Button
                                type="button"
                                className={"mediumEqualButton lightBlueButton roundButton uppercaseText"}
                                name={<Translate id="Add an item"/>}
                                onClick={e => this.setState({productSearchOpen: true})}
                            />
                        ), cancelEditQuickSelectButton, closeEditQuickSelectButton
                    ]);
                }
                content = (
                    <div>
                        <Header buttons={headerButtons}/>
                        {window.AppConf.showQuickSelectProducts ? <ProductGroups/> : <ScanProducts/>}
                        <PaymentButton/>
                    </div>
                );
            }
        }else{
            content = (
                <div className={"boldUppercaseText"} style={{
                    "marginTop": "54px"
                }}>
                    <span style={{fontSize: "35px"}}>
                        <Translate id={"drop your items"}/>
                    </span>
                    <br/>
                    <span style={{fontSize: "50px"}} className={"cutUnderline"}>
                        <Translate id={"one at a time"}/>
                    </span>
                    <div style={{
                        position: 'relative',
                        top: '50px',
                        textAlign: 'center',
                        width: '62%',
                        maxWidth: '450px',
                        margin: 'auto'
                    }}>
                        <img src={window.AppConf.showHangerGif ? addProductAnimationImg : addProductImg} alt="addProduct" style={{
                            width: "100%"
                            /*width: "62%",
                            maxWidth: "450px",
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: 'auto'*/
                        }}/>
                        {
                            window.AppConf.showHangerGif ?
                                (<span style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '10px',
                                    fontSize: '16px',
                                    color: '#f75706',
                                    width: '120px'
                                }}>
                                    <Translate id={"Please leave the hanger"}/>
                                </span>) : ""
                        }
                    </div>
                    <PaymentButton/>
                </div>
            );
        }

        return (
            <div>
                { content }
            </div>
        );
    }

    render() {
        if(this.props.rightPanelDialogue !== false){
            return (
                <div id={this.props.rightPanelFullScreen ? "rightPanelFullscreen" : "rightPanel"}>
                    {this.props.rightPanelDialogue}
                </div>
            );
        }

        let display;
        if(this.props.mode === 'scan'){
            display = this.placeItems();
        } else if (this.props.mode === APP_MODE_BEFORE_PAYMENT) {
            display = (
                <div style={{
                    marginTop: "54px"
                }}>
                    <BeforePayment/>
                </div>
            );
        }else if (this.props.mode === APP_MODE_PAYMENT) {
            display = (
                <div style={{
                    marginTop: "54px"
                }}>
                    <Payment/>
                </div>
            );
        }

        return (
            <div id={this.props.rightPanelFullScreen ? "rightPanelFullscreen" : "rightPanel"}>
                <div style={{
                    "textAlign":"center",
                }}>
                    { display }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(RightPanel));