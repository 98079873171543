import RFIDService from "./RFIDService";
import {
    ADD_PRODUCT,
    CANCEL_ADD_UNKNOWN_ITEM,
    CANCEL_TRANSACTION,
    CHANGE_MODE,
    CHANGE_TRANSACTION_MODE,
    CLOSE_QCO,
    INCREMENT_NEXT_INVOICE_NO,
    INIT_TRANSACTION,
    PRODUCT_NOT_FOUND,
    REMOVE_PRODUCT,
    SCANNER_DETECTED, SHOW_CALL_ASSISTANCE
} from "../actionTypes";
import {
    addUnknownRFIDProduct,
    removeProduct,
    removeUnknownRFIDProduct,
    showDialogue
} from "../actions";
import {
    APP_MODE_PAYMENT,
    APP_MODE_SCAN, APP_MODE_SCREEN_SAVER
} from "../appConstants";
import {getFirstUnknownRFIDProduct} from "../selectors";
import {getTranslate} from "react-localize-redux";


const middleware = store => next => action => {
    next(action);

    if(process.env.REACT_APP_ERPLY_MODE === "1" || window.AppConf.enableRFID !== true){
        return true;
    }

    const state = store.getState();
    const dispatch = store.dispatch;
    const translate = getTranslate(state.localize);

    if (action.type === INCREMENT_NEXT_INVOICE_NO){
        if(state.rootReducer.mode === 'done'){
            RFIDService.log('INCREMENT_NEXT_INVOICE_NO ', action.payload.lastInvoiceNo);
            RFIDService.POSEvents.afterDocumentSave(action.payload.lastInvoiceNo);
        }
    } else if(action.type === CANCEL_TRANSACTION){
        const productsInBasket = state.rootReducer.productsInBasket.reduce(function (acc, product) {
            acc.push(product.data);
            return acc;
        }, []);
        RFIDService.POSEvents.afterDocumentCancelled(productsInBasket);
    } else if(action.type === PRODUCT_NOT_FOUND){
        let unknownProduct = RFIDService.POSEvents.afterProductSearchNotFound(action.payload.searchParam);
        if(unknownProduct !== false){
            dispatch(addUnknownRFIDProduct(unknownProduct));
        }
    } else if(action.type === ADD_PRODUCT){
        const product = action.payload;
        if(RFIDService.POSEvents.productAddAllowed(product.data)){
            RFIDService.POSEvents.addProductAfter(product);
        }else{
            dispatch(removeProduct(product));
        }
        let unknownRFIDProduct = getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts);
        if(unknownRFIDProduct !== false){
            dispatch(removeUnknownRFIDProduct(unknownRFIDProduct));
        }
    } else if(action.type === REMOVE_PRODUCT){
        const product = action.payload.data;
        RFIDService.POSEvents.beforeProductRemove(product);
    } else if(action.type === CHANGE_MODE){
        if( action.payload.mode === APP_MODE_PAYMENT){
            RFIDService.POSEvents.startPayment();
            if(RFIDService.handleSecurityTagsAfterScanning){
                if(RFIDService.securityTags.length > 0){
                    dispatch(showDialogue(
                        translate('Hardtags detected', {number: RFIDService.securityTags.length}),
                        'Your items contain a security tag. After payment please go to the customer service desk to get it removed',
                        'Ok',
                        () => {
                            //RFIDService.cancelAllSecurityTagLines();
                        },
                        undefined,
                        undefined,
                        false,
                        undefined,
                        undefined,
                        true
                    ));
                }
            }
        }else if( action.payload.mode === APP_MODE_SCAN || action.payload.mode === APP_MODE_SCREEN_SAVER){
            RFIDService.startTransaction();
        }
    } else if(action.type === CLOSE_QCO){
        RFIDService.POSEvents.beforeClosePOS();
    } else if(action.type === CANCEL_ADD_UNKNOWN_ITEM){
        let unknownRFIDProduct = getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts);
        if(unknownRFIDProduct !== false){
            dispatch(removeUnknownRFIDProduct(unknownRFIDProduct));
        }
        RFIDService.POSEvents.afterCancelAddUnknownItem(action.productCode);
    } else if(action.type === SCANNER_DETECTED){
        RFIDService.lastScanned.push(action.payload);
        console.log("RFIDService: lastScanned", RFIDService.lastScanned);
    } else if(action.type === CHANGE_TRANSACTION_MODE){
        RFIDService.POSEvents.changeTransactionMode(action.payload);
    } else if(action.type === INIT_TRANSACTION){
        RFIDService.POSEvents.changeTransactionMode('SALE');
    } else if(action.type === SHOW_CALL_ASSISTANCE){
        if(state.rootReducer.mode === APP_MODE_SCAN || state.rootReducer.mode === APP_MODE_SCREEN_SAVER){
            RFIDService.pauseTransaction(() => {

            });
        }
    } else if(
        (state.rootReducer.mode === APP_MODE_SCAN || state.rootReducer.mode === APP_MODE_SCREEN_SAVER) &&
        state.rootReducer.administration === false &&
        state.rootReducer.callAssistance === false &&
        RFIDService.status === 'paused'
    ){
        RFIDService.startTransaction();
    }
    /*else if(action.type === ""){
        RFIDService.POSEvents.afterCloseDay();
    }
    else if(action.type === ""){
        RFIDService.POSEvents.afterOpenDay();
    }
    else if(action.type === ""){
        RFIDService.POSEvents.beforeLogout();
    }*/
};

export default middleware;