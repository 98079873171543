import {
    ADD_ALERT,
    ADD_DOCUMENT_DISCOUNT,
    ADD_MANUAL_PROMOTION_ID,
    ADD_PRODUCT,
    ADD_TRANSLATIONS,
    API_ERROR,
    APPLY_RECEIPT_PLUGIN,
    CANCEL_TRANSACTION,
    CASH_IN_OUT_SUCCESS,
    CHANGE_MODE,
    CHANGE_TRANSACTION_MODE,
    CLOSE_MODAL,
    CLOSE_QCO,
    CREATE_RECEIPT,
    CREATE_RECEIPT_NUMBER,
    GET_NEXT_SEQUENCE_NUMBER,
    INIT_TRANSACTION,
    OPEN_MODAL,
    POS_DAY_CLOSE_SUCCESS,
    POS_DAY_OPEN_SUCCESS,
    PRODUCT_NOT_FOUND,
    REMOVE_CUSTOMER,
    REMOVE_MANUAL_PROMOTION_ID,
    REMOVE_PRODUCT,
    REVERT_NEXT_SEQUENCE_NUMBER,
    SCANNER_DETECTED,
    SECURITY_ITEM_ADDED,
    SET_CUSTOMER,
    SET_CUSTOMER_AGE_VERIFIED,
    SET_ERPLY_CONF,
    SET_OPERATION_MODE,
    SET_POS_DATA,
    SET_POS_DATA_FAILED,
    SET_POS_DAY_OPENED,
    SET_USER,
    TRANSACTION_SUCCESS,
    TRANSACTION_TIMEOUT_REACHED,
    UPDATE_PRODUCT,
    UPDATE_PRODUCTS
} from "../actionTypes";
import {
    addCampaignByName,
    addErrorAlert,
    addInfoAlert, addManualPromotionID,
    addProduct,
    addProductReturnReasonID, addReceiptExtraLine,
    applyPromotions,
    cancelAddUnknownItem,
    cancelTransaction as cancelTransactionAction,
    changeMode,
    changeTransactionMode,
    closeAdministration,
    closeAllDialogues,
    closeAllModals,
    closeDialogue,
    closeQCO,
    closeRightPanelDialogue,
    findCustomerByEmail,
    findCustomerByPhone,
    findProduct,
    findProducts,
    getCampaignsByName,
    initTransaction,
    openLogonModal,
    removeAlert,
    removeDocumentDiscount,
    removeManualPromotionID,
    removeProduct,
    removeSecurityItems,
    securityItemAdded,
    setCustomer,
    setLastScannedGiftCard,
    setLoading,
    setNextSequenceNumber,
    setPaybackInputDone,
    showCallAssistance,
    showDialogue,
    showInputDialogue,
    showRightPanelDialogue,
    showSelectDialogue,
    transactionTimeoutReached,
    updateProduct
} from "../actions";
import POSLogService from "../poslog/POSLogService";
import React from "react";
import Traceability from "../../main/Traceability";
import EnterCustomerInfo from "../../main/EnterCustomerInfo";
import {
    getReasonCodesByPurpose,
    getProductFromBasketByID,
    getCommercialGestureReturnReason,
    getDefectiveItemReturnReason,
    getReasonCodeByID,
    getSecurityItems,
    getReturnToStockReason,
    displayNetPrices,
    getProductByCode,
    transactionMOPLimit,
    transactionProductLineLimit,
    getTillNumber,
    getProductsTotalSum,
    getCampaignByName,
    getNumberOfDecathlonGiftCardsInBasket,
    getUnknownItemCode
} from "../selectors";
import { HWSV, HRV } from "../../util/voucher";
import {ADD_PAYMENT, CARD_PAYMENT_FAILED, INIT_PAYMENT} from "../payment/actionTypes";
import {
    logout as paymentLogout,
    setTotalSum,
    setZipCodeSurveyCounter,
    voidAllPayments
} from "../payment/actions";
import {addTranslationForLanguage, getTranslate, setActiveLanguage, Translate} from "react-localize-redux";
import {timers} from "../../util/timers";
import {
    APP_MODE_SCREEN_SAVER,
    APP_STATUS_ASKED_FOR_ASSISTANCE,
    APP_STATUS_CLOSED,
    APP_STATUS_FAILED_PAYMENT,
    APP_STATUS_FREE,
    APP_STATUS_OPERATION_IN_PROGRESS,
    APP_STATUS_SECURITY_ITEM,
    DECATHLON_EMPLOYEE_ROLE
} from "../appConstants";
import RGFD from "../payment/integrations/giftCard/main";
import GiftCardActivation from "../../main/payment/GiftCardActivation";
import {EI} from "../erplyInstaller/main";
import {ED} from "../erplyInstaller/integrations/externalDevices/main";
import Decimal from "decimal.js";
import * as uuid from "uuid";
import {getProductPackageWeight} from "../../util/helperFunctions";
import Product from "../dto/product";
import CTR from "../../util/receiptTemplate";
import {sendStatusUpdate} from "../../communication/ManagerAppProvider";
import moment from "moment-timezone";
import {
    onPaymentMode as paybackOnPaymentMode,
    onReturnProductAdd as paybackOnReturnProductAdd,
    onTransactionModeChange as paybackOnTransactionModeChange
} from "../../integrations/payback/util";
import Customer from "../dto/customer";
import {setDefaultAppConf} from "../../login/configurationLoader";

let alertTimeout = false;
let applyPromotionsQueue = [];

const actionsMiddleware = store => next => action => {
    console.log('actionsMiddleware', action);
    next(action);

    const state = store.getState();
    const translate = getTranslate(state.localize);

    if(state.rootReducer.view === 'app' && state.rootReducer.mode !== APP_MODE_SCREEN_SAVER && action.type !== TRANSACTION_TIMEOUT_REACHED){
        timers.setTimeout('transactionTimeout', () => {
            let currentState = store.getState();
            console.log('timers timeout reached!', currentState, currentState.rootReducer.mode);
            store.dispatch(transactionTimeoutReached());
        }, window.AppConf.transactionTimeout, true);
    }

    if(action.type === TRANSACTION_TIMEOUT_REACHED && state.rootReducer.mode !== APP_MODE_SCREEN_SAVER){
        let cancelTransaction = () => {
            store.dispatch(cancelTransactionAction());
        };

        let timeoutReachedDialogue = state.rootReducer.dialogues.find(el => el.title === 'timeout reached');
        if(typeof timeoutReachedDialogue !== "undefined"){
            cancelTransaction();
            store.dispatch(closeAllDialogues());
        }else{
            if(state.rootReducer.dialogues.length === 0 && state.rootReducer.modals.length === 0 && state.rootReducer.administration === false && state.rootReducer.callAssistance === false){
                store.dispatch(showDialogue('timeout reached', translate('Do you want to continue with the transaction?'), 'yes', () => {}, () => {
                    cancelTransaction();
                }, 'no', false, undefined, undefined, true));
            }
        }
    }

    if(action.type === CANCEL_TRANSACTION){
        store.dispatch(voidAllPayments());
        store.dispatch(initTransaction());
        store.dispatch(changeMode(APP_MODE_SCREEN_SAVER));
        store.dispatch({type: INIT_PAYMENT});
        store.dispatch(closeRightPanelDialogue());
        if(state.rootReducer.transactionMode !== 'SALE'){
            store.dispatch(changeTransactionMode('SALE'));
        }
        if(state.rootReducer.administration !== false){
            store.dispatch(closeAdministration());
        }
        store.dispatch(closeAllModals());
    }

    const initPOSLogService = function () {
        window.posLogService = POSLogService;
        POSLogService.init(state.rootReducer.posID, state.rootReducer.warehouse.warehouseID, state.rootReducer.user.clientCode, state.rootReducer.user.employeeID);
    };

    const paybackEnabled = window.AppConf.payback || false;

    switch (action.type) {
        case INIT_TRANSACTION: {
            store.dispatch(setActiveLanguage(state.localize.languages[0].code));
            CTR.generatedReceiptNumber = {
                fromNumber: false,
                generated: false
            };
            return true;
        }
        case SET_POS_DATA: {
            initPOSLogService();
            const pos = action.payload;
            POSLogService.pointOfSaleID = pos.pointOfSaleID;
            POSLogService.warehouseID = pos.warehouseID;
            return true;
        }
        case SET_POS_DATA_FAILED: {
            store.dispatch(closeQCO());
            store.dispatch(addErrorAlert('Could not log in to this POS'));
            return false;
        }
        case SET_USER: {
            const user = action.payload;
            POSLogService.clientCode = user.clientCode;
            POSLogService.employeeID = user.employeeID;
            return true;
        }
        case TRANSACTION_SUCCESS: {
            console.log('TRANSACTION_SUCCESS, send poslog!', action.payload);
            POSLogService.sendRetailTransaction(action.payload.invoiceID, state.rootReducer.nextSequenceNumber);
            if(typeof state.payment.zipCodeSurveyCounter !== "undefined"){
                if(state.rootReducer.erplyConf.DKTZipSurveyNonCustomer === '1' ? state.rootReducer.customer.default : true){
                    let nextZipCodeSurveyCount = state.payment.zipCodeSurveyCounter + 1;
                    if(nextZipCodeSurveyCount > state.rootReducer.erplyConf.DKTZipSurveyFrequency){
                        nextZipCodeSurveyCount = 1;
                    }
                    store.dispatch(setZipCodeSurveyCounter(nextZipCodeSurveyCount));
                }
            }
            return true;
        }
        case GET_NEXT_SEQUENCE_NUMBER: {
            POSLogService.getNextSequenceNumber().then((number) => {
                action.payload.success(number);
            }, action.payload.fail);
            return true;
        }
        case REVERT_NEXT_SEQUENCE_NUMBER: {
            if(state.rootReducer.nextSequenceNumber !== false){
                POSLogService.revertSequenceNumber().then(() => {
                    store.dispatch(setNextSequenceNumber(false));
                }, () => {

                });
            }
            return true;
        }
        case CREATE_RECEIPT: {
            if(window.AppConf.printDecathlonReceipt){
                CTR.createReceiptTime = moment().format("HH:mm:ss");
                CTR.onCreateReceipt(state, store.dispatch).then(action.payload.onSuccess);
            }else{
                action.payload.onSuccess(false);
            }
            return true;
        }
        case CREATE_RECEIPT_NUMBER: {
            if(window.AppConf.printDecathlonReceipt){
                let dateTime = moment();
                dateTime.tz(state.rootReducer.erplyConf.timezone);
                let date = dateTime.format("YYYY-MM-DD");
                let time = dateTime.format("HH:mm:ss");

                let pluginRequestParameters = Object.assign({}, state.rootReducer.pluginRequestParameters);
                if(typeof pluginRequestParameters.saveSalesDocument === "undefined"){
                    pluginRequestParameters.saveSalesDocument = {};
                }
                pluginRequestParameters.saveSalesDocument.date = date;
                pluginRequestParameters.saveSalesDocument.time = time;
                store.dispatch({
                    type: "SET_PLUGIN_REQUEST_PARAMETERS",
                    payload: pluginRequestParameters
                });
                action.payload.onSuccess(CTR.createUniqueReceiptNumber(state.rootReducer.warehouse.code, getTillNumber(state.rootReducer.pos), date, time, state.rootReducer.nextInvoiceNo), dateTime);
            }else{
                action.payload.onSuccess(state.rootReducer.nextInvoiceNo);
            }
            return true;
        }
        case APPLY_RECEIPT_PLUGIN: {
            for (const property in action.properties){
                CTR[property] = action.properties[property];
            }
            return true
        }
        case SET_OPERATION_MODE: {
            CTR.init(state);
            store.dispatch({
                type: 'CTR_INIT',
                ctr: CTR
            });
            return true;
        }
        case CASH_IN_OUT_SUCCESS: {
            console.log('CASH_IN_OUT_SUCCESS, send poslog!', action.payload);
            POSLogService.sendCashInOut(action.payload.transactionID, action.payload.addedUnixTime);
            return true;
        }
        case PRODUCT_NOT_FOUND: {
            if(action.payload.searchParam !== getUnknownItemCode()){
                if(HWSV.isWorkshopVoucher(action.payload.searchParam)) {
                    let voucher = new HWSV.voucher(action.payload.searchParam);
                    HWSV.log('voucherCode: ', action.payload.searchParam, 'voucher: ', voucher);
                    let vatPrice = false;
                    let netPrice = false;
                    if(displayNetPrices(state.rootReducer.erplyConf)){
                        netPrice = voucher.price;
                    }else{
                        vatPrice = voucher.price;
                    }
                    store.dispatch(findProduct(voucher.productCode, state.rootReducer.warehouse.warehouseID, state.rootReducer.posID, vatPrice, function (product) {
                        product.data.voucherRFIDTag = {
                            'GTIN': voucher.productCode,
                            'SGTIN': voucher.code,
                            'SERIAL_NUMBER': voucher.getSerialNumber(),
                            'EPC': 'NULL_VALUE'
                        };
                        HWSV.addSerialNumberToProductName(product, voucher.getSerialNumber());
                        if(netPrice !== false){
                            product.setNetPrice(netPrice);
                        }
                        return addProduct(product);
                    }));
                }else if(HRV.isReturnVoucher(action.payload.searchParam)){
                    let voucher = new HRV.voucher(action.payload.searchParam);
                    console.log('HRV voucherCode: ', action.payload.searchParam, 'voucher: ', voucher);
                    let vatPrice = false;
                    let netPrice = false;
                    if(displayNetPrices(state.rootReducer.erplyConf)){
                        netPrice = voucher.amount;
                    }else{
                        vatPrice = voucher.amount;
                    }

                    store.dispatch(findProduct(voucher.articleCode, state.rootReducer.warehouse.warehouseID, state.rootReducer.posID, vatPrice, function (product) {
                        product.amount = voucher.articleQuantity * -1;

                        if(voucher.paymentTypeCode === HRV.commercialGestureVoucherCode){
                            let returnReason = getCommercialGestureReturnReason(state.rootReducer.reasonCodes);
                            if(returnReason !== undefined){
                                product.returnReasonID = returnReason.reasonID;
                                product.returnReason = returnReason;
                                store.dispatch(addInfoAlert('Commercial Gesture Return'));
                            }else{
                                store.dispatch(addErrorAlert('Please add commercial gesture reason code to Erply!'));
                            }
                        }else if(voucher.paymentTypeCode === HRV.defectiveArticleVoucherCode){
                            let returnReason = getDefectiveItemReturnReason(state.rootReducer.reasonCodes);
                            if(returnReason !== undefined){
                                product.returnReasonID = returnReason.reasonID;
                                product.returnReason = returnReason;
                                store.dispatch(addInfoAlert('Defective Item Return'));
                            }else{
                                store.dispatch(addErrorAlert('Please add defective item return reason code to Erply!'));
                            }
                        }

                        product.data.voucherRFIDTag = {
                            'GTIN': voucher.articleCode,
                            'SGTIN': voucher.code,
                            'SERIAL_NUMBER': voucher.getSerialNumber(),
                            'EPC': 'NULL_VALUE'
                        };
                        if(netPrice !== false) {
                            product.setNetPrice(netPrice);
                        }
                        return addProduct(product);
                    }));
                }else if (RGFD.isGiftCard(action.payload.searchParam)){
                    let giftCardsInBasket = getNumberOfDecathlonGiftCardsInBasket(state.rootReducer.productsInBasket, state.rootReducer.productCategories);
                    let allowedGiftCardsInBasket = window.AppConf?.giftCardActivationLimit ?? 10;
                    if(giftCardsInBasket >= allowedGiftCardsInBasket){
                        store.dispatch(addErrorAlert("No more gift cards can be added to the basket"));
                    }else{
                        let giftCard = RGFD.decodeBarcode(action.payload.searchParam);
                        if(state.rootReducer.transactionMode === 'SALE'){
                            store.dispatch(showRightPanelDialogue(
                                <GiftCardActivation
                                    giftCard={giftCard}
                                />
                            ));
                        }else{
                            store.dispatch(showDialogue("Can't process gift card in return mode!", '',
                                'Ok', ()=>{}, undefined, undefined, false,
                                undefined,undefined, true));
                        }
                    }
                }else{
                    store.dispatch(showCallAssistance('unknownItem', '', true, function () {
                        setTimeout(
                            () => {
                                store.dispatch(cancelAddUnknownItem(action.payload.searchParam));
                            }, 100
                        );
                    }, true, (
                        <div className={'boldUppercaseText'} style={{fontSize:'20px', textAlign: 'center'}}>
                            <Translate id={'Unknown item'}/>
                        </div>
                    )));
                }
            }
            return true;
        }
        case ADD_PRODUCT: {
            if(Object.keys(state.rootReducer.discount).length !== 0){
                store.dispatch(removeDocumentDiscount());
            }
            let shouldCloseAdminView = true;
            let closeAdminView = function(){
                let waitingForLogin = () => {
                    if(state.rootReducer.administration === false){
                        return false;
                    }
                    return state.rootReducer.administration.user === false
                };
                if(shouldCloseAdminView && state.rootReducer.administration !== false && !waitingForLogin()){
                    store.dispatch(closeAdministration());
                }
            };
            let product = action.payload;
            console.log('MIDDLEWARE ACTION: ADD_PRODUCT', product, product.data.RFIDTag);

            if(state.rootReducer.returnBaseDocument !== false){
                store.dispatch(addErrorAlert('Can\'t add products to this document!'));
                store.dispatch(removeProduct(product));
                return;
            }

            if(state.rootReducer.transactionMode === 'RETURN' && typeof state.rootReducer.payback.referenceReceipt !== "undefined"){
                let paybackRemoveProduct = paybackOnReturnProductAdd(product, store.dispatch, state);
                if(paybackRemoveProduct){
                    store.dispatch(removeProduct(product));
                    return;
                }
            }

            let isReturnedProduct = product.returnReason !== undefined && product.returnReason.purpose === 'RETURN';
            if(state.rootReducer.transactionMode === 'RETURN' && !isReturnedProduct){
                if(product.isReturnable()){
                    product.amount *= -1;
                    product.rowNetTotal *= -1;
                    product.rowTotal *= -1;
                    product.rowVAT *= -1;

                    if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                        let returnReason = getReturnToStockReason(state.rootReducer.reasonCodes);
                        if(returnReason !== undefined){
                            store.dispatch(addProductReturnReasonID(product.lineNumber, returnReason));
                        }else{
                            store.dispatch(addErrorAlert('Please add return to stock reason code to Erply!'));
                        }
                    }else{
                        let reasonCodeOptions = getReasonCodesByPurpose(state.rootReducer.reasonCodes, 'RETURN').reduce((acc, reasonCode) => {
                            acc.push({
                                name: reasonCode.name,
                                value: reasonCode.reasonID
                            });
                            return acc;
                        }, []);

                        store.dispatch(showSelectDialogue('Return reason', reasonCodeOptions, (reasonID, reason) => {
                            let reasonCode = getReasonCodeByID(state.rootReducer.reasonCodes, reasonID);
                            if(reasonCode === undefined){
                                reasonCode = {reasonID, name: reason};
                            }
                            store.dispatch(addProductReturnReasonID(product.lineNumber, reasonCode));
                        }, e => {}, true, undefined, undefined, product.data.code + ' ' + product.data.name));
                    }
                }else{
                    store.dispatch(showDialogue('Item is not returnable!', '', 'Ok', function () {
                        store.dispatch(removeProduct(product));
                    }, undefined, undefined, false));
                    closeAdminView();
                    return true;
                }
            }

            if(product.isBlocked()){
                if(product.amount >= 0){
                    store.dispatch(showDialogue('Item is not authorized for sale!', product.data.code + ' ' + product.name, 'Ok', function () {
                        store.dispatch(removeProduct(product));
                    }, undefined, undefined, false));
                    closeAdminView();
                    return true;
                }else{
                    if(product.returnReasonID !== getDefectiveItemReturnReason(state.rootReducer.reasonCodes).reasonID){
                        store.dispatch(showDialogue('Item is not authorized for return!', product.data.code + ' ' + product.name, 'Ok', function () {
                            store.dispatch(removeProduct(product));
                        }, undefined, undefined, false));
                        closeAdminView();
                        return true;
                    }
                }
            }

            if(state.rootReducer.transactionMode === 'SALE' && product.isSecurityItem()){
                store.dispatch(securityItemAdded());
                if(state.rootReducer.customerAgeVerified === -1){
                    shouldCloseAdminView = false;
                    store.dispatch(showCallAssistance('securityItem', 'Security item', true, () => {
                        store.dispatch(removeProduct(product));
                    }, true));
                }else if(state.rootReducer.customerAgeVerified === 0){
                    store.dispatch(showDialogue('Item is not authorized for sale!', '', 'Ok', function () {
                        //store.dispatch(removeProduct(product));
                        let securityItems = getSecurityItems(state.rootReducer.productsInBasket);
                        for(let product of securityItems){
                            store.dispatch(removeProduct(product));
                        }
                        store.dispatch(removeSecurityItems());
                    }, undefined, undefined, false));
                    //closeAdminView();
                    return true;
                }
            }

            if(product.data.code === getUnknownItemCode() || product.data.code2 === getUnknownItemCode()){
                shouldCloseAdminView = false;
            }

            if(state.rootReducer.transactionMode === 'SALE' && product.isRiskyItem() && state.rootReducer.customer === false){
                let dialogue = (
                    <Traceability
                        enterDetails={() => {
                            store.dispatch(showRightPanelDialogue((
                                <EnterCustomerInfo
                                    onDone={(customerInfo) => {
                                        if(typeof customerInfo.email !== "undefined"){
                                            store.dispatch(setLoading(true));
                                            store.dispatch(findCustomerByEmail(customerInfo.email, () => {
                                                store.dispatch(setLoading(false));
                                            }, () => {
                                                store.dispatch(setLoading(false));
                                            }));
                                        }else if(typeof customerInfo.phone !== "undefined"){
                                            store.dispatch(setLoading(true));
                                            store.dispatch(findCustomerByPhone(customerInfo.phone, state.rootReducer.erplyConf.DKTCountryCode, () => {
                                                store.dispatch(setLoading(false));
                                            }, () => {
                                                store.dispatch(setLoading(false));
                                            }));
                                        }
                                        store.dispatch(setCustomer(customerInfo));
                                        store.dispatch(closeRightPanelDialogue());
                                    }}
                                    onBack={e => {
                                        store.dispatch(showRightPanelDialogue(dialogue));
                                    }}
                                />
                            )));
                        }}
                        askForHelp={() => {
                            //store.dispatch(closeRightPanelDialogue());
                            //store.dispatch(openAdministrationView('riskyItem', false));
                            store.dispatch(openLogonModal('', (data) => {
                                store.dispatch(showDialogue(
                                    "riskyItemMessageTitle",
                                    "riskyItemMessage",
                                    "accept",
                                    e => {
                                        store.dispatch(closeRightPanelDialogue());
                                    },
                                    e => {},
                                    "back",
                                    false
                                ));
                            }));
                        }}
                    />
                );
                store.dispatch(showRightPanelDialogue(dialogue));
            }

            if(product.hasContainer){
                store.dispatch(
                    findProducts(
                        {
                            productID: product.data.containerID
                        },
                        state.rootReducer.warehouse.warehouseID,
                        state.rootReducer.pos.pointOfSaleID,
                            result => {
                            let productDTO = new Product(result[0].name, result[0].priceListPrice, result[0].priceListPriceWithVat, 1, result[0]);
                            productDTO.containerForProductLine = product.lineNumber;
                            productDTO._isContainer = true;
                            return addProduct(productDTO);
                        }, result => {
                            return addErrorAlert('Could not add container product!');
                        }
                    )
                );
            }

            if(state.rootReducer.productsInBasket.length > transactionProductLineLimit){
                store.dispatch(showDialogue('Can\'t add more products to the basket!', '', 'Ok', function () {
                    store.dispatch(removeProduct(product));
                }, undefined, undefined, false, undefined, undefined, true));
            }

            if( window.AppConf.usedProductConfirm === true &&
                process.env.REACT_APP_ERPLY_MODE !== "1" &&
                state.rootReducer.transactionMode === 'SALE' &&
                (product.data.seriesName === 'DKT:SecondHand' || product.data.seriesName === 'DKT:BuyBack')
            ){
                store.dispatch(showDialogue('You are adding a used product into your basket',
                    `<p style="text-align: center">${product.data.name}</p><p>${translate("I declare that I have read the description of the used goods and I accept its condition")}</p>`,
                    'I accept', function(){}, function () {
                    store.dispatch(removeProduct(product));
                }, "decline", false, undefined, undefined, true));
            }

            if( typeof window.AppConf.importedItemInfo !== "undefined" &&
                process.env.REACT_APP_ERPLY_MODE !== "1" &&
                (product.data.seriesName === 'DKT:PassionBrand' || product.data.seriesName === 'DKT:WorkshopVoucher')
            ){
                let productUpdate = Object.assign( Object.create( Object.getPrototypeOf(product)), product);
                productUpdate.codeInfo = window.AppConf.importedItemInfo.productText;
                store.dispatch(updateProduct(productUpdate));

                if(typeof state.rootReducer.receiptExtraLines.find(line => {
                    if(typeof line !== "string"){
                        return false;
                    }
                    return line.indexOf(window.AppConf.importedItemInfo.receiptText) !== -1;
                }) === "undefined"){
                    store.dispatch(addReceiptExtraLine(window.AppConf.importedItemInfo.receiptText));
                }
            }

            closeAdminView();
            break;
        }
        case REMOVE_PRODUCT: {
            let product = action.payload;
            if(product.isRiskyItem()){
                if(store.getState().rootReducer.rightPanelDialogue !== false){
                    let currentRiskyProducts = getProductFromBasketByID(store.getState().rootReducer.productsInBasket, product.data.productID);
                    if(currentRiskyProducts.length === 0){
                        store.dispatch(closeRightPanelDialogue());
                    }
                }
            }
            /*if(product._isContainer){
                if(typeof product.containerForProduct !== "undefined"){
                    store.dispatch(removeProduct(product.containerForProduct));
                }
            }else if(product.hasContainer){
                let containerProduct = state.rootReducer.productsInBasket.find(el => el.containerForProduct === product);
                if(typeof containerProduct !== "undefined"){
                    store.dispatch(removeProduct(containerProduct));
                }
            }*/
            break;
        }
        case API_ERROR: {
            if(action.displayError !== false){
                store.dispatch(addErrorAlert(action.payload));
            }
            return true;
        }
        case ADD_ALERT: {
            if(action.payload.autoClose){
                if(alertTimeout !== false){
                    window.clearTimeout(alertTimeout);
                    alertTimeout = false;
                }
                alertTimeout = window.setTimeout(function () {
                    store.dispatch(removeAlert());
                }, 3000);
            }
            return true;
        }
        case SET_POS_DAY_OPENED: {
            sendStatusUpdate(APP_STATUS_FREE);
            return true;
        }
        case CHANGE_TRANSACTION_MODE: {
            if(paybackEnabled){
                paybackOnTransactionModeChange(store.dispatch, state);
            }
            return true;
        }
        case CHANGE_MODE: {
            if(state.rootReducer.mode === 'screenSaver'){
                sendStatusUpdate(APP_STATUS_FREE);
            }else if(state.rootReducer.mode === 'scan'){
                sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
                if(paybackEnabled){
                    store.dispatch(setPaybackInputDone(false));
                }
            }else if(action.payload.mode === 'payment'){
                addBagPrompt(store, state);
                if(paybackEnabled){
                    CTR.generatedReceiptNumber = {
                        fromNumber: false,
                        generated: false
                    };
                    paybackOnPaymentMode(store.dispatch, state);

                    if(state.rootReducer.payback.customer !== false){
                        let customer = state.rootReducer.customer === false ? new Customer() : Object.assign({}, state.rootReducer.customer);
                        customer.localRequirements = {
                            name: "PaybackAccount",
                            value: state.rootReducer.payback.customer
                        }
                        store.dispatch(setCustomer(customer));
                    }
                }
            }

            return true;
        }
        case OPEN_MODAL: {
            if(action.payload.type === 'logon'){
                sendStatusUpdate(APP_STATUS_ASKED_FOR_ASSISTANCE);
            }
            return true;
        }
        case CLOSE_MODAL: {
            if(state.rootReducer.mode === 'scan'){
                sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
            }
            return true;
        }
        case SECURITY_ITEM_ADDED: {
            sendStatusUpdate(APP_STATUS_SECURITY_ITEM);
            return true;
        }
        case SET_CUSTOMER_AGE_VERIFIED: {
            sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
            return true;
        }
        case CARD_PAYMENT_FAILED: {
            sendStatusUpdate(APP_STATUS_FAILED_PAYMENT);
            return true;
        }
        /*case CHANGE_MODE: {
            if(state.rootReducer.mode === 'payment' && state.rootReducer.transactionMode === 'RETURN'){
                store.dispatch(changeTransactionMode('SALE'));
            }
            return true;
        }*/
        case POS_DAY_OPEN_SUCCESS: {
            POSLogService.POSEvents.afterOpenDay(action.dayID, action.openedSum, action.openedUnixTime);
            return true;
        }
        case POS_DAY_CLOSE_SUCCESS: {
            POSLogService.POSEvents.afterCloseDay(state.rootReducer.posDayID);
            store.dispatch(paymentLogout());
            return true;
        }
        case ADD_PAYMENT: {
            if(state.payment.payments.length + 1 === transactionMOPLimit){
                store.dispatch(showDialogue('You can only add one more payment method!', '', 'Ok', function () {

                }, undefined, undefined, false, undefined, undefined, true));
            }
            return true;
        }
        case SCANNER_DETECTED: {
            if(state.rootReducer.mode === 'addGiftCard'){
                store.dispatch(setLastScannedGiftCard(action.payload));
            }
            return true;
        }
        case CLOSE_QCO: {
            timers.removeTimeout('userSessionUpdate');
            if(!window.AppConf?.decathlonSSO){
                setTimeout(() => {
                    store.dispatch({
                        type: 'INIT_POS'
                    });
                },100);
            }
            sendStatusUpdate(APP_STATUS_CLOSED);
            setDefaultAppConf();
            return true;
        }
        case SET_ERPLY_CONF: {
            if(window?.AppConf?.useZipCodeSurvey && typeof action.payload.DKTZipSurveyFrequency !== "undefined"){
                store.dispatch(setZipCodeSurveyCounter(1));
            }
            return true;
        }
        default: {}
    }

    if (
        (
            action.type === ADD_PRODUCT ||
            action.type === REMOVE_PRODUCT ||
            action.type === UPDATE_PRODUCT ||
            action.type === ADD_DOCUMENT_DISCOUNT ||
            action.type === SET_CUSTOMER ||
            action.type === ADD_MANUAL_PROMOTION_ID ||
            action.type === REMOVE_MANUAL_PROMOTION_ID ||
            (action.type === CHANGE_MODE && action.payload.mode === 'payment')
        )
        && state.rootReducer.productsInBasket.length > 0
    ) {
        applyPromotionsQueue.push(1);
        setTimeout(() => {
            applyPromotionsQueue.pop();
            if(applyPromotionsQueue.length === 0){
                store.dispatch(setLoading(true));
                store.dispatch(applyPromotions(state.rootReducer.productsInBasket, state.rootReducer.pos, state.rootReducer.customer, state.rootReducer.erplyConf.default_client_idDat, () => {
                    store.dispatch(setLoading(false));
                }, state.rootReducer.manualPromotionIDs));
            }
        }, 200);
    }

    if(action.type === UPDATE_PRODUCTS){
        if(state.rootReducer.mode === 'payment'){
            const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);
            store.dispatch(setTotalSum(totalSum));
        }
    }

    if(window.AppConf.useErplyInstaller === true){
        if(action.type === 'LOGIN'){
            EI.init();
        }else if(action.type === 'ADD_PRODUCT'){
            console.log('ED: ADD_PRODUCT', action);
            if(action.payload.data.unitName === 'kg' && action.payload.barcodeWeight !== true && action.payload.barcodePrice !== true){
                let putProductOnScaleDialogueID = uuid.v4();
                let weighingDialogueID = uuid.v4();
                let cancelLoop = false;

                let scaleLoop = (lastWeight=false) => {
                    if(cancelLoop){
                        return true;
                    }
                    ED.scaleWeight((response) => {
                        ED.log('ScaleWeight response', response);
                        if(response.statusMessage === 'success'){
                            let product = Object.assign( Object.create( Object.getPrototypeOf(action.payload)), action.payload);
                            let weight = parseFloat(response.data.weight.trim());
                            if(weight !== false && weight !== 0 && weight === lastWeight){
                                let packageWeight = getProductPackageWeight(product.data);
                                if(typeof packageWeight !== "undefined"){
                                    weight = (new Decimal(weight)).minus(new Decimal(packageWeight)).toDecimalPlaces(4).toNumber();
                                }
                                if(weight <= 0){
                                    store.dispatch(showDialogue(
                                        'Please use a proper container!',
                                        '',
                                        'Ok',
                                        () => {
                                            scaleLoop();
                                        },
                                        () => {
                                            cancelLoop = true;
                                            store.dispatch(removeProduct(action.payload));
                                        },
                                        undefined,
                                        true,
                                        weighingDialogueID,
                                        undefined,
                                        true
                                    ));
                                }else{
                                    product.setAmount(weight);
                                    store.dispatch(updateProduct(product));
                                    store.dispatch(closeDialogue(weighingDialogueID));
                                }
                            }else{
                                if(weight !== 0){
                                    store.dispatch(closeDialogue(putProductOnScaleDialogueID));
                                    store.dispatch(showDialogue(
                                        'Weighing',
                                        '',
                                        undefined,
                                        undefined,
                                        () => {
                                            cancelLoop = true;
                                            store.dispatch(removeProduct(action.payload));
                                        },
                                        undefined,
                                        true,
                                        weighingDialogueID,
                                        undefined,
                                        true
                                    ));
                                }
                                setTimeout(() => {
                                    scaleLoop(weight);
                                }, 300);
                            }
                        }else{
                            store.dispatch(addErrorAlert(response.statusMessage));
                            store.dispatch(removeProduct(action.payload));
                            store.dispatch(closeDialogue(weighingDialogueID));
                        }
                    });
                };


                store.dispatch(showDialogue(
                    'Put product on the scale',
                    '',
                    undefined,
                    undefined,
                    () => {
                        cancelLoop = true;
                        store.dispatch(removeProduct(action.payload));
                    },
                    undefined,
                    true,
                    putProductOnScaleDialogueID,
                    undefined,
                    true
                ));
                scaleLoop();
            }
        }
    }

    if(action.type === ADD_TRANSLATIONS){
        store.dispatch(addTranslationForLanguage(action.translations, action.language));
    }

    if(action.type === "SALE_SAVED"){
        store.dispatch(showDialogue('Sale saved', '', 'Ok', () => {
            store.dispatch(closeAdministration());
            store.dispatch(initTransaction());
        }));
    }

    if(action.type === SET_CUSTOMER){
        console.log("customer set action", action.payload);
        if(process.env.REACT_APP_ERPLY_MODE !== "1"){
            const customerIsEmployee = (customer) => {
                if(typeof customer.roles === "undefined"){
                    return false;
                }
                return typeof customer.roles.find(role => role.id === DECATHLON_EMPLOYEE_ROLE) !== "undefined";
            }
            if(customerIsEmployee(action.payload)){
                const campaignName = 'Employee discount';
                const campaign = state.rootReducer.campaignsByName?.[campaignName];
                if(typeof campaign === "undefined"){
                    store.dispatch(getCampaignsByName(campaignName, (data) => {
                        if(data.length > 0){
                            store.dispatch(addManualPromotionID(data[0].campaignID))
                            return addCampaignByName(data[0]);
                        }
                        return addCampaignByName({
                            name: campaignName,
                            campaignID: 0
                        });
                    }, () => {
                        console.log('Campaigns not found');
                    }));
                }else{
                    if(campaign.campaignID !== 0){
                        store.dispatch(addManualPromotionID(campaign.campaignID));
                    }
                }
            }
        }
    }

    if(action.type === REMOVE_CUSTOMER){
        const manualPromotionIDs = state.rootReducer.manualPromotionIDs;
        if(manualPromotionIDs.length > 0){
            const employeeDiscount = getCampaignByName(state, 'Employee discount');
            if(employeeDiscount !== false){
                if(manualPromotionIDs.indexOf(employeeDiscount.campaignID) !== -1){
                    store.dispatch(removeManualPromotionID(employeeDiscount.campaignID));
                }
            }
        }
    }
};


const addBagPrompt = (store, state) => {
    if(window.AppConf.addBagPrompt && window.AppConf.bagItemCode){
        const bagInBasket = getProductByCode(state.rootReducer.productsInBasket, window.AppConf.bagItemCode);
        console.log({bagInBasket});
        if(typeof bagInBasket !== "undefined") return true;

        store.dispatch(showDialogue('Do you need a bag?','', 'Yes', () => {
            store.dispatch(showInputDialogue('How many bags do you need?', '', '', (bagAmount) => {
                store.dispatch(setLoading(true));
                store.dispatch(findProduct(window.AppConf.bagItemCode, state.rootReducer.pos.warehouseID, state.rootReducer.pos.pointOfSaleID, false, (product) => {
                    product.amount = parseInt(bagAmount);
                    return addProduct(product);
                }, undefined, undefined, () => {
                    store.dispatch(setLoading(false));
                }));
            }, () => {}, false, true));
        }, () => {

        },'No', false, undefined, undefined, true));
    }
};

export default actionsMiddleware;