import {ED} from "./integrations/externalDevices/main";
import {APP_URL} from "../appConstants";

export const EI = {
    url: 'https://localhost.erply.com:7777/api/v1/',
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('EI: ');
        console.log.apply(this, newArgs);
    },
    init: () => {
        let url = EI.url + 'info';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "proxy?u=" + btoa(url);
        }
        fetch(url).then(response => {
            return response.json();
        }).then((response) => {
            EI.log('api response', response);

            response.data.integrations.map(integration => {
                if(integration.status === 'StatusRunning'){
                    EI.getIntegration(integration, (config) => {
                        if(integration.name === 'extdev-microservice'){
                            ED.init(config.Port);
                        }
                    }, () => {

                    });
                }
            });

        }).catch((response) => {
            EI.log('api request failed', response);
        });
    },
    getIntegration: (integration, onSuccess, onFail) => {
        let url = EI.url + 'config/get';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "postProxy?u=" + btoa(url);
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                entity: integration.name,
                version: integration.version
            })
        }).then((response) => response.json()).then((response) => {
            let config = JSON.parse(response.data.config);
            onSuccess(config);
        }).catch(response => {onFail(response)});
    }
};