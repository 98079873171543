import React, {useState} from 'react';
import ImageBox from "../buttons/ImageBox";
import liteModeImg from './images/liteMode.png';
import fullModeImg from './images/fullMode.png';
import cashierModeImg from './images/cashierMode.png';
import {Translate} from "react-localize-redux";
import Button from "../buttons/Button";

export default function ({onLiteMode, onFullMode, onCashierMode}) {
    const [view, setView] = useState('mode');

    const unifiedVersion = window?.AppConf?.unifiedVersion ?? false;

    if(unifiedVersion && view === 'mode'){
        return (<div style={{
            textAlign: 'center'
        }}>
            <div style={{
                marginTop: '100px',
                display: 'inline-block'
            }}>
                <ImageBox
                    image={cashierModeImg}
                    title={<Translate id={'cashier mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '50px'
                    }}
                    onClick={onCashierMode}
                />
                <ImageBox
                    image={fullModeImg}
                    title={<Translate id={'sco mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    containerStyle={{
                        marginLeft: '100px'
                    }}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '75px'
                    }}
                    onClick={() => {
                        setView('sco');
                    }}
                />
            </div>
        </div>);
    }

    let backButton;
    if(unifiedVersion){
        backButton = <div onClick={() => {
            setView('mode');
        }} style={{marginTop: '10px', marginBottom: '10px'}}>
            <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                    arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
        </div>
    }


    return (
        <div style={{
            textAlign: 'center'
        }}>
            <div style={{
                marginTop: '100px',
                display: 'inline-block'
            }}>
                <ImageBox
                    image={liteModeImg}
                    title={<Translate id={'lite mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '50px'
                    }}
                    onClick={onLiteMode}
                />
                <ImageBox
                    image={fullModeImg}
                    title={<Translate id={'full mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    containerStyle={{
                        marginLeft: '100px'
                    }}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '75px'
                    }}
                    onClick={onFullMode}
                />
            </div>
            {backButton}
        </div>
    );
}