import React, {Component} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import deleteIcon from "./images/trash.png"
import giftCardImg from "./images/giftCard.png"

class GiftCardLine extends Component{
    render() {
        return (
            <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "capitalize"}}><Translate id={"giftCard"}/></span><br/>
                    <span className={"unitPrice"}><Translate id={"Reference"}/> : {this.props.payment.giftCard.cardNumber}</span>
                </td>
                <td className={"productLinePriceColumn"}>{parseFloat(this.props.payment.sum).toFixed(2)} {this.props.currency}</td>
                <td>
                    <img src={giftCardImg} alt={'giftCardImg'} style={{height:"50%"}}/>
                </td>
                <td onClick={e => this.props.removeGiftCardPayment(this.props.payment)} style={{"width": "20px"}}>
                    <img src={deleteIcon} alt={'trash'} height={22}/>
                </td>
            </tr>
        );
    }
}

export default withLocalize(GiftCardLine)