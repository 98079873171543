import React, { Component } from 'react';
import helpImg from './images/Chat.png';
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {

    }
};

class Help extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggleSelect(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div onClick={this.toggleSelect.bind(this)} className={"statusBarItemLeft"}>
                <img src={helpImg} alt={"Help"}/>
                <span style={{
                    textTransform: 'uppercase',
                    margin: '4px',
                    float: 'right'
                }}><Translate id={"Help"}/></span>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withLocalize(Help));
