import React, { Component } from 'react';
import './App.css';
import './fonts/roboto.css';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {LocalizeProvider, localizeReducer, initialize} from 'react-localize-redux';
import {renderToStaticMarkup} from "react-dom/server";
import {Provider} from "react-redux";
import pluginMiddleware from "./redux/plugin/middleware";
import rootReducer from "./redux/reducers/rootReducer";
import paymentReducer from "./redux/payment/reducer";
import apiMiddleware from "./redux/middleware/api";
import epsiMiddleware from "./redux/middleware/epsi";
import {SET_INTEGRATION} from "./redux/payment/actionTypes";
import paymentMiddleware from "./redux/payment/middleware";
import rfidMiddleware from "./redux/rfid/middleware";
import actionsMiddleware from "./redux/middleware/actions";
import customerAPIMiddleware from "./redux/middleware/customerAPI";
import MainLocalized from "./Main"
import ManagerAppProvider from "./communication/ManagerAppProvider";
import DynamicStyles from "./DynamicStyles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createMiddleware } from 'redux-action-listener-hook';
import thunk from "redux-thunk";
import {addTranslations} from "./login/configurationLoader";

class App extends Component {
    constructor(props) {
        super(props);

        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        const store = createStore(combineReducers({
            localize: localizeReducer,
            rootReducer: rootReducer,
            payment: paymentReducer
        }), composeEnhancers(
            applyMiddleware(pluginMiddleware, apiMiddleware, actionsMiddleware, epsiMiddleware, paymentMiddleware, rfidMiddleware, customerAPIMiddleware, createMiddleware(), thunk)
        ));

        let initialLanguages = window?.AppConf?.languages ?? ["en"];
        store.dispatch(initialize({
            languages: initialLanguages,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: false,
                onMissingTranslation: ({ translationId, languageCode }) => {return translationId;}
            }
        }));

        addTranslations(store.dispatch, initialLanguages);

        const paymentIntegration = window.AppConf.EFT;
        store.dispatch({
            type: SET_INTEGRATION,
            payload: paymentIntegration
        });

        this.state = {store};
    }

    render() {
        return (
            <LocalizeProvider store={ this.state.store }>
                <Provider store={this.state.store}>
                    <ManagerAppProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DynamicStyles>
                                <MainLocalized/>
                            </DynamicStyles>
                        </MuiPickersUtilsProvider>
                    </ManagerAppProvider>
                </Provider>
            </LocalizeProvider>
    );
  }
}

export default App;