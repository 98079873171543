import React, { useState, useEffect } from 'react';
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import dialogueImg from "../images/thanks.png";
import {Translate} from "react-localize-redux";
import Button from "../../buttons/Button";
import {validateEmail} from "../../redux/dto/customer";

export default function ({onAccept, onCancel}) {
    let [email, setEmail] = useState('');

    let [validationError, setValidationError] = useState(false);
    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <span
                style={{
                    marginLeft: '30%'
                }}
                className={'errorMessage'}
            >
                {validationError}
            </span>
        );
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                marginTop: '50px',
                marginBottom: '50px',
                alignSelf: 'center'
            }}>
                <img src={dialogueImg} alt={'dialogue'} style={{
                    float: 'left'
                }}/>
                <h1 style={{
                    float: 'left',
                    marginLeft: '40px'
                }} className={'capitalizeFirst'}><Translate id={'please type your email'}/></h1>
            </div>
            <InputWithKeyboard
                placeholder={'E-MAIL'}
                onInputChange={(value) => {
                    setEmail(value);
                }}
                onDone={(value) => {}}
                style={{
                    width: '40%',
                    alignSelf: 'center'
                }}
            />
            {validationErrorDisplay}
            <div style={{
                marginTop: '70px',
                marginRight: '40px',
                width: '40%',
                alignSelf: 'center'
            }}>
                <Button
                    type="button"
                    className={"largeButton grayButton"}
                    name={<Translate id={"cancel"}/>}
                    arrow="right"
                    flencheClass={"flencheSmallRightBottom grayFlenche"}
                    onClick={e => {
                        onCancel();
                    }}
                />
                <Button
                    type="button"
                    className={"largeButton blueButton"}
                    name={<Translate id={"accept"}/>}
                    flencheClass={"flencheRight transparentFlenche"}
                    arrow="right"
                    style={{
                        float: 'right'
                    }}
                    onClick={e => {
                        if(validateEmail(email)){
                            onAccept(email);
                        }else{
                            setValidationError(<Translate id={'emailValidationError'}/>)
                        }
                    }}
                />
            </div>
        </div>
    );
}