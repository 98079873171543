import React, { Component } from 'react';
import '../languageSelect.css'
import downArrow from './downArrow.png'
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from "react-redux";
import ClickAwayListener from "react-advanced-click-away";
import FlagImage from "./FlagImage";

const mapStateToProps = state => {
    let locale = state.localize.languages.find(lang => lang.active).code;

    return {
        locale,
        languages: state.localize.languages.reduce((acc, el) => {
            acc.push(el.code);
            return acc;
        }, [])
    }
};

class LanguageSelect extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggleSelect(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    changeLocale(e, locale){
        this.props.setActiveLanguage(locale);
    }

    render() {
        let languages = this.props.languages.map((languageCode) => {
            return(
                <li className={"languageItem"} key={languageCode} onClick={e => this.changeLocale.bind(this)(e, languageCode)}>
                    <FlagImage locale={languageCode} style={{width: "35px"}}/>
                    <span>
                        <Translate id={languageCode}/>
                    </span>
                </li>
            );
        });

        return (
            <div className={"languageSelectContainer"} onClick={this.toggleSelect.bind(this)}>
                <FlagImage locale={this.props.locale} style={{float:"left", width: "35px"}}/>
                <img src={downArrow} alt={"downArrow"} style={{float:"left", margin: "5px"}}/>
                {this.state.isOpen &&
                    <ClickAwayListener onClickAway={this.toggleSelect.bind(this)}>
                        <div className={"languageListContainer"}>
                            <div className={"triangle-with-shadow"}/>
                            <div className={""}>
                               <ul>{languages}</ul>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(withLocalize(LanguageSelect));
