import React, {Component} from 'react';
import "./main.css"
import {connect} from "react-redux";
import Modal from "../modal/Modal";
import {closeModal} from "../redux/actions";

const mapStateToProps = state => {
    return {
        modals: state.rootReducer.modals
    }
};

const mapDispatchToProps = dispatch => {
    return {
        close: (id) => dispatch(closeModal(id))
    }
};

class ModalContainer extends Component{
    render() {
        let close = this.props.close;
        const modals = this.props.modals.map((modal, index) => {
            return <Modal
                key={ index }
                id={ modal.id }
                className={ modal.className }
                content={ modal.content }
                onClose={ function () {
                    close(modal.id);
                    modal.onClose();
                }}
                canClose={ modal.canClose !== undefined ? modal.canClose : true }
            />
        });

        return (
            <div>
                { modals }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);