import React, { useState, useEffect, useRef } from 'react';

export default function ({timeInSeconds, onDone, style}) {
    let [counter, setCounter] = useState(timeInSeconds);
    const mountedRef = useRef(null);

    useEffect(() => {
        if(counter > 0){
            mountedRef.current = setTimeout(() => {
                setCounter(counter - 1);
            }, 1000);
        }else{
            if(mountedRef.current){
                onDone();
            }
        }

        return () => {
            if(mountedRef.current !== null){
                clearTimeout(mountedRef.current);
            }
        }
    });

    return (
        <div style={style}>
            {counter}
        </div>
    );
}