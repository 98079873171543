import $ from 'jquery';
import JsBarcode from 'jsbarcode';
import htmlReceiptTemplate from './receiptTemplate';
import {calendar} from './calendar';

// const JsBarcode = require('jsbarcode');

const createHTML = function (document, payments, change) {
    const i = {
        previousSale: !0,
        warehouseName: document.warehouseName,
        warehouseID: document.warehouseID,
        posName: document.pointOfSaleName,
        invoiceNo: document.number,
        type: document.type,
        customerName: document.clientName,
        customerCardNumber: document.clientCardNumber,
        customerID: document.clientID,
        rewardPoints: -1,
        employeeName: document.employeeName,
        notes: document.notes,
        items: document.rows,
        payments: payments,
        dateTime: document.date + " " + document.time,
        change: change,
        netTotal: document.netTotal,
        total: document.total
    };

};

const updateTranslations = function (e) {
    let t = $(e).find("[trans]");

    if(t.length !== 0){
        $.each(t, function (e, t) {
            var n = t.tagName.toLowerCase(),
                r = "input" == n || "textarea" == n ? t.placeholder : t.innerHTML;
            t.getAttribute("data-default-translation") && t.getAttribute("data-default-translation") != r && (r = t.getAttribute("data-default-translation"));
            "input" == n || "textarea" == n ? t.setAttribute("placeholder", __t(r)) : t.innerHTML = __t(r);
            t.getAttribute("data-default-translation") || t.setAttribute("data-default-translation", r);
        });
    }
};

const __t = function (string) {
    console.log('ADD TRANSLATION: ', string);
    return string;
};

const round = function (e, t) {
    return parseFloat(e).toFixed(t);
};

const isDecimalsDisabledWithCurrency = function(currency) {
    return "VND" == currency
};

const nl2br = function (e, t) {
    return (e + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + (t || void 0 === t ? "<br />" : "<br>") + "$2")
};

const numberWithThousendSeparators = function (e, t) {
    return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, t)
};

const stripHTML = function (e) {
    return $('<p>' + e + '</p>').text();
};

const displayPrice = function (param1, param2, param3 = 2, config, currentLanguage) {
    var r = 0;
    if(isDecimalsDisabledWithCurrency(config.currency)){
        param3 = 0;
    }
    if ("object" == typeof param1) {
        let price = config.useNetPrices() ? void 0 !== param1.finalPrice ? param1.finalPrice : param1.priceListPrice : void 0 !== param1.finalPriceWithVAT ? param1.finalPriceWithVAT : param1.priceListPriceWithVat;
        var a = {inputPrice: price, product: param1};
        "object" == typeof price && (price = a.inputPrice);
        r = parseFloat(price).toFixed(param3);
    } else {
        r = parseFloat(param1).toFixed(param3);
    }
    if (isNaN(r) && (r = parseFloat(0).toFixed(param3)), param2)return r;
    switch (config.currency) {
        case"VND":
            r = numberWithThousendSeparators(r, ".");
            break;
        case"CAD":
            r = "fr" === currentLanguage ? r + "" + stripHTML(config.currencySign) : stripHTML(config.currencySign) + "" + r;
            break;
        case"USD":
        case"AUD":
        case"GBP":
            r = stripHTML(config.currencySign) + "" + r;
            break;
        default:
            r = r + "" + stripHTML(config.currencySign);
    }
    return r
};

String.prototype.tprintf = function () {
    for (var e, t = this, n = /[{](.*?)[}]/gi, r = 0; null != (e = n.exec(this));){
        e.index === n.lastIndex && n.lastIndex++;
        t = t.replace(e[0], arguments[r]);
        r++;
    }
    return t;
};

export const prepareHTMLReceipt = function (document, currencySign, company, warehouse, pos, erplyConf, currentLanguage) {
    const logo = 'base64logo';

    const config = {
        hour_12_countries: ["US", "AU", "CN", "CA"],
        get: function (key) {
            return void 0 !== erplyConf[key] ? erplyConf[key] : void 0
        },
        getBoolean: function (e,t) {
            return void 0 === config.get(e) && void 0 !== t ? t : 1 === parseInt(config.get(e));
        },
        getDateFormatShort: function () {
            return config.get("dateformat").replace("m", "MM").replace("d", "dd").replace("Y", "yy")
        },
        getDateFormatLong: function () {
            return config.getDateFormatShort().replace("yy", "yyyy")
        },
        getDateTimeFormatLong: function () {
            return -1 !== $.inArray(config.get("country"), config.hour_12_countries) ? config.getDateFormatLong() + " hh:mm TT" : config.getDateFormatLong() + " HH:mm";
        },
        currencySign: currencySign,
        useNetPrices: function () {
            return !config.getBoolean("locale_uses_price_with_tax")
        }
    };
    config.currency = config.get('default_currency');

    const printGiftReceipt = false;
    const printEmployeeToReceipt = false;
    const requiresAllInOneReceiptPrintout = false;

    const iframeWindow = $('<iframe id="iframe-print-html" style="position:absolute;visibility:hidden;"/>').appendTo("body").get(0).contentWindow;
    iframeWindow.document.write(htmlReceiptTemplate);
    var iframeContents = $("#iframe-print-html").contents();

    updateTranslations(iframeContents.find("html"));

    var barcode = iframeContents.find("#barcode");
    if (logo) {
        iframeContents.find("#company-logo").attr("src", logo)
    }
    iframeContents.find("#company-location-info").html(company.name + " - " + warehouse.name + " " + pos.name);
    let warehouseAddress = warehouse.address && warehouse.address.length > 0 ? warehouse.address : company.address;
    warehouseAddress && iframeContents.find("#company-address").html(warehouseAddress);
    var warehousePhone = warehouse.phone && $.trim(warehouse.phone).length > 0 ? $.trim(warehouse.phone) : $.trim(company.phone);
    if("" != warehousePhone){
        iframeContents.find("#company-extra-info").append(__t("Phone: {phone}").tprintf('<span class="default">' + warehousePhone + "</span>") + "<br>");
    }
    if(company.code && "" != company.code){
        iframeContents.find("#company-extra-info").append(__t("Registry code: {registry_code}").tprintf('<span class="default">' + company.code + "</span>") + "<br>");
    }
    if(company.VAT && "" != company.VAT){
        iframeContents.find("#company-extra-info").append(__t("VAT number: {vat_number}").tprintf('<span class="default">' + company.VAT + "</span>") + "<br>");
    }
    let m = document.documentType === "CREDITINVOICE" ? "Credit Invoice" : printGiftReceipt ? "Gift Receipt" : "Receipt number";
    iframeContents.find("#receipt-label").text(__t(m) + " " + document.invoiceNo);
    iframeContents.find("title").html(__t("Receipt number {receipt_number} - Erply").tprintf(document.invoiceNo));

    var receiptDate = new Date();
    if (document.dateTime) {
        receiptDate = calendar.parseDate(document.dateTime, !0)
    }
    iframeContents.find("#receipt-date").text(calendar.formatDate(receiptDate, config.getDateTimeFormatLong()));
    if(!config.getBoolean("hide_customer_person_name_on_receipt")){
        let customer = config.getBoolean("show_customercard_code_on_receipt") ?
            __t("Customer: {customer} ({customer_code})").tprintf(document.customerName, document.customerCardNumber) :
            __t("Customer: {customer}").tprintf(document.customerName);

        if("string" == typeof document.customerCardNumber && 0 == document.customerCardNumber.length ){
            customer = customer.slice(0, -2);
        }
        iframeContents.find("#customer-name").text(customer);
    }

    if (printEmployeeToReceipt) {
        let employeeName = document.employeeName;
        if ("AU" == config.get("country")) {
            let nameParts = employeeName.split(" ");
            nameParts[nameParts.length - 1] = nameParts[nameParts.length - 1].substring(0, 1);
            employeeName = nameParts.join(" ");
        }
        iframeContents.find("#seller-name").text(__t("Seller: {employee_name}").tprintf(employeeName))
    }

    iframeContents.find("#total-label").html(__t("Total {currency}:").tprintf(config.currencySign));
    var total = round(document.total, 2);
    iframeContents.find("#total-sum").text(total);
    iframeContents.find("#sub-total").text(total);
    iframeContents.find("#net-total").text(round(document.netTotal, 2));

    var b = [],
        S = [];
    $.each(document.items, function (e, t) {
        var n = displayPrice(t, !0, undefined, config, currentLanguage),
            r = displayPrice(config.useNetPrices() ? t.rowNetTotal : t.rowTotal, !0, undefined, config, currentLanguage),
            o = '<tr class="cashReceiptRow">';
        if(0 == config.get("code_on_receipt") || "" == config.get("code_on_receipt")){
            o += '<td valign="top">' + t.name + "</td>";
        }else{
            o += '<td valign="top">' + t.code + "<br>" + t.name + "</td>";
        }

        if(config.getBoolean("show_original_price_on_receipt") && t.originalPrice !== t.finalPrice){
            o += '<td colspan="4" valign="top">' + __t("Regular price: ") + t.originalPrice + "</td>";
            o += "</tr>";
            o += '<tr class="cashReceiptRow">';
            o += '<td valign="top"></td>';
        }
        o += '<td valign="top" align="right" style="padding-left:5px;padding-right:3px;" nowrap>' + t.amount + "</td>";
        o += t.discount > 0 ? '<td valign="top" align="right" style="padding-left:2px;" nowrap><span class="small"> -' +
                t.discount + "%</span></td>" : '<td valign="top" align="right" style="padding-left:2px;" nowrap></td>';
        o += '<td valign="top" align="right" style="padding-left:5px;" nowrap>' + n + "</td>";
        o += '<td valign="top" align="right" style="padding-left:5px;" nowrap>' + r + "</td>";
        o += "</tr>";
        b.push(o);
        var a = t.rowVAT;
        void 0 === S[t.vatRate] ? S[t.vatRate] = a : S[t.vatRate] = S[t.vatRate] + a
    });
    var P = iframeContents.find("#row-before-tax-list");
    if (0 != document.rounding) {
        var T = '<tr><td colspan="2" style="height: 5px;"></td></tr><tr><td align="right" style="padding-left:5px;padding-right:5px;">' +
            __t("Rounding:") + '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' + document.rounding + "</td></tr>";
        P.after(T)
    }
    if (config.getBoolean("show_total_discount_on_receipt") && document.receiptTotalDiscount > 0) {
        var T = '<tr><td colspan="2" style="height: 5px;"></td></tr><tr><td align="right" style="padding-left:5px;padding-right:5px;">' +
            __t("Discount:") + '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' + parseFloat(document.receiptTotalDiscount).toFixed(2) + "</td></tr>";
        P.after(T)
    }
    var _ = 0;
    for (var i in S) {
        if (S.hasOwnProperty(i)) {
            let O = i > 0 ? i + "%" : __t("Tax free");
            let T = '<tr><td align="right" style="padding-left:5px;padding-right:5px;">' +
                    __t("Tax ({vat_value}):").tprintf(O) + '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' +
                    round(S[i], 2) + "</td></tr>";
            _ > 0 && (T += '<tr><td colspan="2" style="height: 5px;"></td></tr>');
            P.after(T);
            _++
        }
    }
    var w = iframeContents.find("#row-before-payments-list");
    if (0 != document.change) {
        var T = '<tr><td colspan="2" style="height: 5px;"></td></tr>';
        T += '<tr><td align="right" style="padding-left:5px;padding-right:5px;">' + __t("Change:") +
            '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' + round(document.change, 2) + "</td></tr>";
        w.after(T)
    }
    var C = [], x = !1;
    $.isArray(document.payments) && $.each(document.payments, function (t, n) {
        console.log('RECEIPT: payments: ', t, n);
        if ("CARD" === n.type) {
            var r = {};
            r.amount = n.sum;
            void 0 != n.cardType && (r.cardType = n.cardType);
            void 0 != n.cardNumber && (r.cardNumber = n.cardNumber);
            void 0 != n.cardHolder && (r.cardHolder = n.cardHolder);
            void 0 != n.refNo && (r.refNo = n.refNo);
            void 0 != n.authCode && (r.authCode = n.authCode);
            void 0 != n.expirationDate && (r.expirationDate = n.expirationDate);
            void 0 != n.aid && (r.aid = n.aid);
            void 0 != n.applicationLabel && (r.applicationLabel = n.applicationLabel);
            void 0 != n.pinStatement && (r.pinStatement = n.pinStatement);
            void 0 != n.cryptogramType && (r.cryptogramType = n.cryptogramType);
            void 0 != n.cryptogram && (r.cryptogram = n.cryptogram);
            void 0 != n.entryMode && (r.entryMode = n.entryMode);
            void 0 != n.transactionType && (r.transactionType = n.transactionType);
            void 0 != n.signatureNotRequired && (x = !0);
            r.receipts = n.receipts || {};
            console.log('RECEIPT: payment: ',t , r);
            C.push(r)
        }
        n.caption = n.caption.split(" ")[0];
        var o = "<tr>";
        o += '<td align="right" style="padding-left:5px;padding-right:5px;">' + __t("Paid ({payment_caption}):").tprintf(n.caption) +
            "</td>";
        o += '<td align="right" style="padding-left:5px;" nowrap="nowrap">' +
            round(Math.abs(parseFloat(n.sum).toFixed(2)), 2) + "</td>";
        o += "</tr>";
        t > 0 && 0 == document.change && (o += '<tr><td colspan="2" style="height: 5px;"></td></tr>');
        w.after(o);
    });
    console.log('RECEIPT: payments: card', C);
    var M = iframeContents.find("#row-after-payments-list");
    if (0 != document.customerCredit) {
        var T = '<tr><td align="right" style="padding-left:5px;padding-right:5px;">' + __t("Your account balance:") +
            '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' + displayPrice(document.customerCredit, undefined, undefined, config, currentLanguage) + "</td></tr>";
        M.after(T)
    }
    if (document.rewardPoints > 0 && config.getBoolean("print_customer_reward_points_on_receipt")) {
        var T = '<tr><td align="right" style="padding-left:5px;padding-right:5px;">' + __t("Your reward points total:") +
            '</td><td align="right" style="padding-left:5px;" nowrap="nowrap">' + displayPrice(document.rewardPoints, undefined, undefined, config, currentLanguage) + "</td></tr>";
        M.after(T)
    }
    if (0 != C.length) {
        var E = iframeContents.find("#card-payments-rows"),
            T = '<tr><td colspan="2" style="padding-top:5px;"><br>&nbsp;</td></tr>';
        $.each(C, function (t, n) {
            var r = n.receipts || {};
            if (requiresAllInOneReceiptPrintout && (r.customer || r.merchant)) {
                var o = "";
                if (document.printMerchantCopy) {
                    var a = r.merchant || {};
                    o = a.receipt || "";
                    x = !1 === a.signatureRequired;
                    document.addSignatureSection = !0 === a.signatureRequired
                } else o = (r.customer || {}).receipt || "";
                o.length > 0 && E.after("<pre>" + o + "</pre>")
            } else {
                if(void 0 != n.transactionType){
                    T += '<tr><td style="padding-top:3px;">' + n.transactionType + ':</td><td style="padding-top:3px;">' +
                        round(Math.abs(parseFloat(n.amount).toFixed(2)), 2) + "</td></tr>";
                }else{
                    T += '<tr><td style="padding-top:3px;">' + __t("Amount Paid") +
                        ':</td><td style="padding-top:3px;">' + round(Math.abs(parseFloat(n.amount).toFixed(2)), 2) +
                        "</td></tr>";
                }
                void 0 != n.cardType && (T += '<tr><td style="padding-top:3px;">' + __t("Card Type") + ':</td><td style="padding-top:3px;">' +
                    n.cardType + "</td></tr>");
                void 0 != n.cardNumber && (T += '<tr><td style="padding-top:3px;">' + __t("Card") +
                    ' #:</td><td style="padding-top:3px;">**** **** **** ' + n.cardNumber + "</td></tr>");
                var i = "XX/XX";
                void 0 != n.expirationDate && (i = n.expirationDate);
                T += '<tr><td style="padding-top:3px;">' + __t("Expires") +
                    ':</td><td style="padding-top:3px;">' + i + "</td></tr>";
                void 0 != n.cardHolder && (T += '<tr><td style="padding-top:3px;">' +
                    __t("Cardholder Name") + ':</td><td style="padding-top:3px;">' + n.cardHolder + "</td></tr>");
                void 0 != n.refNo && (T += '<tr><td style="padding-top:3px;">' + __t("Ref") + ' #:</td><td style="padding-top:3px;">' +
                    n.refNo + "</td></tr>");
                void 0 != n.authCode && (T += '<tr><td style="padding-top:3px;">' + __t("Approval Code") +
                    ' #:</td><td style="padding-top:3px;">' + n.authCode + "</td></tr>");
                void 0 != n.aid && (T += '<tr><td style="padding-top:3px;">' +
                    __t("AID") + ':</td><td style="padding-top:3px;">' + n.aid + "</td></tr>");
                void 0 != n.applicationLabel && (T += '<tr><td style="padding-top:3px;">' +
                    __t("Application Label") + ':</td><td style="padding-top:3px;">' + n.applicationLabel + "</td></tr>");
                void 0 != n.pinStatement && (T += '<tr><td style="padding-top:3px;">' + __t("PIN Statement") +
                    ':</td><td style="padding-top:3px;">' + n.pinStatement + "</td></tr>");
                void 0 != n.cryptogramType && (T += '<tr><td style="padding-top:3px;">' + __t("Cryptogram Type") +
                    ':</td><td style="padding-top:3px;">' + n.cryptogramType + "</td></tr>");
                void 0 != n.cryptogram && (T += '<tr><td style="padding-top:3px;">' + __t("Cryptogram") + ':</td><td style="padding-top:3px;">' +
                    n.cryptogram + "</td></tr>");
                void 0 != n.entryMode && (T += '<tr><td style="padding-top:3px;">' +
                    __t("Entry") + ':</td><td style="padding-top:3px;">' + n.entryMode + "</td></tr>");
                T += '<tr><td colspan="2"><br>&nbsp;</td></tr>'
            }
        });
        E.html(T);
    }
    let D;
    if (void 0 != document.addSignatureSection && 1 == document.addSignatureSection) {
        D = iframeContents.find("#after-card-payments-rows");
        T = '<tr><td colspan="2" style="height: 50px;padding-top:50px;"></td></tr>';
        T += '<tr><td colspan="2"><center>........................................................</center></td></tr>';
        T += '<tr><td colspan="2"><center>' + __t("Customer Signature") + "</center></td></tr>";
        D.html(T);
        iframeContents.find("#receipt-footer-copy").html(__t("MERCHANT COPY"))
    }
    if (x) {
        D = iframeContents.find("#after-card-payments-rows");
        T = '<tr><td colspan="2" style="padding-top:20px;"><center>' + __t("SIGNATURE NOT REQUIRED") + "</center></td></tr>";
        D.html(T);
    }
    iframeContents.find("#receipt-rows-list").append(b);
    //document.notes.length > 0 && iframeContents.find("#receipt-notes").append(__t("Note: {receipt_notes}").tprintf(document.notes));
    barcode.css({
        "max-width": 200,
        height: 22
    });
    JsBarcode(barcode[0], document.invoiceNo , {
        width: 1,
        height: 22,
        quite: 10,
        format: "CODE39",
        displayValue: !1,
        fontOptions: "",
        font: "monospace",
        textAlign: "center",
        fontSize: 12,
        backgroundColor: "",
        lineColor: "#000"
    });

    var I = iframeContents.find("#receipt-footer");
    if(config.get("receipt_footer")){
        I.append(nl2br(config.get("receipt_footer")) + "<br>");
    }else{
        I.append(__t("Thank you for the purchase!") + "<br>");
        I.append(__t("Welcome back!") + "<br><br>");
    }
    iframeContents.find('[src=""]').remove();
    var k = document.streamline;
    if(k){
        iframeContents.find(".receipt-content").prepend('<div style="font-size: 5%; color: white !important; -webkit-print-color-adjust: exact; width:0; height:0;">' + k + "</div>");
    }

    var A = new XMLSerializer(),
        R = A.serializeToString(iframeWindow.document);

    $("#iframe-print-html").remove();
    return R;
};