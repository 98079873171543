import React from 'react';

export default function ({containerStyle, children}) {
    return (
        <div style={{
            boxSizing: "border-box",
            float: "left",
            padding: "10px",
            margin: "20px",
            minHeight: "330px",
            minWidth: "300px",
            width: "calc(33% - 40px)",
            border: "1px solid #ebebeb",
            borderRadius: "10px",
            ...containerStyle
        }}>
            {children}
        </div>
    );
}