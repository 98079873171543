import $ from "jquery"
import React from 'react';
import {API} from "../../../actionTypes";

const RGFD = {
    isInit: false,
    POS: {},
    dispatch: () => {}
};

RGFD.init = function(warehouse, POS, erplyConfig, customerCode, translate, dispatch){
    if(RGFD.isInit) return true;
    console.log('RGFD: init');
    RGFD.warehouse = Object.assign({}, warehouse);
    RGFD.POS = Object.assign({}, POS);
    RGFD.currency = erplyConfig.default_currency;
    RGFD.customerCode = customerCode;

    RGFD.isInit = true;

    RGFD.translate = translate;
    RGFD.dispatch = dispatch;
};

RGFD.translate = (text) => {
    return text;
};

RGFD.API = {};

RGFD.giftCardInRows = function(productsInBasket) {
    return typeof productsInBasket.find((product) => {return typeof product.giftCardCode !== "undefined"}) !== "undefined";
};

RGFD.API.sendRequest = function(request, body){
    return new Promise(function (resolve, reject) {
        RGFD.dispatch({
            type: API,
            payload: {
                data: {
                    request,
                    ...body
                },
                onSuccess: (records) => {
                    resolve(records);
                    return {
                        type: 'RGFD_REQUEST_FAILED',
                    };
                },
                onFailure: () => {
                    reject();
                    return {
                        type: 'RGFD_REQUEST_FAILED',
                    };
                },
                label: "Gift card API: " + request
            }
        })
    });
};

RGFD.doCreditResendParameters = {};

RGFD.API.doCredit = function(cardNumber, externalActionId, creditAmount, employeeID, onSuccess, onFailure){
    RGFD.doCreditResendParameters = {
        employeeID,
        onSuccess,
        onFailure
    };
    let params = {
        code: cardNumber,
        value: creditAmount,
        purchaseWarehouseID: RGFD.warehouse.warehouseID,
        purchasePointOfSaleID: RGFD.POS.pointOfSaleID,
    };

    RGFD.API.sendRequest('saveGiftCard', params).then(function (result) {
        RGFD.creditResults.handleSuccess(result, cardNumber, onSuccess, onFailure);
    }, function (result) {
        RGFD.creditResults.handleFail(result, cardNumber);
        onFailure();
    });
};

RGFD.API.doCheck = function(cardNumber){
    let params = {
        code: cardNumber
    };
    return RGFD.API.sendRequest('getGiftCards', params);
};

RGFD.API.doCapture = function(cardNumber, balance, employeeID){
    let params = {
        code: cardNumber,
        balance,
        redemptionEmployeeID: employeeID
    };
    return RGFD.API.sendRequest('saveGiftCard', params);
};

RGFD.API.doVoid = function(transaction_id){
    let params = {};
    params['transaction_id'] = transaction_id;

    return RGFD.API.sendRequest('saveGiftCard', params);
};

RGFD.API.addContractAttribute = function(params, key, value){
    params['contract_attributes'].push({
        "conatt_key": key,
        "conatt_value": value
    });
    return params;
};

RGFD.API.getExternalActionID = function(invoiceID, invoiceNo, cardNumber){
    return 'EAID-' + RGFD.customerCode + '-' + invoiceID + '-' + invoiceNo + '-' + cardNumber;
};

RGFD.API.getGenericExternalActionID = function(){
    return 'EAID-' + RGFD.customerCode + '-' + RGFD.warehouse.warehouseID + '-' + RGFD.POS.pointOfSaleID + Date.now();
};

RGFD.API.resendCredit = function(cardNumber, amount, externalActionID){
    RGFD.creditResults.updateStatus(cardNumber, 'Pending');
    RGFD.API.doCredit(cardNumber, externalActionID, amount, RGFD.doCreditResendParameters.employeeID, RGFD.doCreditResendParameters.onSuccess, RGFD.doCreditResendParameters.onFailure);
};

RGFD.giftCard = function(line, cardNumber, chargedAmount, status, productLineNumber){
    this.line = line;
    this.cardNumber = cardNumber;
    this.chargedAmount = chargedAmount;
    this.status = status;
    this.done = 0;
    this.retry = 0;
    this.expirationDate = '';
    this.authorizationCode = '';
    this.referenceNumber = '';
    this.merchantId = RGFD.API.merchantId;
    this.productLineNumber = productLineNumber;
};

RGFD.getGiftCard = function (cards, cardNumber) {
    let giftCard = false;
    $(cards).each(function (index, card) {
        if(card.cardNumber === cardNumber){
            giftCard = card;
            return false;
        }
    });
    return giftCard;
};

RGFD.creditResults = {
    data: []
};

RGFD.creditResults.init = function(){
    console.log('RGFD: results init called');
    RGFD.creditResults.data = [];
};

RGFD.creditResults.update = function(){
    RGFD.creditResults.data = [...RGFD.creditResults.data];
    return true;
};

RGFD.creditResults.getRow = function(cardNumber){
    return RGFD.getGiftCard(RGFD.creditResults.data, cardNumber);
};

RGFD.creditResults.handleSuccess = function(result, cardNumber, onSuccess, onFailure){
    console.log('RGFD: credit call success');
    const response = JSON.parse(result);
    console.log('RGFD: response:', response);

    if(response.status.responseStatus === 'ok'){
        if(response.data.action_status === "CREDIT_SUCCESS"){
            RGFD.creditResults.updateStatus(cardNumber, 'Success', true, response.data.generic_parameter, response.data.transaction_id);
            onSuccess(response.data);
            return true;
        }else if(response.data.action_status === "CREDIT_ERROR"){
            RGFD.creditResults.updateStatus(cardNumber, response.data.generic_parameter.message);
            RGFD.creditResults.showRetryButton(cardNumber);
            onFailure(response.data);
            return true;
        }
    }

    RGFD.creditResults.updateStatus(cardNumber, 'Failed');
    RGFD.creditResults.showRetryButton(cardNumber);
    onFailure();
    console.log('RGFD: failed doCredit, card number: ' + cardNumber, response);

};

RGFD.creditResults.handleFail = function(result, cardNumber){
    console.log('RGFD: credit call failed');
    RGFD.creditResults.updateStatus(cardNumber, 'Failed');
    RGFD.creditResults.showRetryButton(cardNumber);
};

RGFD.creditResults.showRetryButton = function(cardNumber) {
    console.log('RGFD: showRetryButton', RGFD.creditResults.data, cardNumber);
    let row = RGFD.creditResults.getRow(cardNumber);
    row.retry = 1;
    RGFD.creditResults.update();
};

RGFD.creditResults.updateStatus = function(cardNumber, status, done, genericParameters, transaction_id){
    const row = RGFD.creditResults.getRow(cardNumber);
    row.status = status;
    if(typeof done !== 'undefined'){
        row.done = done;
    }
    if(typeof genericParameters !== 'undefined'){
        if(typeof genericParameters.expiryDate !== 'undefined'){
            row.expirationDate = genericParameters.expiryDate.split('T')[0];
        }
        if(typeof genericParameters.authorizationCode !== 'undefined'){
            row.authorizationCode = genericParameters.authorizationCode;
        }
        if(typeof genericParameters.referenceNumber !== 'undefined'){
            row.referenceNumber = genericParameters.referenceNumber;
        }
        row.currency = genericParameters.currency;
        row.meanOfPayment = genericParameters.meanOfPayment;
        row.transactionID = transaction_id;
    }
    RGFD.creditResults.update();
};

RGFD.creditResults.allSuccessful = function(){
    let allSuccessful = true;
    for(let creditResult of RGFD.creditResults.data){
        if(creditResult.status !== 'Success'){
            allSuccessful = false;
        }
    }
    return allSuccessful;
};

RGFD.creditResults.allVoided = function(){
    let allVoided = true;
    for(let creditResult of RGFD.creditResults.data){
        if(creditResult.status === 'Success'){
            allVoided = false;
        }
    }
    return allVoided;
};

RGFD.payment = {};

RGFD.payment.searchGiftCard = function(number, resolve, reject) {
    const apiError = 'Could not perform the API call to check the number, try again!';

    RGFD.API.doCheck(number).then(function (result) {
        console.log('RGFD: doCheck payment', result);
        if(result.length > 0){
            let erplyGiftCard = result[0];
            const expired = RGFD.payment.isExpired(erplyGiftCard.expirationDate);
            if(expired){
                reject('expired');
            }
            let giftCard = new RGFD.giftCard(0, number, erplyGiftCard.balance, 'Pending');
            giftCard.expirationDate = erplyGiftCard.expirationDate;
            resolve(giftCard);
        }else{
            reject('Gift card not found');
        }
    }, function () {
        reject(apiError);
    });
};

RGFD.payment.isExpired = function(expirationDate){
    const now = (new Date()).getTime();
    if ("" != expirationDate){
        return now > (new Date(expirationDate + 'T00:00:00')).getTime();
    }
    return false;
};

export default RGFD;