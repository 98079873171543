import React, {useState} from "react";
import {Translate} from "react-localize-redux";
import Button from "../../buttons/Button";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import {checkLuhn, getPaybackConf, isCardCode} from "./util";
import {useActionListener} from "redux-action-listener-hook";
import {addErrorAlert} from "../../redux/actions";

const validateCardNumber = (cardNumber) => {
    return !(cardNumber.length !== 16 || !checkLuhn(cardNumber));
}

export default function (props) {
    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        console.log({useActionListener: action});
        const scannedCode = action.payload;
        if(isCardCode(scannedCode)){
            props.onDone(scannedCode);
        }else{
            dispatch(addErrorAlert('Not a proper Payback card number!'));
        }
    });

    const [validationError, setValidationError] = useState(false);
    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <span style={{
                fontSize: 14
            }} className={'errorMessage'}>{validationError}</span>
        );
    }

    let keyboardLayout = "numbers";
    let validationErrorTitle = "Not valid Payback card code";
    let backButton;
    if(props.onBack !== undefined){
        backButton = (
            <div onClick={props.onBack} style={{marginTop: '10px', marginBottom: '10px'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                        arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    const paybackConf = getPaybackConf();

    return (
        <div style={{
            padding: "10px",
            paddingTop: "180px"
        }}>
            <span className={"boldUppercaseText"} style={{
                fontSize: 24
            }}>
                { <Translate id={"Add Payback customer number"}/> }
            </span>
            <br/>
            <InputWithKeyboard
                beforeInput={<div style={{
                    float: "left",
                    marginTop: "5px",
                    padding: "12px 10px 11px 10px",
                    boxSizing: "border-box",
                    fontSize: "20px",
                    width: "100px",
                    backgroundColor: "#d9d9d9",
                    borderRadius: "10px 0 0 10px"
                }}>{paybackConf.manualCodePrefix.slice(0,3) + ' ' + paybackConf.manualCodePrefix.slice(3)}</div>}
                inputClass={""}
                inputStyle={{
                    boxSizing: "border-box",
                    width: "calc(100% - 100px)"
                }}
                inputLabel={"Enter the 10-digit PAYBACK customer number"}
                layout={keyboardLayout}
                onInputChange={(value) => {}}
                onDone={(value) => {
                    if(validateCardNumber(paybackConf.manualCodePrefix + value)){
                        props.onDone(paybackConf.manualCodePrefix + value);
                    }else {
                        if(isCardCode(value)){
                            props.onDone(value)
                        }else{
                            setValidationError(<Translate id={validationErrorTitle}/>)
                        }
                    }
                }}
            />

            { validationErrorDisplay }
            { backButton }
        </div>
    );
}