import React, {Component} from 'react';
import "../main.css"
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {
    addErrorAlert,
    addProduct,
    cancelAddUnknownItem,
    closeAdministration,
    findProduct,
    setLoading
} from "../../redux/actions";
import {displayNetPrices, getFirstUnknownRFIDProduct, getUnknownItemCode} from "../../redux/selectors";
import MultipleInputContent from "../../modal/MultipleInputContent";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.posID,
        unknownItemCode: getUnknownItemCode(),
        hasMoreUnknownRFIDProducts: state.rootReducer.unknownRFIDProducts.length > 1,
        unknownRFIDProduct: getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts),
        erplyConf: state.rootReducer.erplyConf
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findProduct: (code, warehouseID, pointOfSaleID, vatPrice, netPrice, itemCode, originalCode, hasMoreUnknownRFIDProducts) => {
            dispatch(findProduct(code, warehouseID, pointOfSaleID, vatPrice, function (product) {
                dispatch(setLoading(false));
                if(!hasMoreUnknownRFIDProducts){
                    dispatch(closeAdministration());
                }
                product.data.code = itemCode;
                product.isUnknownItem = true;
                product.name += ' (code: ' + itemCode + ')';
                if(netPrice !== false){
                    product.setNetPrice(netPrice);
                }
                return addProduct(product);
            }, (code, error) => {
                dispatch(setLoading(false));
                return addErrorAlert("Could not find unknown item, please check the configuration!");
            }, originalCode));
        },
        cancelAddUnknownItem: (gtin) => {
            dispatch(addErrorAlert('Could not add unknown item!'));
            dispatch(cancelAddUnknownItem(gtin));
            dispatch(closeAdministration());
        },
        setLoading: (loading) => {
            dispatch(setLoading(loading));
        }
    }
};

class UnknownItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            keyboardInputField: 'itemCode',
            selectedUser: false,
            errorMessage: false,
            codeInputValue: '',
            priceInputValue: ''
        };

        this.findItem = this.findItem.bind(this);
    }

    componentDidMount() {

    }

    findItem(inputValues) {
        this.props.setLoading(true);
        if( typeof inputValues.itemPrice === "undefined" ||
            isNaN(inputValues.itemPrice) ||
            inputValues.itemPrice === 0 ||
            inputValues.itemPrice === '' ||
            typeof inputValues.itemCode === "undefined" ||
            inputValues.itemCode === ''
        ){
            this.props.cancelAddUnknownItem(this.props.unknownRFIDProduct.GTIN);
            this.props.setLoading(false);
        }else{
            let originalCode = this.props.unknownRFIDProduct.ITEM_ID || this.props.unknownRFIDProduct.GTIN;
            let vatPrice = false;
            let netPrice = false;

            if(displayNetPrices(this.props.erplyConf)){
                netPrice = inputValues.itemPrice;
            }else{
                vatPrice = inputValues.itemPrice;
            }

            this.props.findProduct(this.props.unknownItemCode, this.props.warehouseID, this.props.pointOfSaleID, vatPrice, netPrice, inputValues.itemCode, originalCode, this.props.hasMoreUnknownRFIDProducts);
            this.setState({
                codeInputValue: '',
                priceInputValue: ''
            });
        }
    }

    render() {
        console.log('RFIDText unknownRFIDProduct', this.props.unknownRFIDProduct);
        const rfidText = this.props.unknownRFIDProduct !== false ? 'GTIN: ' + this.props.unknownRFIDProduct.GTIN : '';

        let initialValues = {};
        let inputFields = [
            { id: 'itemCode', name: 'Item code', isNumber: true, positiveInt: true},
            { id: 'itemPrice', name: 'Item price', isNumber: true}
        ];

        return (
            <Translate>{({translate}) =>
                <MultipleInputContent
                    title={translate('Unknown item')}
                    inputFields={inputFields}
                    translate={translate}
                    onDone={(inputValues) => {
                        console.log('inputValues', inputValues);
                        this.findItem(inputValues);
                    }}
                    initialValues={initialValues}
                    beforeInputFields={
                        <p>{rfidText}</p>
                    }
                    initialKeyboardLayout={'numbers'}
                />
            }
            </Translate>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(UnknownItem));