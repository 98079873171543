import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const WrappedPerfectScrollbar = PerfectScrollbarWithHotfix =>
    React.forwardRef((props, ref) => {
        useEffect(() => {
            return () => {
                // To clear the scheduler at perfect-scrollbar/src/handlers/touch.js:176
                // bc PerfectScrollbar might be destroyed before the timer is up which led to null i.element
                if (ref && ref.current && ref.current._ps) {
                    ref.current._ps.isInitialized = true;
                }
            };
        });
        return < PerfectScrollbarWithHotfix {...props} options={{swipeEasing: false}} ref={ref} />;
    });

export const PerfectScrollbarWithHotfix = WrappedPerfectScrollbar(
    PerfectScrollbar
);