import {removePayment, updatePayment, voidAllPayments, voidGiftCardActivations} from "../../actions";
import {
    addBeforeCompleteTransactionAction,
    addErrorAlert,
    addPluginRequestSaleAttributes,
    addPluginRequestSaleNotes,
    addProduct,
    addReceiptGiftCard,
    changeMode,
    changePaymentMode,
    closeModal,
    findProduct,
    openLogonModal,
    openModal,
    removeBeforeCompleteTransactionAction,
    removeProduct,
    setLoading,
    showDialogue,
    showInputDialogue,
    updateProductGiftCardNumber
} from "../../../actions";
import {getCheckedGiftCardPayments, getFirstStartedGiftCardPayment, giftCardIsInTransaction} from "../../selectors";
import RGFD from "./main";
import * as uuid from "uuid";
import React, {useState} from "react";
import CreditResults from "./CreditResults";
import {getTranslate} from "react-localize-redux";
import {APP_DOCUMENT_SAVE_STATUS_START, APP_MODE_SCAN} from "../../../appConstants";
import {CHANGE_TRANSACTION_SAVE_STATUS} from "../../../actionTypes";
import {getPaymentTypeByReasonCode, getUnknownItemCode} from "../../../selectors";
import Product from "../../../dto/product";

export const checkGiftCard = function (dispatch, state, code, scannedCode, onDone = ()=>{}) {
    const reject = function (title, errorMessage) {
        function after(){
            dispatch(removePayment(payment));
            dispatch(changePaymentMode('options'));
        }
        dispatch(showDialogue(title, errorMessage, 'Ok', after, after));
        onDone(false);
    };
    const payment = Object.assign({}, getFirstStartedGiftCardPayment(state.payment.payments));
    RGFD.init(state.rootReducer.warehouse, state.rootReducer.pos, state.rootReducer.erplyConf, state.rootReducer.user.clientCode, getTranslate(state.localize));

    let number = code;
    if(scannedCode){
        if(RGFD.isGiftCard(code)){
            let giftcard = RGFD.decodeBarcode(number);
            number = giftcard.number;
        }else{
            reject("Could not use gift card", 'Not gift card code');
            return false;
        }
    }

    if(giftCardIsInTransaction(number, state.payment.payments)){
        reject("Could not use gift card", 'It is added to this transaction already!');
        return false;
    }

    RGFD.payment.searchGiftCard(number, function (giftCard) {
        console.log('payment: ', payment);
        console.log('giftCard: ', giftCard);
        const giftCardAmount = parseFloat(giftCard.chargedAmount);
        if(giftCardAmount > 0){
            payment.sum = payment.sum > giftCardAmount ? giftCardAmount : payment.sum;
            payment.status = "checked";
            payment.giftCard = giftCard;
            dispatch(changePaymentMode('options'));
            dispatch(updatePayment(payment));
            onDone(true);
        }else{
            reject("Could not use gift card", 'Total amount used already!')
        }

    }, function (errorMessage) {
        reject("Could not use gift card", errorMessage);
    });
};

export const giftCard = async function (dispatch, state, payload) {
    RGFD.init(state.rootReducer.warehouse, state.rootReducer.pos, state.rootReducer.erplyConf, state.rootReducer.user.clientCode, getTranslate(state.localize));

    if(payload.data.request === 'checkGiftCard'){
        checkGiftCard(dispatch, state, payload.data.number, false, payload.data.onDone);
    } else if(payload.data.request === 'captureFirst'){
        const payments = getCheckedGiftCardPayments(state.payment.payments);
        let payment;
        if(payments.length > 0){
            payment = payments[0];
        }else{
            return true;
        }
        let failed = false;
        let errorMessage = 'Connection issue';

        const setFailed = function () {
            failed = true;
        };
        let externalActionID = RGFD.API.getGenericExternalActionID();
        payment.externalActionID = externalActionID;

        let result = await RGFD.API.doCapture(payment.giftCard.cardNumber, externalActionID, payment.sum, state.rootReducer.user.employeeID).catch(setFailed);
        if(!failed){
            let response = JSON.parse(result);
            console.log('RGFD: payment confirm doCapture result', response);

            if(response.status.responseStatus === 'ok'){
                if(response.data.action_status === 'CAPTURE_SUCCESS'){
                    const genericParameters = response.data.generic_parameter;
                    payment.status = 'success';
                    payment.sumPaid = typeof genericParameters.authorizedAmount !== 'undefined' ? parseFloat(genericParameters.authorizedAmount) : 0;

                    if(payment.giftCard !== false){
                        if(typeof genericParameters.authorizationCode !== 'undefined'){
                            payment.giftCard.authorizationCode = genericParameters.authorizationCode;
                        }
                        if(typeof genericParameters.referenceNumber !== 'undefined'){
                            payment.giftCard.referenceNumber = genericParameters.referenceNumber;
                        }
                        if(typeof genericParameters.meanOfPayment !== 'undefined'){
                            payment.giftCard.meanOfPayment = genericParameters.meanOfPayment;
                            let paymentType = getPaymentTypeByReasonCode(state.payment.paymentTypes, genericParameters.meanOfPayment);
                            if(typeof paymentType !== "undefined"){
                                payment.typeID = paymentType.id;
                            }
                        }
                        payment.giftCard.capturedAmount = genericParameters.authorizedAmount;
                        payment.giftCard.currentBalance = genericParameters.currentBalance;
                        payment.giftCard.previousBalance = genericParameters.previousBalance;
                        payment.giftCard.expirationDate = genericParameters.expiryDate.split('T')[0];
                        payment.giftCard.currency = genericParameters.currency;
                        payment.giftCard.transactionID = response.data.transaction_id;

                        let capturePrintInfo = RGFD.getGiftCardCapturePrintInfo(payment.giftCard);
                        let translate = getTranslate(state.localize);
                        let giftCardPrintText = RGFD.getGiftCardPrintInfoText([capturePrintInfo], translate);
                        dispatch(addReceiptGiftCard(capturePrintInfo));
                        dispatch(addPluginRequestSaleNotes([giftCardPrintText]));
                    }
                    dispatch(payload.onSuccess(payment));
                }else{
                    failed = true;
                    errorMessage = response.data.generic_parameter.message;
                }
            }else {
                failed = true;
            }
        }
        if(failed){
            let after = function(){
                dispatch(removePayment(payment));
                dispatch(changePaymentMode('options'));
            };
            dispatch(showDialogue("Could not use gift card", errorMessage, 'Ok', after, after));
            payload.onFailure();
        }
    } else if(payload.data.request === 'giftCardAvailable'){
        RGFD.API.doCheck(payload.data.number).then(function (result) {
            const response = JSON.parse(result);
            let canUse = false;
            if(response.status.responseStatus === 'ok') {
                if (response.data.action_status === 'CHECK_ERROR') {
                    if(response.data.generic_parameter.message === "Error code: 4 - Inactive account"){
                        canUse = true;
                    }
                }
            }
            if(canUse) {
                payload.onSuccess(true, response.data.generic_parameter);
            }else{
                let errorMessage = 'Can\'t use this gift card!';
                if(response.data.action_status === 'CHECK_SUCCESS'){
                    errorMessage = 'This gift card is already activated!';
                }
                payload.onSuccess(false, errorMessage);
            }
        }, function () {
            payload.onFailure('Could not perform the API call to check the number, try again!');
        });
    } else if(payload.data.request === 'voidGiftCardActivations'){
        let handleVoidFailed = (creditResult) => {
            creditResult.status = 'VoidFailed';
            if(RGFD.creditResults.allVoided()){
                let voidFailed = RGFD.creditResults.data.filter(el => el.status === 'VoidFailed');
                payload.onFailure(voidFailed);
            }
        };

        let activatedGiftCards = RGFD.creditResults.data.filter(el => el.status === 'Success');
        if(activatedGiftCards.length === 0){
            activatedGiftCards = payload.data.giftCards;
        }

        if(activatedGiftCards.length === 0){
            payload.onSuccess();
        }else{
            for(let creditResult of activatedGiftCards){
                RGFD.API.doVoid(creditResult.transactionID).then((result) => {
                    const response = JSON.parse(result);
                    console.log('RGFD: doVoid', response);
                    if(typeof response.data !== "undefined" && response.data.action_status === 'VOID_SUCCESS'){
                        creditResult.status = 'Voided';
                        if(RGFD.creditResults.allVoided()){
                            let voidFailed = RGFD.creditResults.data.filter(el => el.status === 'VoidFailed');
                            if(voidFailed.length > 0){
                                payload.onFailure(voidFailed);
                            }else{
                                payload.onSuccess();
                            }
                        }
                    }else{
                        handleVoidFailed(creditResult);
                    }
                }, () => {
                    handleVoidFailed(creditResult);
                });
            }
        }
    } else if(payload.data.request === 'voidGiftCardPayment'){
        RGFD.API.doVoid(payload.data.transactionID).then((result) => {
            const response = JSON.parse(result);
            console.log('RGFD: doVoid', response);
            if(typeof response.data !== "undefined" && response.data.action_status === 'VOID_SUCCESS'){
                payload.onSuccess();
            }else{
                payload.onFailure();
            }
        }, payload.onFailure);
    }
};

export const posEvents = {
    activateGiftCards: (store, onDone, onFail, beforeCompleteTransactionID) => {
        console.log('RGFD: activate Gift Cards');
        let state = store.getState();
        let hasGiftCardSales = false;
        let giftCardResultsModalID = uuid.v4();
        let lineNo = 1;
        for(let product of state.rootReducer.productsInBasket) {
            if(typeof product.giftCardCode !== 'undefined'){
                hasGiftCardSales = true;
                if(typeof RGFD.creditResults.data.find(el => el.cardNumber === product.giftCardCode) !== "undefined"){
                    continue;
                }

                let eaid = RGFD.API.getGenericExternalActionID();
                let creditResult = new RGFD.giftCard(lineNo, product.giftCardCode, eaid, product.rowTotal, 'Pending', product.lineNumber);
                RGFD.creditResults.data.push(creditResult);

                RGFD.API.doCredit(product.giftCardCode, eaid, product.rowTotal, state.rootReducer.user.employeeID, (response) => {
                    if(RGFD.creditResults.allSuccessful()){
                        let giftCardPrintInfo = RGFD.getGiftCardPrintInfo();
                        let translate = getTranslate(state.localize);
                        let giftCardPrintText = RGFD.getGiftCardPrintInfoText(giftCardPrintInfo, translate);
                        giftCardPrintInfo.map((giftCardPrint) => {
                            store.dispatch(addReceiptGiftCard(giftCardPrint));
                        });
                        store.dispatch(addPluginRequestSaleNotes([giftCardPrintText]));

                        console.log('RGFD: giftCardPrintInfo', giftCardPrintInfo);
                        let saleAttributes = RGFD.getGiftCardAttributes();
                        store.dispatch(addPluginRequestSaleAttributes(saleAttributes));
                        store.dispatch(closeModal(giftCardResultsModalID));
                        RGFD.creditResults.init();
                        onDone();
                    }
                }, function (response) {});
            }
            lineNo++;
        }

        let openCancelActivationOptions = (dispatch) => {
            dispatch(closeModal(giftCardResultsModalID));
            if(window.AppConf.completeTransactionOnGiftCardActivationFail){
                dispatch(showDialogue(
                    'Alert!',
                    'Gift card has not been activated, please process the refund for the customer on a different transaction',
                    'Complete transaction',
                    function () {
                        replaceUnactivatedGiftCardsWithUnknownItem(dispatch);
                }, undefined, undefined, false, undefined, undefined, true));
            }else{
                dispatch(showDialogue('Gift card activation failed!', 'Payments and gift card activations will be voided!', 'Ok', function () {
                    dispatch(setLoading(true));
                    dispatch(changeMode(APP_MODE_SCAN));
                    dispatch(voidAllPayments());
                    dispatch(voidGiftCardActivations(() => {
                        dispatch(setLoading(false));
                        RGFD.creditResults.init();
                    }, (giftCards) => {
                        dispatch(setLoading(false));
                        RGFD.creditResults.init();
                        for(let giftCard of giftCards){
                            giftCard.status = 'Success';
                            RGFD.creditResults.data.push(giftCard);
                            dispatch(showDialogue('Failed to void gift card activation', 'no: ' + giftCard.cardNumber, 'Ok',
                                () => {}, undefined, undefined, false, undefined, undefined, true));
                        }
                    }));
                    dispatch(removeBeforeCompleteTransactionAction(beforeCompleteTransactionID));
                    dispatch({
                        type: CHANGE_TRANSACTION_SAVE_STATUS,
                        payload: APP_DOCUMENT_SAVE_STATUS_START
                    });
                }, undefined, undefined, false, undefined, undefined, true));
            }
        };

        let replaceUnactivatedGiftCardsWithUnknownItem = (dispatch) => {
            dispatch(setLoading(true));
            dispatch(findProduct(
                getUnknownItemCode(),
                state.rootReducer.warehouse.warehouseID,
                state.rootReducer.pos.pointOfSaleID,
                false,
                function (unknownProduct) {
                    dispatch(setLoading(false));
                    for(let product of state.rootReducer.productsInBasket) {
                        if(typeof product.giftCardCode !== 'undefined') {
                            hasGiftCardSales = true;
                            if (typeof RGFD.creditResults.data.find(el => el.cardNumber === product.giftCardCode) !== "undefined") {
                                let nextUnknownProduct = new Product(
                                    unknownProduct.data.name,
                                    unknownProduct.data.priceListPrice,
                                    unknownProduct.data.priceListPriceWithVat,
                                    1,
                                    Object.assign({}, unknownProduct.data)
                                );
                                nextUnknownProduct.data.code = product.giftCardCode;
                                nextUnknownProduct.isUnknownItem = true;
                                nextUnknownProduct.name = 'GIFTCARD ACTIVATION FAILED' + ' (code: ' + product.giftCardCode + ')';
                                nextUnknownProduct.setVatPrice(product.vatPrice);
                                dispatch(addProduct(nextUnknownProduct));
                                dispatch(removeProduct(product));
                            }

                        }
                    }
                    onDone();
                    return setLoading(false);
                }, (code, error) => {
                    dispatch(setLoading(false));
                    return addErrorAlert("Could not find unknown item, please check the configuration!");
                }));
        };

        let openCreditResultsModal = () => {
            let CreditResultsWrapper = () => {
                const [creditResults, setCreditResults] = useState(RGFD.creditResults.data);
                RGFD.creditResults.update = () => {
                    setCreditResults([...RGFD.creditResults.data]);
                };

                return (
                    <CreditResults
                        data={creditResults}
                        onAskAssistance={() => {
                            store.dispatch(openLogonModal('Logon to handle failed gift card activation', (data) => {
                                openCancelActivationOptions(store.dispatch);
                            }));
                        }}
                        onEdit={(creditResult) => {
                            store.dispatch(showInputDialogue('enter gift card code', '', 'card number', (number) => {
                                creditResult.cardNumber = number;
                                creditResult.retry = 0;
                                creditResult.externalActionID = RGFD.API.getGenericExternalActionID();
                                RGFD.creditResults.update();
                                store.dispatch(updateProductGiftCardNumber(creditResult.productLineNumber, number));
                                RGFD.API.resendCredit(creditResult.cardNumber, creditResult.chargedAmount, creditResult.externalActionID);
                            }, undefined, undefined, true, 'zIndex6', undefined, creditResult.cardNumber));
                        }}
                    />
                );
            };

            store.dispatch(openModal({
                content:(
                    <CreditResultsWrapper/>
                ),
                id: giftCardResultsModalID,
                className: "Administration",
                onClose: function(){},
                canClose: false
            }));
        };

        console.log('RGFD: creditResults.data', RGFD.creditResults.data);
        if(hasGiftCardSales){
            openCreditResultsModal();
        }
    },
    beforeSaveTransaction: (store) => {
        let state = store.getState();
        let giftCardInRows = RGFD.giftCardInRows(state.rootReducer.productsInBasket);
        let alreadyAdded = typeof state.rootReducer.beforeCompleteTransactionActions.find(el => el.type === 'activateGiftCards') !== "undefined";
        if(giftCardInRows && !alreadyAdded){
            let id = uuid.v4();
            store.dispatch(addBeforeCompleteTransactionAction({
                id,
                type: 'activateGiftCards',
                run: (onDone, onFail) => {
                    posEvents.activateGiftCards(store, onDone, onFail, id);
                }
            }));
        }
    },
    initTransaction: () => {
        RGFD.creditResults.init();
    }
};