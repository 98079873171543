import { Component } from 'react';
import {connect} from "react-redux";
import {
    findProduct,
    findCustomerByCardNumber,
    scannerDetected,
    setLoading,
    switchUser,
    setAdministrationUser,
    apiError,
    closeModal,
    addProduct, setPaybackCustomer, addPaybackCoupon, addErrorAlert, setPaybackInputDone
} from "../redux/actions";
import {PDTB} from "../util/product";
import {APP_MODE_SCAN, APP_MODE_SCREEN_SAVER} from "../redux/appConstants";
import $ from "jquery";
import {getTranslate} from "react-localize-redux";
import {isCardCode as isPaybackCardCode, isCouponCode as isPaybackCouponCode} from "../integrations/payback/util";
window.jQuery = $;
require('jQuery-Scanner-Detection');

const handleScannerDetect = function (component, code) {
    if(component.customerSearch(code)) return true;
    const logonModal = component.props.modals.find(el => el.type === 'logon');
    if(typeof logonModal !== 'undefined'){
        component.props.verifyUser(code, component.props.clientCode, component.props.translate, (user) => {
            logonModal.afterLogonAction(user);
            return closeModal(logonModal.id);
        });
    }
    if(component.props.administration !== false){
        if(component.props.administration.user === false){
            component.props.verifyUser(code, component.props.clientCode, component.props.translate);
            return true;
        }
    }
    if(component.props.dialogue !== false){
        if(typeof component.props.dialogue.onScannerDetect !== "undefined"){
            component.props.dialogue.onScannerDetect(code);
            return true;
        }
    }
    component.props.codeScanned(code);
    if(component.props.mode === APP_MODE_SCAN || component.props.mode === APP_MODE_SCREEN_SAVER){
        if(process.env.REACT_APP_ERPLY_MODE !== "1"){
            const paybackEnabled = window.AppConf.payback || false;
            if(paybackEnabled){
                if(isPaybackCardCode(code)){
                    component.props.setPaybackCustomer(code);
                    return true;
                }else if(isPaybackCouponCode(code)){
                    if(component.props.payback.coupons.indexOf(code) !== -1){
                        component.props.addErrorAlert('Coupon is already in the transaction!');
                    }else{
                        component.props.addPaybackCoupon(code);
                        component.props.setPaybackInputDone(true);
                    }
                    return true;
                }
            }

            code = PDTB.tryAllpatterns(code);
        }else{
            const weighedBarcode = PDTB.parseWeighedBarcode(code, component.props.erplyConf);
            if(weighedBarcode.productCode.length > 0){
                component.props.findProduct(weighedBarcode.productCode, component.props.warehouseID, component.props.pointOfSaleID, (product) => {
                    if(weighedBarcode.amount > -1){
                        product.amount = parseFloat(weighedBarcode.amount);
                        product.barcodeWeight = true;
                    }
                    if(weighedBarcode.price > -1){
                        product.setNetPrice(weighedBarcode.price);
                        product.barcodePrice = true;
                    }
                    return addProduct(product);
                });
                return true;
            }
        }
        component.props.findProduct(code, component.props.warehouseID, component.props.pointOfSaleID);
    }
}

const mapStateToProps = state => {
    return {
        scanned_code_regex_parser: state.rootReducer.erplyConf.touchpos_scanned_code_regex_parser || false,
        customer_code_starts_with: state.rootReducer.erplyConf.touchpos_customer_code_starts_with || false,
        warehouseID: state.rootReducer.pos.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        mode: state.rootReducer.mode,
        administration: state.rootReducer.administration,
        modals: state.rootReducer.modals,
        clientCode: state.rootReducer.user.clientCode,
        translate: getTranslate(state.localize),
        erplyConf: state.rootReducer.erplyConf,
        dialogue: state.rootReducer.dialogues.length > 0 ? state.rootReducer.dialogues[state.rootReducer.dialogues.length - 1] : false,
        payback: state.rootReducer.payback
    }
};

const mapDispatchToProps = function (dispatch) {
    return {
        findProduct: (code, warehouseID, pointOfSaleID, onSuccess) => {
            dispatch(setLoading(true));
            dispatch(findProduct(code, warehouseID, pointOfSaleID, false, onSuccess, undefined, undefined, () => {
                dispatch(setLoading(false));
            }));
        },
        findCustomer: code => dispatch(findCustomerByCardNumber(code)),
        codeScanned: code => dispatch(scannerDetected(code)),
        verifyUser: (scannedValue, clientCode, translate, afterLogonAction = false) => {
            console.log('verifyUser', scannedValue);

            dispatch(setLoading(true));
            dispatch(switchUser(clientCode, scannedValue, (data) => {
                dispatch(setLoading(false));
                if(afterLogonAction !== false){
                    return afterLogonAction(data[0]);
                }
                return setAdministrationUser(data[0]);
            }, (error) => {
                dispatch(setLoading(false));
                return apiError(translate('Incorrect PIN'));
            }));
        },
        setPaybackCustomer: (customer) => {
            dispatch(setPaybackCustomer(customer));
        },
        addPaybackCoupon: (couponCode) => {
            dispatch(addPaybackCoupon(couponCode));
        },
        setPaybackInputDone: (inputDone) => {
            dispatch(setPaybackInputDone(inputDone));
        },
        addErrorAlert: (message, autoClose=true) => {
            dispatch(addErrorAlert(message, autoClose));
        }
    }
};

class ScannerDetect extends Component{
    constructor(props){
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.customerSearch = this.customerSearch.bind(this);
    }

    customerSearch(code){
        if (this.props.customer_code_starts_with &&
            this.props.customer_code_starts_with !== "" &&
            code.startsWith(this.props.customer_code_starts_with)
        ){
            console.log('Find customer', code);
            this.props.findCustomer(code);
            return true
        }else{
            return false;
        }
    }

    componentDidMount() {
        const component = this;
        $(document).scannerDetection((code) => {
            handleScannerDetect(component, code);
        });

        /*setTimeout(() => {
            handleScannerDetect(component, "3608419339284");
        }, 5000);*/
    }

    componentWillUnmount() {
        $(document).scannerDetection(false);
    }

    render() {
        return ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScannerDetect)