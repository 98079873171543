export default function (name) {
    this.name = name;
    /*
    * connecting
    * connected
    * connectionFailed
    */
    this.status = '';
    this.setConnecting = function () {
        this.status = 'connecting';
    };
    this.setConnected = function () {
        this.status = 'connected';
    };
    this.isConnected = function () {
        return this.status === 'connected';
    };
    this.setConnectionFailed = function () {
        this.status = 'connectionFailed';
    };
    this.hasNoConnection = function () {
        return this.status === 'connectionFailed';
    }
}