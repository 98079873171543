import Decimal from "decimal.js";
import {displayPrice} from "../../util/helperFunctions";

export default function Product(name, netPrice, vatPrice, amount, data) {
    this.getTotalVatPrice = function (precision=4) {
        return (new Decimal(this.amount)).times(new Decimal(this.vatPrice)).toDecimalPlaces(precision).toNumber();
    };
    this.getTotalNetPrice = function () {
        return (new Decimal(this.amount)).times(new Decimal(this.netPrice)).toDecimalPlaces(4).toNumber();
    };
    this.getDisplayTotalPrice = function (netPrice = false) {
        if(netPrice){
            return displayPrice(this.rowNetTotal);
        }else{
            return displayPrice(this.rowTotal);
        }
    };
    this.getDisplayUnitPrice = function (netPrice = false) {
        if(netPrice){
            return displayPrice(this.netPrice);
        }else{
            return displayPrice(this.vatPrice);
        }
    };
    this.getNetPrice = function (vatPrice, vatrate) {
        return (new Decimal(vatPrice)).dividedBy((new Decimal(1)).plus((new Decimal(vatrate)).dividedBy(new Decimal(100)))).toDecimalPlaces(4).toNumber();
    };
    this.getVatPrice = function (netPrice, vatrate) {
        return (new Decimal(netPrice)).mul((new Decimal(1)).plus((new Decimal(vatrate)).dividedBy(new Decimal(100)))).toDecimalPlaces(4).toNumber();
    };
    this.setVatPrice = function (vatPrice) {
        this.vatPrice = vatPrice;
        this.netPrice = this.getNetPrice(vatPrice, this.data.vatrate);
        this.calculateTotals();
        this.rowVAT = (new Decimal(this.rowTotal)).minus((new Decimal(this.rowNetTotal))).toDecimalPlaces(4).toNumber();
        this.manualPrice = true;
    };
    this.setNetPrice = function (netPrice) {
        this.netPrice = netPrice
        this.vatPrice = this.getVatPrice(netPrice, this.data.vatrate);
        this.calculateTotals();
        this.rowVAT = (new Decimal(this.rowTotal)).minus((new Decimal(this.rowNetTotal))).toDecimalPlaces(4).toNumber();
        this.manualPrice = true;
    };
    this.getFlags = function () {
        if(this.flags !== false) return this.flags;

        let attributeNames = {
            AuthorizedForSaleFlag: true,
            HazardousItemFlag: false,
            SerialNumberFlag: false,
            DiscountableFlag: true,
            PromotionableFlag: true,
            ReturnableFlag: true,
            SecurityRequiredFlag: false
        };
        if(typeof this.data.attributes !== "undefined"){
            for(let attribute of this.data.attributes){
                if(typeof attributeNames[attribute.attributeName] !== 'undefined'){
                    attributeNames[attribute.attributeName] = attribute.attributeValue === 'true';
                }
            }
        }
        this.flags = attributeNames;
        return attributeNames;
    };

    this.isDiscountable = function () {
        const flags = this.getFlags();
        return flags.DiscountableFlag;
    };

    this.isSecurityItem = function () {
        const flags = this.getFlags();
        return flags.SecurityRequiredFlag;
    };

    this.isBlocked = function () {
        return !this.getFlags().AuthorizedForSaleFlag;
    };

    this.isRiskyItem = function () {
        return this.getFlags().HazardousItemFlag;
    };

    this.needsSerialNumber = function () {
        return this.getFlags().SerialNumberFlag;
    };

    this.isReturnable = function () {
        return this.getFlags().ReturnableFlag;
    };

    this.calculatePrices = function () {
        if(typeof this.originalPriceWithVAT === "undefined"){
            this.originalPriceWithVAT = this.vatPrice;
        }
        this.vatPrice = (new Decimal(this.originalPriceWithVAT)).minus((new Decimal(this.manualDiscountTotal)).dividedBy(new Decimal(this.amount))).toDecimalPlaces(4).toNumber();
        this.rowVAT = (new Decimal(this.rowTotal)).minus(new Decimal(this.rowNetTotal)).toDecimalPlaces(4).toNumber();
        this.netPrice = this.getNetPrice(this.vatPrice, this.data.vatrate);
    };

    this.setManualDiscountSum = function (sum, useNetPrice = false) {
        let originalVatPrice = new Decimal(this.originalPriceWithVAT || this.vatPrice);
        if(useNetPrice){
            sum = (new Decimal(sum)).times((1 + this.data.vatrate/100)).toDecimalPlaces(4).toNumber();
        }
        this.manualDiscountTotal = sum;
        this.manualDiscountPercentage = (new Decimal(sum)).dividedBy(new Decimal(this.amount)).dividedBy(new Decimal(originalVatPrice)).times(100).toDecimalPlaces(4).toNumber();
        this.discount = this.manualDiscountPercentage;
        this.manualDiscountFinalPrice = (originalVatPrice.times(new Decimal(this.amount))).minus(sum).toDecimalPlaces(4).toNumber();
        this.calculatePrices();
        this.calculateTotals();
        this.calculateManualDiscountUnitPrice();
        this.manualPrice = true;
    };

    this.setManualDiscountPercentage = function (percentage) {
        let floatPercentage = new Decimal(percentage);
        let originalVatPrice = new Decimal(this.originalPriceWithVAT || this.vatPrice);
        this.manualDiscountTotal = originalVatPrice.times(floatPercentage).dividedBy(100).times(new Decimal(this.amount)).toDecimalPlaces(4).toNumber();
        this.manualDiscountPercentage = floatPercentage.toDecimalPlaces(4).toNumber();
        this.discount = floatPercentage;
        this.manualDiscountFinalPrice = originalVatPrice.times(new Decimal(this.amount)).minus(new Decimal(this.manualDiscountTotal)).toDecimalPlaces(4).toNumber();
        this.calculatePrices();
        this.calculateTotals();
        this.calculateManualDiscountUnitPrice();
        this.manualPrice = true;
    };

    this.setManualDiscountUnitPrice = function (price, useNetPrice = false) {
        this.manualDiscountUnitPrice = useNetPrice ? this.getVatPrice(price, this.data.vatrate) : price;
        this.manualDiscountFinalPrice = (new Decimal(this.manualDiscountUnitPrice)).times(this.amount).toDecimalPlaces(4).toNumber();

        let originalRowTotal = this.getOriginalRowTotal();
        this.manualDiscountTotal = this.getManualDiscountTotal(originalRowTotal);
        this.manualDiscountPercentage = originalRowTotal === 0 ? 0 : (new Decimal(this.manualDiscountTotal)).dividedBy(new Decimal(originalRowTotal)).times(100).toDecimalPlaces(4).toNumber();
        this.discount = this.manualDiscountPercentage;
        this.calculatePrices();
        this.calculateTotals();
        this.manualPrice = true;
    };

    this.getOriginalRowTotal = function () {
        return (new Decimal(this.originalPriceWithVAT || this.vatPrice)).times(this.amount).toDecimalPlaces(4).toNumber();
    };

    this.getManualDiscountTotal = function (originalRowTotal) {
        return originalRowTotal === 0 ? 0 : (new Decimal(originalRowTotal)).minus(new Decimal(this.manualDiscountFinalPrice)).toDecimalPlaces(4).toNumber();
    };

    this.calculateManualDiscountUnitPrice = function () {
        this.manualDiscountUnitPrice = (new Decimal(this.manualDiscountFinalPrice)).dividedBy(new Decimal(this.amount)).toDecimalPlaces(4).toNumber();
    };

    this.hasManualDiscount = function () {
        return !(this.manualDiscountFinalPrice === 0 && this.manualDiscountPercentage === 0);
    };

    this.calculateTotals = function () {
        this.rowNetTotal = this.getTotalNetPrice();
        this.rowTotal = this.getTotalVatPrice();
    };

    this.getAppliedPriceListRules = function () {
        if(typeof this.promotionRules === "undefined") return [];
        let priceListRules = [];

        let priceListRuleIDs = this.promotionRules.filter(el => el.name.indexOf('priceListID') !== -1);
        for(let priceListRuleID of priceListRuleIDs){
            let pos = priceListRuleID.name.split('promotionRule')[1].split('priceListID');
            let name = this.getPromotionRuleValue('name', pos[0], pos[1]);
            if(process.env.REACT_APP_ERPLY_MODE !== "1" && name === 'default') continue;

            priceListRules.push({
                name,
                amount: this.getPromotionRuleValue('amount', pos[0], pos[1]),
                finalPrice: this.getPromotionRuleValue('finalPrice', pos[0], pos[1]),
                totalDiscount: this.getPromotionRuleValue('totalDiscount', pos[0], pos[1]),
                priceListDiscountType: this.getPromotionRuleValue('priceListDiscountType', pos[0], pos[1]),
                priceListDiscountPercentage: this.getPromotionRuleValue('priceListDiscountPercentage', pos[0], pos[1])
            });
        }
        return priceListRules;
    };

    this.getAppliedCampaignRules = function () {
        if(typeof this.promotionRules === "undefined") return [];
        let campaignRules = [];

        let campaignRuleIDs = this.promotionRules.filter(el => el.name.indexOf('campaignID') !== -1);
        for(let campaignRuleID of campaignRuleIDs){
            let pos = campaignRuleID.name.split('promotionRule')[1].split('campaignID');
            let name = this.getPromotionRuleValue('name', pos[0], pos[1]);

            campaignRules.push({
                name,
                amount: this.getPromotionRuleValue('amount', pos[0], pos[1]),
                finalPrice: this.getPromotionRuleValue('finalPrice', pos[0], pos[1]),
                totalDiscount: this.getPromotionRuleValue('totalDiscount', pos[0], pos[1]),
                campaignType: this.getPromotionRuleValue('campaignType', pos[0], pos[1]),
                campaignDiscountValue: this.getPromotionRuleValue('campaignDiscountValue', pos[0], pos[1]),
                campaignDiscountPercentage: this.getPromotionRuleValue('campaignDiscountPercentage', pos[0], pos[1])
            });
        }
        return campaignRules;
    };

    this.getPromotionRuleValue = function (name, x, y, defaultValue = '') {
        return (this.promotionRules.find(el => el.name === 'promotionRule' + x + name + y) ?? {value: defaultValue}).value;
    };

    this.resetManualDiscount = function () {
        this.manualDiscountPercentage = 0;
        this.manualDiscountTotal = 0;
        this.manualDiscountFinalPrice = 0;
        this.manualDiscountUnitPrice = 0;
        this.manualPrice = false;
    };

    this.resetManualDiscount();

    this.setAmount = function(amount){
        this.amount = amount;
        if(typeof this.manualDiscountTotal !== "undefined" && this.manualDiscountTotal !== 0){
            this.setManualDiscountPercentage(this.manualDiscountPercentage);
        }else{
            this.calculateTotals();
        }
    };

    this.name = name;
    this.netPrice = netPrice;
    this.vatPrice = vatPrice;
    this.amount = amount;
    this.lineNumber = 0;
    this.data = data;
    this.calculateTotals();
    this.rowVAT = (new Decimal(this.rowTotal)).minus(new Decimal(this.rowNetTotal)).toDecimalPlaces(4).toNumber();
    this.flags = false;
    this.discount = 0;
    this.returnReasonID = undefined;
    this.returnReason = undefined;
    this.originalPriceZero = false;
    this._isContainer = false;
    this.hasContainer = typeof this?.data?.containerID !== "undefined" && parseInt(this.data.containerID) !== 0;
}

export function reassignProduct(data) {
    let product = new Product('',0,0,1,{});
    Object.assign(product, data);
    console.log('reassignProduct: ', data, product);
    return product;
}
