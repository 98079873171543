import React, {useEffect, useState} from "react";

export default function ({locale, style}){
    const [image, setImage] = useState(null);

    useEffect(() => {
        import('../flags/' + locale + '.png').then((image) => {
            setImage(image.default);
        }).catch(e => {
            
        });
    })

    return <img src={image} alt={locale} style={style}/>
}