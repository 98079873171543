import {
    SET_INTEGRATION,
    SET_TOTAL_SUM,
    ADD_PAYMENT,
    LOGIN,
    UPDATE_PAYMENT,
    INIT_PAYMENT,
    REMOVE_PAYMENT,
    ADD_CARD_PAYMENT_PROGRESS_MESSAGE,
    USE_ALL_IN_ONE_RECEIPT,
    SET_DISPLAY_PAYMENT_TYPES,
    SET_ID_CARD_IDENTIFICATION,
    SET_ZIP_CODE_SURVEY_COUNTER
} from "./actionTypes";
import {SET_PAYMENT_TYPES} from "../actionTypes";
import Decimal from "decimal.js";

const initialState = {
    integration: false,
    paymentTypes: false,
    displayPaymentTypes: {
        main:[],
        other:[]
    },
    loggedIn: false,
    payments: [],
    totalSum: 0,
    totalPaid: 0,
    currentCardPaymentProgressMessage: undefined,
    allInOneReceipt: false,
    idCardIdentification: false
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_INTEGRATION: {
            return Object.assign({}, state, {
                integration: action.payload
            })
        }
        case SET_PAYMENT_TYPES: {
            return Object.assign({}, state, {
                paymentTypes: action.payload
            });
        }
        case LOGIN: {
            return Object.assign({}, state, {
                loggedIn: true
            })
        }
        case SET_TOTAL_SUM: {
            return Object.assign({}, state, {
                totalSum: action.payload
            });
        }
        case ADD_PAYMENT: {
            let payments = Array.from(state.payments);
            let payment = action.payload;
            payments.push(payment);
            let sumPaid = payment.sumPaid;
            if(typeof payment.cashRounding !== "undefined"){
                sumPaid = new Decimal(sumPaid).plus(new Decimal(payment.cashRounding)).toDecimalPlaces(4).toString();
            }
            let totalPaid = new Decimal(state.totalPaid).plus(new Decimal(sumPaid)).toDecimalPlaces(4).toString();
            return Object.assign({}, state, {
                payments: payments,
                totalPaid: totalPaid
            });
        }
        case UPDATE_PAYMENT: {
            let payments = Array.from(state.payments);
            let payment = action.payload;
            const paymentIndex = payments.findIndex(el => el.index === payment.index);
            if(typeof payments[paymentIndex] === "undefined"){
                return state;
            }

            payment = Object.assign(payments[paymentIndex], payment);
            payments[paymentIndex] = payment;
            let totalPaid;
            if(payment.status === 'success'){
                totalPaid = payments.reduce((acc, payment) => {
                    if(payment.status === 'success'){
                        acc = acc.plus(new Decimal(payment.sumPaid));
                    }
                    return acc;
                }, new Decimal(0)).toDecimalPlaces(4).toString();
            }else{
                totalPaid = state.totalPaid;
            }
            return Object.assign({}, state, { payments, totalPaid});
        }
        case REMOVE_PAYMENT: {
            let removePayment = action.payload;
            let totalPaid = state.totalPaid;
            if(typeof state.payments.find((el) => el.index === removePayment.index) !== "undefined"){
                totalPaid = new Decimal(state.totalPaid).minus(new Decimal(removePayment.sumPaid)).toDecimalPlaces(4).toString();
            }
            return Object.assign({}, state, {
                payments: state.payments.filter(payment => {
                    return payment.index !== removePayment.index;
                }),
                totalPaid: totalPaid
            });
        }
        case INIT_PAYMENT: {
            return Object.assign({}, state, {
                payments: [],
                totalPaid: 0,
                totalSum: 0,
                currentCardPaymentProgressMessage: undefined
            });
        }
        case ADD_CARD_PAYMENT_PROGRESS_MESSAGE: {
            return Object.assign({}, state, {
                currentCardPaymentProgressMessage: action.message
            });
        }
        case USE_ALL_IN_ONE_RECEIPT: {
            return Object.assign({}, state, {
                allInOneReceipt: true
            });
        }
        case SET_ID_CARD_IDENTIFICATION: {
            return Object.assign({}, state, {
                idCardIdentification: action.value
            });
        }
        case SET_DISPLAY_PAYMENT_TYPES: {
            return Object.assign({}, state, {
                displayPaymentTypes: {
                    main: action.main,
                    other: action.other,
                }
            });
        }
        case SET_ZIP_CODE_SURVEY_COUNTER: {
            return Object.assign({}, state, {
                zipCodeSurveyCounter: action.value
            });
        }
        default:
            return state;
    }
}

export default reducer;
