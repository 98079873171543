import React from 'react';
import {Translate} from "react-localize-redux";
import dialogueImg from '../images/thanks.png';
import ImageBox from "../../buttons/ImageBox";
import Button from "../../buttons/Button";
import CountDown from "../../uiComponents/CountDown";

export default function ({onEmail, onPrint, onDecline, onTimeout}) {
    const showNoReceiptButton = window.AppConf?.showNoReceiptButton ?? true;
    let noReceiptButton;
    if(showNoReceiptButton){
        noReceiptButton = <Button
            onClick={onDecline}
            type="button"
            className={"extralargeButton yellowButton"}
            name={<Translate id="i don't want a receipt"/>}
            arrow="right"
            flencheClass={"flencheSmallRightBottom yellowFlenche"}
            style={{
                marginTop: '10px',
                alignSelf: 'center'
            }}
        />;
    }
    return (
        <div style={{
            textAlign: 'center',
            height: '100%'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 40px)',
                padding: '20px'
            }}>
                <CountDown
                    style={{
                        position: 'fixed',
                        right: '30px',
                        top: '90px',
                        border: '4px solid #c3c3c3',
                        color: '#c3c3c3',
                        borderRadius: '50%',
                        width: '150px',
                        height: '150px',
                        fontSize: '100px',
                        fontWeight: 'bold',
                        lineHeight: 1.5
                    }}
                    onDone={onTimeout}
                    timeInSeconds={window.AppConf.receiptTimeout || 10}
                />
                <div style={{
                    alignSelf: 'center'
                }}>
                    <img src={dialogueImg} alt={'dialogue'} style={{
                        float: 'left'
                    }}/>
                    <h1 style={{
                        float: 'left',
                        marginLeft: '40px'
                    }} className={'capitalizeFirst'}><Translate id={showNoReceiptButton ? 'do you want a receipt' : 'How would you like to receive your receipt'}/>?</h1>
                </div>
                <div style={{
                    width: '600px',
                    marginTop: '10px',
                    flexGrow: 1,
                    flexDirection: 'column',
                    alignSelf: 'center'
                }}>
                    <ImageBox
                        title={<Translate id={'email'}/>}
                        onClick={onEmail}
                        height={'100%'}
                        width={'45%'}
                        imageStyle={{
                            paddingTop: '80px'
                        }}
                        textStyle={{
                            fontSize: '48px'
                        }}
                        boxStyle={{
                            boxSizing: 'border-box',
                            paddingTop: '13vh'
                        }}
                    >
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '26vh'}}>&#xef12;</i>
                    </ImageBox>
                    <ImageBox
                        title={<Translate id={'Print'}/>}
                        onClick={onPrint}
                        height={'100%'}
                        width={'45%'}
                        imageStyle={{
                            paddingTop: '80px'
                        }}
                        textStyle={{
                            fontSize: '48px'
                        }}
                        containerStyle={{
                            float: 'right'
                        }}
                        boxStyle={{
                            boxSizing: 'border-box',
                            paddingTop: '13vh'
                        }}
                    >
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '26vh'}}>&#xedfd;</i>
                    </ImageBox>
                </div>
                { noReceiptButton }
            </div>
        </div>
    );
}