import React, {Component} from 'react';
import "../main.css"
import {Translate, withLocalize} from "react-localize-redux";
import Modal from "../../modal/Modal";
import {connect} from "react-redux";
import {changeAdministrationView, closeAdministration, setAdministrationUser} from "../../redux/actions";
import '../scrollbar.css';
import Logon from "./Logon";
import Main from "./Main";
import AddItem from "./AddItem";
import AddNotes from "./AddNotes";
import UnknownItem from "./UnknownItem";
import SecurityItem from "./SecurityItem";
import DialogueView from "../DialogueView";
import CashInOut from "./CashInOut";
import EditUI from "./EditUI";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        employees: state.rootReducer.employees,
        pointOfSaleID: state.rootReducer.posID,
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        close: (view) => {
            if(view === 'addItem' || view === 'cashInOut' || view === 'editUI' || view === 'addNotes'){
                dispatch(changeAdministrationView('main'));
            }else{
                dispatch(closeAdministration());
            }
        }
    }
};

class Administration extends Component{
    getLogonMessage(props){
        if(props.administration.view === 'unknownItem'){
            return <Translate id={'Logon to access the price form'}/>
        }
        return '';
    }
    render() {
        if(this.props.administration === false) return null;

        let content;

        let afterLogon = function (data) {
            return setAdministrationUser(data[0]);
        };

        if(this.props.administration.view === 'logon'){
            content = <Logon afterLogonAction={afterLogon}/>;
        }else if(this.props.administration.user === false){
            content = <Logon afterLogonAction={afterLogon} message={this.getLogonMessage(this.props)}/>;
        }else if(this.props.administration.view === 'main'){
            content = <Main/>;
        }else if(this.props.administration.view === 'addItem'){
            content = <AddItem/>;
        }else if(this.props.administration.view === 'addNotes'){
            content = <AddNotes/>;
        }else if(this.props.administration.view === 'cashInOut'){
            content = <CashInOut/>;
        } else if(this.props.administration.view === 'unknownItem'){
            content = <UnknownItem/>
        } else if(this.props.administration.view === 'securityItem'){
            content = <SecurityItem/>
        }else if(this.props.administration.view === 'editUI'){
            content = <EditUI/>
        }else if(this.props.administration.view === 'confirm'){
            content = (
                <DialogueView
                    confirmText={ <Translate id={"Yes"}/> }
                    cancelText={ <Translate id={"No"}/> }
                    onConfirm={ this.props.onConfirm}
                    onCancel={ () => {this.props.onCancel(this.props.productsInBasket);} }
                    title={ "Validate security item" }
                    message={ "securityItemMessage" }
                    canClose={false}
                    close={this.props.close}
                />
            );
        }

        return (
            <Modal
                className={"Administration"}
                content={content}
                onClose={e => {
                    this.props.close(this.props.administration.view);
                    this.props.administration.onClose();
                }}
                canClose={this.props.administration.canClose}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Administration));
