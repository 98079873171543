import {connect} from "react-redux";
import React, {Component} from "react";
import {closeApp} from "../redux/actions";
import closeImgErply from "../icons/Erply/close.png";

let mapStateToProps = state => {
    return {
        view: state?.rootReducer?.view || '',
        theme: state.rootReducer.theme
    }
}

let mapDispatchToProps = dispatch => {
    return {
        closeApp: () => {
            dispatch(closeApp());
        }
    }
}

class CloseAppButton extends Component{
    render(){
        if(this.props.view === 'login'){
            if(this.props.theme === 'Erply'){
                return (
                    <div onClick={ e => {this.props.closeApp(e);}}
                         style={{
                             float: 'right',
                             marginRight: '20px',
                             marginTop: '20px'
                         }}
                    >
                        <img src={closeImgErply} alt={'close'} width={24}/>
                    </div>
                );
            }
            return (
                <div onClick={ e => {this.props.closeApp(e);}}
                     className={"blueCircleBackground"}
                     style={{
                         float: 'right',
                         marginRight: '-14px',
                         marginTop: '-14px',
                         marginLeft: '10px'
                     }}
                >
                    <i className="icoFont">&#xeee1;</i>
                </div>
            );
        }else{
            return <div/>;
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CloseAppButton);