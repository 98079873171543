import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Login from "./login/Login";
import Main from "./main/Main";
import ReceiptComponent from "./util/ReceiptComponent";
import TestComponent from "./util/TestComponent";

class AppRouter extends Component {
    render() {
        return (
            <Router>
                <div style={{
                    height: 'calc(100% - 65px)'
                }}>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/app/" exact component={Main} />
                    <Route path="/receiptTest/" exact component={ReceiptComponent} />
                    <Route path="/testComponent/" exact component={TestComponent} />
                </div>
            </Router>
        );
    }
}

export default AppRouter;
