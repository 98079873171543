import React from "react";
import {Translate} from "react-localize-redux";

export default function Header({title, children}){

    return (
        <div style={{
            backgroundColor: '#CCCCCC',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '65px',
            display: 'flex',
            alignItems: 'center'
        }}>
            <span style={{
                fontSize: '20px',
                marginLeft: '20px'
            }}>
                <Translate id={title}/>
            </span>
            { children }
        </div>
    );
}