import React, { Component } from 'react';
import './button.css';

class Button extends Component{
    render() {
        let arrowLeft;
        let arrowRight;

        if(this.props.arrow === 'right'){
            arrowRight = (
                <i className="icoFont" style={{
                    position: "absolute",
                    right: "10px",
                    //top: "calc(50% - 10px)",
                    fontWeight: "800",
                    //fontSize: "20px"
                }}>&#xeaca;</i>
            );
        }else if(this.props.arrow === 'rightClose'){
            arrowRight = (
                <i className="icoFont" style={{
                    fontWeight: "800",
                    // fontSize: "20px",
                    marginLeft: "10px"
                }}>&#xeaca;</i>
            );
        }else if(this.props.arrow === 'left'){
            arrowLeft = (
                <i className="icoFont" style={{
                    position: "relative",
                    marginRight: "10px",
                    fontWeight: "800",
                    // fontSize: "20px"
                }}>&#xeac9;</i>
            );
        }
        let propsStyles = {
            display: 'inline-flex',
            overflow: 'hidden',
            ...this.props.style
        };

        return (
            <div onClick={this.props.onClick} className={"buttonContainer"} style={propsStyles}>
                <button className={"button " + this.props.className} style={{
                    display: 'inline-table',
                    overflow: 'hidden'
                }}>
                    { arrowLeft }
                    <span>{this.props.name}</span>
                    { arrowRight }
                    <div className={this.props.flencheClass}></div>
                </button>
            </div>
        );
    }
}

export default Button;
