import React, {Component} from "react";
import {connect} from "react-redux";
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {
    findCustomerByCardNumber,
    findCustomerByEmail,
    findCustomerByPhone,
    setLoading,
    setRightPanelFullscreen
} from "../../redux/actions";
import decathlonCardScanAnimation from "../images/decathlonCardScanAnimation.gif";
import scanDecathlonCardImg from "../images/scanDecathlonCardSmall.png";
import Button from "../../buttons/Button";
import EnterCustomerInfoFullscreen from "../EnterCustomerInfoFullscreen";
import {getCountryDialCode} from "../../redux/selectors";

const mapStateToProps = state => {
    const customer = state.rootReducer.customer;

    return {
        customer,
        customer_code_starts_with: state.rootReducer.erplyConf.touchpos_customer_code_starts_with || false,
        countryCode: state.rootReducer.erplyConf.DKTCountryCode,
        countryDialCode: getCountryDialCode(state.rootReducer.erplyConf.DKTCountryCode),
        translate: getTranslate(state.localize),
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setRightPanelFullscreen: (fullscreen) => {
            dispatch(setRightPanelFullscreen(fullscreen))
        },
        findCustomer: (customer, countryCode) => {
            dispatch(setLoading(true));

            if(typeof customer.email !== "undefined"){
                dispatch(findCustomerByEmail(customer.email, (customer)=>{
                    dispatch(setLoading(false));
                }, (result) => {
                    dispatch(setLoading(false));
                }));
            }else if(typeof customer.phone !== "undefined"){
                dispatch(findCustomerByPhone(customer.phone, countryCode, (customer)=>{
                    dispatch(setLoading(false));
                }, (result) => {
                    dispatch(setLoading(false));
                }));
            }else if(typeof customer.card_number !== "undefined"){
                dispatch(findCustomerByCardNumber(customer.card_number, (customer)=>{
                    dispatch(setLoading(false));
                }, (result) => {
                    dispatch(setLoading(false));
                }));
            }
        }
    }
}

class FullscreenCustomerSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'scan'
        };
    }

    componentDidMount() {
        this.props.setRightPanelFullscreen(true);
    }

    componentWillUnmount() {
        this.props.setRightPanelFullscreen(false);
    }

    render() {
        if(this.state.view === 'search'){
            return <EnterCustomerInfoFullscreen
                customerCodeStartsWith={this.props.customer_code_starts_with}
                countryDialCode={this.props.countryDialCode}
                translate={this.props.translate}
                onBack={() => {
                    this.setState({
                        view: 'scan'
                    });
                }}
                onDone={(customer) => {
                    this.props.findCustomer(customer, this.props.countryCode);
                }}
            />;
        }
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                padding: "60px 80px",
                boxSizing: "border-box"
            }}>
                <div style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: "1fr",
                }}>
                    <div style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingRight: "50px",
                        whiteSpace: "pre-line",
                        lineHeight: 1.5
                    }}>
                        <Translate id={"Scan your loyalty card or search manually"}/>
                    </div>
                    <div style={{
                        height: "480px",
                        alignItems: "center",
                        display: "flex"
                    }}>
                        <img src={'img/customerSearch.png'} alt={"customer search"} onError={(error) => {
                            error.target.src = window.AppConf.scanDecathlonCardGif ? decathlonCardScanAnimation : scanDecathlonCardImg;
                        }} style={{
                            maxWidth: "100%"
                        }}/>
                    </div>
                </div>
                <div style={{
                    flexGrow: "1"
                }}/>
                <div style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: "1fr",
                }}>
                    <div onClick={this.props.onSkip} style={{margin: '6px'}}>
                        <Button type="button" className={"wideButton grayButton"} name={<Translate id="skip"/>} style={{
                            width: '100%'
                        }}/>
                    </div>
                    <div onClick={() => {
                        this.setState({
                            view: 'search'
                        })
                    }} style={{margin: '6px'}}>
                        <Button type="button" className={"wideButton darkerBlueButton"} name={<Translate id="search"/>} style={{
                            width: '100%'
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(FullscreenCustomerSearch));