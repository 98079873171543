import React from "react";

export default function ({item, onCheckToggle}) {
    return (
        <tr className={"productLine"} onClick={() => {
            onCheckToggle(!item.checked);
        }}>
            <td>
                <input type={"checkbox"} readOnly={"readonly"} checked={item.checked}/>
            </td>
            <td>
                {item.product.itemName}
            </td>
            <td>
                {item.product.code}
            </td>
            <td>
                {item.product.amount}
            </td>
            <td>
                {item.product.rowTotal}
            </td>
        </tr>
    );
}