import NexoCrypto from "./nexoCrypto";
import * as Sentry from "@sentry/react";
import {APP_URL} from "../../../appConstants";

const PMA = {
    logToConsole: true,
    getUrl: () => {
        if(PMA.integrationType === false) return false;
        if(PMA.integrationType === 'cloud'){
            return `${PMA.proxyUrl}?nextURL=https://terminal-api-${PMA.env}.adyen.com/sync`;
        }else{
            return APP_URL + 'postProxy?u=' + btoa(`https://${PMA.poiid}.${PMA.env}.terminal.adyen.com:8443/nexo`);
        }
    },
    proxyUrl: 'https://adyen-proxy-sb.nimi24.com/',
    poiid: false,
    recon: false,
    key: false,
    apiKey: false,
    env: false,
    idEmail: false,
    idPassword: false,
    integrationType: false
};

PMA.init = function({adyenPoiid, adyenRecon, adyenKey, adyenAPIKey, adyenEnv, adyenIDEmail, adyenIDPassword, posName, currency, adyenIntegrationType}){
    PMA.poiid = adyenPoiid;
    PMA.recon = adyenRecon;
    PMA.key = adyenKey;
    PMA.apiKey = adyenAPIKey;
    PMA.env = adyenEnv;
    PMA.idEmail = adyenIDEmail;
    PMA.idPassword = adyenIDPassword;
    PMA.saleID = posName;
    PMA.currency = currency;
    PMA.integrationType = adyenIntegrationType;
    PMA.nexoCrypto = new NexoCrypto();
    PMA.nexoCrypto.configure(PMA.key, 'erplyKey', '0');
};

PMA.log = function() {
    if(PMA.logToConsole){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('PMA:');
        console.log.apply(this, newArgs);
    }
};

PMA.getNested = function (obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
};

PMA.onConnectionStateChange = function(connected){
    PMA.log('onConnectionStateChange original', connected)
};

PMA.requests = {
    getMessageHeader: function(messageClass, messageCategory, serviceID, saleID){
        return {
            "ProtocolVersion":"3.0",
            "MessageClass":messageClass,
            "MessageCategory":messageCategory,
            "MessageType":"Request",
            "ServiceID":serviceID,
            "SaleID":saleID,
            "POIID":PMA.poiid
        }
    },
    getServiceID: function(){
        return Math.floor(Date.now() / 1000).toString();
    },
    request: function(body){
        const url = PMA.getUrl();
        const options = {
            method: 'POST',
            headers: {
                'x-API-key': PMA.apiKey,
                'Content-Type': 'application/json'
            },
            body: PMA.nexoCrypto.encrypt_and_hmac(JSON.stringify(body))
        };

        return new Promise(((resolve, reject) => {
            fetch(url, options).then(response => response.text()).then((response) => {
                try {
                    let result = JSON.parse(PMA.nexoCrypto.decrypt_and_validate_hmac(response));
                    resolve(result);
                }catch (e){
                    PMA.log('payment failed response', response, e);
                    Sentry.captureMessage('Adyen payment failed. Terminal - ' + PMA.poiid, "info");
                    reject(e);
                }
            }).catch(reject);
        }));
    },
    login: function () {
        let serviceID = PMA.requests.getServiceID();

        let messageHeader = PMA.requests.getMessageHeader("Service", "Diagnosis", serviceID, PMA.saleID);
        let body = {
            "SaleToPOIRequest":{
                "MessageHeader": messageHeader,
                "DiagnosisRequest":{
                    "HostDiagnosisFlag":false
                }
            }
        };

        return new Promise((resolve, reject) => {
            PMA.requests.request(body).then(response => {
                PMA.log('login response', response);
                if(typeof response.SaleToPOIResponse !== "undefined"){
                    let poiStatus = response.SaleToPOIResponse.DiagnosisResponse.POIStatus;
                    if(poiStatus.CommunicationOKFlag && poiStatus.GlobalStatus === 'OK'){
                        PMA.onConnectionStateChange(true);
                        resolve();
                    }else{
                        PMA.onConnectionStateChange(false);
                        reject();
                    }
                }else{
                    PMA.onConnectionStateChange(false);
                    reject();
                }

            }, response => {
                PMA.log('login failed response', response);
                PMA.onConnectionStateChange(false);
                reject();
            });
        });

    },
    payment: function (amount, transactionID) {
        let serviceID = PMA.requests.getServiceID();
        let messageHeader = PMA.requests.getMessageHeader("Service", "Payment", serviceID, PMA.saleID);
        const transactionISOTime = new Date().toISOString();
        let body = {
            "SaleToPOIRequest":{
                "MessageHeader": messageHeader,
                "PaymentRequest":{
                    "SaleData":{
                        "SaleTransactionID":{
                            "TransactionID": transactionID.toString(), //PMA.saleID + '-' + serviceID,
                            "TimeStamp": transactionISOTime
                        }
                    },
                    "PaymentTransaction":{
                        "AmountsReq":{
                            "Currency": PMA.currency,
                            "RequestedAmount":Math.abs(amount)
                        }
                    }
                }
            }
        };

        if(amount < 0){
            body["SaleToPOIRequest"]["PaymentRequest"]["PaymentData"] = {
                "PaymentType": "Refund"
            }
        }

        return new Promise((resolve, reject) => {
            PMA.requests.request(body).then(response => {
                PMA.log('payment response', response);
                let paymentResponse = PMA.getNested(response, 'SaleToPOIResponse', 'PaymentResponse');
                let messageHeader = PMA.getNested(response, 'SaleToPOIResponse', 'MessageHeader');
                if(typeof paymentResponse !== "undefined" && paymentResponse.Response.Result === 'Success'){
                    resolve({paymentResponse, messageHeader});
                }else{
                    let paymentReceipt = PMA.getNested(response,'SaleToPOIResponse', 'PaymentResponse', 'PaymentReceipt');
                    reject({paymentReceipt});
                }
            }, response => {
                PMA.requests.waitUntilPaymentProcessed(serviceID).then(waitResponse => {
                    PMA.log('wait until payment processed', waitResponse);
                    let repeatedMessageResponse = PMA.getNested(waitResponse,'SaleToPOIResponse','TransactionStatusResponse','RepeatedMessageResponse');
                    if(typeof repeatedMessageResponse !== "undefined"){
                        let paymentResponse = PMA.getNested(repeatedMessageResponse,'RepeatedResponseMessageBody','PaymentResponse');
                        if(typeof paymentResponse !== "undefined" && paymentResponse.Response.Result === 'Success'){
                            PMA.log('resolving');
                            resolve({paymentResponse: repeatedMessageResponse.RepeatedResponseMessageBody.PaymentResponse, messageHeader: repeatedMessageResponse.MessageHeader});
                        }else{
                            let paymentReceipt = typeof paymentResponse !== "undefined" ? paymentResponse.PaymentReceipt : undefined;
                            reject({paymentReceipt});
                        }
                    }else{
                        reject(waitResponse);
                    }
                }, waitResponse => {
                    PMA.log('wait until payment processed rejected', waitResponse);
                    reject(waitResponse);
                });
            });
        });
    },
    waitUntilPaymentProcessed: function(paymentServiceID, retry = 0) {
        return new Promise((resolve, reject) => {
            let tryAgain = function (response, retry) {
                if(retry > 2){
                    reject(response);
                }else {
                    let nextRetry = retry + 1;
                    setTimeout(() => {
                        resolve(PMA.requests.waitUntilPaymentProcessed(paymentServiceID, nextRetry));
                    }, 2000);
                }
            };

            PMA.requests.verifyPaymentStatus(paymentServiceID).then(response => {
                PMA.log('waitUntilPaymentProcessed', response);
                let transactionStatusResponse = PMA.getNested(response,'SaleToPOIResponse','TransactionStatusResponse','Response');
                if(typeof transactionStatusResponse !== "undefined"){
                    if(transactionStatusResponse.Result === 'Success'){
                        PMA.log('waitUntilPaymentProcessed success');
                        resolve(response);
                    }else{
                        if(transactionStatusResponse.ErrorCondition === 'InProgress'){
                            PMA.log('waitUntilPaymentProcessed inProgress');
                            tryAgain(response, 0);
                        }else{
                            PMA.log('waitUntilPaymentProcessed failed', response);
                            reject(response);
                        }
                    }
                }else{
                    tryAgain(response, retry);
                }
            }, response => {
                PMA.log('waitUntilPaymentProcessed rejected', response);
                tryAgain(response, retry);
            });
        });
    },
    verifyPaymentStatus: function (paymentServiceID) {
        let serviceID = PMA.requests.getServiceID();
        let messageHeader = PMA.requests.getMessageHeader("Service", "TransactionStatus", serviceID, PMA.saleID);

        let body = {
            "SaleToPOIRequest":{
                "MessageHeader": messageHeader,
                "TransactionStatusRequest": {
                    "ReceiptReprintFlag": true,
                    "DocumentQualifier": [
                        "CashierReceipt",
                        "CustomerReceipt"
                    ],
                    "MessageReference": {
                        "SaleID": PMA.saleID,
                        "ServiceID": paymentServiceID,
                        "MessageCategory": "Payment"
                    }
                }
            }
        };

        return new Promise((resolve, reject) => {
            PMA.requests.request(body).then(response => {
                PMA.log('verify payment status response', response);
                resolve(response);
            }, response => {
                PMA.log('verify payment status failed response', response);
                reject(response);
            });
        });
    },
    voidPayment: function (transactionID) {
        let serviceID = PMA.requests.getServiceID();
        let messageHeader = PMA.requests.getMessageHeader("Service", "Reversal", serviceID, PMA.saleID);
        const transactionISOTime = new Date().toISOString();
        let body = {
            "SaleToPOIRequest":{
                "MessageHeader": messageHeader,
                "ReversalRequest":{
                    "OriginalPOITransaction":{
                        "POITransactionID":{
                            "TransactionID":transactionID,
                            "TimeStamp":transactionISOTime
                        }
                    },
                    "ReversalReason":"MerchantCancel"
                }
            }
        };

        return new Promise((resolve, reject) => {
            PMA.requests.request(body).then(response => {
                PMA.log('void response', response);
                let reversalResponse = PMA.getNested(response, 'SaleToPOIResponse', 'ReversalResponse');

                if(typeof reversalResponse !== "undefined"){
                    let paymentReceipt = PMA.getNested(response,'SaleToPOIResponse', 'ReversalResponse', 'PaymentReceipt');
                    if(reversalResponse.Response.Result === 'Success'){
                        resolve({reversalResponse, paymentReceipt});
                    }else{
                        reject({reversalResponse, paymentReceipt});
                    }
                }
            }, response => {
                PMA.log('void failed response', response);
                reject('no connection');
            });
        });
    }
};

export default PMA;