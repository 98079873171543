import React, {Component} from "react";
import StatusBar from "./statusBar/StatusBar";
import Alert from "./alerts/Alert";
import Loading from "./main/Loading";
import AppRouter from "./AppRouter";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

class Main extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        let className = process.env.ERPLY_MODE ? 'AppErply' : 'App'
        return (
            <div className={className}>
                <StatusBar/>
                <Alert/>
                <Loading/>
                <AppRouter />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Main));
