export const validateEmail = function (email) {
    if(email === ''){
        return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validatePhone = function (phone) {
    if(phone === ''){
        return false;
    }
    const re = /^([+\d].*)?\d$/;
    return re.test(String(phone));
};

export default function (cardNumber = "") {
    this.card_number = cardNumber;
    this.canRemove = true;

    this.getCustomerAttribute = function () {
        let data = {};
        if(this.card_number !== ''){
            data['card_number'] = this.card_number;
        }
        if(typeof this.id_person !== 'undefined'){
            data['id_person'] = this.id_person;
        } else{
            if(typeof this.email !== 'undefined'){
                data['email'] = this.email;
            }
            if(typeof this.phone !== 'undefined'){
                data['phone'] = this.phone;
            }
        }
        if(typeof this.zipCode !== "undefined"){
            data['zipCode'] = this.zipCode;
        }
        if(typeof this.taxRegistrationNumber !== 'undefined'){
            data['taxRegistrationNumber'] = this.taxRegistrationNumber;
        }
        if(typeof this.localRequirements !== 'undefined'){
            data.localRequirements = this.localRequirements;
        }
        if(typeof this.businessName !== 'undefined'){
            data.businessName = this.businessName;
        }
        if(typeof this.firstName !== 'undefined'){
            data.firstName = this.firstName;
        }
        if(typeof this.lastName !== 'undefined'){
            data.lastName = this.lastName;
        }
        if(typeof this.locale !== 'undefined'){
            data.locale = this.locale;
        }
        return data;
    };

}