import React from 'react';
import './button.css';
import checkMark from './images/checkMarkWhite.png';

export default function (props) {
    return (
        <div
            onClick={props.onClick}
            style={{
                backgroundColor: '#CCCCCC',
                padding: '15px 10px',
                borderRadius: '5px',
                maxWidth: '40px',
                ...props.style
            }}
        >
            <img src={checkMark} alt={'Check mark'} width={20}/>
        </div>
    );
}