import React from 'react';
import '../statusBar.css';
import LanguageSelect from "./LanguageSelect";
import Steps from "./Steps";
import {UserIcon} from "./User";
import CloseAppButton from "../CloseAppButton";

export default function () {
    return (
        <div>
            <LanguageSelect />
            <div className={"statusBar"} style={{
                padding: "20px"
            }}>
                <Steps/>
                <CloseAppButton/>
                <UserIcon/>
            </div>
        </div>
    );
}