import {getCafaConf} from "../redux/actions";
import {addTranslationForLanguage, initialize} from "react-localize-redux";
import {renderToStaticMarkup} from "react-dom/server";
import {SET_PLUGIN_URLS} from "../redux/actionTypes";
import {SET_INTEGRATION} from "../redux/payment/actionTypes";

export const addTranslations = (dispatch, languages) => {
    for(let translation of languages){
        console.log('translation', translation);
        fetch('/translations/' + translation + '.json').then(response => response.json()).then(response=>{
            dispatch(addTranslationForLanguage(response, translation));
        }).catch(() => {});
    }
}

const getBoolean = (value) => {
    return value === "1";
}

export const setDefaultAppConf = () => {
    window.AppConf = {
        defaultConf: true,
        plugins: [],
        preprod: true,
        languages: ['en'],
        enableRFID: false,
        handleSecurityTagsAfterScanning: false,
        EFT: 'demo',
        paymentTypes: {
            integration: [],
            manualInput: [],
            voucher: []
        },
        voucherProducts: [],
        manualVoucherInput: [],
        giftCardItemCode: "0000000",
        completeTransactionOnGiftCardActivationFail: false,
        transactionTimeout: 120,
        scanCustomerCardTimeout: 30,
        showNoReceiptButton: true,
        printReceiptOnTimeout: false,
        receiptTimeout: 15,
        mixedTransaction: true,
        showPrintingDialog: true,
        printDecathlonReceipt: false,
        ignoreDiscountableFlag: true,
        showHangerGif:true,
        scanDecathlonCardGif: false,
        sendSentryLogs: false,
        usedProductConfirm: true,
        fullscreenCustomerSearch: false,
        unifiedVersion: true
    };
    if(typeof process.env.REACT_APP_POSLOG_URL !== "undefined"){
        window.AppConf.POSLog = {
            url: process.env.REACT_APP_POSLOG_URL
        }
    }
    if(typeof process.env.REACT_APP_DECATHLON_CRM_URL !== "undefined"){
        window.AppConf.CustomerAPI = {
            url: process.env.REACT_APP_DECATHLON_CRM_URL
        }
    }
    if(typeof process.env.REACT_APP_ONEPAY_URL !== "undefined"){
        window.AppConf.FirstData = {
            url: process.env.REACT_APP_ONEPAY_URL
        }
    }
}

export default (dispatch, pos) => {
    if(window.AppConf.defaultConf){
        dispatch(getCafaConf( (data) => {
            console.log("CAFA_CONF", data);

            if(data === false || data === null){
                return {
                    type: 'CAFA_CONF_NOT_SET'
                }
            }

            for(let conf of data){
                if(conf.level === 'Pos' && conf.level_id === pos.pointOfSaleID.toString()){
                    switch (conf.name){
                        case 'languages': {
                            let languages = conf.value.split(',');
                            dispatch(initialize({
                                languages,
                                options: {
                                    renderToStaticMarkup,
                                    renderInnerHtml: false,
                                    onMissingTranslation: ({ translationId, languageCode }) => {return translationId;}
                                }
                            }));
                            addTranslations(dispatch, languages);
                            break;
                        }
                        case 'plugins': {
                            dispatch({
                                type: SET_PLUGIN_URLS,
                                payload: conf.value.split(',')
                            })
                            break;
                        }
                        case 'EFT':
                            dispatch({
                                type: SET_INTEGRATION,
                                payload: conf.value
                            });
                            window.AppConf[conf.name] = conf.value;
                            break;
                        case 'voucherProducts': {
                            window.AppConf[conf.name] = Object.values(conf.value);
                            break;
                        }
                        case 'manualVoucherInput': {
                            window.AppConf[conf.name] = conf.value.split(',');
                            break;
                        }
                        case 'paymentTypeGiftCard': {
                            window.AppConf.paymentTypes.giftCard = getBoolean(conf.value);
                            break;
                        }
                        case 'paymentTypeCard': {
                            window.AppConf.paymentTypes.card = Object.values(conf.value);
                            break;
                        }
                        case 'paymentTypeIntegration': {
                            window.AppConf.paymentTypes.integration = Object.values(conf.value);
                            break;
                        }
                        case 'paymentTypeManualInput': {
                            window.AppConf.paymentTypes.manualInput = Object.values(conf.value);
                            break;
                        }
                        case 'paymentTypeVoucher': {
                            window.AppConf.paymentTypes.voucher = Object.values(conf.value);
                            break;
                        }
                        case 'preprod':
                        case 'handleSecurityTagsAfterScanning':
                        case 'completeTransactionOnGiftCardActivationFail':
                        case 'showNoReceiptButton':
                        case 'printReceiptOnTimeout':
                        case 'showPrintingDialog':
                        case 'alwaysPrintReceipt':
                        case 'mixedTransaction':
                        case 'printDecathlonReceipt':
                        case 'ignoreDiscountableFlag':
                        case 'displayNetPrices':
                        case 'showHangerGif':
                        case 'scanDecathlonCardGif':
                        case 'decathlonQRCodeSupervisor':
                        case 'sendSentryLogs':
                        case 'usedProductConfirm':
                        case 'fullscreenCustomerSearch':
                        case 'allowGiftCardPaymentWithGiftCard':
                        case 'useZipCodeSurvey':
                        case 'enableRFID': {
                            window.AppConf[conf.name] = getBoolean(conf.value);
                            break;
                        }
                        default: {
                            window.AppConf[conf.name] = conf.value;
                        }

                    }
                }
            }

            return {
                type: 'CAFA_CONF_SET'
            }
        }, () => {

        }));
    }
}