import {
    GIFT_CARD_API,
    PAYMENT_API,
    CARD_PAYMENT_FAILED,
    VOID_ALL_PAYMENTS
} from "./actionTypes";
import $ from "jquery";
import {getPeripheral} from "../selectors";
import {
    addErrorAlert,
    addPeripheral,
    changePaymentMode,
    removePeripheral,
    setLoading,
    showDialogue,
    updatePeripheral
} from "../actions";
import Peripheral from "../dto/peripheral";
import {CHANGE_MODE, INIT_TRANSACTION, SCANNER_DETECTED} from "../actionTypes";
import {
    addCardPaymentProgressMessage,
    removePayment,
    updatePayment,
    voidCardPayment,
    voidGiftCardPayment,
    voidIntegratedPayment
} from "./actions";
import {APP_MODE_PAYMENT, PAYMENT_MODE_OPTIONS} from "../appConstants";
import {checkGiftCard, giftCard, posEvents as giftCardPOSEvents} from "./integrations/giftCard/middleware";
import {checkGiftCard as checkGiftCardErply, giftCard as giftCardErply, posEvents as giftCardPOSEventsErply} from "./integrations/giftCardErply/middleware";

const paymentMiddleware = (store) => next => action => {
    console.log('payment middleware called', action);
    next(action);
    const state = Object.assign({}, store.getState());

    if (action.type === SCANNER_DETECTED) {
        const scannedCode = action.payload;
        if(state.rootReducer.paymentMode === 'giftCard'){
            store.dispatch(setLoading(true));
            store.dispatch(changePaymentMode("giftCardCheck"));
            if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                return checkGiftCard(store.dispatch, state, scannedCode, true, (success)=>{
                    store.dispatch(setLoading(false));
                });
            }else{
                return checkGiftCardErply(store.dispatch, state, scannedCode, true, (success)=>{
                    store.dispatch(setLoading(false));
                });
            }
        }

    }else if (action.type === GIFT_CARD_API){
        if(process.env.REACT_APP_ERPLY_MODE !== "1"){
            return giftCard(store.dispatch, state, action.payload);
        }else{
            return giftCardErply(store.dispatch, state, action.payload);
        }
    }else if (action.type === CHANGE_MODE){
        if(action.payload.mode === APP_MODE_PAYMENT){
            if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                giftCardPOSEvents.beforeSaveTransaction(store);
            }else{
                giftCardPOSEventsErply.beforeSaveTransaction(store);
            }
        }
    }else if (action.type === INIT_TRANSACTION){
        if(process.env.REACT_APP_ERPLY_MODE !== "1"){
            giftCardPOSEvents.initTransaction();
        }else{
            giftCardPOSEventsErply.initTransaction();
        }
    }else if (action.type === CARD_PAYMENT_FAILED){
        store.dispatch(changePaymentMode(PAYMENT_MODE_OPTIONS));
        store.dispatch(removePayment(action.payment));
        store.dispatch(addErrorAlert(action.message));
        return;
    }else if (action.type === VOID_ALL_PAYMENTS){
        state.payment.payments.forEach((payment) => {
            if(payment.type === 'CARD'){
                payment.status = 'voiding';
                store.dispatch(updatePayment(payment));
                let tryVoidCardPayment = () => {
                    store.dispatch(setLoading(true));
                    store.dispatch(voidCardPayment(payment, () => {
                        store.dispatch(setLoading(false));
                        return removePayment(payment);
                    }, (message = '') => {
                        store.dispatch(setLoading(false));
                        return showDialogue('Could not void payment', message, 'retry', () => {
                            tryVoidCardPayment();
                        }, () => {
                            store.dispatch(removePayment(payment));
                        }, 'cancel', false);
                    }));
                };
                tryVoidCardPayment();
            }else if(payment.isIntegrated){
                payment.status = 'voiding';
                store.dispatch(updatePayment(payment));
                let tryVoidIntegratedPayment = () => {
                    store.dispatch(setLoading(true));
                    store.dispatch(voidIntegratedPayment(payment, () => {
                        store.dispatch(setLoading(false));
                        return removePayment(payment);
                    }, (message = '') => {
                        store.dispatch(setLoading(false));
                        return showDialogue('Could not void payment', message, 'retry', () => {
                            tryVoidIntegratedPayment();
                        }, () => {
                            store.dispatch(removePayment(payment));
                        }, 'cancel', false);
                    }));
                };
                tryVoidIntegratedPayment();
            } else if(payment.type === 'GIFTCARD'){
                let tryVoidGiftCardPayment = () => {
                    store.dispatch(voidGiftCardPayment(payment.giftCard.transactionID, () => {
                        store.dispatch(removePayment(payment));
                    }, () => {
                        store.dispatch(showDialogue('', 'Could not void gift card payment', 'retry', () => {
                            tryVoidGiftCardPayment();
                        }, () => {
                            store.dispatch(removePayment(payment));
                        }, 'cancel', false));
                    }));
                };
                if(payment.status === 'success'){
                    tryVoidGiftCardPayment();
                }
            }else{
                store.dispatch(removePayment(payment));
            }
        });
    }

    if (action.type !== PAYMENT_API) {
        return;
    }
    const {
        data,
        onSuccess,
        onFailure,
        successActions
    } = action.payload;


    const integrationName = typeof data?.integration === "string" ? data.integration : data?.integration?.type ?? state.payment.integration;

    if(data.request === 'login'){
        let peripheral = new Peripheral('paymentTerminal');
        peripheral.setConnecting();
        store.dispatch(addPeripheral(peripheral));
    }

    import('./integrations/' + integrationName + '/middleware').then((module) => {
        //store.dispatch(addCardPaymentProgressMessage("Follow payment terminal instructions"));
        module.default(data, function (result) {
            store.dispatch(onSuccess(result));
            if(successActions){
                $(successActions).each(function (index, successAction) {
                    store.dispatch(successAction(result));
                });
            }
        }, function (payment, result) {
            store.dispatch(onFailure(payment, result));
        }, state, function (connected) {
            console.log('payment middleware: connectionStateChange', connected);
            const peripheral = getPeripheral(store.getState().rootReducer.peripherals, 'paymentTerminal');
            if(peripheral !== false){
                if(connected){
                    peripheral.setConnected();
                }else{
                    peripheral.setConnectionFailed();
                }
                store.dispatch(updatePeripheral(peripheral));
            }
        }, (progressMessage) => {
            store.dispatch(addCardPaymentProgressMessage(progressMessage));
        }, store.dispatch);
    }, () => {
        if(data.request === 'login'){
            store.dispatch(removePeripheral('paymentTerminal'));
            store.dispatch(addErrorAlert('Payment terminal is not available!'));
        }else if (data.request === 'processCard'){
            store.dispatch(onFailure(data.cardPayment, 'Payment terminal is not available!'));
        }
    });
};

export default paymentMiddleware;