import React, {Component} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import Modal from "../modal/Modal";
import {connect} from "react-redux";
import {getNotConnectedPeripherals} from "../redux/selectors";
import {closeQCO} from "../redux/actions";


const mapStateToProps = state => {
    return {
        peripherals: state.rootReducer.peripherals,
        pos: state.rootReducer.pos
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeQCO: () => {
            dispatch(closeQCO());
        }
    }
};

class Peripherals extends Component{
    render() {
        if(this.props.peripherals.length === 0 || this.props.pos === false) return null;

        const notConnected = getNotConnectedPeripherals(this.props.peripherals);
        if(notConnected.length === 0) return null;
        let list = notConnected.map(function (peripheral, index) {
            return (
                <li key={index}>
                    {peripheral.name}: <Translate id={peripheral.status}/>
                </li>
            );
        });

        let content = (
            <ul>{list}</ul>
        );

        return (
            <Modal className={"Administration"} content={content} onClose={e => {
                this.props.closeQCO();
            }}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Peripherals));