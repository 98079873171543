import React, {Component, Fragment} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import deleteIcon from "./images/trash.png";
import deleteIconErply from "./images/Erply/trash.png";
import Decimal from "decimal.js";
import {displayPrice, getAttributeValue, getProductPackageWeight, numberIsOdd} from "../util/helperFunctions";

class ProductLine extends Component{
    render() {
        let removeProduct = this.props.removeProduct !== false && !this.props.product._isContainer ? (
            this.props.theme === 'Erply' ?
                <td onClick={e => this.props.removeProduct(this.props.product)} style={{
                    width: "64px",
                    boxSizing: "border-box",
                    borderLeft: '1px solid #E6E6E6'
                }}>
                    <img src={deleteIconErply} alt={'trash'}/>
                </td> :
                <td onClick={e => this.props.removeProduct(this.props.product)} style={{
                    width: "20px",
                    paddingRight: "33px"
                }}>
                    <img src={deleteIcon} alt={'trash'} style={{
                        maxHeight: '22px'
                    }}/>
                </td>
        ) : <td/>;
        let rfidInfo = this.props.product.data.RFIDTag !== undefined && this.props.product.data.RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE' &&
            typeof this.props.product.giftCardCode === "undefined" ?
            <span className={"unitPrice"}><br/><Translate id={"RFID"}/> : {this.props.product.data.RFIDTag.SERIAL_NUMBER}</span> : null;
        let packageWeight;
        let packageWeightValue = getProductPackageWeight(this.props.product.data);
        if(typeof packageWeightValue !== "undefined"){
            packageWeight = <span className={"unitPrice"}><br/><Translate id={'package weight'}/>: {packageWeightValue}{this.props.product.data.unitName}</span>;
        }
        let returnReason = '';
        if(this.props.product.returnReason !== undefined){
            returnReason = <span className={"unitPrice"}><br/>{ this.props.product.returnReason.name }</span>;
        }
        let discountInfo = '';
        if(this.props.product.hasManualDiscount()){
            discountInfo = (
                <span className={"unitPrice"}>
                    <br/>
                    <Translate id={'Disc'}/>: {parseFloat(this.props.product.manualDiscountPercentage).toFixed(2)}% {displayPrice(this.props.displayNetPrices ? this.props.product.getNetPrice(this.props.product.manualDiscountTotal, this.props.product.data.vatrate) : this.props.product.manualDiscountTotal)}{this.props.currency}
                </span>);
        }
        let priceListPromotionLines = this.props.product.getAppliedPriceListRules();
        let campaignPromotionLines = this.props.product.getAppliedCampaignRules();

        let plusAmount;
        let minusAmount;

        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            plusAmount = (
                <i
                    onClick={e => this.props.editProductAmount(this.props.product, (new Decimal(this.props.product.amount)).plus(1).toDecimalPlaces(4).toNumber())}
                    className={"icoFont"}
                    style={{
                        fontSize: '16px',
                        marginLeft: '5px'
                    }}>
                    &#xefc2;
                </i>
            );
            minusAmount = (
                <i
                    onClick={e => this.props.editProductAmount(this.props.product, (new Decimal(this.props.product.amount)).minus(1).toDecimalPlaces(4).toNumber())}
                    className={"icoFont"}
                    style={{
                        fontSize: '16px',
                        marginRight: '5px'
                    }}>
                    &#xef9a;
                </i>
            );
        }

        let productInfoButton;
        let productShortDescription;
        if(window.AppConf.productDescriptionFromAttributes){
            productShortDescription = getAttributeValue(this.props.product.data, 'shortDescriptionSCO');
        }else{
            productShortDescription = this.props.product.data.description;
        }
        if(typeof productShortDescription !== "undefined" && productShortDescription !== ""){
            productInfoButton = (
                <span
                    style={{
                        fontSize: '28px',
                        cursor: 'pointer',
                        float: 'right',
                        lineHeight: 2
                    }}
                    onClick={ e => {
                        this.props.onInfoButtonClick(this.props.product);
                    }}
                >
                    <i className="icoFont">&#xef4e;</i>
                </span>
            );
        }

        let displayAmount;
        if(!this.props.product._isContainer){
            displayAmount = (
                <div>
                    { minusAmount }
                    <div
                        onClick={e => this.props.editProductAmount(this.props.product)}
                        style={{
                            display: 'inline'
                        }}
                        className={"grayBackground roundBackground"}>
                        {this.props.product.amount}
                    </div>
                    { plusAmount }
                </div>
            );
        }

        return (
            <tr className={`productLine ${this.props.product._isContainer ? 'productLineSmall' : ''} ${this.props.theme === 'Erply' && numberIsOdd(this.props.product.lineNumber) ? 'productLineOdd' : ''}`}>
                <td className={"productLineNameColumn"}>
                    { productInfoButton }
                    <span>{this.props.product.name}</span>
                    <br/>
                    <span className={"productCode"}>{this.props.product.data.code}</span>
                    <br/>
                    <span className={process.env.REACT_APP_ERPLY_MODE === "1" ? "unitPriceErply" : "unitPrice"}><Translate id={"unitPrice"}/> : {this.props.product.getDisplayUnitPrice(this.props.displayNetPrices)} {this.props.currency}</span>
                    { rfidInfo }
                    { returnReason }
                    { packageWeight }
                </td>
                <td>
                    { displayAmount }
                </td>
                <td className={"productLinePriceColumn"} onClick={e => this.props.editProductPrice(this.props.product, this.props.currency)}>
                    {this.props.product.getDisplayTotalPrice(this.props.displayNetPrices)} {this.props.currency}
                    { this.props.displayTax ? (
                        <Fragment>
                            <br/>
                            <span className={"unitPrice"}>
                                &nbsp;<Translate id={"Taxes"}/>: {displayPrice(this.props.product.rowVAT)} {this.props.currency}
                            </span>
                        </Fragment>
                    ) : null}
                    <br/>
                    { discountInfo }
                    { priceListPromotionLines.map((line, index) => {
                        return (
                            <span className={"unitPrice"} key={index}>
                                <br/>
                                { line.name } { line.totalDiscount }{this.props.currency}
                            </span>
                        );
                    })}
                    { campaignPromotionLines.map((line, index) => {
                        return (
                            <span className={"unitPrice"} key={index}>
                                <br/>
                                { line.name } { line.totalDiscount }{this.props.currency}
                            </span>
                        );
                    })}
                </td>
                {removeProduct}
            </tr>
        );
    }
}

export default withLocalize(ProductLine)
