import React, {Component} from 'react';
import './login.css'
import Button from "../buttons/Button";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {
    addErrorAlert,
    changeOperationMode,
    changeView,
    checkPOSDayOpened,
    closeModal,
    closeQCO,
    getAllowedWarehouses,
    getCompanyInfo,
    getEmployees,
    getErplyConfiguration,
    getErplyServiceEndpoints,
    getPointsOfSale,
    getProductCategories,
    getProductGroups,
    getQuickPosProducts,
    getReasonCodes,
    getUICustomization,
    getVatRates,
    login,
    oAuthLogin,
    openModal,
    POSOpenDay,
    refreshUserSession,
    setLoading,
    setPosDayOpened,
    setPOSID,
    setWarehouse,
    showDialogue,
    showSelectDialogue,
    verifyUserSSOToken
} from "../redux/actions";
import {getTranslate, Translate} from "react-localize-redux";
import {getPaymentTypes, login as paymentLogin} from "../redux/payment/actions";
import * as uuid from "uuid";
import {
    OPEN_POS_OPEN_DAY_VIEW,
    POS_DAY_OPEN_SUCCESS, SET_POS_DATA,
    SET_POS_UI_CUSTOMIZATION,
    SET_UI_CUSTOMIZATION, SET_USER
} from "../redux/actionTypes";
import OpenDay from "../main/OpenDay";
import Keyboard from "../keyboard/Keyboard";
import ChooseOperationMode from "./ChooseOperationMode";
import {DKT_SSO_URL} from "../redux/appConstants";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from 'uiComponents/WrappedPerfectScrollBar.js';
import configurationLoader from "./configurationLoader";

const mapStateToProps = function (state) {
    return {
        view: state.rootReducer.view,
        translate: getTranslate(state.localize),
        theme: state.rootReducer.theme,
        decathlonSSO: window.AppConf?.decathlonSSO || false,
        decathlonQRCodeLogin: window.AppConf?.decathlonQRCodeLogin || false,
        decathlonSSOQRCodeURL: window.AppConf?.decathlonSSOQRCodeURL || false,
        clientCode: window.AppConf?.clientCode || false,
        preprod: window.AppConf.preprod || false,
        user: state.rootReducer.user
    }
};

const mapDispatchToProps = function (dispatch) {
    let setPos = (user, translate) => {
        return new Promise((resolve, reject) => {
            dispatch(getPointsOfSale((pointsOfSale) => {
                return getAllowedWarehouses(user.userID, (allowedWarehouses) => {
                    dispatch(setLoading(false));
                    dispatch(changeView('app'));
                    let allowedPointsOfSale = pointsOfSale.reduce((acc, el) => {
                        if(typeof allowedWarehouses.find(warehouse => parseInt(warehouse.warehouseID) === parseInt(el.warehouseID)) !== "undefined"){
                            acc.push({
                                name: el.name,
                                value: el.pointOfSaleID
                            });
                        }
                        return acc;
                    }, []);
                    let displayPOSSelect = () => {
                        return showSelectDialogue(
                            'Select POS',
                            allowedPointsOfSale,
                            (posID, posName) => {
                                dispatch(showDialogue(
                                    translate("Are you sure you want to select till", {tillName: posName}),
                                    '',
                                    'Yes',
                                    () => {
                                        dispatch(changeView('login'));
                                        let pos = pointsOfSale.find(el => el.pointOfSaleID === posID);
                                        if(typeof pos !== "undefined"){
                                            dispatch({
                                                type: SET_POS_DATA,
                                                payload: pos
                                            });
                                            dispatch(setLoading(true));
                                            resolve(pos);
                                        }else{
                                            reject();
                                        }
                                    },
                                    () => {
                                        dispatch(displayPOSSelect());
                                    },
                                    'No',
                                    false,
                                    undefined,
                                    undefined,
                                    true
                                ));
                            },
                            e => {
                                reject();
                            },
                            false
                        );
                    }
                    return displayPOSSelect();
                }, (message) => {
                    reject();
                    return addErrorAlert(message);
                });
            }, (message) => {
                reject();
                return addErrorAlert(message);
            }));
        });
    };

    return {
        login: (values, translate) => {
            let onLoginSuccess = (user) => {
                setTimeout(() => {
                    onLoginSuccessDelayed(user);
                },1);
            }
            let onLoginSuccessDelayed = (user) => {
                setPos(user, translate).then((pos) => {
                    dispatch(setPOSID(pos.pointOfSaleID));
                    dispatch(getCompanyInfo());
                    dispatch(getReasonCodes());
                    dispatch(getVatRates());
                    dispatch(getPaymentTypes());
                    dispatch(getEmployees(pos.pointOfSaleID));
                    dispatch(getProductGroups());
                    dispatch(getProductCategories());
                    dispatch(getErplyServiceEndpoints(() => {
                        if(process.env.REACT_APP_ERPLY_MODE === "1"){
                            dispatch(getQuickPosProducts(pos.warehouseID, pos.pointOfSaleID));
                            dispatch(getUICustomization('UICustomization', 'Company', SET_UI_CUSTOMIZATION));
                            dispatch(getUICustomization('UICustomizationPOS', 'Pos', SET_POS_UI_CUSTOMIZATION));
                        }
                        configurationLoader(dispatch, pos);
                    }));
                    dispatch(setWarehouse(pos.warehouseID, [warehouseData => {
                        return getErplyConfiguration((erplyConf) => {
                            dispatch(paymentLogin(pos, warehouseData[0]));
                            dispatch(setLoading(false));
                            dispatch(changeView('app'));

                            let openDay = (openedSum) => {
                                return POSOpenDay(pos.pointOfSaleID, openedSum, function (data) {
                                    let dayID = data[0].dayID;
                                    dispatch({
                                        type: POS_DAY_OPEN_SUCCESS,
                                        openedSum,
                                        dayID,
                                        openedUnixTime: data[0].openedUnixTime
                                    });
                                    return setPosDayOpened(dayID);
                                }, function () {
                                    setTimeout(() => {
                                        dispatch(addErrorAlert('opening the day failed'));
                                    }, 100);
                                    return closeQCO();
                                });
                            };
                            let checkPOSDay = (autoOpen) => {
                                dispatch(checkPOSDayOpened(pos.pointOfSaleID, function (dayOpened, dayID) {
                                    if (dayOpened) {
                                        return setPosDayOpened(dayID);
                                    } else {
                                        if(autoOpen){
                                            return openDay(0);
                                        }

                                        let openDayModalID = uuid.v4();
                                        dispatch({
                                            type: OPEN_POS_OPEN_DAY_VIEW
                                        });
                                        return openModal({
                                            content:
                                                <OpenDay
                                                    modalID={openDayModalID}
                                                    pos={pos}
                                                    onConfirm={(openedSum) => {
                                                        dispatch(openDay(openedSum));
                                                    }}
                                                    onCancel={() => {
                                                        dispatch(closeQCO());
                                                    }}
                                                />,
                                            id: openDayModalID,
                                            className: "Administration",
                                            onClose: function () {
                                                dispatch(closeQCO());
                                            },
                                            canClose: true
                                        });
                                    }
                                }, function () {
                                    setTimeout(() => {
                                        dispatch(addErrorAlert('opening the day failed'));
                                    }, 100);
                                    return closeQCO();
                                }));
                            };

                            if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                                let operationModeModalID = uuid.v4();
                                dispatch(openModal({
                                    content:
                                        <ChooseOperationMode
                                            onFullMode={() => {
                                                dispatch(closeModal(operationModeModalID));
                                                dispatch(changeOperationMode('full'));
                                                checkPOSDay(false);
                                            }}
                                            onLiteMode={() => {
                                                dispatch(closeModal(operationModeModalID));
                                                dispatch(changeOperationMode('lite'));
                                                checkPOSDay(true);
                                            }}
                                            onCashierMode={() => {
                                                dispatch(closeModal(operationModeModalID));
                                                dispatch(changeOperationMode('cashier'));
                                                checkPOSDay(false);
                                            }}
                                        />,
                                    id: operationModeModalID,
                                    className: "Administration",
                                    onClose: function () {
                                        dispatch(closeQCO());
                                    },
                                    canClose: true
                                }));
                            }else{
                                checkPOSDay(false);
                            }
                        }, () => {
                            dispatch(setLoading(false));
                        });
                    }]));
                }, () => {
                    dispatch(setLoading(false));
                });
            };

            let onLoginFailure = () => {
                dispatch(setLoading(false));
            };

            let onSessionTimeout = (user) => {
                let handleRefreshUserSession = () => {
                    dispatch(refreshUserSession(user.clientCode, values.username, values.password, () => {
                        return closeQCO();
                    }, () => {
                        handleRefreshUserSession();
                    }));
                }
                handleRefreshUserSession();
            };

            dispatch(setLoading(true));
            if(typeof values.authCode !== "undefined"){
                dispatch(oAuthLogin(values.authCode, onLoginSuccess, onLoginFailure));
            }else if(typeof values.accessToken !== "undefined"){
                dispatch(verifyUserSSOToken(values.accessToken, (data) => {
                    let user = data.session.user;
                    user.sessionKey = data.session.sessionKey;
                    user.clientCode = values.clientCode.toString();
                    user.employeeID = user.employeeId.toString();
                    onLoginSuccess(user);
                    return {
                        type: SET_USER,
                        payload: user
                    }
                }, (message) => {
                    dispatch(addErrorAlert(message));
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                    return setLoading(false);
                }));
            }else{
                dispatch(login(values.clientCode, values.username, values.password, onLoginSuccess, onLoginFailure, onSessionTimeout));
            }
        }
    }
};

class Login extends Component {
    constructor(props) {
        super(props);

        let clientCode = props.clientCode || localStorage.getItem('lastLoginClientCode') || '';
        let username = localStorage.getItem('lastLoginUsername') || '';

        this.state = {
            inputValues: {
                clientCode,
                username,
                password: window.AppConf.loginPassword || ""
            },
            inputErrors: {},
            activeInput: "clientCode",
            keyboardLayout: "numbers",
            isSubmitting: false
        };

        this.selectInput = this.selectInput.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.qrMessageEvent = this.qrMessageEvent.bind(this);
    }

    componentDidMount() {
        if(this.props.decathlonQRCodeLogin) {
            this.initQRCodeLogin();
        }
        if(this.props.decathlonSSO){
            console.log('mounted login');
            let authCode = new URLSearchParams(window.location.search).get('code');
            if(authCode !== null){
                this.props.login({authCode}, this.props.translate);
            }else{
                if(this.props.user === false){
                    window.location.href = DKT_SSO_URL + '?redirectUri=' + encodeURIComponent(window.location.origin);
                }
            }
        }
    }

    componentWillUnmount() {
        if(this.props.decathlonQRCodeLogin){
            window.removeEventListener("message", this.qrMessageEvent);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let el = document.getElementsByName(this.state.activeInput);
        if(el.length > 0){
            el[0].focus();
        }
    }

    selectInput(name) {
        this.setState({
            activeInput: name
        });
    }

    updateValue(value) {
        let inputValues = {...this.state.inputValues};
        inputValues[this.state.activeInput] = value;
        this.setState({
            inputValues
        });
    }

    validate(values) {
        let errors = {};
        if (!values.clientCode) {
            errors.clientCode = 'Required';
        }
        if (!values.username) {
            errors.username = 'Required';
        }
        if (!values.password) {
            errors.password = 'Required';
        }
        this.setState({
            inputErrors: errors
        });
        return Object.keys(errors).length === 0;
    }

    onSubmit(values) {
        let validates = this.validate(this.state.inputValues);
        if(!validates){
            return false;
        }
        this.setState({
            isSubmitting: true
        });
        this.props.login(values, this.props.translate);
        localStorage.setItem('lastLoginClientCode', values.clientCode);
        localStorage.setItem('lastLoginUsername', values.username);
    }

    displayQRLogin(){
        return <div style={{
            position: 'relative',
            height: '100%'
        }}>
            <iframe
                title={'QRCode'}
                src={this.props.decathlonSSOQRCodeURL}
                style={{
                    border: 'none',
                    margin: '0 auto',
                    display: 'block',
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '640px',
                    webkitTransform: 'scale(0.8)'
                }}
            />
            <Button
                type="button"
                className={"mediumEqualButton roundButton"}
                style={{
                    margin: "20px"
                }}
                name={this.props.translate("Reload")}
                arrow="rightClose"
                onClick={e => {
                    window.location.reload();
                }}
            />
        </div>
    }

    initQRCodeLogin(){
        window.addEventListener("message", this.qrMessageEvent);
    }

    qrMessageEvent(e) {
        console.log({qrmessageEvent: e, url: this.props?.decathlonSSOQRCodeURL});
        if(typeof this.props?.decathlonSSOQRCodeURL === "undefined" || e.origin !== new URL(this.props.decathlonSSOQRCodeURL).origin) return;
        console.log({accessToken: e.data});
        var accessToken = e.data;
        this.props.login({accessToken, clientCode: this.props.clientCode}, this.props.translate);
    }

    render() {
        if (this.props.view !== 'login') {
            return <Redirect to={this.props.view}/>
        }

        if (this.props.decathlonQRCodeLogin){
            if(this.props.user !== false){
                return '';
            }
            return this.displayQRLogin();
        }

        let ErrorMessage = (props) => {
            return <div className={"errorMessage smallPaddingLeft"}>{props.message ?? ''}</div>;
        };

        let loginButton;
        if(this.props.theme === 'Erply'){
            loginButton =
                <Button
                    type="button"
                    disabled={this.state.isSubmitting}
                    className={"largerButton lightBlueButton roundButton uppercaseText fullWidth"}
                    style={{
                        width: "365px",
                        marginTop: "10px"
                    }}
                    name={this.props.translate("Sign in")}
                    arrow="rightClose"
                    onClick={e => {
                        console.log('clicked submit');
                        this.onSubmit(this.state.inputValues);
                    }}
                />
        }else{
            loginButton =
                <Button
                    type="button"
                    disabled={this.state.isSubmitting}
                    className={"largeButton blueButton"}
                    name={this.props.translate("Sign in")}
                    flencheClass={"flencheRight transparentFlenche"}
                    arrow="right"
                    onClick={e => {
                        console.log('clicked submit');
                        this.onSubmit(this.state.inputValues);
                    }}
                />
        }

        let keyboardHeight = 0;
        let keyboardEl = document.getElementsByClassName('keyboardWrapper');
        if(keyboardEl.length > 0){
            keyboardHeight = keyboardEl[0].offsetHeight;
        }
        return (
            <div onKeyPress={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onSubmit(this.state.inputValues);
                }
            }}>
                <PerfectScrollbar
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: window.innerHeight - 65 - keyboardHeight
                    }}
                    containerRef={(ref) => {
                        this._scrollRef = ref;
                    }}
                >
                    <div style={window.innerHeight < 620 ? {top: 0} : {}} className={"centerContent"}>
                        <table className={'loginTable'}>
                            <tbody>
                            <tr>
                                <td>
                                    <label><Translate id={"Client Code"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="clientCode"
                                        className={"fullWidth loginInput"}
                                        onClick={e => {this.selectInput("clientCode")}}
                                        onFocus={e => {this.selectInput("clientCode")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.clientCode}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="clientCode" message={this.state.inputErrors.clientCode ?? ''}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label><Translate id={"Username"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="username"
                                        className={"fullWidth loginInput"}
                                        onClick={e => {this.selectInput("username")}}
                                        onFocus={e => {this.selectInput("username")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.username}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="username" message={this.state.inputErrors.username ?? ''}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label><Translate id={"Password"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="password"
                                        className={"fullWidth loginInput"}
                                        onClick={e => {this.selectInput("password")}}
                                        onFocus={e => {this.selectInput("password")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.password}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="password" message={this.state.inputErrors.password ?? ''}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {loginButton}
                    </div>
                </PerfectScrollbar>
                <Keyboard options={{
                    currentValue: this.state.inputValues[this.state.activeInput],
                    updateValue: (value) => {
                        this.updateValue(value);
                    },
                    layout: this.state.keyboardLayout,
                    onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                    onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                    onDone: function () {},
                    onClick: function () {}
                }} style={{
                    position: 'absolute',
                    bottom: 0
                }}/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
