import React, {Component, useState} from 'react';
import AskReceipt from "../main/done/AskReceipt";
import EnterEmail from "../main/done/EnterEmail";
import Done from "../main/done/Done";
import ChooseOperationMode from "../login/ChooseOperationMode";
import GiftCardActivation from "../main/payment/GiftCardActivation";
import CreditResults from "../redux/payment/integrations/giftCard/CreditResults";
import Modal from "../modal/Modal";
import EditUI from "../main/administration/EditUI";
import RGFD from "../redux/payment/integrations/giftCard/main";
import {closeModal, closeRightPanelDialogue} from "../redux/actions";
import PMA from "../redux/payment/integrations/adyen/main";
import NexoCrypto from "../redux/payment/integrations/adyen/nexoCrypto";
import CheckList from "../main/lists/productCheckList/CheckList";
import {Translate} from "react-localize-redux";
import Button from "../buttons/Button";
import DoneErply from "../main/done/DoneErply";
import AddItem from "../main/administration/AddItem";
import Administration from "../main/administration/Administration";
import ZipCodeSurvey from "../main/inputForms/ZipCodeSurvey";
import RemoveItems from "../main/RemoveItems";
import RighPanel from "../main/RighPanel";
import ProductSearch from "../main/ProductSearch";
import GiftCardPayment from "../main/payment/GiftCardPayment";
import ReturnDocument from "../main/administration/ReturnDocument";
import {default as PaybackPayment} from "../integrations/payback/Payment";
import EnterCardNumber from "../integrations/payback/EnterCardNumber";
import FullscreenCustomerSearch from "../main/payment/FullscreenCustomerSearch";

function useCreditResults(data) {
    console.log('RGFD: useCreditResults');
    const [creditResults, setCreditResults] = useState(data);
    return [creditResults, setCreditResults];
}

export default function () {
    //return <Administration/>;
    /*return (<Modal
        id={ 1 }
        content={<AddItem/>}
        onClose={ function () {

        }}
        canClose={ true }
    />);*/

    /*let rows = [
        {itemName: 'Test 1', code: '123456', 'amount': 2},
        {itemName: 'Test 2', code: '123456', 'amount': 5},
        {itemName: 'Test 3', code: '123456', 'amount': 4},
        {itemName: 'Test 4', code: '123456', 'amount': 1},
        {itemName: 'Test 5', code: '123456', 'amount': 3},
        {itemName: 'Test 6', code: '123456', 'amount': 6},
        {itemName: 'Test 7', code: '123456', 'amount': 2},
        {itemName: 'Test 8', code: '123456', 'amount': 1},
        {itemName: 'Test 9', code: '123456', 'amount': 6},
        {itemName: 'Test 10', code: '123456', 'amount': 2},
        {itemName: 'Test 11', code: '123456', 'amount': 4},
        {itemName: 'Test 12', code: '123456', 'amount': 4},
        {itemName: 'Test 13', code: '123456', 'amount': 4},
        {itemName: 'Test 14', code: '123456', 'amount': 4},
        {itemName: 'Test 15', code: '123456', 'amount': 4},
        {itemName: 'Test 16', code: '123456', 'amount': 4},
        {itemName: 'Test 17', code: '123456', 'amount': 4},
        {itemName: 'Test 18', code: '123456', 'amount': 4}
    ];

    let documentNumber = '123456789';

    const [listItems, setListItems] = useState(rows.reduce((acc, item) => {
        acc.push({
            checked: false,
            product: item
        });
        return acc;
    }, []));

    return (<Modal
        id={ 1 }
        content={
            <div>
                <h2>
                    <Translate id={"Return"}/> {documentNumber}
                </h2>
                <Button
                    style={{
                        position: "absolute",
                        top: "20px",
                        right: "100px"
                    }}
                    type="button"
                    className={"largeButton yellowButton"}
                    name={<Translate id="Ok"/>}
                    arrow="right"
                    flencheClass={"flencheSmallRightBottom yellowFlenche"}
                    onClick={() => {
                        let checkedProducts = listItems.reduce((acc, item) => {
                            if(item.checked){
                                acc.push(item.product);
                            }
                            return acc;
                        }, []);
                        console.log({checkedProducts});
                    }}/>
                <CheckList
                    listItems={listItems}
                    setListItems={setListItems}
                />
            </div>
        }
        onClose={ function () {

        }}
        canClose={ true }
    />);*/

    /*
    const [creditResults, setCreditResults] = useCreditResults(RGFD.creditResults.data);
    let giftCard = () => new RGFD.giftCard(1, '1234123412341234', '1231212512', 5, 'Failed');

    RGFD.creditResults.update = () => {
        console.log('RGFD: creditResults update', creditResults);
    };

    return (
        <CreditResults
            data={creditResults}
            onAskAssistance={() => {
                let newGiftCard = giftCard();
                newGiftCard.retry = 1;
                RGFD.creditResults.data.push(newGiftCard);
                setCreditResults([...RGFD.creditResults.data]);
            }}
        />
    );*/
    /*return (<Modal
        id={ 1 }
        content={<EditUI/>}
        onClose={ function () {

        }}
        canClose={ true }
    />);*/

    /*return (
        <div style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            top: '65px',
            width: '50%',
            borderLeft: '1px solid #ccc'
        }}>
            <ZipCodeSurvey
                currency={'€'}
            />
        </div>
    );*/

    /*return (
        <AskReceipt
            onEmail={() => alert('email')}
            onPrint={() => alert('print')}
            onDecline={() => alert('decline')}
        />
    )*/
    /*return (
        <div id={"rightPanel"}>
            <RemoveItems
                title={<Translate id={"removeAllItemsFromBin"}/>}
                confirmText={<Translate id={"I confirm"}/>}
                cancelText={<Translate id={"backToBasket"}/>}
                onConfirm={() => {}}
                onCancel={() => {}}
            />
        </div>
    );*/
    /*return (
        <div id={"rightPanel"}>
            <ProductSearch
                onSelect={(product) => {}}
                onClose={() => {}}
            />
        </div>
    );*/
    /*return (
        <div id={"rightPanel"}>
            <div style={{
                "textAlign":"center",
            }}>
                <GiftCardPayment/>
            </div>
        </div>
    );*/
    /*return (<Modal
        id={ 1 }
        content={<ReturnDocument
            document={{
                rows: [
                    {itemName: 'Test 1', code: '123456', 'amount': 2},
                    {itemName: 'Test 2', code: '123456', 'amount': 5},
                    {itemName: 'Test 3', code: '123456', 'amount': 4},
                    {itemName: 'Test 4', code: '123456', 'amount': 1},
                    {itemName: 'Test 5', code: '123456', 'amount': 3},
                    {itemName: 'Test 6', code: '123456', 'amount': 6},
                    {itemName: 'Test 7', code: '123456', 'amount': 2},
                    {itemName: 'Test 8', code: '123456', 'amount': 1},
                    {itemName: 'Test 9', code: '123456', 'amount': 6},
                    {itemName: 'Test 10', code: '123456', 'amount': 2},
                    {itemName: 'Test 11', code: '123456', 'amount': 4},
                    {itemName: 'Test 12', code: '123456', 'amount': 4},
                    {itemName: 'Test 13', code: '123456', 'amount': 4},
                    {itemName: 'Test 14', code: '123456', 'amount': 4},
                    {itemName: 'Test 15', code: '123456', 'amount': 4},
                    {itemName: 'Test 16', code: '123456', 'amount': 4},
                    {itemName: 'Test 17', code: '123456', 'amount': 4},
                    {itemName: 'Test 18', code: '123456', 'amount': 4}
                ],
                number: '123456789'
            }}
        />}
        onClose={ function () {

        }}
        canClose={ true }
    />)*/
    /*return (
        <div id={"rightPanel"}>
            <div style={{
                "textAlign":"center",
            }}>
                <div style={{
                    marginTop: "54px"
                }}>
                    <PaybackPayment/>
                    {/!*<EnterCardNumber/>*!/}
                </div>
            </div>
        </div>
    );*/
    return (
        <div id={"rightPanelFullscreen"}>
            <div style={{
                "textAlign":"center",
            }}>
                <div style={{
                    marginTop: "54px"
                }}>
                    <FullscreenCustomerSearch/>
                </div>
            </div>
        </div>
    );
}