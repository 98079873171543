import {getAttributeValue} from "../../util/helperFunctions";
import {
    addBeforeCompleteTransactionAction,
    addErrorAlert, addInfoAlert,
    addReceiptExtraLine,
    cancelTransaction,
    changeTransactionMode, closeDialogue,
    createReceiptNumber, findCustomerByCardNumber,
    removeBeforeCompleteTransactionActionsByType, setCustomer,
    setLoading,
    setPaybackCustomer,
    setPaybackInputDone,
    setPaybackReferenceReceipt,
    showDialogue,
    showInputDialogue
} from "../../redux/actions";
import * as uuid from "uuid";
import {getTranslate} from "react-localize-redux";
import {getProductsTotalSum} from "../../redux/selectors";
import CTR from "../../util/receiptTemplate";
import Customer from "../../redux/dto/customer";


export function getPaybackConf(){
    const paybackConf = window?.AppConf?.payback || false;
    if(paybackConf !== false){
        paybackConf.reprocessErrorCodes = [
            "EXTINT-00071"
        ];
    }
    return paybackConf;
}

export const anonymizeCardNumber = (number) => {
    const lastFour = number.substring(number.length - 4);
    return number.substring(0, number.length - 4).replace(/\d/g,"#") + lastFour;
}

export const getReprocessReceiptMessage = (cardNumber, translate) => {
    return `\
        <br/>\
        <p style="text-align: center;">\
            ${translate('Your Decathlon PAYBACK advantages today')}<br/>\
            ${translate('will be credited to you within the next 24 hours')}<br/>\
            ${translate('PAYBACK card no.')}: ${anonymizeCardNumber(cardNumber)}<br/>\
        </p>\
    `;
}

export const getSignupReceiptMessage = (translate) => {
    return `\
        <br/>\
        <p style="text-align: center;">\
            ${translate('You are not yet a PAYBACK customer?')}<br/>\
            ${translate('Then register now for free')}<br/>\
            ${translate('and get 100 extra points')}:<br/><br/>\
            decat.de/payback-anmeldung<br/>\
        </p>\
    `;
}

export function checkGS1(code){
    let input = code.slice(0,12);
    let checkDigit = parseInt(code.slice(12,13));

    let array = input.split('').reverse();

    let total = 0;
    let i = 1;
    array.forEach(number => {
        number = parseInt(number);
        if (i % 2 === 0) {
            total = total + number;
        }
        else
        {
            total = total + (number * 3);
        }
        i++;
    });

    let calculatedCheckDigit = (Math.ceil(total / 10) * 10) - total;
    return checkDigit === calculatedCheckDigit;
}

export function luhn_checksum(code){
    var len = code.length
    var parity = len % 2
    var sum = 0
    for (var i = len-1; i >= 0; i--) {
        var d = parseInt(code.charAt(i))
        if (i % 2 === parity) { d *= 2 }
        if (d > 9) { d -= 9 }
        sum += d
    }
    return sum % 10
}

export function checkLuhn(num){
    return luhn_checksum(num) === 0;
}

export function formatTransactionDateTime(x){
    let xa = Array.from(x);
    xa.splice(xa.length - 2, 0, ':');
    return xa.join('');
}

export function isCouponCode(code){
    let paybackConf = getPaybackConf();
    return code.slice(0,3) === paybackConf.couponPrefix && code.length === 13;
}

export function isCardCode(code){
    let paybackConf = getPaybackConf();
    return code.slice(0,3) === paybackConf.codePrefix && code.length === 13 && checkGS1(code);
}

export function anonymizeCardCode(code){
    if(code.length === 13){
        return code.replace(code.substring(3,9), "XXXXXX");
    }else{
        return code.replace(code.substring(6,12), "XXXXXX");
    }
}

export function formatPaybackCustomerCode(code){
    code = anonymizeCardCode(code);
    let parts = code.match(/.{1,3}/g);
    let lastChar = parts.pop();
    return parts.join(" ") + lastChar;
}

export function getPOSLogProductLines(transaction){
    return transaction.lineItem.filter(el => typeof el.sale !== "undefined");
}

export const API = {
    clientCode: false,
    sessionKey: false,
    warehouseCode: false,
    translate: string => string,
    sendRequest: (request, data) => {
        return new Promise((resolve, reject) => {
            let paybackConf = getPaybackConf();
            fetch(paybackConf.url + request, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    clientCode: API.clientCode,
                    sessionKey: API.sessionKey
                },
                body: JSON.stringify(data)
            }).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    sendGetRequest: (request) => {
        return new Promise((resolve, reject) => {
            let paybackConf = getPaybackConf();
            fetch(paybackConf.url + request, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    clientCode: API.clientCode,
                    sessionKey: API.sessionKey
                }
            }).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    processPurchase: (receiptNumber, rows, paybackCardNumber, couponCodes, effectiveDate) => {
        return new Promise((resolve, reject) => {
            let paybackConf = getPaybackConf();
            let document = {
                paybackCardNumber,
                storeNumber: API.warehouseCode,
                number: receiptNumber,
                partnerShortName: paybackConf.partnerShortName,
                rows
            }
            if(couponCodes.length > 0){
                document.couponCodes = couponCodes;
            }
            if(typeof effectiveDate !== "undefined"){
                document.referenceReceiptDateTime = effectiveDate;
            }
            API.sendRequest('processPurchase', document).then(resolve, reject);
        });
    },
    refundPurchase: (receiptNumber, rows, referenceReceipt, paybackCardNumber) => {
        return new Promise((resolve, reject) => {
            let paybackConf = getPaybackConf();
            if(typeof referenceReceipt === "undefined" || referenceReceipt?.number === false){
                resolve({
                    status: "error"
                });
                return;
            }
            let document = {
                paybackCardNumber,
                storeNumber: API.warehouseCode,
                number: receiptNumber,
                partnerShortName: paybackConf.partnerShortName,
                rows,
                referenceReceiptNumber: referenceReceipt.number
            }
            if(referenceReceipt.transactionDateTime !== false){
                document.referenceReceiptDateTime = referenceReceipt.transactionDateTime;
            }
            API.sendRequest('refundPurchase', document).then(resolve, reject);
        });
    },
    reverseCollect: (paybackCardNumber, receiptNumber) => {
        return new Promise((resolve, reject) => {
            let paybackConf = getPaybackConf();
            let document = {
                paybackCardNumber,
                storeNumber: API.warehouseCode,
                number: 'R' + receiptNumber,
                referenceReceiptNumber: receiptNumber,
                partnerShortName: paybackConf.partnerShortName
            }
            API.sendRequest('reverseCollect', document).then(resolve, reject);
        });
    },
    validateReferenceReceiptNumber: (receiptNumber) => {
        return new Promise((resolve, reject) => {
            API.sendGetRequest('getPOSLog?receiptNumber=' + receiptNumber).then((records) => {
                if(records.status.responseStatus === 'ok'){
                    if(records.data.metadata.hits > 0){
                        let transactionDateTimes = records.data.data[0].poslog?.transaction?.poslogDateTime;
                        let transactionDateTime = {
                            value: false
                        };
                        if(typeof transactionDateTimes !== "undefined"){
                            transactionDateTime = transactionDateTimes.find(el => el.typeCode === "Transaction");
                            if(typeof transactionDateTime === "undefined"){
                                transactionDateTime = {
                                    value: false
                                }
                            }else{
                                transactionDateTime.value = formatTransactionDateTime(transactionDateTime.value);
                            }
                        }
                        let paybackAccountNumber = records.data.data[0].poslog.transaction.retailTransaction.customer?.[0].localRequirements.find(el => el.name === 'PaybackAccount')?.value;
                        let customerAccountNumber = records.data.data[0].poslog.transaction.retailTransaction.customer?.[0]?.customerID;
                        let products = getPOSLogProductLines(records.data.data[0].poslog.transaction.retailTransaction);
                        resolve({paybackAccountNumber, customerAccountNumber, products, transactionDateTime: transactionDateTime.value});
                    }else{
                        reject(false);
                    }
                }else{
                    reject(true);
                }
            }, reject);
        });
    },
}

export const processSale = async (receiptNumber, products, payback, translate, effectiveDate, dispatch) => {
    let paybackConf = getPaybackConf();
    let aggregatedRows = products.reduce((acc, product) => {
        let productClone =  Object.assign({}, product);
        if(typeof acc[productClone.data.code] !== "undefined"){
            acc[productClone.data.code].rowTotal = parseFloat(acc[productClone.data.code].rowTotal) + parseFloat(productClone.rowTotal);
            acc[productClone.data.code].rowVAT = parseFloat(acc[productClone.data.code].rowVAT) + parseFloat(productClone.rowVAT);
            acc[productClone.data.code].amount = parseFloat(acc[productClone.data.code].amount) + parseFloat(productClone.amount);
        }else{
            acc[productClone.data.code] = productClone;
        }
        if(acc[productClone.data.code].amount === 0){
            delete acc[productClone.data.code];
        }
        return acc;
    }, {});


    let rows = Object.values(aggregatedRows).reduce((acc, product) => {
        let row = {
            code: product.data.code,
            code2: product.data.code2,
            itemName: product.name,
            rowTotal: product.rowTotal.toFixed(2),
            rowVAT: product.rowVAT.toFixed(2),
            amount: product.amount,
            partnerProductCategoryCode: getAttributeValue(product, 'PartnerProductCategoryCode') || '0000',
            partnerProductCategoryName: getAttributeValue(product, 'PartnerProductCategoryName') || "Not available",
            departmentCode: getAttributeValue(product, 'DepartmentCode') || "0000",
            departmentName: getAttributeValue(product, 'DepartmentName') || "Not available",
            vatrate: product.data.vatrate
        }
        if (row.amount >= 0) {
            acc.purchase.push(row);
        } else {
            row.rowTotal = Math.abs(row.rowTotal);
            row.rowVAT = Math.abs(row.rowVAT);
            row.amount = Math.abs(row.amount);
            acc.refund.push(row);
        }
        return acc;
    }, {
        purchase: [],
        refund: []
    });

    let receiptData = {
        purchase: false,
        refund: false
    }

    const tryProcessPurchase = (cardNumber) => {
        return new Promise((resolve, reject) => {
            const paybackCardNumber = cardNumber ?? payback.customer;
            API.processPurchase(receiptNumber, rows.purchase, paybackCardNumber, payback.coupons, effectiveDate).then((purchaseData) => {
                console.log({processPurchaseStatus: purchaseData.status});
                if (purchaseData.status === "ok") {
                    receiptData.purchase = purchaseData.response;
                    resolve(true);
                } else {
                    let errorCode = purchaseData?.response?.error?.code;
                    if (paybackConf.reprocessErrorCodes.indexOf(errorCode) !== -1) {
                        receiptData.purchase = {
                            errorData: true,
                            receipt: getReprocessReceiptMessage(payback.customer, translate)
                        }
                        resolve(true);
                    } else if (errorCode === 'LOY-00070') {
                        dispatch(setLoading(false));
                        dispatch(showInputDialogue("Error", "PAYBACK customer number is invalid. Please check and correct your input.", "Enter Payback customer number", (newCardNumber) => {
                            dispatch(setLoading(true));
                            tryProcessPurchase(newCardNumber).then(resolve, reject);
                        }, () => {
                            resolve(false);
                        }, true, true, undefined, undefined, paybackCardNumber));
                    }else{
                        resolve(false);
                    }
                }
            }, (e) => {
                resolve(false);
            });
        });
    }

    if (rows.purchase.length > 0) {
        await tryProcessPurchase();
    }
    if (rows.refund.length > 0) {
        let refundReceiptNumber = rows.purchase.length > 0 ? receiptNumber + "0" : receiptNumber;
        try{
            const refundData = await API.refundPurchase(refundReceiptNumber, rows.refund, payback.referenceReceipt, payback.customer);
            console.log({refundPurchaseStatus: refundData.status});
            if (refundData.status === "ok") {
                receiptData.refund = refundData.response;
            }else{
                let errorCode = refundData?.response?.error?.code;
                if(paybackConf.reprocessErrorCodes.indexOf(errorCode) !== -1){
                    receiptData.refund = {
                        errorData: true,
                        receipt: getReprocessReceiptMessage(payback.customer, translate)
                    }
                }
            }
        }catch (e){
            return false;
        }
    }

    if(receiptData.refund === false && receiptData.purchase === false){
        return false;
    }
    return createReceipt(receiptData, payback.customer, translate);
}

export const createReceipt = ({purchase, refund}, cardNumber, translate) => {
    if(purchase.errorData){
        return purchase.receipt;
    }
    if(refund.errorData){
        return refund.receipt;
    }
    let awardedPoints = 0;
    if(purchase !== false){
        awardedPoints = purchase.transactions.reduce((acc, transaction) => {
            acc += parseInt(transaction.TotalPoints.LoyaltyAmount);
            return acc;
        }, 0);
    }

    let deductedPoints = 0;

    if(refund !== false){
        deductedPoints = refund.transactions.reduce((acc, transaction) => {
            if(transaction !== null){
                acc += parseInt(transaction.TotalPoints.LoyaltyAmount);
            }
            return acc;
        }, 0);
    }

    awardedPoints -= deductedPoints;

    let pointsBalance = refund !== false ? refund.accountBalanceDetails.AvailablePointsAmount : purchase.accountBalanceDetails.AvailablePointsAmount;

    let receivedPoints = '';
    if(purchase !== false && typeof purchase.paybackPurchaseAmount !== "undefined"){
        receivedPoints = `<br/>${translate('You receive')} ${awardedPoints} ${translate('PAYBACK points on a PAYBACK purchase of')} ${purchase.paybackPurchaseAmount} EUR!<br/>`;
    }
    return `\
        <p style="text-align: center">\
            ${translate('Your Decathlon PAYBACK advantages today')}<br/>\
            ${translate('PAYBACK card no.')}: ${anonymizeCardNumber(cardNumber)}<br/>\
            ${translate('Points balance')}: ${pointsBalance}<br/>\
            ${translate('Points balance corresponds to')}: ${calculateMoneyFromPoints(pointsBalance)}€<br/>\
            ${receivedPoints}
        </p>\
    `;
};

export const calculateMoneyFromPoints = (points) => {
    let paybackConf = getPaybackConf();
    return (points * paybackConf.pointToEur).toFixed(2);
};

const setAPICredentials = (state) => {
    API.clientCode = state.rootReducer.user.clientCode;
    API.sessionKey = state.rootReducer.user.sessionKey;
    API.warehouseCode = state.rootReducer.warehouse.code;
};

export const onPaymentMode = (dispatch, state) => {
    console.log('Payback onPaymentMode', state.payment.totalSum);
    if(parseFloat(getProductsTotalSum(state.rootReducer.productsInBasket)) === 0){
        return;
    }

    setAPICredentials(state);

    dispatch(removeBeforeCompleteTransactionActionsByType('registerPaybackPurchase'));

    const translateFunction = getTranslate(state.localize);
    const translate = (string) => {
        return translateFunction(string,null, {
            language: CTR.language
        });
    }
    if(state.rootReducer.payback.customer !== false){
        console.log("Payback translate", {language: CTR.language, adv: translate("Your Decathlon PAYBACK advantages today")});
        const addAction = (payback) => {
            let id = uuid.v4();
            dispatch(addBeforeCompleteTransactionAction({
                id,
                type: 'registerPaybackPurchase',
                run: (onDone, onFail) => {
                    dispatch(setLoading(true));
                    dispatch(createReceiptNumber((receiptNumber, dateTime) => {
                        processSale(receiptNumber, state.rootReducer.productsInBasket, payback, translate, dateTime.format(), dispatch).then(receiptData => {
                            console.log("Payback receipt", {receiptData});

                            dispatch(setLoading(false));
                            if(receiptData !== false){
                                dispatch(addReceiptExtraLine(receiptData));
                            }
                            onDone();
                        });
                    }));
                }
            }));
        }

        let payback = Object.assign({}, state.rootReducer.payback);
        addAction(payback);
    }else{
        dispatch(addReceiptExtraLine(getSignupReceiptMessage(translate)));
    }
};

export const onTransactionModeChange = (dispatch, state) => {
    const translate = getTranslate(state.localize);
    setAPICredentials(state);

    const askGoodWill = () => {
        return new Promise((resolve, reject) => {
            dispatch(showDialogue(
                'Goodwill return',
                'Returns without reference to the proof of purchase should only be made in exceptional cases.',
                'Return without proof of purchase',
                resolve,
                reject,
                'Cancel return',
                false
            ));
        });
    };

    const askReferenceReceiptNumber = (referenceReceiptNumber = '') => {
        const dialogueID = uuid.v4();
        return new Promise((resolve, reject) => {
            dispatch({
                type: 'SHOW_DIALOGUE',
                payload: {
                    id: dialogueID,
                    title: translate('Reference receipt number'),
                    inputFields: [{ id: 'PBReferenceInput', name: 'Reference receipt number', type: 'input'}],
                    onDone: (inputValues) => {
                        resolve(inputValues['PBReferenceInput']);
                    },
                    onClose: () => {
                        askGoodWill().then(() => {
                            dispatch(setPaybackInputDone(true));
                        }, () => {
                            dispatch(changeTransactionMode("SALE"));
                        });
                    },
                    canClose: true,
                    multipleInputDialogue: true,
                    initialValues: {
                        PBReferenceInput: referenceReceiptNumber
                    },
                    keyboardLayout: 'numbersOnly',
                    changeToLettersLayout: 'capitalLettersOnly',
                    changeToNumbersLayout: 'numbersOnly',
                    onScannerDetect: (code) => {
                        dispatch(closeDialogue(dialogueID));
                        resolve(code);
                    }
                }
            });
        });
    };

    const askOffline = () => {
        return new Promise((resolve, reject) => {
            dispatch(showDialogue(
                'connectionFailed',
                'Unfortunately we could not connect to the internet and/or access our database. Try again or complete the return in offline mode.',
                'Return in offline mode',
                resolve,
                reject,
                'Try again',
                false,
                undefined,
                undefined,
                true
            ));
        });
    };


    if(state.rootReducer.transactionMode === "RETURN" && typeof state.rootReducer.payback.referenceReceipt === "undefined"){
        const tryAskReferenceReceiptNumber = (referenceReceiptNumber = '') => {
            askReferenceReceiptNumber(referenceReceiptNumber).then((referenceReceiptNumber) => {
                dispatch(setLoading(true));
                API.validateReferenceReceiptNumber(referenceReceiptNumber).then(({paybackAccountNumber, customerAccountNumber, transactionDateTime, products}) => {
                    dispatch(setLoading(false));
                    if(typeof paybackAccountNumber !== "undefined"){
                        dispatch(setPaybackCustomer(paybackAccountNumber));
                        dispatch(setPaybackReferenceReceipt(referenceReceiptNumber, products, transactionDateTime));
                    }
                    if(typeof customerAccountNumber !== "undefined"){
                        dispatch(findCustomerByCardNumber(customerAccountNumber, (customer) => {
                            customer.canRemove = false;
                        }, () => {
                            let customer = new Customer(customerAccountNumber);
                            customer.canRemove = false;
                            dispatch(setCustomer(customer));
                        }));
                    }
                    dispatch(addInfoAlert("Payback reference receipt number validated!"));
                }, (connectionError) => {
                    dispatch(setLoading(false));
                    if(connectionError){
                        askOffline().then(() => {
                            dispatch(setPaybackReferenceReceipt(referenceReceiptNumber));
                        }, () => {
                            tryAskReferenceReceiptNumber(referenceReceiptNumber);
                        });
                    }else{
                        dispatch(addErrorAlert('The specified proof of purchase could not be found. Please check your input.'));
                        tryAskReferenceReceiptNumber(referenceReceiptNumber);
                    }
                });
            });
        }

        tryAskReferenceReceiptNumber();
    }
}

export const onReturnProductAdd = (product, dispatch, state) => {
    let document = state.rootReducer.payback.referenceReceipt;
    let productsByPaybackSequenceNumber = state.rootReducer.productsInBasket.reduce((acc, el) => {
        if(typeof el.paybackSequenceNumber !== "undefined"){
            acc[el.paybackSequenceNumber] = el;
        }
        return acc;
    }, {});
    let productOnDocument = document.products.find(el => {
        if(el.sale.itemID === product.data.code){
            return typeof productsByPaybackSequenceNumber[el.sequenceNumber] === "undefined";
        }else{
            return false;
        }
    });
    if(typeof productOnDocument === "undefined"){
        dispatch(showDialogue("Product mismatch", "This product is not part of the referenced sales document. Please check the customer's receipt.", "Continue with another product", () => {}, () => {
            dispatch(cancelTransaction());
        }, "Cancel return", false, undefined, undefined, true));
        return true;
    }
    product.paybackSequenceNumber = productOnDocument.sequenceNumber;
    product.setVatPrice(productOnDocument.sale.extendedAmount / productOnDocument.sale.quantity);
    return false;
}