import React, {Component} from 'react';
import "../main.css"
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {
    addErrorAlert,
    addProduct,
    findProduct,
    productNotFound, setLoading,
    showDialogue,
    showInputDialogue
} from "../../redux/actions";
import Keyboard from "../../keyboard/Keyboard";
import Header from "./components/Header";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.posID,
        translate: getTranslate(state.localize),
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findProduct: (code, warehouseID, pointOfSaleID, translate) => {
            if(code.trim() === ''){
                dispatch(addErrorAlert('Enter the item code'));
                return;
            }
            let searchCodeType = process.env.REACT_APP_ERPLY_MODE === "1" ? 'searchNameIncrementally' : 'code';
            dispatch(setLoading(true));
            dispatch(findProduct(code, warehouseID, pointOfSaleID, false, function (product) {
                if(product.needsSerialNumber()){
                    return showInputDialogue('Insert serial number!', '', '', function (serialNumber) {
                        product.data.RFIDSerialNumber = serialNumber;
                        dispatch(addProduct(product));
                    }, undefined, false, true);
                }else{
                    return addProduct(product);
                }
            }, function (code, error) {
                return showDialogue(translate('Could not find product with code') + ' ' + code, translate('Do you want to add unknown item') + '?', 'Yes', function () {
                    dispatch(productNotFound(code, error));
                }, function () {},'No');
            }, undefined, () => {
                dispatch(setLoading(false));
            }, searchCodeType));
        }
    }
};

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            selectedUser: false,
            errorMessage: false
        };

        this.findItem = this.findItem.bind(this);
    }

    componentDidMount() {

    }

    findItem() {
        const code = document.getElementsByName("itemCode")[0].value;
        console.log('code', code);
        this.props.findProduct(code, this.props.warehouseID, this.props.pointOfSaleID, this.props.translate);
    }

    render() {
        let content;
        if(this.props.theme === 'Erply'){
            content = <div>
                <Header title={"Adding an item"}/>
                <div style={{
                    margin: '15% auto 0px',
                    width: '400px'
                }}>
                    <label style={{
                        fontSize: '16px',
                        color: '#808080',
                        marginLeft: '15px'
                    }}>
                        <Translate id={"Enter the item code"}/>
                    </label>
                    <input
                        type="text"
                        name={'itemCode'}
                        className={'underlineInput'}
                        autoComplete={'off'}
                    />
                </div>
            </div>;
        }else{
            content = <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Adding an item"}/></h1>
                <label><Translate id={"Enter the item code"}/></label><br/>
                <input type="text" name={'itemCode'} className={'underlineInput'} autoComplete={'off'}/>
            </div>;
        }
        return (
            <div>
                {content}
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: this.state.keyboardLayout,
                        inputFieldName: "itemCode",
                        onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                        onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                        onDone: this.findItem
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(AddItem));
