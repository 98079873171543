import React, {Component, Fragment} from 'react';
import "../App.css"
import "./main.css"
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import ProductLine from "./ProductLine";
import bagIcon from "./images/iconBag.png";
import bagIconErply from "./images/Erply/addBag.png";
import presentIcon from "./images/presentIcon.png"
import {
    addDocumentDiscount,
    addDocumentDiscountApproval,
    addErrorAlert,
    changeMode,
    closeModal,
    closeRightPanelDialogue,
    displayShortProductDescription,
    findProduct,
    getUserRights,
    openGetUserRightsByPinModal,
    openLogonModal,
    openModal,
    removeCustomer,
    removeDocumentDiscount,
    removePaybackCoupon,
    removePaybackCustomer,
    removeProduct,
    setLoading,
    showInputDialogue,
    showRightPanelDialogue,
    showSelectDialogue,
    updateProduct
} from "../redux/actions"
import {
    getActiveLanguage,
    getProductsTotalSum,
    getReasonCodeByID,
    getReasonCodesByPurpose,
    nonDiscountableProductsInList,
    displayNetPrices,
    getNumberOfArticles,
    transactionMOPLimit,
    getProductsTotalSumBeforeManualDiscount,
    getNumberOfDecathlonGiftCardsInBasket,
    getProductsTotalTax,
    getBagItemCode
} from "../redux/selectors";
import {getCheckedGiftCardPayments, getGiftCardsSum} from "../redux/payment/selectors";
import GiftCardLine from "./GiftCardLine";
import {removePayment} from "../redux/payment/actions";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import RemoveItems from "./RemoveItems";
import {APP_MODE_SCAN} from "../redux/appConstants";
import MembershipLine from "./MembershipLine";
import PaymentLine from "./payment/PaymentLine";
import iconCard from "./images/iconBankCardBlue.png";
import iconCardErply from "./images/Erply/bankCard.png";
import iconCash from "./images/pictureCash.png";
import iconCashErply from "./images/Erply/cash.png";
import iconDefault from "./images/voucherPaymentBlue.png";
import iconDefaultErply from "./images/Erply/number.png";
import iconVoucher from "./images/voucherPaymentBlue.png";
import iconVoucherErply from "./images/Erply/bankCard.png";
import {createModalID} from "../modal/Modal";
import MultipleInputContent from "../modal/MultipleInputContent";
import operatorBypassApproval from "../redux/dto/operatorBypassApproval";
import Decimal from "decimal.js";
import GiftCardActivation from "./payment/GiftCardActivation";
import {displayPrice} from "../util/helperFunctions";
import BasketLine from "../integrations/payback/BasketLine";
import paybackLogoImg from "../integrations/payback/img/paybackLogo.png";
import paybackCouponImg from "../integrations/payback/img/coupon.png";
import {formatPaybackCustomerCode} from "../integrations/payback/util";
import PaymentButton from "./PaymentButton";


const paymentTypeToImage = (type, theme) => {
    if(type.toLowerCase().indexOf('voucher') !== -1){
        return theme === 'Decathlon' ? iconVoucher : iconVoucherErply;
    }
    switch (type.toLowerCase()) {
        case "card":{
            return theme === 'Decathlon' ? iconCard : iconCardErply;
        }
        case "cash":{
            return theme === 'Decathlon' ? iconCash : iconCashErply;
        }
        default: {
            return theme === 'Decathlon' ? iconDefault : iconDefaultErply;
        }
    }
};

const getHasRightToMakeDiscount = (userRights) => {
    return userRights.rightMakeDiscountInPOS == 1;
};

const editProductPrice = (dispatch, clientCode, posID, originalProduct, currency, reasonCodes, hasRightToMakeDiscount, discountSupervisorLimit, discount, useNetPrice, user) => {
    console.log('Edit product price', originalProduct);

    let product = Object.assign( Object.create( Object.getPrototypeOf(originalProduct)), originalProduct);

    function calculateFields(inputValues, activeInput){
        if(activeInput === 'sum' && !isNaN(inputValues[activeInput])){
            product.setManualDiscountSum(inputValues[activeInput], useNetPrice);
        }else if (activeInput === 'percentage' && !isNaN(inputValues[activeInput])){
            product.setManualDiscountPercentage(inputValues[activeInput]);
        }else if (activeInput === 'unitPrice'){
            product.setManualDiscountUnitPrice(inputValues[activeInput], useNetPrice);
        }
    }

    let getInputValues = product => {
        console.log('getInputValues', product, product.getNetPrice(product.manualDiscountUnitPrice, product.data.vatrate).toString(), product.manualDiscountUnitPrice.toString());
        let inputValues = product.hasManualDiscount() ? {
            unitPrice: useNetPrice ? product.getNetPrice(product.manualDiscountUnitPrice, product.data.vatrate).toString() : product.manualDiscountUnitPrice.toString(),
            percentage: product.manualDiscountPercentage.toString(),
            sum: useNetPrice ? product.getNetPrice(product.manualDiscountTotal, product.data.vatrate).toString() : product.manualDiscountTotal.toString()
        } : {};
        console.log('inputValues', inputValues);
        return inputValues;
    };

    let initialValues = getInputValues(product);

    let inputFields = [
        { id: 'unitPrice', name: 'Unit price', isNumber: true},
        { id: 'percentage', name: 'Discount %', isNumber: true},
        { id: 'sum', name: 'Discount sum', isNumber: true}
    ];
    let editModalID = createModalID();
    dispatch(openModal({
        content:
            <Translate>{({ translate }) =>
                <MultipleInputContent
                    title={translate('Edit product price')}
                    inputFields={ inputFields }
                    translate={translate}
                    onDone={(inputValues) => {
                        console.log('inputValues', inputValues);
                        if(Object.keys(inputValues).length === 0){
                            dispatch(closeModal(editModalID));
                            return;
                        }

                        if(product.netPrice === 0 || product.originalPriceZero){
                            if(useNetPrice){
                                product.setNetPrice(inputValues.unitPrice);
                            }else{
                                product.setVatPrice(inputValues.unitPrice);
                            }
                        }
                        if(!product.hasManualDiscount() && product.manualPrice === false){
                            dispatch(closeModal(editModalID));
                            return;
                        }

                        let askReasonCodesIfApplicable = () => {
                            return new Promise((resolve) => {
                                if(parseFloat(inputValues['percentage']) === 0){
                                    if(product.amount < 0){
                                        delete product.manualDiscountReasonCodeID;
                                        delete product.manualDiscountReason;
                                    }else{
                                        delete product.returnReason;
                                        delete product.returnReasonID;
                                    }
                                    product.manualPrice = false;
                                    resolve();
                                    return;
                                }

                                let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, 'DISCOUNT').reduce((acc, reasonCode) => {
                                    acc.push({
                                        name: reasonCode.name,
                                        value: reasonCode.reasonID
                                    });
                                    return acc;
                                }, []);
                                if(reasonCodeOptions.length === 0){
                                    resolve();
                                    return;
                                }

                                dispatch(showSelectDialogue('Discount reason', reasonCodeOptions, (reasonID, reason) => {
                                    let reasonCode = getReasonCodeByID(reasonCodes, reasonID);
                                    if(reasonCode === undefined){
                                        reasonCode = {reasonID, name: reason};
                                    }
                                    if(product.amount < 0){
                                        product.manualDiscountReasonCodeID = reasonID;
                                        product.manualDiscountReason = reasonCode;
                                    }else{
                                        product.returnReasonID = reasonID;
                                        product.returnReason = reasonCode;
                                    }

                                    resolve();
                                }, e => {}, false));
                            });
                        };

                        askReasonCodesIfApplicable().then(() => {
                            if(inputValues['percentage'] > discountSupervisorLimit && !hasRightToMakeDiscount){
                                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, ((userRights, supervisor) => {
                                    if(getHasRightToMakeDiscount(userRights)){
                                        console.log("adding product discount operatorBypassApproval", {supervisor});
                                        product.discountApproval = new operatorBypassApproval('ModLineDisc', supervisor.employeeID);
                                        dispatch(updateProduct(product));
                                    }else{
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                    }
                                })));
                            }else{
                                console.log("adding product discount operatorBypassApproval", {user});
                                product.discountApproval = new operatorBypassApproval('ModLineDisc', user.employeeID);
                                dispatch(updateProduct(product));
                            }
                            if(typeof discount.documentDiscountPercentage !== "undefined" && parseFloat(inputValues['percentage']) !== parseFloat(discount.documentDiscountPercentage)){
                                dispatch(removeDocumentDiscount());
                            }
                        });

                        dispatch(closeModal(editModalID));
                    }}
                    onChange={(inputValues, activeInput, changeInput) => {
                        if(inputValues[activeInput] === ''){
                            changeInput({});
                        }else{
                            if(inputValues[activeInput].slice(-1) !== '.'){
                                calculateFields(inputValues, activeInput);
                                changeInput(getInputValues(product));
                            }
                        }
                    }}
                    initialValues={ initialValues }
                    beforeInputFields={
                        <table>
                            <tbody>
                            <tr>
                                <td>{translate('Product')}:</td>
                                <td>{product.name}</td>
                            </tr>
                            <tr>
                                <td>{translate('Price')}:</td>
                                <td>{product.getDisplayUnitPrice(useNetPrice)} {currency}</td>
                            </tr>
                            </tbody>
                        </table>
                    }
                    initialKeyboardLayout={'numbers'}
                    inputValuesDisplayRounding={window?.AppConf?.priceDecimals ?? 2}
                />

            }
            </Translate>,
        id: editModalID,
        className: "Administration",
        onClose: function () {

        },
        canClose: true
    }));
};

const showDocumentDiscountDialogue = (dispatch, discount, onDone) => {
    let inputFields = [
        { id: 'percentage', name: 'Discount %', isNumber: true},
        { id: 'sum', name: 'Discount sum', isNumber: true}
    ];

    const initialValues = {
        sum: discount.documentDiscountPrice || '',
        percentage: discount.documentDiscountPercentage || ''
    };

    let editModalID = createModalID();

    return (openModal({
        content:
            <Translate>{({ translate }) =>
                <MultipleInputContent
                    title={'Receipt discount'}
                    inputFields={ inputFields }
                    translate={translate}
                    onDone={(inputValues) => {
                        console.log('inputValues', inputValues);
                        onDone(inputValues);
                        dispatch(closeModal(editModalID));
                    }}
                    onChange={(inputValues, activeInput, changeInput) => {
                        let newValues = {};
                        newValues[activeInput] = inputValues[activeInput];
                        changeInput(newValues);
                    }}
                    initialValues={ initialValues }
                    initialKeyboardLayout={'numbers'}
                    inputValuesDisplayRounding={window?.AppConf?.priceDecimals ?? 2}
                />

            }
            </Translate>,
        id: editModalID,
        className: "Administration",
        onClose: function () {

        },
        canClose: true
    }));
}

const mapStateToProps = (state) => {
    return {
        products: state.rootReducer.productsInBasket,
        productCategories: state.rootReducer.productCategories,
        customer: state.rootReducer.customer,
        payback: state.rootReducer.payback,
        currency: state.rootReducer.currency,
        decathlonBagCode: getBagItemCode(),
        mode: state.rootReducer.mode,
        cashierMode: state.rootReducer.cashierMode,
        transactionMode: state.rootReducer.transactionMode,
        warehouseID: state.rootReducer.pos.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        clientCode: state.rootReducer.user.clientCode,
        giftCardPayments: getCheckedGiftCardPayments(state.payment.payments),
        payment: state.payment,
        reasonCodes: state.rootReducer.reasonCodes,
        discountSupervisorLimit: parseFloat(state.rootReducer.erplyConf.DKTDiscountSupervisorLimit) || 10,
        translate: getTranslate(state.localize),
        language: getActiveLanguage(state.localize.languages),
        uiCustomizationPOS: state.rootReducer.uiCustomizationPOS,
        discount: state.rootReducer.discount,
        displayNetPrices: displayNetPrices(state.rootReducer.erplyConf),
        theme: state.rootReducer.theme,
        leftPanelFullScreen: state.rootReducer.leftPanelFullScreen
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addBag: (decathlonBagCode, warehouseID, pointOfSaleID) => {
            dispatch(setLoading(true));
            dispatch(findProduct(decathlonBagCode, warehouseID, pointOfSaleID, false, undefined, undefined, undefined, () => {
                dispatch(setLoading(false));
            }));
        },
        removeProduct: product => {
            dispatch(showRightPanelDialogue(
                <RemoveItems
                    title={<Translate id={"removeItemFromBin"}/>}
                    confirmText={<Translate id={"yes"}/>}
                    onConfirm={() => {
                        dispatch(removeProduct(product));
                        dispatch(closeRightPanelDialogue());
                    }}
                    cancelText={<Translate id={"no"}/>}
                    onCancel={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            ));
        },
        openGiftCardView: (productsInBasket, productCategories) => {
            let giftCardsInBasket = getNumberOfDecathlonGiftCardsInBasket(productsInBasket, productCategories);
            let allowedGiftCardsInBasket = window.AppConf?.giftCardActivationLimit ?? 10;
            if(giftCardsInBasket >= allowedGiftCardsInBasket){
                dispatch(addErrorAlert("No more gift cards can be added to the basket"));
            }else{
                dispatch(changeMode('addGiftCard'));
                dispatch(showRightPanelDialogue(
                    <GiftCardActivation/>
                ));
            }
        },
        removePayment: payment => dispatch(removePayment(payment)),
        editProductAmount: (originalProduct, newAmount, transactionMode, productsInBasket) => {
            if(originalProduct._isContainer || originalProduct.barcodePrice || originalProduct.barcodeWeight){
                return;
            }
            let product = Object.assign( Object.create( Object.getPrototypeOf(originalProduct)), originalProduct);
            console.log('product:', product, originalProduct);

            let updateProductAmount = (product, newAmount) => {
                product.setAmount(newAmount);
                dispatch(updateProduct(product));
                if(product.hasContainer){
                    let containerProduct = productsInBasket.find(el => el.containerForProductLine === product.lineNumber);
                    if(typeof containerProduct !== "undefined"){
                        let productContainer = Object.assign( Object.create( Object.getPrototypeOf(containerProduct)), containerProduct);
                        productContainer.setAmount(newAmount);
                        dispatch(updateProduct(productContainer));
                    }
                }
            }
            if(newAmount === false){
                if(transactionMode === 'SALE' && originalProduct.amount < 0){
                    return;
                }
                let displayEnterAmountDialogue = () => {
                    dispatch(showInputDialogue('Enter amount', '', '', (inputAmount) => {
                        let parsedInput = parseFloat(inputAmount.replace(',', '.'));
                        if(isNaN(parsedInput)){
                            dispatch(addErrorAlert('Please enter a number'));
                        }else{
                            if(parsedInput === 0){
                                return;
                            }
                            if(parsedInput > 999999){
                                dispatch(addErrorAlert('Please enter a number smaller than 1000000'));
                                displayEnterAmountDialogue();
                                return;
                            }
                            updateProductAmount(product, parsedInput);
                        }
                    }, () => {}, true, true));
                }
                displayEnterAmountDialogue();
            }else{
                if(typeof product.baseDocumentRow !== "undefined"){
                    if(newAmount === 0){
                        return;
                    }else if(product.baseDocumentRow.amount < Math.abs(newAmount)){
                        dispatch(addErrorAlert('Quantity can\'t be higher than on the base document!'));
                        return;
                    }
                }

                if(newAmount === 0){
                    return;
                }
                if(transactionMode === 'SALE' && originalProduct.amount < 0){
                    return;
                }
                console.log('product:', product);
                updateProductAmount(product, newAmount);
            }
        },
        editProductPriceIfAllowed: (clientCode, posID, originalProduct, currency, reasonCodes, discountSupervisorLimit, discount, useNetPrice) => {
            let productIsDiscountable = window.AppConf.ignoreDiscountableFlag ? true : originalProduct.isDiscountable();
            if(originalProduct._isContainer){
                return;
            }
            if(typeof originalProduct.giftCardCode !== "undefined" || !productIsDiscountable){
                dispatch(addErrorAlert("Can't edit this product price!"));
                return;
            }
            if(typeof discount?.documentDiscountPercentage !== "undefined" || typeof discount?.documentDiscountPrice !== "undefined"){
                dispatch(addErrorAlert("Can't edit product price! Transaction discount already added!"));
                return;
            }
            dispatch(openLogonModal('Logon to edit product price!', (data) => {
                dispatch(getUserRights(data[0].userID, posID, (records) => {
                    editProductPrice(dispatch, clientCode, posID, originalProduct, currency, reasonCodes, getHasRightToMakeDiscount(records[0]), discountSupervisorLimit, discount, useNetPrice, data[0]);
                    return {
                        type: 'USER_RIGHTS_SUCCESS'
                    }
                }));
            }));
        },
        addDocumentDiscount: (posID, clientCode, reasonCodes, products, discount, useNetPrices) => {
            let nonDiscountableProductsInBasket = nonDiscountableProductsInList(products);
            if(nonDiscountableProductsInBasket){
                dispatch(addErrorAlert('At least one of the products is not discountable!'));
                return;
            }

            dispatch(openLogonModal('Logon to edit product price!', (data) => {
                let user = data[0];
                dispatch(getUserRights(user.userID, posID, (records) => {
                    let hasRight = getHasRightToMakeDiscount(records[0]);
                    return showDocumentDiscountDialogue(dispatch, discount, ({percentage, sum}) => {
                        if(sum === '' && percentage === '') return;

                        if(sum !== ''){
                            let documentTotal = getProductsTotalSumBeforeManualDiscount(products, useNetPrices);
                            percentage = new Decimal(sum).div(new Decimal(documentTotal)).mul(100).toDecimalPlaces(4).toString();
                        }


                        let askReasonCodesIfApplicable = () => {
                            return new Promise((resolve) => {
                                let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, 'DISCOUNT').reduce((acc, reasonCode) => {
                                    acc.push({
                                        name: reasonCode.name,
                                        value: reasonCode.reasonID
                                    });
                                    return acc;
                                }, []);
                                if(reasonCodeOptions.length === 0){
                                    resolve();
                                    return;
                                }
                                dispatch(showSelectDialogue('Discount reason', reasonCodeOptions, (reasonID, reason) => {
                                    let reasonCode = getReasonCodeByID(reasonCodes, reasonID);
                                    if(reasonCode === undefined){
                                        reasonCode = {reasonID, name: reason};
                                    }
                                    resolve(reasonCode);
                                }, e => {}, false));
                            });
                        };

                        askReasonCodesIfApplicable().then((reasonCode) => {
                            if(hasRight){
                                dispatch(addDocumentDiscount(percentage, reasonCode, sum));
                                dispatch(addDocumentDiscountApproval(user.employeeID));
                            }else{
                                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, ((userRights, supervisor) => {
                                    if(getHasRightToMakeDiscount(userRights)){
                                        dispatch(addDocumentDiscount(percentage, reasonCode, sum));
                                        dispatch(addDocumentDiscountApproval(supervisor.employeeID));
                                    }else{
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                    }
                                })))
                            }
                        });
                    }, undefined, true, true);
                }));
            }));
        },
        displayShortProductDescription: (product, language) => {
            dispatch(displayShortProductDescription(product.data, language));
        },
        openLogonModal: (title, afterLogonAction) => {
            dispatch(openLogonModal(title, afterLogonAction));
        },
        removePaybackCustomer: () => {
            dispatch(removePaybackCustomer());
        },
        removePaybackCoupon: (coupon) => {
            dispatch(removePaybackCoupon(coupon));
        },
        removeCustomer: () => {
            dispatch(removeCustomer());
        }
    }
};

class ProductList extends Component{
    getTotalPrice(products, payment){
        let total = getProductsTotalSum(products);
        const giftCardsSum = new Decimal(getGiftCardsSum(payment.payments));

        return displayPrice(new Decimal(total).minus(new Decimal(payment.totalPaid)).minus(giftCardsSum));
    };

    getTotalTaxes(products){
        return displayPrice(getProductsTotalTax(products));
    };

    getTotalDiscount(products, displayNetPrices){
        let total = getProductsTotalSum(products);
        let totalBefore = getProductsTotalSumBeforeManualDiscount(products, displayNetPrices);
        return displayPrice(totalBefore - total);
    }

    render() {
        let containerStyle = {
            width: "100%",
            overflow: "hidden",
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        };

        let addProducts;
        if(this.props.mode === 'scan') {
            let giftCardTextParts = this.props.translate('giftCard').split(' ');
            let giftCardTextFirstWord = giftCardTextParts.shift();
            let giftCardTextOtherWords = giftCardTextParts.join(' ');
            let giftCardText = (
                <div>
                    <span style={{color: '#f29125', display: 'inline-block', marginRight: '5px', width: 'fit-content'}}>{giftCardTextFirstWord}</span>
                    <span style={{display: 'inline-block', marginRight: '4px', width: 'fit-content'}}>{giftCardTextOtherWords}</span>
                    <img style={{
                        position: 'relative',
                        top: '3px',
                        marginBottom: '3px',
                        height: '20px'
                    }} src={presentIcon} alt="presentIcon"/>
                </div>
            );

            let buyGiftCardButton;

            if(process.env.REACT_APP_ERPLY_MODE !== "1" && this.props.transactionMode === 'SALE'){
                buyGiftCardButton = (
                    <div
                        className={"addProductContainer"}
                        onClick={e => {
                            this.props.openGiftCardView(this.props.products, this.props.productCategories);
                        }}
                        style={{
                            textAlign: 'center',
                            borderLeft: '2px solid #ebebeb'
                        }}
                    >
                        <div
                            className={'capitalizeFirst'}
                            style={{
                                fontSize: "16px",
                                color: '#2a92d0',
                                fontWeight: 'bold',
                                width: '100%',
                                textAlign: 'center'
                            }}
                        >
                            <Translate id={"click here to buy a"}/>
                        </div>
                        <div style={{
                            display: 'inline-grid',
                            fontSize: '20px',
                            border: '4px solid #1180bd',
                            color: '#1180bd',
                            padding: '0 4px',
                            boxSizing: 'border-box'
                        }}>
                            { giftCardText }
                        </div>
                    </div>
                )
            }

            let addBagButton;
            let bagCode = this.props.uiCustomizationPOS.shoppingBag.code !== '' ? this.props.uiCustomizationPOS.shoppingBag.code : this.props.decathlonBagCode;

            if(/*!this.props.cashierMode &&*/ this.props.uiCustomizationPOS.shoppingBag.display === 1 && bagCode !== false){
                if(this.props.theme === 'Erply'){
                    addBagButton = (
                        <div style={{
                            padding: '10px'
                        }} onClick={e => {
                            this.props.addBag(bagCode, this.props.warehouseID, this.props.pointOfSaleID);
                        }}>
                            <img style={{
                                margin: '10px',
                                float: 'left'
                            }} src={bagIconErply} alt="bagIcon"/>
                            <span className={"boldUppercaseText"} style={{
                                fontSize: "14px",
                                float: 'left',
                                lineHeight: 3.2
                            }}>
                            <Translate id={"addBag"}/>
                            </span>
                        </div>
                    );
                }else{
                    addBagButton = (
                        <div className={"addProductContainer"} onClick={e => {
                            this.props.addBag(bagCode, this.props.warehouseID, this.props.pointOfSaleID);
                        }}>
                            <div style={{
                                float: 'left',
                                width: '80%'
                            }}>
                                <div style={{
                                    float: 'left',
                                    marginRight: '10px',
                                    marginBottom: '10px'
                                }}>
                                    <img style={{
                                        verticalAlign: 'middle'
                                    }} src={bagIcon} alt="bagIcon"/>
                                </div>
                                <span style={{
                                    fontSize: "20px",
                                    textTransform: "none",
                                    fontStyle: 'italic',
                                    paddingTop: '5px',
                                    display: 'block'
                                }}>
                                <Translate id={"addBag"}/>
                                </span>
                            </div>
                            <div style={{
                                float: 'right',
                                width: '20%'
                            }}>
                                <i className={"icoFont blueCircleBackgroundSmall"} style={{
                                    float:"right",
                                    zoom: 1.4,
                                    // marginTop: "8px"
                                    marginLeft: "10px"
                                }}>&#xefc2;</i>
                            </div>
                        </div>
                    );
                }
            }

            addProducts = (
                <div style={{
                    borderTop: '2px solid #ebebeb',
                    borderBottom: '2px solid #ebebeb'
                }}>
                    { buyGiftCardButton }
                    { addBagButton }
                    <div style={{clear: 'both'}}/>
                </div>
            );
        }

        if( this.props.products.length === 0 &&
            this.props.customer === false &&
            this.props.payback.customer === false &&
            this.props.payback.coupons.length === 0
        ) {
            let noArticlesContent;
            if(process.env.REACT_APP_ERPLY_MODE === "1"){
                noArticlesContent = (
                    <div className={'boldText'} style={{
                        fontSize: '24px',
                        color: '#f0f0f0'
                    }}>
                        <Translate id={"No items have been added yet"}/>
                    </div>
                );
            }else{
                noArticlesContent = (
                    <Translate id={"noArticlesInBasket"}/>
                );
            }

            return (
                <div style={containerStyle}>
                    <div style={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <span style={{
                            width: "100%",
                            textAlign: "center"
                        }}>
                            {noArticlesContent}
                        </span>
                    </div>
                    {addProducts}
                </div>
            );
        }

        setTimeout(function () {
            let productListDiv = document.getElementById("productList");
            if(productListDiv === null) return false;
            let out = productListDiv.getElementsByClassName("scrollbar-container ps")[0];
            if(typeof out !== "undefined" && out !== null){
                let isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
                if(!isScrolledToBottom){
                    out.scrollTop = out.scrollHeight - out.clientHeight;
                }
            }
        }, 200);

        let membershipLine;
        if(this.props.customer !== false && this.props.customer.default !== true){
            membershipLine = <MembershipLine customer={this.props.customer} remove={() => {
                this.props.removeCustomer();
            }}/>;
        }

        let paybackLines = [];
        if(this.props.payback.customer !== false){
            paybackLines.push(
                <BasketLine
                    key="0"
                    title="Added Payback card"
                    value={formatPaybackCustomerCode(this.props.payback.customer)}
                    image={paybackLogoImg}
                    remove={this.props.mode === APP_MODE_SCAN && typeof this.props.payback.referenceReceipt === "undefined" ? this.props.removePaybackCustomer : false}
                />);
        }
        this.props.payback.coupons.forEach((coupon, index) => {
            paybackLines.push(
                <BasketLine
                    key={index + 1}
                    title="Added Payback coupon"
                    value={coupon}
                    image={paybackCouponImg}
                    remove={this.props.mode === APP_MODE_SCAN ? () => {
                        this.props.removePaybackCoupon(coupon);
                    } : false}
                />
            );
        });

        let paymentLines = this.props.payment.payments.filter(el => el.status === 'success').map((payment, index) => {
            if(payment.status !== 'success'){
                return '';
            }
            let displayDelete = false;
            if(this.props.payment.payments.length >= transactionMOPLimit){
                if(payment.type.toUpperCase() !== 'CARD'){
                    displayDelete = true;
                }
            }
            return (
                <PaymentLine
                    key={index}
                    payment={payment}
                    currency={this.props.currency}
                    image={paymentTypeToImage(payment.type, this.props.theme)}
                    removePayment={this.props.removePayment}
                    displayDelete={displayDelete}
                />
            );
        });

        let documentDiscountTotal = this.getTotalDiscount(this.props.products, this.props.displayNetPrices);
        let productListTotals;

        if(this.props.theme === 'Erply'){
            productListTotals = (
                <div className={"productListTotalsErply"}>
                    <div style={{
                        // display: "inline-block",
                        float: 'right'
                    }}>
                        <div style={{
                            color: '#FFFFFF',
                            opacity: 0.7,
                            display: 'inline-block',
                            marginRight: '10px'
                        }}>
                            <Translate id={"toBePaid"} style={{"text-transform": "capitalize:first !important"}}/>
                            <br/>
                            {this.props.displayNetPrices ? <span style={{
                                fontSize: "16px",
                                fontWeight: "normal"
                            }}>
                                        <Translate id={"with taxes"}/>
                                    </span> : ""}
                        </div>
                        <div className={"totalPriceErply"} style={{
                            display: "inline-block",
                        }} onClick={e => {
                            if(this.props.mode === APP_MODE_SCAN){
                                this.props.addDocumentDiscount(this.props.pointOfSaleID, this.props.clientCode, this.props.reasonCodes, this.props.products, this.props.discount, this.props.displayNetPrices);
                            }
                        }}>
                            {this.getTotalPrice(this.props.products, this.props.payment)} {this.props.currency}
                        </div>
                    </div>
                </div>
            );
        }else{
            productListTotals = (
                <div className={"productListTotals"}>
                    <div style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoColumns: "1fr",
                    }}>
                        <table className={"responsiveTable bold"}>
                            <tbody>
                            <tr>
                                <td style={{"fontWeight": "normal", "paddingBottom": "0"}}>
                                    <Translate id={"articles"} />
                                </td>
                                <td style={{"paddingBottom": "0"}}>
                                    <div className={"roundBackground blueBackground"} style={{"display": "inline-block"}}>
                                        {getNumberOfArticles(this.props.products)}
                                    </div>
                                </td>
                            </tr>
                            {
                                this.props.cashierMode ? (
                                    <Fragment>
                                        { parseFloat(documentDiscountTotal) !== 0 ? (
                                            <tr  style={{
                                                fontSize: "18px"
                                            }}>
                                                <td><Translate id={"Discount sum"}/></td>
                                                <td>{documentDiscountTotal} {this.props.currency}</td>
                                            </tr>
                                        ) : null}
                                        <tr style={{
                                            fontSize: "18px"
                                        }}>
                                            <td><Translate id={"Taxes"}/></td>
                                            <td>
                                                {this.getTotalTaxes(this.props.products)} {this.props.currency}
                                            </td>
                                        </tr>
                                    </Fragment>
                                ) : null
                            }
                            <tr>
                                <td>
                                    <Translate id={"toBePaid"} style={{"text-transform": "capitalize:first !important"}}/>
                                    <br/>
                                    {this.props.displayNetPrices ? <span style={{
                                        fontSize: "16px",
                                        fontStyle: "italic",
                                        fontWeight: "normal",
                                        fontFamily: "Roboto Condensed"
                                    }}>
                                            <Translate id={"with taxes"}/>
                                        </span> : ""}
                                </td>
                                <td style={{
                                    textAlign: "right"
                                }}>
                                    <div className={"totalPrice"} style={{"display": "inline-block"}} onClick={e => {
                                        if(this.props.mode === APP_MODE_SCAN){
                                            this.props.addDocumentDiscount(this.props.pointOfSaleID, this.props.clientCode, this.props.reasonCodes, this.props.products, this.props.discount, this.props.displayNetPrices);
                                        }
                                    }}>
                                        {this.getTotalPrice(this.props.products, this.props.payment)} {this.props.currency}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {this.props.mode === APP_MODE_SCAN && this.props.leftPanelFullScreen ? (
                            <div style={{
                                borderLeft: "2px solid #e6e6e6",
                                display: "flex"
                            }}>
                                <div style={{
                                    margin: "auto"
                                }}>
                                    <PaymentButton/>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        }

        let editProductAmountFunction;

        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            editProductAmountFunction = (product, newAmount=false) => {
                this.props.editProductAmount(product, newAmount, this.props.transactionMode, this.props.products);
            }
        }else{
            editProductAmountFunction = (product, newAmount=false) => {
                this.props.openLogonModal('Logon to edit product amount!', (data) => {
                    this.props.editProductAmount(product, newAmount, this.props.transactionMode, this.props.products);
                });
            }
        }

        return (
            <div style={containerStyle}>
                <div id={"productList"}>
                    <PerfectScrollbar style={{
                        position: "absolute",
                        left: 0,
                        right:0,
                        top: 0,
                        bottom: 0,
                    }}>
                    <table className={`${this.props.theme === 'Erply' ? "responsiveTableErply" : "responsiveTable"} bold borderBottomTd`}>
                        <tbody>
                        {
                            this.props.products.map( (product) => {
                                return <ProductLine
                                    key={product.lineNumber}
                                    theme={this.props.theme}
                                    product={product}
                                    removeProduct={this.props.mode === APP_MODE_SCAN ? this.props.removeProduct : false}
                                    currency={this.props.currency}
                                    editProductAmount={ this.props.mode === APP_MODE_SCAN ? editProductAmountFunction : () => {}}
                                    editProductPrice={
                                        this.props.mode === APP_MODE_SCAN ?
                                        (product, currency) => {
                                            this.props.editProductPriceIfAllowed(
                                                this.props.clientCode,
                                                this.props.pointOfSaleID,
                                                product,
                                                currency,
                                                this.props.reasonCodes,
                                                this.props.discountSupervisorLimit,
                                                this.props.discount,
                                                this.props.displayNetPrices
                                            );
                                        }
                                        : () => {}
                                    }
                                    onInfoButtonClick={(product) => {
                                        this.props.displayShortProductDescription(product, this.props.language);
                                    }}
                                    displayNetPrices={this.props.displayNetPrices}
                                    displayTax={this.props.cashierMode}
                                />
                            })
                        }
                        { membershipLine }
                        { paybackLines }
                        {
                            this.props.giftCardPayments.map(giftCardPayment => {
                               return <GiftCardLine key={giftCardPayment.index} payment={giftCardPayment} removeGiftCardPayment={this.props.removePayment} currency={this.props.currency}/>
                            })
                        }
                        {paymentLines}
                        </tbody>
                    </table>
                    </PerfectScrollbar>
                </div>
                <div>
                    { addProducts }
                    { productListTotals }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(ProductList))
