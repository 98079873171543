import React, {Component} from 'react';
import "./main.css"
import {Translate, withLocalize} from "react-localize-redux";
import giftCardImg from "./images/membership.png"
import deleteIcon from "./images/trash.png";

class MembershipLine extends Component{
    render() {
        let displayField;
        let email = this.props.customer.email;
        let phone = this.props.customer.phone;
        if(this.props.customer.card_number !== ''){
            displayField = <span className={"unitPrice"}><Translate id={"Number"}/> : {this.props.customer.card_number}</span>;
        }else if( email !== undefined && email !== ""){
            displayField = <span className={"unitPrice"}><Translate id={"Email"}/> : {this.props.customer.email}</span>
        }else if( phone !== undefined && phone !== ""){
            displayField = <span className={"unitPrice"}><Translate id={"Phone"}/> : {this.props.customer.phone}</span>
        }else if(this.props.customer.fullName !== undefined){
            displayField = <span className={"unitPrice"}>{this.props.customer.fullName}</span>
        }
        return (
            <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "capitalize"}}><Translate id={"membership"}/></span><br/>
                    { displayField }
                </td>
                <td className={"productLinePriceColumn"}/>
                <td>
                    <img src={giftCardImg} alt={'giftCardImg'} style={{height:"50%"}}/>
                </td>
                {
                    this.props.customer.canRemove ?
                        <td onClick={e => this.props.remove()} style={{"width": "20px"}}>
                            <img src={deleteIcon} alt={'trash'} style={{
                                maxHeight: '22px'
                            }}/>
                        </td> : <td/>
                }
            </tr>
        );
    }
}

export default withLocalize(MembershipLine)
