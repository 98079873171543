import React from 'react';
import StatusBarErply from "./Erply/StatusBar";
import StatusBarDecathlon from "./Decathlon/StatusBar";

export default function () {
    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        return <StatusBarErply/>
    }else{
        return <StatusBarDecathlon/>
    }
}