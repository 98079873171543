import React, {Component} from 'react';
import {connect} from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

const mapStateToProps = function (state) {
    return {
        loading: state.rootReducer.loading
    }
};

const mapDispatchToProps = function (dispatch) {
    return {

    }
};

class Loading extends Component {
    render() {
        if(this.props.loading){
            return (
                <div style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    background: 'rgba(0,0,0,0.2)',
                    zIndex: 10
                }}>
                    <Loader
                        type="TailSpin"
                        color="#0082C3"
                        height={100}
                        width={100}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            margin: "-50px 0 0 -50px"
                        }}
                    />
                </div>
            );
        }else{
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
