import React, { Component } from 'react';
import './screenSaver.css';
import scanItemImg from "./scanItem.png";
import {Translate} from 'react-localize-redux';
import {connect} from "react-redux";
import {changeMode} from "../../redux/actions";
import {APP_MODE_SCAN} from "../../redux/appConstants";
import {getActiveLanguage} from "../../redux/selectors";

const mapStateToProps = state => {
    return {
        erplyConf: state.rootReducer.erplyConf,
        uiCustomization: state.rootReducer.uiCustomization,
        activeLanguage: getActiveLanguage(state.localize.languages)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeMode: mode => dispatch(changeMode(mode))
    }
};

class ScreenSaver extends Component{
    render() {
        return (
            <div style={{
                backgroundColor: "#f2f2f2"
            }} className={"touchToStartWrapper"} onClick={e => this.props.changeMode(APP_MODE_SCAN)}>
                <div className={"touchToStartContainer"}>
                    <span>
                        {
                            this.props.uiCustomization.screenSaver.text[this.props.activeLanguage] ||
                            <div>
                                <div><Translate id={"Welcome"}/>!</div>
                                <div><Translate id={"Scan an item to start"}/></div>
                            </div>
                        }
                    </span>
                    {
                        this.props.uiCustomization.screenSaver.image === 'default' ?
                            <img src={scanItemImg} alt={"screenSaver"}/> :
                            <img src={this.props.erplyConf.invoiceLogoURL} alt={"screenSaver"}/>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaver);