import * as React from 'react';

export default function DynamicStyles(props){
    return (
        <div style={{
            height: "100%"
        }}>
            <link rel="stylesheet" type="text/css" href={process.env.ERPLY_MODE === true ? './css/AppErply.css' : './css/AppDecathlon.css'} />
            {props.children}
        </div>
    )
};