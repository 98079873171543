import {
    PAYMENT_API,
    LOGIN,
    ADD_PAYMENT,
    SET_TOTAL_SUM,
    UPDATE_PAYMENT,
    LOGIN_FAILED,
    REMOVE_PAYMENT,
    GIFT_CARD_API,
    ADD_CARD_PAYMENT_PROGRESS_MESSAGE,
    CARD_PAYMENT_FAILED,
    VOID_ALL_PAYMENTS, SET_ID_CARD_IDENTIFICATION, SET_ZIP_CODE_SURVEY_COUNTER
} from "./actionTypes";
import {apiError} from "../actions";
import {API, SET_PAYMENT_TYPES} from "../actionTypes";

export const processCard = (payment, referenceNumber, successActions = []) => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "processCard",
                cardPayment: payment,
                referenceNumber: referenceNumber
            },
            label: "Start payment",
            onSuccess: function (data) {
                return updatePayment(data);
            },
            onFailure: function (payment, result) {
                return paymentFailed(payment, result);
            },
            successActions: successActions
        }
    }
};


export const processIntegratedPayment = (payment, paymentType, successActions = []) => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "processPayment",
                integration: paymentType,
                payment
            },
            label: "Start payment",
            onSuccess: function (data) {
                return updatePayment(data);
            },
            onFailure: function (payment, result) {
                return paymentFailed(payment, result);
            },
            successActions: successActions
        }
    }
};

export const login = () => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "login"
            },
            onSuccess: function () {
                return {
                    type: LOGIN,
                    payload: ''
                }
            },
            onFailure: function () {
                return {
                    type: LOGIN_FAILED,
                    payload: ''
                }
            },
            onConnectionLost: function () {

            }
        }
    }
};

export const voidCardPayment = (payment, onSuccess, onFailure) => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "voidPayment",
                payment
            },
            onSuccess,
            onFailure
        }
    }
};

export const voidIntegratedPayment = (payment, onSuccess, onFailure) => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "voidPayment",
                integration: payment.type,
                payment
            },
            onSuccess,
            onFailure
        }
    }
};

export const logout = () => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "logout"
            }
        }
    }
};

export const readIdCard = (onSuccess, onFailure) => {
    return {
        type: PAYMENT_API,
        payload: {
            data: {
                request: "readIdCard"
            },
            onSuccess,
            onFailure
        }
    }
};

export const setTotalSum = (totalSum) => {
    return {
        type: SET_TOTAL_SUM,
        payload: totalSum
    }
};

export const addPayment = (payment) => {
    return {
        type: ADD_PAYMENT,
        payload: payment
    }
};

export const updatePayment = (payment) => {
    return {
        type: UPDATE_PAYMENT,
        payload: payment
    }
};

export const paymentFailed = (payment, message) => {
    return {
        type: CARD_PAYMENT_FAILED,
        payment,
        message
    }
};

export const removePayment = (payment) => {
    return {
        type: REMOVE_PAYMENT,
        payload: payment
    }
};

export const captureFirstGiftCard = (onSuccess, onFailure, successActions = []) => {
    return {
        type: GIFT_CARD_API,
        payload: {
            data: {
                request: "captureFirst"
            },
            label: "Do capture",
            onSuccess: function (data) {
                onSuccess();
                return updatePayment(data);
            },
            onFailure,
            successActions: successActions
        }
    }
};

export const checkGiftCard = (number, onDone) => {
    return {
        type: GIFT_CARD_API,
        payload: {
            data: {
                request: "checkGiftCard",
                number,
                onDone
            },
            label: "Check giftcard"
        }
    }
};

export const checkGiftCardAvailable = (number, onSuccess, onFailure) => {
    return {
        type: GIFT_CARD_API,
        payload: {
            data: {
                request: "giftCardAvailable",
                number
            },
            onSuccess,
            onFailure,
            label: "Check giftcard available"
        }
    }
};

export const voidGiftCardActivations = (onSuccess, onFailure, giftCards=[]) => {
    return {
        type: GIFT_CARD_API,
        payload: {
            data: {
                request: "voidGiftCardActivations",
                giftCards
            },
            onSuccess,
            onFailure,
            label: "Void gift card activations"
        }
    }
};

export const voidGiftCardPayment = (transactionID, onSuccess, onFailure) => {
    return {
        type: GIFT_CARD_API,
        payload: {
            data: {
                request: "voidGiftCardPayment",
                transactionID
            },
            onSuccess,
            onFailure,
            label: "Void gift card activations"
        }
    }
};

export const getPaymentTypes = () => ({
    type: API,
    payload: {
        data: {
            request: "getPaymentTypes",
            getAllPages: true
        },
        onSuccess: function (data) {
            return {
                type: SET_PAYMENT_TYPES,
                payload: data
            }
        },
        onFailure: (error) => {
            console.log('Could not get payment types');
            return apiError(error);
        },
        label: 'Get payment types'
    }
});

export const savePayment = (parameters, onSuccess, onFailure) => ({
    type: API,
    payload: {
        data: {
            request: "savePayment",
            ...parameters
        },
        onSuccess,
        onFailure,
        label: 'Save payment'
    }
});

export const addCardPaymentProgressMessage = (message) => {
    return {
        type: ADD_CARD_PAYMENT_PROGRESS_MESSAGE,
        message
    }
};

export const voidAllPayments = () => {
    return {
        type: VOID_ALL_PAYMENTS
    }
};

export const setIdCardIdentification = (value) => {
    return {
        type: SET_ID_CARD_IDENTIFICATION,
        value
    }
};

export const setZipCodeSurveyCounter = (value) => {
    return {
        type: SET_ZIP_CODE_SURVEY_COUNTER,
        value
    }
};