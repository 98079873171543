import React, { Component } from 'react';
import './alert.css';
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        alert: state.rootReducer.alert
    }
};

class Alert extends Component{
    render() {
        if(this.props.alert === false) {
            return false;
        }

        let className = "alert " + (this.props.alert.type === 'info' ? 'infoAlert' : 'errorAlert');

        return (
            <div className={className}><Translate id={ this.props.alert.message }/></div>
        );
    }
}

export default connect(mapStateToProps)(withLocalize(Alert));