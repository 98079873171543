import React from 'react';
import '../statusBar.css';
import {connect} from "react-redux";
import userImg from "./images/Lock.png";
import {openAdministrationView} from "../../redux/actions";

export const UserIcon = connect(state => {
    return {
        view: state.rootReducer.view,
        mode: state.rootReducer.mode
    }
}, function (dispatch) {
    return {
        openAdministrationView: () => dispatch(openAdministrationView())
    }
})(function (props) {
    if(props.view === 'app' && props.mode !== 'screenSaver'){
        return (
            <div className={"statusBarItemRight"} onClick={e => {props.openAdministrationView();}}>
                <img src={userImg} alt={"User"}/>
            </div>
        );
    }
    return <div/>;
});