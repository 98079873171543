import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    API_START,
    API_END,
    ACCESS_DENIED,
    API_ERROR,
    API,
    SET_USER,
    SET_POS_ID,
    SET_POS_DATA,
    SET_WAREHOUSE,
    SET_ERPLY_CONF,
    INIT_TRANSACTION,
    CHANGE_VIEW,
    CHANGE_MODE,
    SET_DEFAULT_CUSTOMER,
    SET_PAYMENT_MODE,
    TRANSACTION_FAILED,
    PRODUCT_NOT_FOUND,
    CANCEL_TRANSACTION,
    UPDATE_PRODUCTS,
    TRANSACTION_SUCCESS,
    SET_CUSTOMER,
    CUSTOMER_API,
    SET_ERROR,
    CLOSE_DIALOGUE,
    SHOW_DIALOGUE,
    CLOSE_ADMINISTRATION_VIEW,
    OPEN_ADMINISTRATION_VIEW,
    SET_EMPLOYEES,
    SET_ADMIN_USER,
    UPDATE_PERIPHERAL,
    ADD_PERIPHERAL,
    SCANNER_DETECTED,
    CLOSE_QCO,
    CHANGE_ADMINISTRATION_VIEW,
    SHOW_CALL_ASSISTANCE,
    CLOSE_CALL_ASSISTANCE,
    CANCEL_ADD_UNKNOWN_ITEM,
    SECURITY_ITEM_ADDED,
    SET_CUSTOMER_AGE_VERIFIED,
    REMOVE_SECURITY_ITEMS,
    SHOW_RIGHT_PANEL_DIALOGUE,
    CLOSE_RIGHT_PANEL_DIALOGUE,
    ADD_UNKNOWN_RFID_PRODUCT,
    REMOVE_UNKNOWN_RFID_PRODUCT,
    ADD_ALERT,
    REMOVE_ALERT,
    UPDATE_PRODUCT,
    SET_REASON_CODES,
    CHANGE_TRANSACTION_MODE,
    ADD_PRODUCT_RETURN_REASON_ID,
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_POS_DAY_OPENED,
    SET_POS_DATA_FAILED,
    PRINT_RECEIPT,
    SET_VAT_RATES,
    ADD_DOCUMENT_DISCOUNT,
    ADD_PRODUCT_DISCOUNT_APPROVAL,
    ADD_DOCUMENT_DISCOUNT_APPROVAL,
    REMOVE_PERIPHERAL,
    TRANSACTION_TIMEOUT_REACHED,
    CLOSE_ALL_MODALS,
    OPEN_CASH_DRAWER,
    PRINT_HTML,
    EPSI_REQUEST,
    HANDLE_FISCAL_REQUEST,
    SET_COMPANY_INFO,
    SET_LOADING,
    SET_CUSTOMER_SELECT_LIST,
    SET_PRODUCT_SELECT_LIST,
    SET_PRODUCT_GROUPS,
    SET_PRODUCT_CATEGORIES,
    SET_QUICK_POS_PRODUCTS,
    UPDATE_PRODUCT_GROUP_PRODUCTS,
    SET_OPERATION_MODE,
    SET_DELAY_RECEIPT,
    CLOSE_ALL_DIALOGUES,
    CAFA_API,
    SET_EDIT_QUICK_SELECT_PRODUCTS,
    ADD_QUICK_POS_PRODUCT,
    SET_QUICK_POS_PRODUCTS_ORDER,
    SET_ERPLY_SERVICE_ENDPOINTS,
    SET_LAST_SCANNED_GIFT_CARD,
    ADD_BEFORE_COMPLETE_TRANSACTION_ACTION,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION,
    ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    ADD_RECEIPT_GIFT_CARD,
    UPDATE_PRODUCT_GIFT_CARD_NUMBER,
    ADD_PLUGIN_REQUEST_SALE_NOTES,
    REMOVE_PLUGIN_REQUEST_SALE_NOTE,
    SET_RETURN_BASE_DOCUMENT,
    SET_ADD_RECEIPT_TITLE,
    ADD_TRANSLATIONS,
    REMOVE_DOCUMENT_DISCOUNT,
    CREATE_RECEIPT,
    UPDATE_USER_SESSION,
    UPDATE_RETURN_BASE_DOCUMENT_PAYMENT,
    AUTH_API,
    SET_PAYBACK_CUSTOMER,
    SET_PAYBACK_INPUT_DONE,
    ADD_PAYBACK_COUPON,
    REMOVE_PAYBACK_COUPON,
    ADD_RECEIPT_EXTRA_LINE,
    REMOVE_RECEIPT_EXTRA_LINE,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE,
    CREATE_RECEIPT_NUMBER,
    REMOVE_PAYBACK_CUSTOMER,
    SET_PAYBACK_REFERENCE_RECEIPT,
    ADD_MANUAL_PROMOTION_ID,
    REMOVE_MANUAL_PROMOTION_ID,
    ADD_CAMPAIGN_BY_NAME,
    SET_NEXT_SEQUENCE_NUMBER,
    REVERT_NEXT_SEQUENCE_NUMBER,
    REMOVE_CUSTOMER,
    SET_RIGHT_PANEL_FULLSCREEN
} from "./actionTypes";

import Product from "./dto/product";
import Customer from "./dto/customer";
import {getCurrentTimestamp} from "../util/calendar";
import {createModalID} from "../modal/Modal";
import VerifyPin from "../main/administration/VerifyPin";
import React from "react";
import * as uuid from "uuid";
import Logon from "../main/administration/Logon";
import {Translate} from "react-localize-redux";
import operatorBypassApproval from "./dto/operatorBypassApproval";
import {getDocumentNotes, getProductName, getProductShortDescription, getTillNumber} from "./selectors";
import CTR from "../util/receiptTemplate";
import {timers} from "../util/timers";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from 'uiComponents/WrappedPerfectScrollBar.js';

export const setLoading = loading => {
    return {
        type: SET_LOADING,
        payload: loading
    }
};

export const addProduct = product => {
    if(product.data.priceListPriceWithVat === 0){
        product.originalPriceZero = true;
    }
    return {
        type: ADD_PRODUCT,
        payload: product
    }
};

export const applyPromotions = (productsInBasket, pos, customer, defaultCustomerID, onDone = ()=>{}, manualPromotionIDs = []) => {
    let data = {
        request: "applyPromotions",
        warehouseID: pos.warehouseID,
        pointOfSaleID: pos.pointOfSaleID
    };

    if(manualPromotionIDs.length > 0){
        data.manualPromotionIDs = manualPromotionIDs.join(',');
    }

    if(customer !== false && typeof customer.customerID !== "undefined"){
        data.customerID = customer.customerID;
    }else{
        data.customerID = defaultCustomerID;
    }

    let c = 0;
    for(let product of productsInBasket) {
        data["productID" + c ] = product.data.productID;
        data["amount" + c ] = product.amount;
        if(product.manualPrice){
            if(product.originalPriceZero){
                data["price" + c] = product.originalPrice || product.netPrice;
                data["discount" + c] = product.manualDiscountPercentage;
            }else if(product.hasManualDiscount()){
                data["discount" + c] = product.manualDiscountPercentage;
            }else{
                data["price" + c] = product.netPrice;
            }
        }
        c++;
    }

    return {
        type: API,
        payload: {
            data: data,
            onSuccess: function (data) {
                onDone();
                return {
                    type: UPDATE_PRODUCTS,
                    payload: data[0]
                }
            },
            onFailure: function (error) {
                onDone();
                return apiError(error, false);
            },
            label: "Apply promotions"
        }
    };
};

export const updateProduct = product => ({
    type: UPDATE_PRODUCT,
    payload: product
});

export const removeProduct = product => ({
    type: REMOVE_PRODUCT,
    payload: product
});

export const addProductReturnReasonID = (lineNumber, returnReason) => ({
    type: ADD_PRODUCT_RETURN_REASON_ID,
    payload: {
        lineNumber: lineNumber,
        returnReason: returnReason
    }
});

export const updateProductGiftCardNumber = (lineNumber, number) => ({
    type: UPDATE_PRODUCT_GIFT_CARD_NUMBER,
    payload: {
        lineNumber,
        number
    }
});

export const addProductDiscountApproval = (lineNumber, employeeID) => {
    let discountApproval = new operatorBypassApproval('ModLineDisc', employeeID);
    return {
        type: ADD_PRODUCT_DISCOUNT_APPROVAL,
        payload: {
            lineNumber,
            discountApproval
        }
    };
};

export const addDocumentDiscountApproval = (employeeID) => {
    let discountApproval = new operatorBypassApproval('ModTotalDisc', employeeID);
    return {
        type: ADD_DOCUMENT_DISCOUNT_APPROVAL,
        payload: {
            discountApproval
        }
    };
};

export const addUnknownRFIDProduct = product => {
    return {
        type: ADD_UNKNOWN_RFID_PRODUCT,
        payload: product
    }
};

export const removeUnknownRFIDProduct = product => ({
    type: REMOVE_UNKNOWN_RFID_PRODUCT,
    payload: product
});


export function findProduct(code, warehouseID, pointOfSaleID, vatPrice = false, onSuccess, onFailure, originalCode, onDone=()=>{}, searchCodeType='searchNameIncrementally') {
    console.log("findProduct function", code, warehouseID, pointOfSaleID);
    let parameters = {
        request: "getProducts",
        recordsOnPage: "1",
        active: "1",
        getAllLanguages: "1",
        warehouseID: warehouseID,
        pointOfSaleID: pointOfSaleID,
        getReplacementProducts: "1",
        getRelatedProducts: "1",
        orderBy: "name",
        orderByDir: "asc",
        getPriceListPrices: "1",
        getContainerInfo: "1",
        getRecipes: "1",
        type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
        getPriceCalculationSteps: "1"
    };
    parameters[searchCodeType] = code;

    return {
        type: API,
        payload: {
            data: parameters,
            onSuccess: function (data) {
                onDone();
                if (data.length === 0) {
                    let error = 'Product missing from database!';
                    if(onFailure !== undefined){
                        return onFailure(code, error);
                    }else{
                        return productNotFound(code, error);
                    }
                }
                const firstProduct = data[0];
                let product = new Product(firstProduct.name, firstProduct.priceListPrice, firstProduct.priceListPriceWithVat, 1, firstProduct);
                product.originalCode = originalCode;
                if(vatPrice !== false){
                    product.setVatPrice(vatPrice);
                }
                console.log('product vat price', product.vatPrice);
                if(onSuccess !== undefined){
                    return onSuccess(product);
                }else{
                    return addProduct(product);
                }
            },
            onFailure: (error) => {
                onDone();
                console.log("Error occured finding the product!", error);
                if(onFailure !== undefined){
                    return onFailure(code, error);
                }else{
                    return productNotFound(code, error);
                }
            },
            label: "Find product"
        }
    }
}

export function findProducts(params, warehouseID, pointOfSaleID, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "getProducts",
                //recordsOnPage: "20",
                active: "1",
                getAllLanguages: "1",
                warehouseID: warehouseID,
                pointOfSaleID: pointOfSaleID,
                // getReplacementProducts: "1",
                // getRelatedProducts: "1",
                orderBy: "name",
                orderByDir: "asc",
                getPriceListPrices: "1",
                getContainerInfo: "1",
                getRecipes: "1",
                type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
                getPriceCalculationSteps: "1",
                ...params
            },
            onSuccess: function (data) {
                return onSuccess(data);
            },
            onFailure: (error) => {
                return onFailure(error);
            },
            label: "Find products"
        }
    }
}

export function setProductSelectList(products) {
    return {
        type: SET_PRODUCT_SELECT_LIST,
        payload: products
    }
}

export function productNotFound(searchParam, error) {
    return {
        type: PRODUCT_NOT_FOUND,
        payload: {
            searchParam: searchParam,
            error: error
        }
    }
}

export function login(clientCode, username, password, onSuccess, onFailure, onSessionTimeout) {
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength: 86400
            },
            onSuccess: function (data) {
                const user = data[0];
                user.clientCode = clientCode;
                timers.setTimeout('userSessionUpdate', () => {
                    onSessionTimeout(user);
                }, 86000);
                onSuccess(user);
                return {
                    type: SET_USER,
                    payload: user
                }
            },
            onFailure: (error) => {
                console.log("Could not verify user!", error);
                onFailure();
                return apiError(error);
            },
            label: "Verify user"
        }
    }
}

export function oAuthLogin(authCode, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "oAuthLogin",
                authCode,
                sessionLength: 86400
            },
            onSuccess: function (user) {
                onSuccess(user);
                return {
                    type: SET_USER,
                    payload: user
                }
            },
            onFailure: (error) => {
                console.log("Could not verify user!", error);
                onFailure();
                return apiError(error);
            },
            label: "oAuth login"
        }
    }
}

export function refreshUserSession(clientCode, username, password, onFailure, onSessionTimeout) {
    let sessionLength = 86400;
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength

            },
            onSuccess: function (data) {
                const user = data[0];
                timers.setTimeout('userSessionUpdate',() => {
                    onSessionTimeout(user);
                }, (sessionLength - 400));

                return {
                    type: UPDATE_USER_SESSION,
                    sessionKey: user.sessionKey
                }
            },
            onFailure: onFailure,
            label: "Verify user"
        }
    }
}

export function verifyUser(clientCode, username, password, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength: 86400

            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: "Verify user"
        }
    }
}

export function verifyUserSSOToken(accessToken, onSuccess, onFailure) {
    return {
        type: AUTH_API,
        payload: {
            method: 'POST',
            headers: {
                accessToken
            },
            parameters: {
                sessionLength:86400
            },
            onSuccess,
            onFailure
        }
    }
}

export function getUserRights(userID, posID, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "getUserRights",
                userID: userID,
                posID: posID
            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: "Get user rights"
        }
    }
}

export function switchUser(clientCode, cardCode, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "switchUser",
                clientCode: clientCode,
                cardCode: cardCode,
                sessionLength: 86400

            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: "Switch user"
        }
    }
}

export function setAdministrationUser(user) {
    console.log(SET_ADMIN_USER, user);
    return {
        type: SET_ADMIN_USER,
        payload: user
    }
}

export function setPOSID(posID) {
    console.log('setting POS ID');
    return {
        type: SET_POS_ID,
        payload: posID
    }
}

export function getPointsOfSale(onSuccess, onFailure=()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getPointsOfSale",
                getAllPages: true
            },
            onSuccess,
            onFailure,
            label: 'Get points of sale'
        }
    }
}

export function getAllowedWarehouses(userID, onSuccess, onFailure=()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getAllowedWarehouses",
                userID,
                getAllPages: true
            },
            onSuccess,
            onFailure,
            label: 'Get points of sale'
        }
    }
}

export function setPOSData(posID, onSuccess = () => {}, onFailure = () => {}) {
    console.log('setting POS Data');
    let convertIDsToString = function (data) {
        data.warehouseID = data.warehouseID.toString();
        data.pointOfSaleID = data.pointOfSaleID.toString();
        return data;
    };

    let erplyMode = process.env.REACT_APP_ERPLY_MODE === "1";

    let params = {
        request: "getPointsOfSale"
    };

    if(erplyMode){
        params['pointOfSaleID'] = posID;
    }else {
        params['getAllPages'] = true;
    }

    return {
        type: API,
        payload: {
            data: params,
            onSuccess: function (data) {
                let pos;
                if(erplyMode){
                    pos = convertIDsToString(data[0]);
                    if(pos.pointOfSaleID !== posID){
                        return {
                            type: SET_POS_DATA_FAILED,
                            payload: 'POS ID not found'
                        }
                    }
                }else {
                    pos = data.find(pos => posID === getTillNumber(pos));
                    if(typeof pos === "undefined"){
                        return {
                            type: SET_POS_DATA_FAILED,
                            payload: 'POS ID not found'
                        }
                    }
                }
                onSuccess(pos);
                return {
                    type: SET_POS_DATA,
                    payload: pos
                }
            },
            onFailure: function (error) {
                onFailure();
                return {
                    type: SET_POS_DATA_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'Get Erply POS Data'
        }
    };
}

export function checkPOSDayOpened(posID, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "POSOpenDay",
                pointOfSaleID: posID,
                queryOpenDay: 1
            },
            onSuccess: function (data) {
                let dayOpened = data.length !== 0;
                let dayID = dayOpened ? data[0].dayID : false;
                return onSuccess(dayOpened, dayID);
            },
            onFailure: onFailure,
            successActions: [],
            label: 'POS Open Day'
        }
    };
}

export function POSOpenDay(posID, openedSum, onSuccess, onFailure) {
    let params = {
        request: "POSOpenDay",
        pointOfSaleID: posID,
        openedSum: openedSum,
        openedUnixTime: getCurrentTimestamp()
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'POS Open Day'
        }
    };
}

export function getPointOfSaleDayTotals(posID, onSuccess, onFailure) {
    let params = {
        request: "getPointOfSaleDayTotals",
        pointOfSaleID: posID
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: function (data) {
                if(data.length > 0){
                    return onSuccess(data);
                }else{
                    return onFailure();
                }
            },
            onFailure: onFailure,
            successActions: [],
            label: 'getPointOfSaleDayTotals'
        }
    };
}

export function POSCloseDay(posID, dayID, closedSum, onSuccess, onFailure) {
    let params = {
        request: "POSCloseDay",
        pointOfSaleID: posID,
        dayID: dayID,
        closedUnixTime: getCurrentTimestamp(),
        closedSum: 0,
        bankedSum: closedSum
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'POS Close Day'
        }
    };
}

export function getCloseDayHtmlReport(posID, warehouseID, onSuccess, onFailure) {
    let params = {
        request:"getReports",
        type:"ZReport",
        format:"HTML",
        getShortReport:1,
        pointOfSaleID:posID,
        warehouseID:warehouseID
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get close day report'
        }
    }

}

export function getRecentSales(posID, warehouseID, onSuccess, onFailure, numberOrCustomer) {
    let params = {
        request: 'getSalesDocuments',
        warehouseID: warehouseID,
        posID: posID,
        recordsOnPage: 100,
        confirmed: 1,
        getRowsForAllInvoices: 0,
        getCustomerInformation: 0
    };
    if(numberOrCustomer !== undefined){
        params['numberOrCustomer'] = numberOrCustomer;
    }
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get recent sales'
        }
    }
}

export function getSalesDocument(id, onSuccess, onFailure, parameters={}) {
    let params = {
        request: 'getSalesDocuments',
        id,
        ...parameters
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: (documents) => {
                if(documents.length > 0){
                    return onSuccess(documents[0]);
                }else{
                    return onFailure('Failed to get sales document');
                }
            },
            onFailure,
            successActions: [],
            label: 'Get sales document'
        }
    }
}

export function getPayments(documentID, onSuccess, onFailure) {
    let params = {
        request: 'getPayments',
        documentID,
        recordsOnPage: 100
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess,
            onFailure,
            successActions: [],
            label: 'Get payments'
        }
    }
}

export function setPosDayOpened(dayID) {
    return {
        type: SET_POS_DAY_OPENED,
        payload: dayID
    }
}

export function setWarehouse(warehouseID, successActions = []) {
    console.log('getting warehouse data', warehouseID);
    return {
        type: API,
        payload: {
            data: {
                request: "getWarehouses",
                warehouseID: warehouseID
            },
            onSuccess: function (data) {
                console.log('got warehouse data');
                return {
                    type: SET_WAREHOUSE,
                    payload: data[0]
                }
            },
            onFailure: function (error) {

            },
            label: 'Get Erply Warehouse',
            successActions: successActions
        }
    };
}

export function getErplyConfiguration(onSuccess, onFailure) {
    console.log('getting Erply configuration');
    return {
        type: API,
        payload: {
            data: {
                request: "getConfParameters"
            },
            onSuccess: function (data) {
                setTimeout(() => {
                    onSuccess(data[0]);
                }, 100);
                return {
                    type: SET_ERPLY_CONF,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                onFailure();
                console.log('Could not get Erply configuration');
            },
            label: 'Get Erply Configuration'
        }
    }
}

export function getErplyServiceEndpoints(onSuccess = ()=>{}, onFailure = ()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getServiceEndpoints"
            },
            onSuccess: function (data) {
                setTimeout(() => {
                    onSuccess(data[0]);
                }, 100);
                return {
                    type: SET_ERPLY_SERVICE_ENDPOINTS,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                onFailure();
            },
            label: 'Get Erply service endpoints'
        }
    }
}

export function getCompanyInfo() {
    return {
        type: API,
        payload: {
            data: {
                request: "getCompanyInfo"
            },
            onSuccess: function (data) {
                return {
                    type: SET_COMPANY_INFO,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                console.log('Could not get company info');
            },
            label: 'Get company info'
        }
    }
}

export function getReasonCodes() {
    console.log('getting Erply reason codes');
    return {
        type: API,
        payload: {
            data: {
                request: "getReasonCodes",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_REASON_CODES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get Erply reason codes');
            },
            label: 'Get Erply reason codes'
        }
    }
}

export function getVatRates() {
    console.log('getting Erply vat rates');
    return {
        type: API,
        payload: {
            data: {
                request: "getVatRates",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_VAT_RATES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get Erply vat rates');
            },
            label: 'Get Erply Vat rates'
        }
    }
}

export function getProductGroups() {
    return {
        type: API,
        payload: {
            data: {
                request: "getProductGroups",
                getAllLanguages: 1,
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_PRODUCT_GROUPS,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get product groups');
            },
            label: 'Get product groups'
        }
    }
}

export function getProductCategories() {
    return {
        type: API,
        payload: {
            data: {
                request: "getProductCategories",
                lang: "eng",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_PRODUCT_CATEGORIES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get product groups');
            },
            label: 'Get product groups'
        }
    }
}

export function getCampaignsByName(name, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "getCampaigns",
                name
            },
            onSuccess,
            onFailure,
            label: 'Get campaign by name'
        }
    }
}

export function addCampaignByName(campaign) {
    return {
        type: ADD_CAMPAIGN_BY_NAME,
        payload: {
            campaign
        }
    }
}

export function updateProductGroupProducts(productGroupID, products, productsPageNo) {
    return {
        type: UPDATE_PRODUCT_GROUP_PRODUCTS,
        productGroupID,
        products,
        productsPageNo
    }
}

export function setEditQuickProducts(edit){
    return {
        type: SET_EDIT_QUICK_SELECT_PRODUCTS,
        edit
    }
}

export function addQuickPosProduct(product) {
    return {
        type: ADD_QUICK_POS_PRODUCT,
        payload: product
    }
}

export function saveQuickPosProducts(productIDs, pointOfSaleID, saved) {
    if(window.AppConf.posSpecificQuickSelectProducts === true){
        let method = saved ? 'PUT' : 'POST';
        console.log('saveQuickPosProducts', method, productIDs, saved);
        return {
            type: CAFA_API,
            payload: {
                method,
                parameters: {
                    level: 'Pos',
                    level_id: pointOfSaleID.toString(),
                    name: 'quickPosProductIDs',
                    value: productIDs.join(',')
                },
                onSuccess: function (result) {
                    return addInfoAlert('Saved');
                },
                onFailure: (error) => {
                    console.log('Could not save quick selection products');
                },
            }
        }
    }
}

export function getQuickPosProducts(warehouseID, pointOfSaleID) {
    if(window.AppConf.posSpecificQuickSelectProducts === true){
        return {
            type: CAFA_API,
            payload: {
                method: 'GET',
                parameters: {
                    level: 'Pos',
                    level_id: pointOfSaleID,
                    name: 'quickPosProductIDs'
                },
                onSuccess: function (productIDsString) {
                    let productIDs = typeof productIDsString === 'string' ? productIDsString.split(',') : productIDsString;
                    console.log('productIDs', productIDs);
                    if(productIDsString === false){
                        return {
                            type: 'NO_QUICK_POS_PRODUCTS_SET'
                        }
                    }
                    if(productIDs.length === 0){
                        return {
                            type: SET_QUICK_POS_PRODUCTS,
                            payload: {
                                data: {},
                                order: [],
                                saved: true
                            }
                        }
                    }
                    return {
                        type: API,
                        payload: {
                            data: {
                                request: "getProducts",
                                productIDs: productIDsString,
                                getAllLanguages: "1",
                                warehouseID,
                                pointOfSaleID,
                                getPriceListPrices: "1",
                                getContainerInfo: "1",
                                getRecipes: "1",
                                type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
                                getPriceCalculationSteps: "1",
                                getAllPages: true
                            },
                            onSuccess: function (data) {
                                console.log('quickPosProducts', data);
                                let productsByID = data.reduce((acc, el) => {
                                    acc[el.productID] = el;
                                    return acc;
                                }, {});
                                return {
                                    type: SET_QUICK_POS_PRODUCTS,
                                    payload: {
                                        data: productsByID,
                                        order: productIDs,
                                        saved: true
                                    }
                                }
                            },
                            onFailure: (error) => {
                                console.log('Could not get quick selection products');
                            },
                            label: 'Get quick selection products'
                        }
                    }
                },
                onFailure: (error) => {
                    console.log('Could not get quick selection products');
                },
            }
        }
    }else{
        return {
            type: API,
            payload: {
                data: {
                    request: "getProducts",
                    active: "1",
                    getAllLanguages: "1",
                    warehouseID,
                    pointOfSaleID,
                    orderBy: "name",
                    orderByDir: "asc",
                    getPriceListPrices: "1",
                    getContainerInfo: "1",
                    getRecipes: "1",
                    type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
                    getPriceCalculationSteps: "1",
                    getAllPages: true,
                    quickPosProducts: 1
                },
                onSuccess: function (data) {
                    let productsByID = data.reduce((acc, el) => {
                        acc[el.productID] = el;
                        return acc;
                    }, {});
                    let productIDs = data.reduce((acc, el) => {
                        acc.push(el.productID);
                        return acc;
                    }, []);
                    return {
                        type: SET_QUICK_POS_PRODUCTS,
                        payload: {
                            data: productsByID,
                            order: productIDs,
                            saved: true
                        }
                    }
                },
                onFailure: (error) => {
                    console.log('Could not get quick selection products');
                },
                label: 'Get quick selection products'
            }
        }
    }
}

export function setQuickPosProductsOrder(order) {
    return {
        type: SET_QUICK_POS_PRODUCTS_ORDER,
        payload: order
    }
}

export function getUICustomization(name, level, actionType) {
    return {
        type: CAFA_API,
        payload: {
            method: 'GET',
            parameters: {
                level,
                name
            },
            onSuccess: function (data) {
                if(data === false){
                    return {
                        type: 'NO_UI_CUSTOMIZATION_SET'
                    }
                }
                data.saved = true;
                return {
                    type: actionType,
                    payload: data
                }
            },
            onFailure: function (error) {
                console.log('Could not get UI customization data');
            }
        }
    }
}

export function saveUICustomization(data, name, level) {
    let method = data.saved ? 'PUT' : 'POST';
    return {
        type: CAFA_API,
        payload: {
            method,
            parameters: {
                level,
                name,
                value: JSON.stringify(data)
            },
            onSuccess: function (result) {
                return addInfoAlert('Saved');
            },
            onFailure: (error) => {
                console.log('Could not save UI customization');
            },
        }
    }
}

export function getCafaConf(onSuccess, onFailure) {
    return {
        type: CAFA_API,
        payload: {
            method: 'GET',
            parameters: {
                getAll: true
            },
            onSuccess,
            onFailure
        }
    }
}

export function POSCashInOut(request, pointOfSaleID, employeeID, sum, reason, reasonID, onSuccess, onFailure) {
    let params = {
        request: request,
        pointOfSaleID: pointOfSaleID,
        employeeID: employeeID,
        sum: sum,
        comment: reason,
        attributeName1: 'reasonID',
        attributeType1: 'int',
        attributeValue1: reasonID
    };

    if(process.env.REACT_APP_ERPLY_MODE !== "1"){
        params['attributeName2'] = 'POSLogSent';
        params['attributeType2'] = 'text';
        params['attributeValue2'] = 0;
    }

    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: 'POSCashIn'
        }
    }
}

export function sendEmailReceipt(documentID, email, onSuccess, onFailure, document=false) {
    return {
        type: API,
        payload: {
            data: {
                id: documentID,
                type: 'SALESDOCUMENT',
                email: email,
                request: 'sendByEmail'
            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: 'Send email receipt',
            document
        }
    }
}

export function changeTransactionMode(mode, referenced=false) {
    return {
        type: CHANGE_TRANSACTION_MODE,
        payload: mode,
        referenced
    }
}

export function setNextSequenceNumber(number) {
    return {
        type: SET_NEXT_SEQUENCE_NUMBER,
        payload: number
    }
}

export function revertNextSequenceNumber(number) {
    return {
        type: REVERT_NEXT_SEQUENCE_NUMBER,
        payload: number
    }
}

export function completeTransaction(
    invoiceNo,
    productsInBasket,
    payment,
    customer,
    pos,
    erplyConf,
    user,
    totalSum,
    discount,
    pluginRequestParameters,
    onSuccess,
    onFail,
    creditToDocumentID = false,
    receipt
) {
    console.log('completing transaction', invoiceNo, productsInBasket, payment, customer, pos, erplyConf, user, totalSum, discount, pluginRequestParameters);
    const requests = [];
    let type = totalSum < 0 ? "CREDITINVOICE" : "CASHINVOICE";
    let salesDocumentRequest = {
        "requestName": "saveSalesDocument",
        "requestID": 0,
        "currencyCode": erplyConf.default_currency,
        "warehouseID": pos.warehouseID,
        "pointOfSaleID": pos.pointOfSaleID,
        "confirmInvoice": 1,
        "type": type,
        "paymentType": "CARD",
        "employeeID": user.employeeID,
        "invoiceNo": invoiceNo,
        "temporaryUUID": uuid.v4()
    };

    if(creditToDocumentID !== false){
        salesDocumentRequest.creditToDocumentID = creditToDocumentID
    }

    salesDocumentRequest.notes = getDocumentNotes(pluginRequestParameters, payment.payments);

    if(process.env.REACT_APP_ERPLY_MODE === "1") {
        if(typeof customer.customerID !== "undefined"){
            salesDocumentRequest.customerID = customer.customerID
        }
    }

    if(type === 'CREDITINVOICE'){
        salesDocumentRequest['creditInvoiceType'] = 'RETURN';
        salesDocumentRequest['isCashInvoice'] = 1;
    }

    let attributeCounter = 0;

    salesDocumentRequest['attributeName' + attributeCounter] = 'source';
    salesDocumentRequest['attributeType' + attributeCounter] = 'text';
    salesDocumentRequest['attributeValue' + attributeCounter] = 'SCO_' + process.env.REACT_APP_VERSION;
    attributeCounter++;

    let longAttributeCounter = 0;

    if(receipt !== false){
        salesDocumentRequest['longAttributeName' + longAttributeCounter] = 'receiptHtml';
        salesDocumentRequest['longAttributeValue' + longAttributeCounter] = receipt;

        salesDocumentRequest['attributeName' + attributeCounter] = 'receiptNumber';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = CTR.lastReceiptNumber;
        attributeCounter++;
    }

    if(process.env.REACT_APP_ERPLY_MODE !== "1"){
        salesDocumentRequest['attributeName' + attributeCounter] = 'POSLogSent';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = 0;
        attributeCounter++;
    }

    if(process.env.REACT_APP_ERPLY_MODE !== "1" && customer !== false && (customer.default !== true || typeof customer.zipCode !== "undefined" || typeof customer.localRequirements !== "undefined")) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'customer';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(customer.getCustomerAttribute());
        attributeCounter++;
        if(typeof customer.card_number !== "undefined" && customer.card_number !== ''){
            salesDocumentRequest.notes = "Customer card: " + customer.card_number + " \n\r" + salesDocumentRequest.notes;
        }
    }

    let c = 0;
    let tagLineNo = 1;
    for(let product of productsInBasket) {
        salesDocumentRequest["itemName" + c] = product.name;
        salesDocumentRequest["productID" + c] = product.data.productID;
        salesDocumentRequest["amount" + c] = product.amount;
        salesDocumentRequest["price" + c] = product.netPrice;
        salesDocumentRequest["vatrateID" + c] = product.data.vatrateID;
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        if(product.hasManualDiscount()){
            /*salesDocumentRequest["promotionRule" + c + "amount1"] = product.amount;
            salesDocumentRequest["promotionRule" + c + "finalPrice1"] = product.manualDiscountFinalPrice;
            salesDocumentRequest["promotionRule" + c + "totalDiscount1"] = product.manualDiscountTotal;
            salesDocumentRequest["promotionRule" + c + "manualDiscountPercentage1"] = product.manualDiscountPercentage;*/
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.manualDiscountPercentage;

            if(product.manualDiscountReasonCodeID !== 0){
                salesDocumentRequest["promotionRule" + c + "amount1"] = product.amount;
                salesDocumentRequest["promotionRule" + c + "finalPrice1"] = product.manualDiscountFinalPrice * -1;
                salesDocumentRequest["promotionRule" + c + "totalDiscount1"] = product.manualDiscountTotal * -1;
                salesDocumentRequest["promotionRule" + c + "manualDiscountPercentage1"] = product.manualDiscountPercentage;
                salesDocumentRequest["promotionRule" + c + "manualDiscountReasonID1"] = product.manualDiscountReasonCodeID;
            }

            if(product.discountApproval !== undefined){
                salesDocumentRequest['attributeName' + attributeCounter] = 'discountApproval' + c;
                salesDocumentRequest['attributeType' + attributeCounter] = 'text';
                salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.discountApproval);
                attributeCounter++;
            }
        }else if(product.discount !== 0){
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.discount;
        }

        if(typeof product.promotionRules !== "undefined"){
            for(let promotionRule of product.promotionRules){
                salesDocumentRequest[promotionRule.name] = promotionRule.value;
            }
        }

        if(product.returnReasonID !== undefined){
            salesDocumentRequest["returnReasonID" + c] = product.returnReasonID;
        }
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        if(typeof product.data.RFIDTag !== 'undefined'){
            if(product.data.RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE' && typeof product.giftCardCode === "undefined"){
                salesDocumentRequest["itemName" + c] = product.name + ' RFID: ' + product.data.RFIDTag.SERIAL_NUMBER;
            }
            //product.data.RFIDTag.NUM_LINE = tagLineNo;
            salesDocumentRequest['attributeName' + attributeCounter] = 'RFIDTag' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.data.RFIDTag);
            attributeCounter++;
            tagLineNo++;
        }

        if(product.isUnknownItem === true){
            salesDocumentRequest['attributeName' + attributeCounter] = 'unknownItemCode' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = product.data.code;
            attributeCounter++;
        }

        c++;
    }

    if(discount.documentDiscountPercentage !== undefined) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'documentDiscountPercentage';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = discount.documentDiscountPercentage;
        attributeCounter++;
    }
    if(discount.documentDiscountApproval !== undefined) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'documentDiscountApproval';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(discount.documentDiscountApproval);
        attributeCounter++;
    }

    if(typeof pluginRequestParameters.saveSalesDocument !== 'undefined'){
        let pluginAttributes = pluginRequestParameters.saveSalesDocument.attributes ?? [];
        for(let attribute of pluginAttributes){
            salesDocumentRequest['attributeName' + attributeCounter] = attribute.name;
            salesDocumentRequest['attributeType' + attributeCounter] = attribute.type ?? 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = attribute.value;
            attributeCounter++;
        }

        let pluginSaveSalesDocument = Object.assign({}, pluginRequestParameters.saveSalesDocument);
        delete pluginSaveSalesDocument.attributes;
        delete pluginSaveSalesDocument.notes;

        salesDocumentRequest = Object.assign(salesDocumentRequest, pluginSaveSalesDocument);
    }
    requests.push(salesDocumentRequest);

    let paymentCounter = 1;
    for(let paymentData of payment.payments){
        if(paymentData.status !== 'success'){
            continue;
        }
        let paymentRequest = {
            "requestName": "savePayment",
            "requestID": paymentCounter,
            "documentID": "CURRENT_INVOICE_ID",
            "type": paymentData.type,
            "sum": parseFloat(paymentData.sumPaid)
        };
        if(paymentData.type === 'CARD'){
            paymentRequest["attributeName1"] = "authCode";
            paymentRequest["attributeType1"] = "text";
            paymentRequest["attributeValue1"] = paymentData.transaction.authCode;
            paymentRequest["attributeName2"] = "refNo";
            paymentRequest["attributeType2"] = "text";
            paymentRequest["attributeValue2"] = paymentData.transaction.refNo;
            paymentRequest["attributeName3"] = "CreditOrDebit";
            paymentRequest["attributeType3"] = "text";
            paymentRequest["attributeValue3"] = paymentData.transaction.cardType;
            paymentRequest["cardNumber"] = paymentData.transaction.cardNumber;
            paymentRequest["cardType"] = paymentData.transaction.cardType;
            if(paymentData.transaction.signature !== null && paymentData.transaction !== ''){
                paymentRequest["signature"] = paymentData.transaction.signature;
            }
            if(paymentData.currencyCode !== null){
                paymentRequest["currencyCode"] = paymentData.currencyCode;
            }
        }else if(paymentData.type === 'GIFTCARD'){
            if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                paymentRequest["attributeName1"] = "giftCardID";
                paymentRequest["attributeType1"] = "text";
                paymentRequest["attributeValue1"] = paymentData.giftCard.cardNumber;
                paymentRequest["attributeName2"] = "POSLogGiftCard";
                paymentRequest["attributeType2"] = "text";
                paymentRequest["attributeValue2"] = JSON.stringify({
                    authorizationCode: paymentData.giftCard.authorizationCode,
                    expirationDate: paymentData.giftCard.expirationDate,
                    merchantId: paymentData.giftCard.merchantId,
                    no: paymentData.giftCard.cardNumber,
                    referenceNumber: paymentData.giftCard.referenceNumber
                });
            }else{
                paymentRequest['giftCardVatRateID'] = paymentData.giftCard.vatrateID;
                paymentRequest["attributeName1"] = "giftCardID";
                paymentRequest["attributeType1"] = "text";
                paymentRequest["attributeValue1"] = paymentData.giftCard.giftCardID;
            }
        }else if(paymentData.type === 'CASH'){
            requests[0]['paymentType'] = 'CASH';
            paymentRequest['cashPaid'] = paymentData.cashPaid;
            paymentRequest['cashChange'] = paymentData.cashChange;
        }

        if(typeof paymentData.typeID !== "undefined"){
            delete paymentRequest['type'];
            paymentRequest['typeID'] = paymentData.typeID;
        }

        if(paymentData.serialNumber !== ''){
            paymentRequest['attributeName3'] = 'voucherSerial';
            paymentRequest['attributeValue3'] = paymentData.serialNumber;
        }

        let ac = 10;
        paymentData.attributes.forEach(attribute => {
            paymentRequest["attributeName" + ac] = attribute.name;
            paymentRequest["attributeType" + ac] = attribute.type;
            paymentRequest["attributeValue" + ac] = attribute.value;
            ac++;
        });

        paymentData.documentAttributes.forEach(attribute => {
            salesDocumentRequest["attributeName" + attributeCounter] = attribute.name;
            salesDocumentRequest["attributeType" + attributeCounter] = attribute.type;
            salesDocumentRequest["attributeValue" + attributeCounter] = attribute.value;
            attributeCounter++;
        });

        requests.push(paymentRequest);
        paymentCounter++;
    }

    return {
        type: API,
        payload: {
            data: requests,
            onSuccess: function (requests) {
                let allSuccessful = true;
                let invoiceID = 0;
                let invoiceNo = 0;
                let receiptLink = false;
                for(let request of requests){
                    if(request.status.responseStatus !== 'ok'){
                        allSuccessful = false;
                    }else{
                        if( typeof request.records !== "undefined" &&
                            typeof request.records[0] !== "undefined" &&
                            typeof request.records[0].invoiceID !== "undefined"
                        ){
                            invoiceID = request.records[0].invoiceID;
                            invoiceNo = request.records[0].invoiceNo;
                            receiptLink = request.records[0].receiptLink;
                        }
                    }
                }
                if(allSuccessful){
                    onSuccess(invoiceID, invoiceNo, receiptLink);
                    return transactionSuccess(invoiceID, invoiceNo, receiptLink);
                }else{
                    onFail(requests);
                    return {
                        type: TRANSACTION_FAILED,
                        payload: ""
                    }
                }
            },
            onFailure: function (error) {
                onFail([]);
                return {
                    type: TRANSACTION_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'completeTransaction'
        }
    };
}

export function saveSale(productsInBasket, customer, pos, erplyConf, user, onFail) {
    console.log('saving sale', productsInBasket, customer, pos, erplyConf, user);
    const requests = [];
    const salesDocumentRequest = {
        "requestName": "saveSalesDocument",
        "requestID": 0,
        "currencyCode": erplyConf.default_currency,
        "warehouseID": pos.warehouseID,
        "pointOfSaleID": pos.pointOfSaleID,
        "confirmInvoice": 0,
        "type": "CASHINVOICE",
        "paymentType": "CARD",
        "employeeID": user.employeeID
    };

    let attributeCounter = 0;

    if(process.env.REACT_APP_ERPLY_MODE !== "1" && customer !== false && customer.default !== true) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'customer';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(customer.getCustomerAttribute());
        attributeCounter++;
    }

    let c = 0;
    let tagLineNo = 1;
    for(let product of productsInBasket) {
        salesDocumentRequest["itemName" + c] = product.name;
        salesDocumentRequest["productID" + c] = product.data.productID;
        salesDocumentRequest["amount" + c] = product.amount;
        salesDocumentRequest["price" + c] = product.netPrice;
        salesDocumentRequest["vatrateID" + c] = product.data.vatrateID;
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        if(typeof product.data.RFIDTag !== 'undefined'){
            product.data.RFIDTag.NUM_LINE = tagLineNo;
            salesDocumentRequest['attributeName' + attributeCounter] = 'RFIDTag' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.data.RFIDTag);
            attributeCounter++;
            tagLineNo++;
        }
        c++;
    }

    requests.push(salesDocumentRequest);
    return {
        type: API,
        payload: {
            data: requests,
            onSuccess: function (requests) {
                let allSuccessful = true;
                for(let request of requests){
                    if(request.status.responseStatus !== 'ok'){
                        allSuccessful = false;
                    }
                }
                if(allSuccessful){
                    return {
                        type: "SALE_SAVED"
                    }
                }else{
                    onFail();
                    return {
                        type: TRANSACTION_FAILED,
                        payload: ""
                    }
                }
            },
            onFailure: function (error) {
                onFail();
                return {
                    type: TRANSACTION_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'saveSale'
        }
    };
}

export const saveSalesDocument = (parameters, onSuccess, onFailure) => ({
    type: API,
    payload: {
        data: {
            request: "saveSalesDocument",
            ...parameters
        },
        onSuccess,
        onFailure,
        label: 'Save sales document'
    }
});

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

export const accessDenied = url => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = (error, displayError = true) => ({
    type: API_ERROR,
    payload: error,
    displayError: displayError
});

export const addErrorAlert = (message, autoClose = true) => ({
    type: ADD_ALERT,
    payload: {
        type: 'error',
        message: message,
        autoClose: autoClose
    }
});

export const addInfoAlert = (message, autoClose = true) => ({
    type: ADD_ALERT,
    payload: {
        type: 'info',
        message: message,
        autoClose: autoClose
    }
});

export const removeAlert = () => ({
    type: REMOVE_ALERT
});

export const initTransaction = () => ({
    type: INIT_TRANSACTION,
    payload: {}
});

export const cancelTransaction = () => ({
    type: CANCEL_TRANSACTION,
    payload: {}
});

export const transactionSuccess = (invoiceID, invoiceNo, receiptLink) => ({
    type: TRANSACTION_SUCCESS,
    payload: {
        invoiceID: invoiceID,
        invoiceNo: invoiceNo,
        receiptLink: receiptLink
    }
});

export const changeView = view => ({
    type: CHANGE_VIEW,
    payload: {
        view: view
    }
});

export const changeMode = mode => ({
    type: CHANGE_MODE,
    payload: {
        mode: mode
    }
});

export const setDefaultCustomer = () => ({
    type: SET_DEFAULT_CUSTOMER,
    payload: {}
});

export const setPaybackInputDone = (inputDone) => ({
    type: SET_PAYBACK_INPUT_DONE,
    inputDone
});

export const setPaybackCustomer = (customer) => ({
    type: SET_PAYBACK_CUSTOMER,
    customer
});

export const removePaybackCustomer = () => ({
    type: REMOVE_PAYBACK_CUSTOMER
});

export const addPaybackCoupon = (coupon) => ({
    type: ADD_PAYBACK_COUPON,
    coupon
});

export const removePaybackCoupon = (coupon) => ({
    type: REMOVE_PAYBACK_COUPON,
    coupon
});

export const setPaybackReferenceReceipt = (number, products = [], transactionDateTime = false) => ({
    type: SET_PAYBACK_REFERENCE_RECEIPT,
    payload: {
        number,
        transactionDateTime,
        products
    }
});

export const addReceiptExtraLine = (line) => ({
    type: ADD_RECEIPT_EXTRA_LINE,
    line
});

export const removeReceiptExtraLine = (line) => ({
    type: REMOVE_RECEIPT_EXTRA_LINE,
    line
});

export const addManualPromotionID = (id) => ({
    type: ADD_MANUAL_PROMOTION_ID,
    id
});

export const removeManualPromotionID = (id) => ({
    type: REMOVE_MANUAL_PROMOTION_ID,
    id
});

export const changePaymentMode = mode => ({
    type: SET_PAYMENT_MODE,
    payload: {
        mode: mode
    }
});
export const changeOperationMode = mode => ({
    type: SET_OPERATION_MODE,
    mode
});

export const getCustomers = (query, params, onSuccess, onFailure) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomers",
        data: {
            searchNameIncrementally: query,
            getAllPages: true,
            searchFromMiddle: "1"
        },
        onSuccess,
        onFailure,
        label: "find customers"
    }
});

export const setCustomerSelectList = customers => ({
    type: SET_CUSTOMER_SELECT_LIST,
    payload: customers
});

export const findCustomerByRegistryCode = (code) => {
    return {
        type: 'CUSTOMER_API',
        payload: {
            request: "getCustomers",
            data: {
                searchRegistryCode: code
            },
            onSuccess: function (result) {
                let customer = new Customer();
                Object.assign(customer, result[0]);
                return setCustomer(customer);
            },
            onFailure: function (result) {
                return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
            },
            label: "find customers"
        }
    }
};

export const findCustomerByCardNumber = (cardNumber, onSuccess=()=>{}, onFailure=()=>{}) => {
    let requestName;
    let params;
    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        requestName = "getCustomers";
        params = {
            searchCode: cardNumber
        };
    }else{
        requestName = "getCustomer";
        params = {
            card_number: cardNumber
        };
    }
    return {
        type: CUSTOMER_API,
        payload: {
            request: requestName,
            data: params,
            onSuccess: function (result) {
                let customer = new Customer();
                Object.assign(customer, result[0]);
                onSuccess(customer);
                return setCustomer(customer);
            },
            onFailure: function (result) {
                onFailure(result);
                return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
            },
            label: "find customer by code"
        }
    }
};



export const findCustomerByEmail = (email, onSuccess=()=>{}, onFailure=()=>{}) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomer",
        data: {
            email: email
        },
        onSuccess: function (result) {
            let customer = new Customer();
            Object.assign(customer, result[0]);
            onSuccess(customer);
            return setCustomer(customer);
        },
        onFailure: function (result) {
            onFailure(result);
            return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
        },
        label: "find customer by email"
    }
});

export const findCustomerByPhone = (phone, country, onSuccess=()=>{}, onFailure=()=>{}) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomer",
        data: {
            mobile_country_code: country,
            mobile: phone
        },
        onSuccess: function (result) {
            let customer = new Customer();
            Object.assign(customer, result[0]);
            onSuccess(customer);
            return setCustomer(customer);
        },
        onFailure: function (result) {
            onFailure(result);
            return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
        },
        label: "find customer by phone"
    }
});

export const setCustomer = customer => ({
    type: SET_CUSTOMER,
    payload: customer
});

export const removeCustomer = () => ({
    type: REMOVE_CUSTOMER,
    payload: false
});

export const setCustomerAgeVerified = (oldEnough) => ({
    type: SET_CUSTOMER_AGE_VERIFIED,
    payload: oldEnough ? 1 : 0
});

export const setError = message => ({
    type: SET_ERROR,
    payload: {
        message: message
    }
});

export const showDialogue = (title, message, confirmText, onConfirm, onCancel, cancelText, canClose=true, id=uuid.v4(), initialInputValue='', smallDialogue=false) => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        confirmText,
        onConfirm,
        onCancel,
        cancelText,
        canClose,
        initialInputValue,
        smallDialogue
    }
});

export const showInputDialogue = (title, message, inputLabel, onConfirm, onCancel, canClose=true, inputNumber=false, className, id=uuid.v4(), initialInputValue='') => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        inputLabel,
        onConfirm,
        onCancel,
        canClose,
        inputDialogue: true,
        inputNumber,
        className,
        initialInputValue
    }
});

export const showSelectDialogue = (title, options, onSelect, onCancel, canClose=true, id=uuid.v4(), initialInputValue='', message, className) => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        options,
        onSelect,
        onCancel,
        canClose,
        selectDialogue: true,
        initialInputValue,
        className
    }
});

export const closeDialogue = (id) => ({
    type: CLOSE_DIALOGUE,
    payload: id
});

export const closeAllDialogues = () => ({
    type: CLOSE_ALL_DIALOGUES
});

export const showRightPanelDialogue = (dialogue) => ({
    type: SHOW_RIGHT_PANEL_DIALOGUE,
    payload: dialogue
});

export const closeRightPanelDialogue = () => ({
    type: CLOSE_RIGHT_PANEL_DIALOGUE
});

export const setRightPanelFullscreen = (fullscreen) => ({
    type: SET_RIGHT_PANEL_FULLSCREEN,
    payload: fullscreen
});

export const openModal = (data) => ({
    type: OPEN_MODAL,
    payload: data
});

export const closeModal = (id) => ({
    type: CLOSE_MODAL,
    payload: id
});

export const closeAllModals = () => ({
    type: CLOSE_ALL_MODALS
});

export const openAdministrationView = (view = 'logon', canClose = true, onClose = ()=>{}) => {
    console.log('openAdministrationView action', view, canClose, onClose);
    return {
        type: OPEN_ADMINISTRATION_VIEW,
        payload: {
            view,
            canClose,
            onClose
        }
    };
};

export const closeAdministration = () => ({
    type: CLOSE_ADMINISTRATION_VIEW
});

export const changeAdministrationView = (view) => ({
    type: CHANGE_ADMINISTRATION_VIEW,
    payload: view
});

window.getEmployeesTry = 1;

export const getEmployees = (pointOfSaleID) => ({
    type: API,
    payload: {
        data: {
            request: "getEmployees",
            pointsOfSale: pointOfSaleID,
            getAllPages: true
        },
        onSuccess: function (data) {
            return {
                type: SET_EMPLOYEES,
                payload: data
            }
        },
        onFailure: (error) => {
            console.log('Could not get employees');
            return apiError(error);
        },
        label: 'Get employees'
    }
});

export const deletePayment = (paymentID) => {
    return {
        type: API,
        payload: {
            data: {
                request: "deletePayment",
                paymentID
            },
            onSuccess: function (data) {
                return {
                    type: "PAYMENT_DELETED",
                    payload: data
                }
            },
            onFailure: (error) => {
                return apiError(error);
            },
            label: 'Remove payment'
        }
    };
};

export const removeSalesDocument = (documentID) => {
    return {
        type: API,
        payload: {
            data: {
                request: "deleteSalesDocument",
                documentID
            },
            onSuccess: function (data) {
                return {
                    type: "DOCUMENT_DELETED",
                    payload: data
                }
            },
            onFailure: (error) => {
                return apiError(error);
            },
            label: 'Remove sales document'
        }
    };
};

export const addPeripheral = (peripheral) => ({
    type: ADD_PERIPHERAL,
    payload: peripheral
});

export const updatePeripheral = (peripheral) => ({
    type: UPDATE_PERIPHERAL,
    payload: peripheral
});

export const removePeripheral = (name) => ({
    type: REMOVE_PERIPHERAL,
    name
});

export const scannerDetected = code => ({
    type: SCANNER_DETECTED,
    payload: code
});

export const closeQCO = () => ({
    type: CLOSE_QCO,
    payload: ''
});

export const closeApp = () => {
    return {
        type: API,
        payload: {
            data: {
                request: 'closeApp'
            },
            onSuccess: function (data) {
                return {
                    type: 'APP_CLOSED'
                }
            },
            onFailure: function (error) {
                return apiError(error, false);
            },
            label: "Close app"
        }
    };
};

export const showCallAssistance = (administrationView, titleMessage = '', canCancel = false, onCancel = ()=>{}, canClose = false, content=undefined, confirmText='Logon', onConfirm=()=>{}) => ({
    type: SHOW_CALL_ASSISTANCE,
    payload: {
        administrationView,
        titleMessage,
        canCancel,
        onCancel,
        canClose,
        content,
        confirmText,
        onConfirm
    }
});

export const showCallAssistanceConfirm = (administrationView, titleMessage = '') => ({
    type: SHOW_CALL_ASSISTANCE,
    payload: {
        administrationView: 'confirm',
        titleMessage: titleMessage
    }
});

export const closeCallAssistance = () => ({
    type: CLOSE_CALL_ASSISTANCE
});

export const cancelAddUnknownItem = (productCode) => ({
    type: CANCEL_ADD_UNKNOWN_ITEM,
    productCode: productCode
});

export const securityItemAdded = () => ({
    type: SECURITY_ITEM_ADDED
});

export const removeSecurityItems = () => ({
    type: REMOVE_SECURITY_ITEMS
});

export const printReceipt = (receiptLink, giftReceipt = false, duplicate = false, document, onSuccess = () => {}, onFail = () => {}) => ({
    type: PRINT_RECEIPT,
    payload: {
        receiptLink,
        giftReceipt,
        duplicate,
        document,
        onSuccess,
        onFail
    }
});

export const printHtml = (html, onSuccess = () => {}, onFail = () => {}) => ({
    type: PRINT_HTML,
    payload: {
        data: html,
        onSuccess,
        onFail
    }
});

export const epsiRequest = (params, onSuccess = () => {}, onFail = () => {}) => ({
    type: EPSI_REQUEST,
    payload: {
        params,
        onSuccess,
        onFail
    }
});

export const handleFiscalRequest = (onSuccess, onFail) => ({
    type: HANDLE_FISCAL_REQUEST,
    payload: {
        onSuccess,
        onFail
    }
});

export const createReceipt = (onSuccess) => ({
    type: CREATE_RECEIPT,
    payload: {
        onSuccess
    }
});

export const createReceiptNumber = (onSuccess) => ({
    type: CREATE_RECEIPT_NUMBER,
    payload: {
        onSuccess
    }
});

export const openVerifyPinModal = (dispatch, clientCode, onSuccess, onFailure, onClose=()=>{}, canClose=true) => {
    if(window?.AppConf?.decathlonQRCodeSupervisor){
        return openLogonModal('This action needs supervisor confirmation!', (data) => {
            onSuccess(data[0]);
        }, canClose, true);
    }
    let modalID = createModalID();
    return openModal({
        content: <VerifyPin onDone={(pin) => {
            dispatch(setLoading(true));
            dispatch(switchUser(clientCode, pin, (records) => {
                dispatch(setLoading(false));
                onSuccess(records[0]);
                return closeModal(modalID);
            }, () => {
                dispatch(setLoading(false));
                onFailure();
                return {
                    type: 'SWITCH_USER_FAILED'
                }
            }))
        }
        }/>,
        id: modalID,
        className: "Administration",
        onClose: onClose,
        canClose: canClose
    });
};

export const openGetUserRightsByPinModal = (dispatch, clientCode, posID, onSuccess, onClose=()=>{}, canClose=true) => {
    return openVerifyPinModal(dispatch, clientCode, (user) => {
        dispatch(getUserRights(user.userID, posID, (records) => {
            onSuccess(records[0], user);
            return {
                type: 'USER_RIGHTS_SUCCESS'
            }
        }, () => {
            return addErrorAlert('Could not get user right records, please try again!');
        }));
    }, () => {
        dispatch(addErrorAlert('This pin is not correct!'));
    }, onClose, canClose);
};

export const openLogonModal = (title, afterLogonAction, canClose = true, supervisor=false) => {
    let logonModalID = uuid.v4();
    return openModal({
        content: <Logon
            message={<Translate id={title}/>}
            afterLogonAction={function (data) {
                afterLogonAction(data);
                return closeModal(logonModalID);
            }}
            supervisor={supervisor}
        />,
        id: logonModalID,
        afterLogonAction,
        type: 'logon',
        className: "Administration",
        onClose: function(){},
        canClose
    });
};

export const addDocumentDiscount = (percentage, returnReason, sum) => {
    return {
        type: ADD_DOCUMENT_DISCOUNT,
        percentage,
        returnReason,
        sum
    }
};

export const removeDocumentDiscount = () => {
    return {
        type: REMOVE_DOCUMENT_DISCOUNT
    }
};

export const openCashDrawer = () => {
    return {
        type: OPEN_CASH_DRAWER
    }
};

export const transactionTimeoutReached = () => {
    return {
        type: TRANSACTION_TIMEOUT_REACHED
    }
};

export const setDelayReceipt = (delayReceipt) => {
    return {
        type: SET_DELAY_RECEIPT,
        delayReceipt
    }
};

export const displayShortProductDescription = (product, language) => {
    let modalID = uuid.v4();
    let picture = typeof product.images !== "undefined" ? product.images[0].smallURL : false;
    let name = getProductName(product, language);

    return openModal({
        content: (
            <div style={{
                height: '100%'
            }}>
                <div className={"dialogueTitle"}>{name}</div>
                <div  style={{
                    height: 'calc(100% - 20px)',
                    display: 'flex'
                }}>
                    <div style={{
                        width: '30%'
                    }}>
                        {
                            picture !== false ?
                                <img
                                    src={picture} alt={name}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%'
                                    }}
                                /> : ""
                        }
                    </div>
                    <PerfectScrollbar
                        className={'smallDialogueMessage'}
                        style={{
                            width: '70%',
                            height: 'calc(100% - 20px)',
                            paddingLeft: '10px',
                            boxSizing: 'border-box'
                    }}>
                        {getProductShortDescription(product, language)}
                    </PerfectScrollbar>
                </div>
            </div>
        ),
        id: modalID,
        className: "smallDialogue",
        onClose: () => {},
        canClose: true
    });
};

export const setLastScannedGiftCard = (code) => {
    return {
        type: SET_LAST_SCANNED_GIFT_CARD,
        code
    }
};

export const addReceiptGiftCard = (giftCard) => ({
    type: ADD_RECEIPT_GIFT_CARD,
    giftCard
});

export const addBeforeCompleteTransactionAction = (action) => ({
    type: ADD_BEFORE_COMPLETE_TRANSACTION_ACTION,
    action
});

export const removeBeforeCompleteTransactionAction = (id) => ({
    type: REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION,
    id
});

export const removeBeforeCompleteTransactionActionsByType = (actionType) => ({
    type: REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE,
    actionType
});

export const addPluginRequestSaleAttributes = (attributes) => ({
    type: ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    attributes
});

export const addPluginRequestSaleNotes = (notes) => ({
    type: ADD_PLUGIN_REQUEST_SALE_NOTES,
    notes
});

export const removePluginRequestSaleNote = (noteIndex) => ({
    type: REMOVE_PLUGIN_REQUEST_SALE_NOTE,
    noteIndex
});

export const setReturnBaseDocument = (document, payments) => ({
    type: SET_RETURN_BASE_DOCUMENT,
    document,
    payments
});

export const updateReturnBaseDocumentPayment = (payment) => ({
    type: UPDATE_RETURN_BASE_DOCUMENT_PAYMENT,
    payment
});

export const setAddReceiptTitle = (addReceiptTitle) => ({
    type: SET_ADD_RECEIPT_TITLE,
    addReceiptTitle
});

export const addTranslation = (translations, language) => ({
    type: ADD_TRANSLATIONS,
    translations,
    language
});