import React, {Component} from 'react';
import "./main.css"
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import Keyboard from "../keyboard/Keyboard";
import {closeModal, showDialogue} from "../redux/actions";

const mapStateToProps = state => {
    return {
        currency: state.rootReducer.currency,
        posCurrencyUnits: state.rootReducer.erplyConf?.pos_currency_units ?? false,
        translate: getTranslate(state.localize)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirmInput: (openedSum, openDayModalID, translate, currency, onConfirm, onCancel) => {
            dispatch(closeModal(openDayModalID));
            dispatch(showDialogue(
                'Validate open day',
                translate("Are you sure you want to validate") + ' '  + openedSum + currency + ' ' + translate('to open day') + '?',
                'accept',
                onConfirm,
                onCancel,
                'refuse',
                false
            ));
        }
    }
};

class OpenDay extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentInput: "register",
            total: 0
        };

        this.selectInput = this.selectInput.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
    }

    selectInput(name) {
        this.setState({
            currentInput: name
        });
    }

    calculateTotal(money) {
        let total = money.reduce((acc, el) => {
            let inputValue = document.getElementsByName('money_' + el)[0].value;
            acc += inputValue * el;
            return acc;
        }, 0);
        this.setState({
            total: total
        });
    }

    render() {
        let calculateTotal = this.calculateTotal;
        let currentInput = this.state.currentInput;

        let money = [
            100,
            50,
            20,
            10,
            5,
            2,
            1,
            0.5,
            0.2,
            0.1,
            0.05,
            0.02,
            0.01
        ];

        if(this.props.posCurrencyUnits !== false){
            money = this.props.posCurrencyUnits.split(',').reduce((acc, el) => {
                let parts = el.split('=');
                acc.push(parts[1]);
                return acc;
            }, []);
        }

        let that = this;
        return (
            <div>
                <h2 style={{
                    float: "left"
                }}><Translate id={'Open day'}/></h2>
                <div className={"moneyInputs"}>
                    <ul>
                        { money.map((el, index) => {
                            let inputName = 'money_' + el;
                            let className = this.state.currentInput === inputName ? 'selectedMoney' : '';
                            return (
                                <li key={index} onClick={e => {this.selectInput(inputName)}} className={className}>
                                    <span className={"moneyTitle"}>{ this.props.currency }{ el }</span>
                                    <input
                                        type={"text"}
                                        name={inputName}
                                        placeholder={"0"}
                                        autoComplete={'off'}
                                        onChange={e => calculateTotal(money)}
                                    />
                                </li>
                            );
                        }) }
                        <li style={{
                            width: "calc(100% - 25px)"
                        }} onClick={e => {this.selectInput("money_register")}} className={this.state.currentInput === "money_register" ? 'selectedMoney' : ''}>
                            <span style={{
                            width: "50px"
                        }}><Translate id={"Register"}/></span>
                            <input style={{
                                width: "calc(100% - 110px)"
                            }} type={"text"} name={"money_register"} placeholder={"0"} value={this.state.total} readOnly={"readonly"}/>
                            <span style={{
                            float: "right",
                            width: "10px"
                        }}>{ this.props.currency }</span></li>
                    </ul>
                </div>
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: 'numbers',
                        inputFieldName: this.state.currentInput,
                        onChangeToLetters: () => {},
                        onChangeToNumbers: () => {},
                        onDone: function () {
                            const inputValue = document.getElementsByName("money_register")[0].value;
                            that.props.onConfirmInput(inputValue, that.props.modalID, that.props.translate, that.props.currency, () => {
                                that.props.onConfirm(inputValue);
                            }, that.props.onCancel);
                        },
                        onClick: function () {
                            if(currentInput !== 'money_register'){
                                calculateTotal(money);
                            }
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(OpenDay));