/**
 * Parse different type of barcodes
 */
export const PDTB = {
    patterns: [
        /\b01021(.{7})\d{4}21?\d{12}\b/,
        /\b21(.{7})\d{4}\b/,
        /\b01\d{1}200(.{6})\d{4}21?\d{12}\b/,
        /\b200(.{6})\d{4}\b/,
        /\b01\d{1}203\d{2}(.{7})\d{1}21?\d{12}\b/,
        /\b203\d{2}(.{7})\d{1}\b/,
        /\b01(.*?)21?\d{12}\b/,
        /^0(\d{12})$/
    ],
    parse: (code, pattern) => {
        let regEx = new RegExp(pattern);
        let result = code.match(regEx);
        if(result && result.length > 1){
            if(result[1].length <= 7){
                return result[1].replace(/^0+/, '');
            }
            return result[1].replace(/^0+/, '').padStart(13, '0');
        }
        return false;
    },
    tryAllpatterns: (searchParam) => {
        let code = false;
        for(let pattern of PDTB.patterns){
            code = PDTB.parse(searchParam, pattern);
            if(code !== false){
                break;
            }
        }
        return code !== false ? code : searchParam;
    },
    // Copied from Berlin POS
    parseWeighedBarcode: (code, {
        barcode_type,
        barcode_price_prefix,
        barcode_weight_prefix,
        barcode_code_length,
        barcode_measurement_length,
        barcode_weight_decimals
    }) => {
        var t = {
            productCode: "",
            amount: -1,
            price: -1
        },
            n = barcode_type,
            r = barcode_price_prefix;
        r || (r = "");
        var o = barcode_weight_prefix;
        o || (o = "");
        var a = barcode_code_length,
            i = barcode_measurement_length,
            s = barcode_weight_decimals;
        if (s && (s = parseInt(s)),
        (r.length > 0 || o.length > 0) && parseInt(a) > 0 && parseInt(i) > 0) {
            if ("price" == n)
                var l = r.length + parseInt(a) + parseInt(i) + 1;
            else
                var l = o.length + parseInt(a) + parseInt(i) + 1;
            if (l == code.length && /^\d+$/.test(code)) {
                if ("price" == n)
                    var u = code.substring(0, r.length)
                        , c = r
                        , d = r.length;
                else
                    var u = code.substring(0, o.length)
                        , c = o
                        , d = o.length;
                if (u == c) {
                    var p = code.substring(d, parseInt(a) + d)
                        , m = code.substring(d + parseInt(a), parseInt(i) + (d + parseInt(a)));
                    if ("price" == n) {
                        var f = m.substring(0, m.length - 2)
                            , h = m.substring(m.length - 2, m.length);
                        t.price = parseFloat(parseInt(f) + "." + h)
                    } else if (s && 0 != s) {
                        var f = m.substring(0, m.length - parseInt(s))
                            , h = m.substring(m.length - parseInt(s), m.length);
                        t.amount = parseFloat(parseInt(f) + "." + h).toFixed(parseInt(s))
                    } else
                        t.amount = parseInt(m);
                    t.productCode = p
                }
            }
        }
        return t
    }
};

export const parseCode = (code, regex) => {
    if (regex && regex !== "") {
        const parser = new RegExp(regex);
        const match = code.match(parser);
        if(match && match.length > 1){
            code = match[1];
        }
    }
    return code;
};