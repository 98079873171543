import React, {Component} from 'react';
import "../main.css"
import {Translate, withLocalize} from "react-localize-redux";
import {displayPrice} from "../../util/helperFunctions";
import deleteIcon from "../images/trash.png";

class PaymentLine extends Component{
    render() {
        let serialNumber;
        if(this.props.payment.serialNumber !== ''){
            serialNumber = <span className={"unitPrice"}>N°: {this.props.payment.serialNumber}</span>
        }
        return (
            <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "capitalize"}}><Translate id={this.props.payment.title}/></span><br/>
                    { serialNumber }
                </td>
                <td className={"productLinePriceColumn"}>{displayPrice(this.props.payment.sumPaid)} {this.props.currency}</td>
                <td>
                    <img src={this.props.image} alt={'paymentImg'} style={{maxHeight: "60px"}}/>
                </td>
                {
                    this.props?.displayDelete ?
                        <td onClick={e => this.props.removePayment(this.props.payment)} style={{"width": "20px"}}>
                            <img src={deleteIcon} alt={'trash'} height={22}/>
                        </td> :
                        <td/>
                }
            </tr>
        );
    }
}

export default withLocalize(PaymentLine)