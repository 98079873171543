import React, {Component} from 'react';
import "./main.css"
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        uiCustomization: state.rootReducer.uiCustomization
    }
};

class BasketHeader extends Component{
    render() {
        return (
            <div className={"basketHeader"} style={{
                backgroundColor: this.props.uiCustomization.basketHeader.color
            }}>
                { this.props.children }
            </div>
        );
    }
}

export default connect(mapStateToProps)(BasketHeader);