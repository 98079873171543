const calculateModulo11 = function (number) {
    const reversedDigits = String(number).split("").reverse();
    const sum = (counter => reversedDigits.reduce((acc, digit) => {
        if(counter > 16) return acc;
        acc += parseInt(digit) * counter;
        counter++;
        return acc;
    }, 0))(2);

    const remainder = sum % 11;
    const checkDigit = 11 - remainder;
    if(checkDigit === 10) return 'X';
    if(checkDigit === 11) return '0';
    return checkDigit.toString();
};

const checkModulo11 = function (code, controlDigit) {
    return calculateModulo11(code.substr(0,15)) === controlDigit;
};

const ltrim = function(string, char) {
    let index = 0;
    while (string[index] === char){
        index++;
    }
    return string.substr(index);
};

const priceHasNoDecimals = () => window?.AppConf?.priceDecimals === 0;

/**
 * Handle payment vouchers
 * test commercial voucher: 734273024001042200001299826000
 * test e-reservation: 777701754067711213141000029999780186648
 */
export const HPV = {
    length: 30,
    lengthGiftCard: 39
};

HPV.log = function(){
    let newArgs = Array.prototype.slice.call(arguments);
    newArgs.unshift('HPV: ');
    console.log.apply(this, newArgs);
};

HPV.voucher = function(code) {
    this.issuingEntity = code.substr(0,6);
    this.paymentTypeCode = HPV.getVoucherCode(code);
    this.counter = code.substr(9, 6);
    this.controlDigit = HPV.getControlDigit(code);
    this.amount = parseFloat(code.substr(16, 8)) / 100;
    if(priceHasNoDecimals()){
        this.amount = parseFloat(code.substr(16, 8));
    }
    this.currencyCode = code.substr(24, 3);
    this.code = code;
    this.getScannerCode = function () {
        return '010' + this.articleCode + '21' + this.issuingEntity + this.paymentTypeCode + this.counter + this.controlDigit;
    };
    this.getSerialNumber = function () {
        return this.code.substr(0, 16);
    }
};

HPV.voucherGiftCard = function(code) {
    this.cardNumber = code.substr(0,16);
    this.cardType = code.substr(16, 2);
    this.paymentTypeCode = HPV.getVoucherCodeGiftCard(code);
    this.amount = parseFloat(code.substr(21, 8)) / 100;
    this.currencyCode = code.substr(29, 3);
    this.articleCode = code.substr(32, 7);
    this.code = code;
    this.getScannerCode = function () {
        return '010' + this.articleCode + '21' + this.issuingEntity + this.paymentTypeCode + this.counter + this.controlDigit;
    };
    this.getSerialNumber = function () {
        return this.code.substr(0, 16);
    }
};

HPV.getVoucherCode = function(code){
    return parseInt(code.substr(6,3));
};

HPV.getVoucherCodeGiftCard = function(code){
    return parseInt(code.substr(18,3));
};

HPV.getControlDigit = function(code){
    return code.substr(15, 1);
};

HPV.isPaymentVoucher = function(code, typeCode){
    if(code.length !== HPV.length){
        HPV.log('is not payment voucher, wrong length');
        return false;
    }

    // Ignore this
    /*const currentTypeCode = HPV.getVoucherCode(code);
    if(currentTypeCode !== parseInt(typeCode)){
        HPV.log('is not payment voucher, wrong code');
        return false;
    }*/

    const controlDigit = HPV.getControlDigit(code);
    if(!checkModulo11(code, controlDigit)){
        HPV.log('is not payment voucher, wrong control digit!', controlDigit);
        return false;
    }

    HPV.log('is payment voucher');
    return true;
};

HPV.isPaymentVoucherGiftCard = function (code) {
    if(code.length !== HPV.lengthGiftCard){
        HPV.log('is not payment voucher gift card, wrong length');
        return false;
    }

    const typeCode = HPV.getVoucherCodeGiftCard(code);
    const typeCodes = HPV.getTypeCodes();
    if(typeCodes.indexOf(typeCode) === -1){
        HPV.log('is not payment voucher giftcard, wrong code');
        return false;
    }

    HPV.log('is payment voucher');
    return true;
};

/**
 * Handle Return Voucher
 * eg:
 * 73427399501373960000169582600100000000007895
 * 73427399601374100000169582600100000000007895
 */
export const HRV = {
    length: 44,
    defectiveArticleVoucherCode: "996",
    commercialGestureVoucherCode: "995",
    lastVoucher: false
};

HRV.voucher = function(code) {
    this.issuingEntity = HRV.getIssuingEntity(code);
    this.paymentTypeCode = HRV.getVoucherCode(code);
    this.counter = code.substr(9, 6);
    this.controlDigit = HRV.getControlDigit(code);
    this.amount = parseFloat(code.substr(16, 8)) / 100;
    if(priceHasNoDecimals()){
        this.amount = parseFloat(code.substr(16, 8));
    }
    this.currencyCode = code.substr(24, 3);
    this.articleQuantity = parseFloat(code.substr(27, 3));
    this.articleCode = ltrim(code.substr(30, 14), '0');
    this.code = code;

    this.getScannerCode = function () {
        return '010' + this.articleCode + '21' + this.issuingEntity + this.paymentTypeCode + this.counter + this.controlDigit;
    };

    this.getSerialNumber = function () {
        return this.code.substr(0, 16);
    }
};

HRV.getIssuingEntity = function (code){
    return code.substr(0,6);
};

HRV.getVoucherCode = function(code){
    return code.substr(6,3);
};

HRV.getControlDigit = function(code){
    return code.substr(15, 1);
};

HRV.isReturnVoucher = function(code){
    console.log('HRV: is return voucher ' + code + ' ?');
    if(code.length !== HRV.length){
        console.log('HRV: is not return voucher, wrong length');
        return false;
    }

    const voucherCode = HRV.getVoucherCode(code);
    if(voucherCode !== HRV.commercialGestureVoucherCode && voucherCode !== HRV.defectiveArticleVoucherCode){
        console.log('HRV: is not return voucher, wrong code');
        return false;
    }

    if(!checkModulo11(code, HRV.getControlDigit(code))){
        console.log('HRV: is not return voucher, wrong control digit!');
        return false;
    }

    console.log('HRV: is return voucher');
    return true;
};



/**
 * Handle workshop voucher
 */
export const HWSV = {
    getTypes: () => window?.AppConf?.voucherProducts ?? [],
    getRecognitionCode: function(code){
        return code.substr(6,3);
    },
    getControlDigit: function(code){
        return code.substr(15, 1);
    },
    getRecognitionCodes: function () {
        return this.getTypes().reduce((acc, el) => {
            acc.push(el.recognitionCode);
            return acc;
        }, []);
    },
    getTypeByRecognitionCode: code => HWSV.getTypes().find(el => el.recognitionCode === code),
    length: 30,
    endCode: '000'
};

HWSV.log = function(){
    let newArgs = Array.prototype.slice.call(arguments);
    newArgs.unshift('HWSV: ');
    console.log.apply(this, newArgs);
};

HWSV.voucher = function(code) {
    this.store = code.substr(0,6);
    this.recognitionCode = HWSV.getRecognitionCode(code);
    this.counter = code.substr(9, 6);
    this.checkNumber = code.substr(15, 1);
    this.price = parseFloat(code.substr(16, 8)) / 100;
    if(priceHasNoDecimals()){
        this.price = parseFloat(code.substr(16, 8));
    }
    this.currency = code.substr(24, 3);
    this.code = code;

    let type = HWSV.getTypeByRecognitionCode(this.recognitionCode);
    this.productCode = type.productCode;

    this.getSerialNumber = function () {
        return this.code.substr(0, 16);
    };
};

HWSV.isWorkshopVoucher = function (code) {
    HWSV.log('is workshop voucher ' + code + ' ?');
    if(code.length !== HWSV.length){
        HWSV.log('is not workshop voucher, wrong length');
        return false;
    }

    const recognitionCode = HWSV.getRecognitionCode(code);
    const recognitionCodes = HWSV.getRecognitionCodes();
    if(recognitionCodes.indexOf(recognitionCode) === -1){
        HWSV.log('is not workshop voucher, wrong code');
        return false;
    }

    if(code.substr(-1 * HWSV.endCode.length) !== HWSV.endCode){
        HWSV.log('is not workshop voucher, wrong ending');
        return false;
    }

    let controlDigit = HWSV.getControlDigit(code);
    if(!checkModulo11(code, controlDigit)){
        HWSV.log('is not payment voucher, wrong control digit!', controlDigit);
        return false;
    }

    HWSV.log('is workshop voucher');
    return true;
};

HWSV.addSerialNumberToProductName = function(product, serialNumber){
    let info = " N°: " + serialNumber;
    product.name += info;
};

export const AVMB = {
    vouchers: [
        {
            id: 'workshop',
            name: 'Workshop / Kiosk (Bourne)',
            inputFields: ['voucherSerial', 'voucherPrice'],
            useAction: ({voucherSerial, voucherPrice}, findProduct, onFail) => {
                let price = voucherPrice.toString().replace(',','.');
                let priceEdit = priceHasNoDecimals() ? price : price * 100;
                let code = voucherSerial + (priceEdit).toString().padStart(8,'0') + 'X'.repeat(3) + '000';
                if(HWSV.isWorkshopVoucher(code)){
                    findProduct(code);
                }else{
                    onFail();
                }
            }
        },
        {
            id: 'returnVoucher',
            name: 'Defective / Commercial Return',
            inputFields: ['voucherSerial', 'voucherPrice', 'voucherQuantity', 'voucherArticleCode'],
            useAction: ({voucherSerial, voucherPrice, voucherQuantity, voucherArticleCode}, findProduct, onFail) => {
                let price = voucherPrice.toString().replace(',','.');
                let priceEdit = priceHasNoDecimals() ? price : price * 100;

                let code = voucherSerial + (priceEdit).toString().padStart(8, '0') + 'X'.repeat(3) +
                    voucherQuantity.toString().padStart(3, '0') + voucherArticleCode.toString().padStart(14, '0');
                if(HRV.isReturnVoucher(code)){
                    findProduct(code);
                }else{
                    onFail();
                }
            },
        }
    ]
};
