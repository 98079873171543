import React, {Component} from "react";
import Button from "../../buttons/Button";
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import bankCardImg from "../images/iconBankCard.png";
import bankCardErplyImg from "../images/Erply/bankCard.png";
import voucherImg from "../images/voucherPayment.png";
import giftCardImg from "../../icons/giftCardWhite.png";
import giftCardErplyImg from "../images/Erply/giftCard.png";
import cashImg from "../images/pictureCashWhite.png";
import cashErplyImg from "../images/Erply/cash.png";
import cashErplyWhiteImg from "../images/Erply/cashWhite.png";
import downArrowErplyImg from "../images/Erply/arrowDown.png";
import insertCardImg from "../images/insertCard.png";
import insertCardErplyImg from "../images/Erply/cardPayment.png";
import otherImg from "../images/Erply/number.png";
import {connect} from "react-redux";
import {
    addErrorAlert,
    changeMode,
    closeModal,
    completeTransaction,
    createReceipt,
    deletePayment,
    handleFiscalRequest,
    initTransaction,
    openCashDrawer,
    openGetUserRightsByPinModal,
    openLogonModal,
    openModal,
    removeBeforeCompleteTransactionAction,
    removeSalesDocument,
    revertNextSequenceNumber,
    setLoading,
    showDialogue
} from "../../redux/actions";
import { changePaymentMode } from "../../redux/actions";
import {
    addPayment,
    captureFirstGiftCard,
    checkGiftCard,
    processCard,
    processIntegratedPayment,
    removePayment,
    setTotalSum,
    voidAllPayments,
    voidGiftCardActivations
} from "../../redux/payment/actions";
import paymentDTO from "../../redux/payment/dto/payment";
import {
    basketHasDecathlonGiftCard,
    getPaymentsTotalSumPaid,
    getPaymentTypeByReasonCode,
    getPaymentTypeByType,
    getProductsTotalSum, transactionMOPLimit
} from "../../redux/selectors";
import {CHANGE_TRANSACTION_SAVE_STATUS, INCREMENT_NEXT_INVOICE_NO} from "../../redux/actionTypes";
import {
    APP_DOCUMENT_SAVE_STATUS_BEFORE_COMPLETE,
    APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE,
    APP_DOCUMENT_SAVE_STATUS_PENDING,
    APP_DOCUMENT_SAVE_STATUS_START,
    APP_MODE_DONE,
    APP_MODE_SCREEN_SAVER,
    PAYMENT_MODE_OPTIONS
} from "../../redux/appConstants";
import {getCashPayment, getGiftCardsSum} from "../../redux/payment/selectors";
import {
    INPUT_PAYMENT_STARTED
} from "../../redux/payment/actionTypes";
import Logon from "../administration/Logon";
import * as uuid from "uuid";
import PaymentInput from "./PaymentInput";
import LeftImageButton from "../../buttons/LeftImageButton";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import GiftCardPayment from "./GiftCardPayment";
import Decimal from "decimal.js";
import {createModalID} from "../../modal/Modal";
import MultipleInputContent from "../../modal/MultipleInputContent";
import {interpolate, isEmptyString, round005} from "../../util/helperFunctions";
import Loader from "react-loader-spinner";
import LeftImageButtonErply from "../../buttons/LeftImageButtonErply";

const paymentLogon = (dispatch, afterLogon, paymentMethod) => {
    let logonModalID = uuid.v4();
    dispatch(openModal({
        content: <Logon
            message={<span><Translate id={'Logon to use this payment method'}/>: <Translate id={paymentMethod}/></span>}
            afterLogonAction={function (data) {
                console.log('paymentLogon', data);
                dispatch(closeModal(logonModalID));
                afterLogon(data[0]);
                return {
                    type: 'LOGGED_ON'
                };
            }
            }/>,
        type: 'logon',
        afterLogonAction: afterLogon,
        id: logonModalID,
        className: "Administration",
        onClose: function(){},
        canClose: true
    }));
};

const startPaymentWithInput = (dispatch, payment, title, canGiveChange = false, paymentType, user=false) => {
    const afterLogon = (user) => {
        let inputPaymentModalID = uuid.v4();
        let roundToNearestFiveCents = paymentType.roundToNearestFiveCents || false;

        dispatch(openModal({
            content:
                <Translate>{({translate}) =>
                    <PaymentInput
                        title={title}
                        paymentType={paymentType.name}
                        totalSum={payment.sum}
                        canGiveChange={canGiveChange}
                        onConfirm={function (paid, change) {
                            let paymentSum = parseFloat(payment.sum);
                            paid = parseFloat(paid);
                            change = parseFloat(change);

                            if (canGiveChange) {
                                let changeSum = paymentSum >= 0 ? (change > 0 ? change : 0) : (change < 0 ? change : 0);
                                payment.sumPaid = new Decimal(paid).minus(new Decimal(changeSum)).toDecimalPlaces(4).toString();
                                payment.cashPaid = paid;
                                payment.cashChange = changeSum;
                            } else {
                                payment.sumPaid = paymentSum >= 0 ? (paymentSum < paid ? paymentSum : paid) : (paymentSum >= paid ? paymentSum : paid);
                            }

                            if (paymentSum >= 0 && typeof paymentType.limit !== "undefined") {
                                if (payment.sumPaid > paymentType.limit) {
                                    dispatch(addErrorAlert(translate('This MOP has a limit of ${limit}', {limit: paymentType.limit})));
                                    return;
                                }
                            }

                            payment.approvedUserID = user.userID;
                            if (paymentType.openCashDrawer) {
                                dispatch(openCashDrawer());
                            }
                            dispatch(addPayment(payment));
                            dispatch(closeModal(inputPaymentModalID));
                        }}
                        roundToNearestFiveCents={roundToNearestFiveCents}
                        fullAmountButton={true}
                    />
                }
                </Translate>,
            id: inputPaymentModalID,
            className: "Administration",
            onClose: function () {},
            canClose: true
        }));
        dispatch({
            type: INPUT_PAYMENT_STARTED,
            payload: payment
        });
    };

    if(user === false){
        paymentLogon(dispatch, afterLogon, paymentType.name);
    }else{
        afterLogon(user);
    }
};

const askPaymentInput = (dispatch, payment, title, onConfirm, onClose=()=>{}, logonRequired=true, canGiveChange=false) => {
    let openPaymentInputModal = () => {
        let inputPaymentModalID = uuid.v4();
        dispatch(openModal({
            content:
                <PaymentInput
                    title={title}
                    paymentType={payment.type}
                    totalSum={ payment.sum }
                    canGiveChange={canGiveChange}
                    onConfirm={function (paid, change) {
                        onConfirm(paid, change);
                        dispatch(closeModal(inputPaymentModalID));
                    }}
                />,
            id: inputPaymentModalID,
            className: "Administration",
            onClose,
            canClose: true
        }));
        dispatch({
            type: INPUT_PAYMENT_STARTED,
            payload: payment
        });
    };

    if(logonRequired){
        paymentLogon(dispatch, (user) => {
            openPaymentInputModal();
        }, title);
    }else{
        openPaymentInputModal();
    }
};

export const returnPaymentNeedsSupervisorConfirm = (erplyConf, paymentType, paymentSum) => {
    let limits = {
        'CASH': 'DKTReturnCashSupervisorLimit',
        'CARD': 'DKTReturnCardSupervisorLimit',
        'manualCard': 'DKTReturnManualCardSupervisorLimit'
    };

    let limit = limits[paymentType] !== undefined ? erplyConf[limits[paymentType]] : undefined;
    return limit !== undefined ? (paymentSum * -1) > limit : false;
};

const handleBeforeCompleteActions = (dispatch, actions, onFail) => {
    if(actions.length > 0){
        let action = actions.pop();
        action.run(() => {
            dispatch(removeBeforeCompleteTransactionAction(action.id));
            handleBeforeCompleteActions(dispatch, actions, onFail);
        }, onFail);
    }
};

const mapStateToProps = state => {
    const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);
    const remainingSum = new Decimal(totalSum).minus(new Decimal(state.payment.totalPaid)).toDecimalPlaces(4).toString();
    const giftCardsSum = getGiftCardsSum(state.payment.payments);
    const toBeCreditedSum = new Decimal(remainingSum).minus(new Decimal(giftCardsSum)).toDecimalPlaces(4).toString();
    const paymentsLength = state.payment.payments.length;
    const nextPaymentIndex = paymentsLength === 0 ? 0 : state.payment.payments[paymentsLength - 1].index + 1;
    const customer = state.rootReducer.customer;
    return {
        customer,
        showZipCodeSurvey: state.payment.zipCodeSurveyCounter === 1 && typeof customer.zipCode === "undefined" &&
            (state.rootReducer.erplyConf.DKTZipSurveyNonCustomer === "1" ? customer.default : true),
        showPayback: window.AppConf.payback || false,
        paymentMode: state.rootReducer.paymentMode,
        totalSum,
        remainingSum,
        giftCardsSum,
        toBeCreditedSum,
        referenceNumber: state.rootReducer.nextInvoiceNo,
        nextPaymentIndex: nextPaymentIndex,
        payment: state.payment,
        productsInBasket: state.rootReducer.productsInBasket,
        productCategories: state.rootReducer.productCategories,
        pos: state.rootReducer.pos,
        erplyConf: state.rootReducer.erplyConf,
        user: state.rootReducer.user,
        documentSaveStatus: state.rootReducer.documentSaveStatus,
        discount: state.rootReducer.discount,
        progressMessage: state.payment.currentCardPaymentProgressMessage,
        pluginRequestParameters: state.rootReducer.pluginRequestParameters,
        operationMode: state.rootReducer.operationMode,
        uiCustomizationPOS: state.rootReducer.uiCustomizationPOS,
        beforeCompleteTransactionActions: state.rootReducer.beforeCompleteTransactionActions,
        creditToDocumentID: state.rootReducer.returnBaseDocument !== false ? state.rootReducer.returnBaseDocument.documentID : false,
        translate: getTranslate(state.localize),
        //only gift card activations, payments not needed.
        receiptGiftCards: state.rootReducer.receiptGiftCards.filter(el => typeof el.amountCharged !== "undefined"),
        theme: state.rootReducer.theme,
        allowGiftCardPaymentWithGiftCard: window.AppConf.allowGiftCardPaymentWithGiftCard || false,
        giftCardPaymentEnabled: window.AppConf?.paymentTypes?.giftCard ?? true
    }
};

const mapDispatchToProps = dispatch => {
    function cancelTransaction(receiptGiftCards) {
        dispatch(setLoading(true));
        dispatch(voidAllPayments());
        dispatch(voidGiftCardActivations(() => {
            dispatch(setLoading(false));
        }, (giftCards) => {
            dispatch(setLoading(false));
            for(let giftCard of giftCards){
                dispatch(showDialogue('Failed to void gift card activation', 'no: ' + giftCard.cardNumber, 'Ok',
                    () => {}, undefined, undefined, false, undefined, undefined, true));
            }
        }, receiptGiftCards));
        dispatch(revertNextSequenceNumber());
        dispatch(initTransaction());
        dispatch(changeMode(APP_MODE_SCREEN_SAVER));
    }

    const doHandleFiscalRequest = (translate, receiptGiftCards) => {
        dispatch({
            type: CHANGE_TRANSACTION_SAVE_STATUS,
            payload: APP_DOCUMENT_SAVE_STATUS_PENDING
        });
        dispatch(handleFiscalRequest(() => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE
            });
        }, (response) => {
            dispatch(showDialogue('could not complete transaction', translate('Fiscal request failed. Please call assistance!') + ' ' + translate('Error code') + ': ' + (response?.errorCode || '') + '. ' + (response?.textResponse || ''), 'retry', () => {
                doHandleFiscalRequest(translate, receiptGiftCards);
            }, ()=>{
                cancelTransaction(receiptGiftCards);
            },'Cancel', false));
        }));
    }
    return {
        startPayment: (totalSum) => {
            dispatch(setTotalSum(totalSum));
        },
        startCardPayment: (nextPaymentIndex, paymentSum, referenceNumber, erplyConf, clientCode, posID, withInput=false, paymentType={}) => {
            let startPayment = () => {
                let onPaymentDone = () => {
                    if(paymentType.openCashDrawer){
                        dispatch(openCashDrawer());
                    }
                    return changePaymentMode('options');
                };
                const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", "CARD");
                if(typeof paymentType.extraInfo !== "undefined"){
                    payment.extraInfo = paymentType.extraInfo;
                }
                if(withInput){
                    let canGiveChange = typeof paymentType.canGiveChange !== "undefined" ? paymentType.canGiveChange : false;
                    askPaymentInput(dispatch, payment, paymentType?.name || 'CARD', (paidSum, change) => {
                        let paymentSum = parseFloat(payment.sum);
                        paidSum = parseFloat(paidSum);
                        change = parseFloat(change);

                        if(canGiveChange){
                            payment.cashChange = paymentSum >= 0 ? (change > 0 ? change : 0) : (change < 0 ? change : 0);
                        }

                        payment.sum = paidSum;
                        dispatch(addPayment(payment));
                        dispatch(changePaymentMode('card'));
                        dispatch(processCard(payment, referenceNumber, [onPaymentDone]));
                    }, undefined, typeof paymentType.logonRequired !== "undefined" ? paymentType.logonRequired : true, canGiveChange);
                }else{
                    dispatch(addPayment(payment));
                    dispatch(changePaymentMode('card'));
                    dispatch(processCard(payment, referenceNumber, [onPaymentDone]));
                }
            };

            if(paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, 'CARD', paymentSum)){
                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, (userRights, user) => {
                    if(userRights.rightMakeDiscountInPOS != 1){
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                    }else{
                        startPayment();
                    }
                }));
            }else{
                startPayment();
            }
        },
        startGiftCardPayment: (nextPaymentIndex, paymentSum) => {
            dispatch(changePaymentMode('giftCard'));
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", "GIFTCARD");
            dispatch(addPayment(payment));
        },
        startCashPayment: (nextPaymentIndex, paymentSum, payments, roundToNearestFiveCents, erplyConf, clientCode, posID) => {
            console.log('payments: ', payments);
            let oldPayment = getCashPayment(payments);
            if(oldPayment !== false){
                paymentSum = new Decimal(paymentSum).plus(new Decimal(oldPayment.sumPaid)).toDecimalPlaces(4).toString();
                dispatch(removePayment(oldPayment));
            }
            let roundingSum = 0;
            let appliedPaymentSum = paymentSum;
            if(roundToNearestFiveCents){
                appliedPaymentSum = round005(paymentSum);
                roundingSum = new Decimal(paymentSum).minus(new Decimal(appliedPaymentSum)).toDecimalPlaces(4).toString();
            }
            const payment = new paymentDTO(nextPaymentIndex, appliedPaymentSum, "success", "CASH");
            payment.cashRounding = roundingSum;

            if(paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, 'CASH', paymentSum)){
                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, (userRights, user) => {
                    if(userRights.rightMakeDiscountInPOS != 1){
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                    }else{
                        startPaymentWithInput(dispatch, payment, 'Pay with cash', true, {name: 'Cash', roundToNearestFiveCents}, user);
                    }
                }));
            }else{
                startPaymentWithInput(dispatch, payment, 'Pay with cash', true, {name: 'Cash', roundToNearestFiveCents, limit: erplyConf?.DKTCashPaymentLimit ?? undefined});
            }
        },
        startCustomPayment: (withInput, erplyPaymentTypes, nextPaymentIndex, paymentSum, paymentType, erplyConf, clientCode, posID, canGiveChange = false, sumPaid = false) => {
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "success", paymentType.type);
            let erplyPaymentType;
            if(typeof paymentType.reasonCode !== "undefined"){
                erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
                payment.title = paymentType.name;
            }else if(typeof paymentType.type !== "undefined"){
                erplyPaymentType = getPaymentTypeByType(erplyPaymentTypes, paymentType.type);
                payment.title = erplyPaymentType.name;
            }
            if(typeof erplyPaymentType !== "undefined"){
                payment.typeID = erplyPaymentType.id;
            }else{
                dispatch(addErrorAlert('Payment type ' + (typeof paymentType.reasonCode !== "undefined" ? 'with reason code ' + paymentType.reasonCode : paymentType.type) + ' is missing. Please add to Erply BO!'));
                return;
            }
            if(typeof paymentType.extraInfo !== "undefined"){
                payment.extraInfo = paymentType.extraInfo;
            }
            if(withInput){
                if((paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, paymentType.type, paymentSum)) || paymentType?.supervisor === true){
                    dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, (userRights, user) => {
                        if(userRights.rightMakeDiscountInPOS != 1){
                            dispatch(addErrorAlert('User has no right to perform this action!'));
                        }else{
                            startPaymentWithInput(dispatch, payment, paymentType.name, true, paymentType, user);
                        }
                    }));
                }else{
                    startPaymentWithInput(dispatch, payment, paymentType.name, true, paymentType);
                }
            }else{
                payment.sumPaid = sumPaid !== false ? sumPaid : paymentSum;
                if(paymentType.openCashDrawer){
                    dispatch(openCashDrawer());
                }
                dispatch(addPayment(payment));
            }
        },
        startVoucherPayment: (erplyPaymentTypes, nextPaymentIndex, paymentSum, paymentType) => {
            let erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
            if(typeof erplyPaymentType === "undefined"){
                dispatch(addErrorAlert('Payment type with reason code ' + paymentType.reasonCode + ' is missing. Please add to Erply BO!'));
                return;
            }

            paymentLogon(dispatch, () => {
                let editModalID = createModalID();
                dispatch(openModal({
                    content:
                        <Translate>{({ translate }) =>
                            <MultipleInputContent
                                title={ paymentType.name }
                                inputFields={ paymentType.inputs }
                                translate={translate}
                                onDone={(inputValues) => {
                                    let useAmount;
                                    if(paymentType.allowSplit === false){
                                        useAmount = paymentSum;
                                    }else{
                                        useAmount = typeof inputValues['value'] !== "undefined" ? parseFloat(inputValues['value'].replace(',', '.')) || 0 : paymentSum;
                                        if(useAmount === 0){
                                            dispatch(addErrorAlert('please insert correct amount'));
                                            return;
                                        }
                                        if(useAmount > paymentSum){
                                            useAmount = paymentSum;
                                        }
                                    }

                                    for(let input of paymentType.inputs){
                                        if(typeof input.validate !== "undefined"){
                                            let value = inputValues[input.id];
                                            if(input.validate?.notEmpty){
                                                if(isEmptyString(value)){
                                                    dispatch(addErrorAlert('Enter details'));
                                                    return;
                                                }
                                            }
                                        }
                                    }

                                    const payment = new paymentDTO(nextPaymentIndex, useAmount, "success", paymentType.type);
                                    payment.title = paymentType.name;

                                    for(let output of paymentType.outputs){
                                        let outputValue = interpolate(output.value, inputValues);
                                        if(output.type === 'documentAttributes'){
                                            payment.addDocumentAttribute(output.name, outputValue);
                                        }else{
                                            payment[output.type] = outputValue;
                                        }
                                    }

                                    payment.typeID = erplyPaymentType.id;
                                    payment.sumPaid = payment.sum;
                                    dispatch(addPayment(payment));
                                    if(paymentType.openCashDrawer){
                                        dispatch(openCashDrawer());
                                    }

                                    dispatch(closeModal(editModalID));
                                }}
                                initialKeyboardLayout={'numbers'}
                            />
                        }
                        </Translate>,
                    id: editModalID,
                    className: "Administration",
                    onClose: function () {

                    },
                    canClose: true
                }));
            }, paymentType.name);
        },
        startIntegratedPayment: (erplyPaymentTypes, nextPaymentIndex, paymentSum, paymentType) => {
            let erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
            if(typeof erplyPaymentType === "undefined"){
                dispatch(addErrorAlert('Payment type with reason code ' + paymentType.reasonCode + ' is missing. Please add to Erply BO!'));
                return;
            }
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", paymentType.type);
            payment.typeID = erplyPaymentType.id;
            payment.isIntegrated = true;
            dispatch(addPayment(payment));
            dispatch(processIntegratedPayment(payment, paymentType));
        },
        checkGiftCard: (number) => {
            dispatch(setLoading(true));
            dispatch(checkGiftCard(number, () => {
                dispatch(setLoading(false));
            }));
        },
        captureFirstGiftCard: () => {
            dispatch(changePaymentMode('giftCardCapture'));
            let onDone = () => {
                dispatch(changePaymentMode('options'));
            };
            dispatch(captureFirstGiftCard(onDone, () => {}));
        },
        changeTransactionSaveStatus: (status) => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: status
            });
        },
        handleBeforeCompleteActions: (actions, onFail) => {
            handleBeforeCompleteActions(dispatch, actions, onFail);
        },
        handleFiscalRequest(translate, receiptGiftCards){
            doHandleFiscalRequest(translate, receiptGiftCards);
        },
        completeTransaction: (referenceNumber, productsInBasket, payment, customer, pos, erplyConf, user, totalSum, discount, pluginRequestParameters, creditToDocumentID) => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: APP_DOCUMENT_SAVE_STATUS_PENDING
            });

            if(totalSum !== getPaymentsTotalSumPaid(payment.payments)){
                dispatch(showDialogue('ALERT ERROR ON TRANSACTION', 'transactionDiscrepancyError', 'Ok', function () {
                    cancelTransaction();
                }, undefined, undefined, false, undefined, undefined, true));
                return false;
            }

            function doComplete(retry, receipt){
                if(retry > 4) {
                    dispatch(openLogonModal('Logon to handle failure to complete transaction', (data) => {
                        dispatch(showDialogue('could not complete transaction', 'There is a connection or API issue, please reboot the SCO. If the issue persists, please contact your technical support.', 'Ok', function () {
                            cancelTransaction();
                        }, undefined, undefined, false));
                    }, false));
                    return false;
                }
                dispatch(setLoading(true));
                let next = retry + 1;
                dispatch(
                    completeTransaction(
                        referenceNumber,
                        productsInBasket,
                        payment,
                        customer,
                        pos,
                        erplyConf,
                        user,
                        totalSum,
                        discount,
                        pluginRequestParameters,
                        (invoiceID, invoiceNo, receiptLink) => {
                            dispatch(setLoading(false));
                            dispatch(changeMode(APP_MODE_DONE));
                            dispatch({type: INCREMENT_NEXT_INVOICE_NO, payload: {lastInvoiceNo: invoiceNo}})
                        }, (requests) => {
                            dispatch(setLoading(false));
                            dispatch(addErrorAlert('Communication error, trying again...'));
                            for(let request of requests){
                                if(request.status.responseStatus === 'ok'){
                                    if(request.status.requestName === 'saveSalesDocument'){
                                        dispatch(removeSalesDocument(request.records[0].invoiceID));
                                    }else if(request.status.requestName === 'savePayment'){
                                        dispatch(deletePayment(request.records[0].paymentID));
                                    }
                                }
                            }
                            window.setTimeout(function () {
                                doComplete(next, receipt);
                            }, 5000);
                        },
                        creditToDocumentID,
                        receipt
                    ));
            }

            function doCreateReceipt() {
                dispatch(createReceipt((receipt) => {
                    doComplete(0, receipt);
                }));
            }

            doCreateReceipt();
        },
        backToOptions: (payments) => {
            let currentPayment = payments.find(payment => payment.status === 'started' && payment.type === 'GIFTCARD');
            if(typeof currentPayment !== "undefined"){
                dispatch(removePayment(currentPayment));
            }
            dispatch(changePaymentMode(PAYMENT_MODE_OPTIONS));
        },
        addErrorAlert: (message) => {
            dispatch(addErrorAlert(message));
        },
    }
};

class Payment extends Component{

    constructor(props) {
        super(props);
        this.state = {
            paymentOptions: 'main'
        };
    }

    componentDidMount() {
        this.props.startPayment(this.props.totalSum);
        this.completeTransaction();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            this.completeTransaction();
        }, 100);
    }

    paymentsCompleted() {
        return this.props.totalSum >= 0 ? this.props.remainingSum <= 0 : this.props.remainingSum >= 0;
    }

    paymentsCanBeCompleted() {
        if(this.props.totalSum < 0){
            return false;
        }
        return (new Decimal(this.props.remainingSum).minus(new Decimal(this.props.giftCardsSum))).toDecimalPlaces(4) <= 0;
    }

    completeTransaction() {
        console.log('Try to complete transaction', this.paymentsCompleted());
        if(this.props.documentSaveStatus !== APP_DOCUMENT_SAVE_STATUS_PENDING && this.props.customer !== false){
            if(this.paymentsCompleted()){
                if(this.props.documentSaveStatus === APP_DOCUMENT_SAVE_STATUS_START){
                    this.props.changeTransactionSaveStatus(APP_DOCUMENT_SAVE_STATUS_BEFORE_COMPLETE);
                    let actions = Array.from(this.props.beforeCompleteTransactionActions);
                    this.props.handleBeforeCompleteActions(actions, () => {

                    });
                    return true;
                }

                if(this.props.beforeCompleteTransactionActions.length > 0 || this.props.documentSaveStatus === APP_DOCUMENT_SAVE_STATUS_START){
                    return false;
                }


                if(this.props.pos.fiscalReceiptRequired && this.props.documentSaveStatus !== APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE){
                    this.props.handleFiscalRequest(this.props.translate, this.props.receiptGiftCards);
                    return true;
                }

                console.log('Completing transaction');
                this.props.completeTransaction(
                    this.props.referenceNumber,
                    this.props.productsInBasket,
                    this.props.payment,
                    this.props.customer,
                    this.props.pos,
                    this.props.erplyConf,
                    this.props.user,
                    this.props.totalSum,
                    this.props.discount,
                    this.props.pluginRequestParameters,
                    this.props.creditToDocumentID
                );
            }else if(this.paymentsCanBeCompleted()){
                if(this.props.paymentMode === 'options'){
                    this.props.captureFirstGiftCard();
                }
            }
        }
    }

    paymentOptions() {
        if(this.props.payment.payments.length >= transactionMOPLimit){
            return <div><h2><Translate id={"Can't add more payment methods. Please remove one to continue!"}/></h2></div>
        }
        let mainPaymentTypes = this.props.payment.displayPaymentTypes.main.filter(el => this.props.uiCustomizationPOS.paymentMethods[el.name] === 1).map((payment, index) => {
            let erplyPaymentType = getPaymentTypeByType(this.props.payment.paymentTypes, payment.type);
            return (
                <div key={index} onClick={
                    e => {
                        console.log('mainPaymentType clicked', payment, erplyPaymentType);
                        if(payment.input === 'manual'){
                            this.props.startCustomPayment(true, this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment, this.props.erplyConf, this.props.user.clientCode, this.props.pos.pointOfSaleID);
                        }else if(typeof payment.input === "function"){
                            let sumPaid = payment.input(this.props.toBeCreditedSum);
                            if(sumPaid === 0){
                                this.props.addErrorAlert("Can't use this payment method")
                            }else{
                                this.props.startCustomPayment(false, this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment, this.props.erplyConf, this.props.user.clientCode, this.props.pos.pointOfSaleID, false, sumPaid);
                            }
                        }
                    }
                }>
                    {
                        this.props.theme === 'Erply' ?
                            <LeftImageButtonErply
                                className={'paymentButtonErply grayButton'}
                                image={otherImg}
                                title={<Translate id={erplyPaymentType.name}/>}
                            />
                            :
                            <LeftImageButton
                                className={'paymentButton'}
                                image={voucherImg}
                                title={<Translate id={erplyPaymentType.name}/>}
                            />
                    }
                </div>
            )
        });

        const getPaymentTypes = (category, paymentType, defaultType = false) => {
            if(typeof window?.AppConf?.paymentTypes?.[category] === "undefined"){
                return [];
            }
            return window.AppConf.paymentTypes[category].filter(el => {
                if(this.props.operationMode !== 'full'){
                    if(el.logonRequired !== false){
                        return false;
                    }
                }
                if(defaultType && typeof el.display === "undefined"){
                    return true;
                }
                if(typeof el.display === "object"){
                    return el.display?.[this.props.operationMode] === paymentType;
                }else {
                    return el.display === paymentType;
                }
            });
        }
        const manualInputOtherPaymentTypes = getPaymentTypes("manualInput", "other", true);
        const manualInputMainPaymentTypes = getPaymentTypes("manualInput", "main");
        const otherVoucherPaymentTypes = getPaymentTypes("voucher", "other", true);
        const mainVoucherPaymentTypes = getPaymentTypes("voucher", "main");
        const otherIntegratedPaymentTypes = getPaymentTypes("integration", "other", true);
        const mainIntegratedPaymentTypes = getPaymentTypes("integration", "main");

        let hasOtherPayments = (manualInputOtherPaymentTypes.length + otherVoucherPaymentTypes.length + otherIntegratedPaymentTypes.length) > 0;

        const mapCustomPayments = (customPaymentTypes) => customPaymentTypes.map((payment, index) => {
            let paymentTransactionMode = payment.transactionMode ?? 'both';
            if(this.props.totalSum >= 0){
                if(paymentTransactionMode === 'return' ){
                    return;
                }
            }else{
                if(paymentTransactionMode === 'sale' ){
                    return;
                }
            }
            let withInput = true;

            if(payment.allowSplit === false){
                if(this.props.payment.payments.length > 0){
                    return;
                }
                withInput = false;
            }

            return (
                <div key={index} onClick={
                    e => {
                        if(payment.type === 'card'){
                            this.props.startCardPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.referenceNumber,
                                this.props.erplyConf,
                                this.props.user.clientCode,
                                this.props.pos.pointOfSaleID,
                                true,
                                payment
                            );
                        }else{
                            this.props.startCustomPayment(withInput, this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment, this.props.erplyConf, this.props.user.clientCode, this.props.pos.pointOfSaleID);
                        }
                    }
                }>
                    <LeftImageButton
                        className={'paymentButton'}
                        image={voucherImg}
                        title=<Translate id={payment.name}/>
                    />
                </div>
            )
        });
        const customOtherPayments = mapCustomPayments(manualInputOtherPaymentTypes);
        const customMainPayments = mapCustomPayments(manualInputMainPaymentTypes);

        let mapVoucherPayments = (voucherPaymentTypes) => {
            return voucherPaymentTypes.map((payment, index) => {
                if (payment.allowSplit === false) {
                    if (this.props.payment.payments.length > 0) {
                        return;
                    }
                }
                return (
                    <div key={index} onClick={
                        e => this.props.startVoucherPayment(this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment)
                    }>
                        <LeftImageButton
                            className={'paymentButton'}
                            image={voucherImg}
                            title=<Translate id={payment.name}/>
                        />
                    </div>
                )
            })
        }
        const otherVoucherPayments = mapVoucherPayments(otherVoucherPaymentTypes);
        const mainVoucherPayments = mapVoucherPayments(mainVoucherPaymentTypes);


        let mapIntegratedPayments = (paymentTypes) => {
            return paymentTypes.map((payment, index) => {
                if (payment.allowSplit === false) {
                    if (this.props.payment.payments.length > 0) {
                        return;
                    }
                }
                return (
                    <div key={index} onClick={
                        e => this.props.startIntegratedPayment(this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment)
                    }>
                        <LeftImageButton
                            className={'paymentButton'}
                            image={voucherImg}
                            title=<Translate id={payment.name}/>
                        />
                    </div>
                )
            })
        }
        let otherIntegratedPayments = mapIntegratedPayments(otherIntegratedPaymentTypes);
        let mainIntegratedPayments = mapIntegratedPayments(mainIntegratedPaymentTypes);

        let displayPayments;

        if(this.state.paymentOptions === 'main'){
            let otherPaymentsButton;
            let cashPaymentButton;

            if(hasOtherPayments){
                if(this.props.theme === 'Erply'){
                    otherPaymentsButton = (
                        <div onClick={e => this.setState({paymentOptions: 'other'})}>
                            <LeftImageButtonErply
                                className={'paymentButtonErply grayButton'}
                                image={otherImg}
                                title=<Translate id={"Other payments"}/>
                            />
                        </div>
                    );
                }else{
                    otherPaymentsButton = (
                        <div onClick={e => this.setState({paymentOptions: 'other'})}>
                            <LeftImageButton
                                className={'paymentButton'}
                                image={voucherImg}
                                title=<Translate id={"Other payments"}/>
                            />
                        </div>
                    );
                }
            }

            if(this.props.operationMode === 'full'){
                if(this.props.uiCustomizationPOS.paymentMethods.cash === 1){
                    if(this.props.theme === 'Erply'){
                        cashPaymentButton = (
                            <div onClick={e => this.props.startCashPayment(this.props.nextPaymentIndex, this.props.toBeCreditedSum, this.props.payment.payments, parseInt(this.props.erplyConf.pos_round_cash_to_nearest_005) === 1, this.props.erplyConf, this.props.user.clientCode, this.props.pos.pointOfSaleID)}>
                                <LeftImageButtonErply
                                    className={'paymentButtonErply greenButton'}
                                    image={cashErplyWhiteImg}
                                    title=<Translate id={"Cash"}/>
                                />
                            </div>
                        );
                    }else {
                        cashPaymentButton = (
                            <div onClick={e => this.props.startCashPayment(this.props.nextPaymentIndex, this.props.toBeCreditedSum, this.props.payment.payments, parseInt(this.props.erplyConf.pos_round_cash_to_nearest_005) === 1, this.props.erplyConf, this.props.user.clientCode, this.props.pos.pointOfSaleID)}>
                                <LeftImageButton
                                    className={'paymentButton'}
                                    image={cashImg}
                                    title=<Translate id={"Cash"}/>
                                />
                            </div>
                        );
                    }
                }
            }

            let cardPaymentButton;
            let giftCardPaymentButton;

            if(this.props.uiCustomizationPOS.paymentMethods.card === 1){
                let CardPaymentButton = ({theme, title='bankCard', className='cardPaymentButton'}) => {
                    if(theme === 'Erply'){
                        return <LeftImageButtonErply
                            title={<Translate id={title}/>}
                            className={'paymentButtonErply cardPaymentButtonErply'}
                            image={bankCardErplyImg}
                        />;
                    }else {
                        return <LeftImageButton
                            title={<Translate id={title}/>}
                            className={className}
                            image={bankCardImg}
                        />;
                    }
                }
                if(window.AppConf.paymentTypes.card){
                    cardPaymentButton = window.AppConf.paymentTypes.card.map((cardPayment) => {
                        return <div onClick={e => {
                            this.props.startCardPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.referenceNumber,
                                this.props.erplyConf,
                                this.props.user.clientCode,
                                this.props.pos.pointOfSaleID,
                                false,
                                cardPayment
                            );
                        }}>
                            <CardPaymentButton theme={this.props.theme} title={cardPayment.name} className={'cardPaymentButtonSmaller'}/>
                        </div>;
                    });
                }else{
                    cardPaymentButton = (
                        <div onClick={e => {
                            this.props.startCardPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.referenceNumber,
                                this.props.erplyConf,
                                this.props.user.clientCode,
                                this.props.pos.pointOfSaleID
                            );
                        }}>
                            <CardPaymentButton theme={this.props.theme}/>
                        </div>
                    );
                }
            }

            if(this.props.uiCustomizationPOS.paymentMethods.giftCard === 1){
                if(this.props.theme === 'Erply'){
                    giftCardPaymentButton = (
                        <div onClick={e => this.props.startGiftCardPayment(this.props.nextPaymentIndex, this.props.toBeCreditedSum, this.props.referenceNumber)}>
                            <LeftImageButtonErply
                                className={'paymentButtonErply lightBlueButton'}
                                image={giftCardErplyImg}
                                title=<Translate id={"giftCard"}/>
                            />
                        </div>
                    );
                }else{
                    if(this.props.giftCardPaymentEnabled && !(!this.props.allowGiftCardPaymentWithGiftCard && basketHasDecathlonGiftCard(this.props.productsInBasket, this.props.productCategories))){
                        giftCardPaymentButton = (
                            <div onClick={e => this.props.startGiftCardPayment(this.props.nextPaymentIndex, this.props.toBeCreditedSum, this.props.referenceNumber)}>
                                <LeftImageButton
                                    className={'paymentButton'}
                                    image={giftCardImg}
                                    title=<Translate id={"gift card or loyalty voucher"}/>
                                />
                            </div>
                        );
                    }
                }
            }

            displayPayments = (
                <div id={"paymentOptions"}>
                    { cardPaymentButton }
                    { giftCardPaymentButton }
                    { mainPaymentTypes }
                    { customMainPayments }
                    { mainVoucherPayments }
                    { mainIntegratedPayments }
                    { otherPaymentsButton }
                    { cashPaymentButton }
                </div>
            );

        }else{
            displayPayments = (
                <div id={"paymentOptions"}>
                    <div onClick={e => this.setState({paymentOptions: 'main'})} style={{marginBottom: '10px'}}>
                        <Button type="button" className={"largeButton blueButton"} name={<Translate id="back"/>}
                                arrow="left" flencheClass={"flencheSmallLeftBottom blueFlenche"}/>
                    </div>
                    { customOtherPayments }
                    { otherVoucherPayments }
                    { otherIntegratedPayments }
                </div>
            );
        }

        let title;
        if(this.props.theme === 'Erply'){
            title =
                <div style={{
                    display: 'inline-grid'
                }}>
                    <img
                        src={cashErplyImg}
                        alt={'MOP'}
                        style={{
                            margin: '16px auto'
                        }}
                    />
                    <span
                        className={"boldText"}
                        style={{
                            margin: '16px auto',
                            fontSize: '24px'
                        }}
                    >
                        <Translate id={'Choose your payment method'}/>
                    </span>
                    <img
                        src={downArrowErplyImg}
                        alt={'MOP'}
                        style={{
                            margin: '16px auto'
                        }}
                    />
                </div>;
        }else{
            title =
                <span className={"boldUppercaseText"}>
                    <Translate id={'choosePaymentMethod'}/>
                </span>;
        }

        return (
            <div>
                {title}
                <br/>
                <PerfectScrollbar style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: this.props.theme === 'Erply' ? '230px' : '180px',
                    height: 'calc(100% - 190px)'
                }}>
                    { displayPayments }
                </PerfectScrollbar>
            </div>
        );
    }
    cardPayment() {
        if(this.props.theme === 'Erply'){
            return (
                <div style={{
                    display: 'inline-grid'
                }}>
                    <img
                        src={bankCardErplyImg}
                        alt={'Bank card'}
                        style={{
                            margin: '30px auto',
                            width: '31px'
                        }}
                    />
                    <span
                        className={"boldText"}
                        style={{
                            fontSize: '24px'
                        }}
                    >
                        { <Translate id={this.props.progressMessage || "Apply your card"}/>}
                    </span>
                    <img
                        src={insertCardErplyImg}
                        alt="insertCardImg"
                        style={{
                            margin: '90px auto',
                            width: '390px',
                            maxWidth: '80%',
                            paddingLeft: '133px'
                        }}
                    />
                </div>
            );
        }else{
            return (
                <div>
                <span className={"boldUppercaseText"}>
                        { <Translate id={this.props.progressMessage || "Follow payment terminal instructions"}/>}
                    </span>
                    <br/>
                    <div style={{
                        "marginBottom": "45px"
                    }}/>
                    <img src={insertCardImg} alt="insertCardImg"/>
                </div>
            );
        }
    }
    giftCardPayment() {
        return (
            <GiftCardPayment
                backToOptions={() => {
                    this.props.backToOptions(this.props.payment.payments);
                }}
                onManualEntry={(number) => {
                    this.props.checkGiftCard(number);
                }}
            />
        );
    }
    render() {
       if (this.paymentsCompleted()){
            return null;
        }else{
            switch (this.props.paymentMode) {
                case 'options':
                    return this.paymentOptions();
                case 'card':
                    return this.cardPayment();
                case 'giftCard':
                    return this.giftCardPayment();
                default:
                    return (
                        <Loader
                            type="TailSpin"
                            color="#0082C3"
                            height={100}
                            width={100}
                            style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                margin: "-50px 0 0 -50px"
                            }}
                        />
                    );
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Payment));
