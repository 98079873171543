import React from "react";
import {getISO6392BFromISO6391} from "../../redux/selectors";
import ImageBoxErply from "../../buttons/ImageBoxErply";

export default function ({setProduct, products, productsOnLine, containerStyle = {}, language}) {
    const getProductName = (product) => {
        let lang = getISO6392BFromISO6391(language);
        if(lang === false){
            return product.name;
        }
        lang = lang.toUpperCase();
        return product['name' + lang];
    };

    return (
        <div style={containerStyle}>
            {
                products.map((product, index) => {
                    let picture = typeof product.images !== "undefined" ? product.images[0].thumbURL : false;
                    let boxStyle;
                    let textStyle;

                    if(picture !== false){
                        boxStyle = {
                            backgroundImage: 'url(' + picture + ')',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        };
                        textStyle = {
                            bottom: 0,
                            backgroundColor: 'rgb(255,255,255,0.8)'
                        }
                    }else{
                        boxStyle = {
                            backgroundColor: '#b8e2f2'
                        };
                        textStyle = {
                            position: 'initial',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            overflow: 'hidden'
                        }
                    }
                    textStyle.fontSize = 12;
                    boxStyle.padding = 0;

                    return <ImageBoxErply
                        key={index}
                        boxStyle={boxStyle}
                        textStyle={textStyle}
                        title={getProductName(product)}
                        width={(100 / productsOnLine) + '%'}
                        height={150}
                        padding={'5px'}
                        onClick={e => {
                            setProduct(product);
                        }}
                    />
                })
            }
        </div>
    );
}
