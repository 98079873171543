const HOUR_MS = 3600000;

export const erplyDateFormatToMoment = (dateFormat) => dateFormat.replace('d', 'DD').replace('m', 'MM').replace('Y', 'YYYY');

export const getCurrentTimestamp = function () {
    return Math.floor(Date.now() / 1000).toString();
};

export const stringToDate = function(date,format)
{
    var delimiter = format.match(/\W/g)[0];
    var formatLowerCase = format.toLowerCase();
    var formatItems = formatLowerCase.split(delimiter);
    var dateItems = date.split(delimiter);
    var monthIndex = formatItems.indexOf("m");
    var dayIndex = formatItems.indexOf("d");
    var yearIndex = formatItems.indexOf("y");
    var month = parseInt(dateItems[monthIndex]) - 1;
    return new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
};

export const dateFormat = function(date, format) {
    var delimiter = format.match(/\W/g)[0];
    var formatLowerCase = format.toLowerCase();
    var formatItems = formatLowerCase.split(delimiter);

    var day = date.getDate().toString().padStart(2,'0');
    var month = (date.getMonth() + 1).toString().padStart(2,'0');
    var year = date.getFullYear().toString();

    formatItems[formatItems.indexOf("d")] = day;
    formatItems[formatItems.indexOf("m")] = month;
    formatItems[formatItems.indexOf("y")] = year;

    return formatItems.join(delimiter);
};

export const calendar = {
    parseAndFormatDate: function (e, t, n) {
        if ("0000-00-00" != e && "" !== e) {
            var r = this.parseDate(e, n);
            if (r)return this.formatDate(r, t)
        }
        return e
    }, isValidDate: function (e) {
        return "[object Date]" === Object.prototype.toString.call(e) && !isNaN(e)
    }, parseDate: function (e, t) {
        function pointlessFunction(e, t) {
            if (+e)for (; e.getDate() != t.getDate();)e.setTime(+e + (e < t ? 1 : -1) * HOUR_MS)
        }

        if ("object" == typeof e)return e;
        if ("number" == typeof e)return new Date(1e3 * e);
        if ("string" == typeof e) {
            if (e.match(/^\d+(\.\d+)?$/))return new Date(1e3 * parseFloat(e));
            void 0 === t && (t = !0);
            var r = function (e, t) {
                var r = e.match(/^([0-9]{4})(-([0-9]{2})(-([0-9]{2})([T ]([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?(Z|(([-+])([0-9]{2})(:?([0-9]{2}))?))?)?)?)?$/);
                if (!r)return null;
                var o = new Date(r[1], 0, 1);
                if (t || !r[13]) {
                    var a = new Date(r[1], 0, 1, 9, 0);
                    if(r[3]) {
                        o.setMonth(r[3] - 1);
                        a.setMonth(r[3] - 1);
                    }
                    if(r[5]){
                        o.setDate(r[5]);
                        a.setDate(r[5])
                    }
                    pointlessFunction(o, a);
                    if(r[7]) o.setHours(r[7]);
                    if(r[8]) o.setMinutes(r[8]);
                    if(r[10])o.setSeconds(r[10]);
                    if(r[12])o.setMilliseconds(1e3 * Number("0." + r[12]));
                    pointlessFunction(o, a);
                } else if (o.setUTCFullYear(r[1], r[3] ? r[3] - 1 : 0, r[5] || 1), o.setUTCHours(r[7] || 0, r[8] || 0, r[10] || 0, r[12] ? 1e3 * Number("0." + r[12]) : 0), r[14]) {
                    var i = 60 * Number(r[16]) + (r[18] ? Number(r[18]) : 0);
                    i *= "-" == r[15] ? 1 : -1;
                    o = new Date(+o + 60 * i * 1e3);
                }
                return calendar.isValidDate(o) ? o : null
            }(e, t);
            return calendar.isValidDate(r) ? r : (e && (r = new Date(e)), calendar.isValidDate(r) ? r : null)
        }
        return null
    }, formatDate: function (e, t, n) {
        return calendar.formatDates(e, null, t, n)
    }, formatDates: function (e, t, n, r) {
        function o(e) {
            return (e < 10 ? "0" : "") + e
        }

        var a = {
            s: function (e) {
                return e.getSeconds()
            }, ss: function (e) {
                return o(e.getSeconds())
            }, m: function (e) {
                return e.getMinutes()
            }, mm: function (e) {
                return o(e.getMinutes())
            }, h: function (e) {
                return e.getHours() % 12 || 12
            }, hh: function (e) {
                return o(e.getHours() % 12 || 12)
            }, H: function (e) {
                return e.getHours()
            }, HH: function (e) {
                return o(e.getHours())
            }, d: function (e) {
                return e.getDate()
            }, dd: function (e) {
                return o(e.getDate())
            }, ddd: function (e, t) {
                return t.dayNamesShort[e.getDay()]
            }, dddd: function (e, t) {
                return t.dayNames[e.getDay()]
            }, M: function (e) {
                return e.getMonth() + 1
            }, MM: function (e) {
                return o(e.getMonth() + 1)
            }, MMM: function (e, t) {
                return t.monthNamesShort[e.getMonth()]
            }, MMMM: function (e, t) {
                return t.monthNames[e.getMonth()]
            }, yy: function (e) {
                return (e.getFullYear() + "").substring(2)
            }, yyyy: function (e) {
                return e.getFullYear()
            }, t: function (e) {
                return e.getHours() < 12 ? "a" : "p"
            }, tt: function (e) {
                return e.getHours() < 12 ? "am" : "pm"
            }, T: function (e) {
                return e.getHours() < 12 ? "A" : "P"
            }, TT: function (e) {
                return e.getHours() < 12 ? "AM" : "PM"
            }, u: function (e) {
                return calendar.formatDate(e, "yyyy-MM-dd'T'HH:mm:ss'Z'")
            }, S: function (e) {
                var t = e.getDate();
                return t > 10 && t < 20 ? "th" : ["st", "nd", "rd"][t % 10 - 1] || "th"
            }
        };
        r = r || "yyyy-MM-dd HH:mm:ss";
        var i, s, l, u, c = e, d = t, p = n.length, m = "";
        for (i = 0; i < p; i++)if ("'" == (s = n.charAt(i))) {
            for (l = i + 1; l < p; l++)if ("'" == n.charAt(l)) {
                if(c){
                    m += l == i + 1 ? "'" : n.substring(i + 1, l);
                    i = l;
                }
                break
            }
        } else if ("(" == s) {
            for (l = i + 1; l < p; l++)if (")" == n.charAt(l)) {
                var f = calendar.formatDate(c, n.substring(i + 1, l), r);
                if(parseInt(f.replace(/\D/, ""), 10)){
                    m += f;
                }
                i = l;
                break
            }
        } else if ("[" == s) {
            for (l = i + 1; l < p; l++)if ("]" == n.charAt(l)) {
                var h = n.substring(i + 1, l), f = calendar.formatDate(c, h, r);
                f != calendar.formatDate(d, h, r) && (m += f);
                i = l;
                break
            }
        } else if ("{" == s) {
            c = t;
            d = e;
        } else if ("}" == s) {
            c = e;
            d = t;
        }
        else {
            for (l = p; l > i; l--){
                if (u = a[n.substring(i, l)]) {
                    c && (m += u(c, r));
                    i = l - 1;
                    break
                }
            }
            l == i && c && (m += s)
        }
        return m
    }
};