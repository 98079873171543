import Decimal from "decimal.js";

export const getFirstStartedGiftCardPayment = (payments) => {
    const startedPayments = payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "started";
    });
    return startedPayments.length > 0 ? startedPayments[0] : false;
};

export const getCheckedGiftCardPayments = (payments) => {
    return payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "checked";
    });
};

export const giftCardIsInTransaction = (number, payments) => {
    return payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "checked" && payment.giftCard.cardNumber === number;
    }).length > 0;
};

export const getGiftCardsSum = payments => {
    let total = getCheckedGiftCardPayments(payments).reduce((acc, payment) => {
        acc = acc.plus(new Decimal(payment.sum));
        return acc;
    }, new Decimal(0));

    return total.toDecimalPlaces(4).toString();
};

export const getCashPayment = (payments) => {
    let cashPayments = payments.filter(function (payment) {
        return payment.type === "CASH";
    });

    return cashPayments.length > 0 ? cashPayments[0] : false;
};

export const getCardPayments = (payments) => {
    return payments.filter(function (payment) {
        return payment.type === "CARD";
    });
};

export const getTotalPaid = (payments) => {
    return payments.reduce(function (acc, payment) {
        acc += payment.sumPaid - payment.sumChange;
        return acc;
    }, 0);
};

export const getCashChange = (payments) => {
    return payments.reduce(function (acc, payment) {
        acc = acc.plus(new Decimal(payment.cashChange));
        return acc;
    }, new Decimal(0)).toDecimalPlaces(4).toString();
};

