import React, {Component} from 'react';
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import idCard from "../images/IDKaart.PNG";
import Button from "../../buttons/Button";
import scanDecathlonCardImg from "../images/scanDecathlonCardSmall.png";
import CountDown from "../../uiComponents/CountDown";
import ZipCodeSurvey from "../inputForms/ZipCodeSurvey";
import {
    addErrorAlert,
    changeMode,
    closeRightPanelDialogue,
    findCustomerByCardNumber,
    findCustomerByEmail,
    findCustomerByPhone,
    findCustomerByRegistryCode,
    setCustomer,
    setDefaultCustomer, setLoading, showRightPanelDialogue
} from "../../redux/actions";
import {readIdCard} from "../../redux/payment/actions";
import PaybackPayment from "../../integrations/payback/Payment";
import {APP_MODE_PAYMENT} from "../../redux/appConstants";
import EnterCustomerInfo from "../EnterCustomerInfo";
import Loader from "react-loader-spinner";
import {getProductsTotalSum} from "../../redux/selectors";
import decathlonCardScanAnimation from "../images/decathlonCardScanAnimation.gif";
import EnterCustomerPhone from "../EnterCustomerPhone";
import FullscreenCustomerSearch from "./FullscreenCustomerSearch";

const mapStateToProps = state => {
    const customer = state.rootReducer.customer;
    const paybackEnabled = window.AppConf.payback || false;
    const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);

    return {
        customer,
        eftIdCardIdentification: state.payment.idCardIdentification,
        showZipCodeSurvey: state.payment.zipCodeSurveyCounter === 1 && typeof customer.zipCode === "undefined" &&
            (state.rootReducer.erplyConf.DKTZipSurveyNonCustomer === "1" ? customer.default : true),
        showPayback: paybackEnabled && parseFloat(totalSum) !== 0 ? !state.rootReducer.payback.inputDone : false,
        translate: getTranslate(state.localize),
        theme: state.rootReducer.theme,
        countryCode: state.rootReducer.erplyConf.DKTCountryCode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startPayment: () => {
            dispatch(changeMode(APP_MODE_PAYMENT));
        },
        enterEmail: () => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerInfo
                    emailOnly={true}
                    title={<Translate id={"findCustomer"}/>}
                    onDone={(customerInfo) => {
                        dispatch(setLoading(true));
                        console.log('customerInfo: ', customerInfo);
                        dispatch(findCustomerByEmail(customerInfo.email, (customer)=>{
                            dispatch(setLoading(false));
                        }, (result) => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        enterPhone: (countryCode) => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerPhone
                    title={<Translate id={"findCustomer"}/>}
                    countryCode={countryCode}
                    onDone={(customerInfo) => {
                        dispatch(setLoading(true));
                        console.log('customerInfo: ', customerInfo);
                        dispatch(findCustomerByPhone(customerInfo.phone, countryCode, (customer)=>{
                            dispatch(setLoading(false));
                        }, (result) => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        enterCardCode: () => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerInfo
                    cardCode={true}
                    title={<Translate id={"findCustomer"}/>}
                    onDone={customer => {
                        dispatch(setLoading(true));
                        dispatch(findCustomerByCardNumber(customer.card_number, () => {
                            dispatch(setLoading(false));
                        }, () => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        setDefaultCustomer: () => {
            dispatch(setDefaultCustomer());
        },
        startIdCardRead: (onDone) => {
            dispatch(readIdCard((response) => {
                onDone();
                let idCode = response?.records?.[0]?.additionalData?.iDCode;
                if(typeof idCode === "undefined" || idCode === ''){
                    return addErrorAlert('Could not read ID card');
                }
                return findCustomerByRegistryCode(response.records[0].additionalData.iDCode);
            }, () => {
                onDone();
                return addErrorAlert('Could not read ID card');
            }));
        },
        addZipCodeToCustomer: (customer, zipCode) => {
            customer.zipCode = zipCode;
            dispatch(setCustomer(customer));
        }
    }
};


class BeforePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readIdCard: false
        };

        this.customerSearch = this.customerSearch.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.getCurrentView() === null){
            this.props.startPayment();
        }
    }

    componentDidMount() {
        if(this.getCurrentView() === null){
            this.props.startPayment();
        }
    }

    customerSearch(){
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            if(this.props.eftIdCardIdentification){
                let startIdCardRead = () => {
                    if(this.state.readIdCard) return;

                    this.setState({
                        readIdCard: true
                    });
                    this.props.startIdCardRead(() => {
                        this.setState({
                            readIdCard: false
                        });
                    });
                };
                return (
                    <div>
                        <span className={"boldUppercaseText"} onClick={startIdCardRead}>
                            <Translate id={"click to scan id card"}/>
                        </span>
                        <br/>
                        <div style={{
                            "marginBottom": "30px"
                        }}/>
                        <img src={idCard} alt="Read ID Card" style={{
                            width: '50%'
                        }} onClick={startIdCardRead}/>
                        <br/>
                        <div style={{

                        }}>
                            <div style={{
                                position: "absolute",
                                width: "100%",
                                bottom: "18px"
                            }}>
                                <Button
                                    onClick={e => {
                                        this.props.setDefaultCustomer();
                                    }}
                                    type="button"
                                    className={"largerButton roundButton darkYellowButton uppercaseText"}
                                    name={<Translate id="I don't have ID card"/>}
                                    arrow="right"
                                    style={{
                                        marginTop: '20px'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            }else{
                this.props.setDefaultCustomer();
            }
        }
        if(window?.AppConf?.fullscreenCustomerSearch){
            return <FullscreenCustomerSearch
                onSkip={() => {
                    this.props.setDefaultCustomer();
                }}
            />
        }

        let paymentButton = (
            <Button
                onClick={e => {
                    this.props.setDefaultCustomer();
                }}
                type="button"
                className={"largeButton yellowButton"}
                name={<Translate id="noDecathlonCard"/>}
                arrow="right"
                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                style={{
                    marginTop: '20px'
                }}
            />
        );
        let enterEmailButton = (
            <div style={{marginBottom: "10px"}} onClick={e => {
                this.props.enterEmail();
            }}>
                <Button type="button" className={"largeButton blueButton"} name={<Translate id="enterEmail"/>}
                        arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
            </div>
        );
        let enterCardCodeButton = (
            <div style={{marginBottom: "10px"}} onClick={e => {
                this.props.enterCardCode();
            }}>
                <Button type="button" className={"largeButton blueButton"} name={<Translate id="enterCardCode"/>}
                        arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
            </div>
        );

        let enterPhoneButton = (
            <div style={{marginBottom: "10px"}} onClick={e => {
                this.props.enterPhone(this.props.countryCode);
            }}>
                <Button type="button" className={"largeButton blueButton"} name={<Translate id="Enter phone number"/>}
                        arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
            </div>
        );

        let orLine = (
            <div style={{
                width: "300px",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "calc(50% - 150px)"
            }}>
                <div className={"diagonalLine"} style={{
                    float: "left",
                    marginTop: "40px"
                }}/>
                <span style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    color: "#0082C3",
                    fontSize: "20px"
                }}>
                            <Translate id="or"/>
                        </span>
                <div className={"diagonalLine"} style={{
                    float: "right",
                    marginTop: "10px"
                }}/>
            </div>
        );

        //if(typeof window.AppConf.scanCustomerCardTimeout !== "undefined"){
            let scanCustomerCountDown = <CountDown
                style={{
                    display: 'inline-block',
                    color: '#7f7f7f',
                    backgroundColor: '#f7f7f7',
                    borderRadius: '50%',
                    width: '200px',
                    height: '50px',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    lineHeight: 1.5
                }}
                onDone={e => {
                    this.props.setDefaultCustomer();
                }}
                timeInSeconds={window.AppConf.scanCustomerCardTimeout || 30}
            />
        //}

        return (
            <div>
                <span className={"boldUppercaseText"}>
                        <Translate id={"scanDecathlonCard"}/>
                    </span>
                <br/>
                <div style={{
                    "marginBottom": "30px"
                }}/>
                <img src={window.AppConf.scanDecathlonCardGif ? decathlonCardScanAnimation : scanDecathlonCardImg} alt="scanDecathlonCard"/>
                <br/>
                {scanCustomerCountDown}
                <br/>
                <div style={{

                }}>
                    { orLine }
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "18px"
                    }}>
                        { enterCardCodeButton }
                        { enterEmailButton }
                        { enterPhoneButton }
                        { paymentButton }
                    </div>
                </div>
            </div>
        );
    }
    zipCodeSurvey(){
        return (
            <ZipCodeSurvey
                onDone={({zipCode}) => {
                    this.props.addZipCodeToCustomer(this.props.customer, zipCode);
                }}
            />
        );
    }
    getCurrentView(){
        if (this.props.showPayback) {
            return <PaybackPayment/>;
        } else if (this.props.customer === false) {
            return this.customerSearch();
        } else if (this.props.showZipCodeSurvey) {
            return this.zipCodeSurvey();
        } else {
            return null;
        }
    }

    render() {
        const view = this.getCurrentView();
        return view !== null ? view : (
            <Loader
                type="TailSpin"
                color="#0082C3"
                height={100}
                width={100}
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    margin: "-50px 0 0 -50px"
                }}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(BeforePayment));