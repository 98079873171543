import React from 'react';
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../../uiComponents/WrappedPerfectScrollBar';
import DocumentListItem from "./DocumentListItem";
import {Translate} from "react-localize-redux";
import "../../main.css";
import {numberIsOdd} from "../../../util/helperFunctions";
import {useSelector} from "react-redux";

export default function(props) {
    const theme = useSelector(state => state.rootReducer.theme);

    let dateFormat = props.dateFormat;
    return (
        <div
            id={props.id}
            style={{
            "height": "100%",
            "width": "100%",
            "overflow": "hidden"
        }}>
            <div className={theme === 'Erply' ? 'documentListErply' : 'documentList'}>
                <PerfectScrollbar style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0,
                    padding: theme === 'Erply' ? '0 20px' : 0
                }}>
                    <table className={`${theme === 'Decathlon' ? 'bold responsiveTable borderBottomTd documentTable' : 'responsiveTableErply'}`}>
                        <thead style={theme === 'Erply' ? {
                            fontSize: '16px',
                        } : {
                            fontSize: '20px',
                            borderBottom: '2px solid #ebebeb'
                        }}>
                            <tr>
                                <th><Translate id={'Number'}/></th>
                                <th><Translate id={'Date'}/></th>
                                <th><Translate id={'Customer'}/></th>
                                <th style={{textAlign: 'right'}}><Translate id={'Sum'}/></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            props.documents.map( (document, index) => {
                                return <DocumentListItem
                                    key={index}
                                    document={document}
                                    dateFormat={dateFormat}
                                    printReceipt={props.printReceipt}
                                    sendEmailReceipt={props.sendEmailReceipt}
                                    refundReceipt={document.type !== 'CREDITINVOICE' ? props.refundReceipt : false}
                                    className={theme === 'Erply' ? (numberIsOdd(index + 1) ? ' productLineOdd' : '') : ''}
                                />
                            })
                        }
                        </tbody>
                    </table>
                </PerfectScrollbar>
            </div>
        </div>
    );
}