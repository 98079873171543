const pluginMiddleware = (store) => next => action => {
    next(action);
    console.log('plugin middleware', action);
    const state = Object.assign({}, store.getState());

    if(typeof state.rootReducer.plugins !== "undefined"){
        for (let plugin of state.rootReducer.plugins) {
            console.log('plugin middleware, import', plugin);
            import(/* webpackIgnore: true */ plugin).then((module) => {
                module.default(store, state, action);
            });
        }
    }
};

export default pluginMiddleware;
