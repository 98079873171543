import React from 'react';
import './button.css';

export default function ({image, imageStyle, containerStyle, boxStyle, textStyle, width, height, padding="5px", onClick, title, children}) {
    let imageEl;

    if(image && image !== false){
        let imageStyles = {
            display: "block",
            paddingTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            maxHeight: "calc(100% - 90px)",
            maxWidth: '90%',
            ...imageStyle
        };
        imageEl = (
            <img src={image} alt={'image'} style={imageStyles}/>
        );
    }

    let boxStyles = {
        width: "100%",
        height: "100%",
        position: "relative",
        overflowWrap: "anywhere",
        maxHeight: "100%",
        ...(boxStyle ?? {})
    };

    let textStyles = {
        position: "absolute",
        bottom: "20px",
        width: "100%",
        textAlign: "center",
        fontSize: '18px',
        ...(textStyle ?? {})
    };

    return (
        <div style={{
            width: width || "100%",
            height: height || "100%",
            boxSizing: "border-box",
            padding,
            float: "left",
            ...containerStyle
        }} onClick={onClick}>
            <div className={'shadowBox'} style={boxStyles}>
                {imageEl}
                {children}
                <div className={'boldUppercaseText'} style={textStyles}>{title}</div>
            </div>
        </div>
    );
}