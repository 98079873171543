import React, {Component} from 'react';
import "../main.css"
import {getTranslate, Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import ImageBox from "../../buttons/ImageBox";
import iconArticle from "../images/iconArticle.png";
import iconArticleErply from "../images/Erply/bagWhite.png";
import iconNotes from "../images/edit.png";
import iconNotesErply from "../images/Erply/addNotes.png";
import iconSaveSale from "../images/iconSaveSale.png";
import iconSaveSaleErply from "../images/Erply/save.png";
import iconCashInOut from "../images/iconCash.png";
import iconCashInOutErply from "../images/Erply/cashWhiteSmaller.png";
import iconReturn from "../images/return.png";
import iconReturnErply from "../images/Erply/returnMode.png";
import iconRecentSales from "../images/iconRecentSales.png";
import iconRecentSalesErply from "../images/Erply/clock.png";
import iconVoucher from "../images/voucherPaymentBlue.png";
import iconEditUIErply from "../images/Erply/editUI.png";
import iconCashDrawer from "../images/cashDrawer.png";
import iconCashDrawerErply from "../images/Erply/cashDrawer.png";
import iconEditProduct from "../images/edit.png";
import iconEditProductErply from "../images/Erply/addNotes.png";
import iconCloseDay from "../images/close.png";
import iconCloseDayErply from "../images/Erply/signOutWhite.png";
import iconReport from "../images/iconReport.png";
import iconReportErply from "../images/Erply/document.png";
import iconCloseQCO from "../images/signOff.png";
import iconCloseQCOErply from "../images/Erply/signOutBlack.png";
import iconCashierMode from "../../login/images/cashierMode.png";
import {
    closeQCO,
    changeAdministrationView,
    showDialogue,
    saveSale,
    changeTransactionMode,
    closeAdministration,
    POSCloseDay,
    getPointOfSaleDayTotals,
    getCloseDayHtmlReport,
    openModal,
    getRecentSales,
    printReceipt,
    addInfoAlert,
    showInputDialogue,
    sendEmailReceipt,
    addErrorAlert,
    removeAlert,
    closeModal,
    findProduct,
    getUserRights,
    openCashDrawer,
    setEditQuickProducts,
    setLoading,
    getSalesDocument,
    findProducts,
    addProduct,
    setReturnBaseDocument,
    getPayments,
    changeOperationMode,
    initTransaction
} from "../../redux/actions";
import * as uuid from "uuid";
import Button from "../../buttons/Button";
import {EMAIL_NOT_SENT, EMAIL_SENT, POS_DAY_CLOSE_FAILED, POS_DAY_CLOSE_SUCCESS} from "../../redux/actionTypes";
import DocumentList from "../lists/document/DocumentList";
import Keyboard from "../../keyboard/Keyboard";
import VoucherInput from "../VoucherInput";
import { AVMB } from "../../util/voucher";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import ReturnDocument from "./ReturnDocument";
import Product from "../../redux/dto/product";
import ImageBoxErply from "../../buttons/ImageBoxErply";
import Header from "./components/Header";
import {displayPrice} from "../../util/helperFunctions";
import ChooseOperationMode from "../../login/ChooseOperationMode";
import {mixedTransactions} from "../../redux/selectors";

const showXReport = function (posID, warehouseID, onClose, theme) {
    return getCloseDayHtmlReport(posID, warehouseID, function (reportData) {
        let reportModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0
                }}>
                    {
                        theme === 'Erply' ?
                            <div>
                                <Header title={'X-report'}>
                                    <div onClick={e => window.frames["x-report"].print()} style={{
                                        marginTop: '4px',
                                        marginLeft: '40px'
                                    }}>
                                        <Button
                                            type="button"
                                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                                            name={<Translate id="Print"/>}
                                        />
                                    </div>
                                </Header>
                                <div style={{
                                    width: "100%",
                                    marginTop: '75px'
                                }}/>
                            </div>
                            :
                            <div>
                                <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'X-report'}/></h1>
                                <div onClick={e => window.frames["x-report"].print()} style={{
                                    float: 'left',
                                    marginTop: '16px',
                                    marginLeft: '40px'
                                }}>
                                    <Button type="button" className={"mediumButton blueButton"} name={<Translate id="Print"/>}
                                            arrow="left" flencheClass={"flencheSmallLeftBottom blueFlenche"}/>
                                </div>
                                <div style={{
                                    width: "100%",
                                    borderTop: "1px solid gray",
                                    marginTop: '75px'
                                }}/>
                            </div>
                    }
                    <iframe title={'x-report'} name={'x-report'} srcDoc={reportData.htm} style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        border: "none"
                    }}/>
                </div>,
            id: reportModalID,
            className: "Administration",
            onClose: onClose,
            canClose: true
        });

    }, function () {
        return {
            type: 'GET_X_REPORT_FAILED'
        }
    });
};

const addReturnProducts = function (salesDocument, payments, selectedRows, dispatch, warehouseID, pointOfSaleID) {
    dispatch(setLoading(true));
    let productIDs = selectedRows.reduce((acc, row) => {
        acc.push(row.productID);
        return acc;
    }, []);
    dispatch(changeTransactionMode('RETURN', true));
    dispatch(findProducts({productIDs: productIDs.join(','), recordsOnPage: 1000}, warehouseID, pointOfSaleID, result => {
        console.log({result});
        let productByID = result.reduce((acc, product) => {
            acc[product.productID] = product;
            return acc;
        }, {});

        selectedRows.map(row => {
            let product = productByID[row.productID];
            if(typeof product !== "undefined"){
                let productDTO = new Product(row.itemName, row.finalNetPrice, row.finalPriceWithVAT, row.amount, product);
                productDTO.baseDocumentRow = row;
                dispatch(addProduct(productDTO));
            }
        });
        dispatch(setLoading(false));
        return setReturnBaseDocument(salesDocument, payments);
    }, result => {
        return addErrorAlert('Could not get product info from API');
    }));
};

const showRecentSales = function (posID, warehouseID, onClose, dateFormat, dispatch, numberOrCustomer, lastRecentSalesModalID, returnBaseDocument, theme) {
    dispatch(setLoading(true));
    return getRecentSales(posID, warehouseID, function (documents) {
        dispatch(setLoading(false));
        if(lastRecentSalesModalID !== undefined){
            dispatch(closeModal(lastRecentSalesModalID));
        }

        let recentSalesModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0
                }}>
                    {
                        theme === 'Erply' ?
                            <div>
                                <Header title={'Recent sales'}/>
                                <div style={{
                                    marginTop: '65px',
                                    padding: '20px'
                                }}>
                                    <label style={{
                                        marginLeft: '15px',
                                        color: '#808080',
                                        fontSize: '12px'
                                    }}>
                                        <Translate id={'Search'}/>
                                    </label>
                                    <input
                                        type={"text"}
                                        name={'recentSaleSearch'}
                                        className={"searchInputErply underlineInput"}
                                        value={numberOrCustomer || ''}
                                        autoComplete={'off'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            :
                            <div>
                                <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'Recent sales'}/></h1>
                                <input type={"text"} name={'recentSaleSearch'}
                                       className={"searchInput underlineInput"}
                                       value={numberOrCustomer || ''}
                                       autoComplete={'off'}
                                       readOnly={true}
                                />
                                <div style={{
                                    width: "100%",
                                    borderTop: "1px solid gray"
                                }}/>
                            </div>
                    }
                    <DocumentList
                        id="recentSaleList"
                        documents={documents}
                        dateFormat={dateFormat}
                        printReceipt={(document, giftReceipt = false) => {
                            dispatch(addInfoAlert('Printing receipt', false));
                            dispatch(printReceipt(document.receiptLink, giftReceipt, true, document, () => {
                                dispatch(removeAlert());
                            }));
                        }}
                        sendEmailReceipt={(document) => {
                            dispatch(showInputDialogue('Type email', '', 'Send receipt to email', (email) => {
                                dispatch(addInfoAlert('Sending email', false));
                                dispatch(sendEmailReceipt(document.id, email, function () {
                                    dispatch(addInfoAlert('Email sent!'));
                                    return {
                                        type: EMAIL_SENT
                                    }
                                }, function () {
                                    dispatch(addErrorAlert('Sending email failed!'));
                                    return {
                                        type: EMAIL_NOT_SENT
                                    }
                                }, document));
                            }, undefined, undefined, undefined, 'zIndex6'))
                        }}
                        refundReceipt={(document) => {
                            if(returnBaseDocument !== false){
                                dispatch(addErrorAlert('Return already in progress'));
                                return;
                            }
                            dispatch(setLoading(true));
                            dispatch(getSalesDocument(document.id, (salesDocument) => {
                                return getPayments(document.id, (payments) => {
                                    dispatch(setLoading(false));
                                    console.log({salesDocument, payments});
                                    let refundModal = uuid.v4();
                                    return openModal({
                                        content:
                                            <ReturnDocument
                                                document={salesDocument}
                                                onDone={(selectedProducts) => {
                                                    console.log({selectedProducts});
                                                    if(selectedProducts.length === 0){
                                                        dispatch(addErrorAlert('Please select at least one item'))
                                                    }else{
                                                        addReturnProducts(salesDocument, payments, selectedProducts, dispatch, warehouseID, posID);
                                                        dispatch(closeModal(refundModal));
                                                        dispatch(closeModal(recentSalesModalID));
                                                        dispatch(closeAdministration());
                                                    }
                                                }}
                                            />,
                                        id: refundModal,
                                        className: "Administration",
                                        onClose: () => {},
                                        canClose: true
                                    });
                                }, () => {
                                    dispatch(setLoading(false));
                                });
                            }, () => {
                                dispatch(setLoading(false));
                            }, {nonReturnedItemsOnly: 1}));
                        }}
                    />
                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: 'numbers',
                            inputFieldName: "recentSaleSearch",
                            onChangeToLetters: () => {},
                            onDone: function () {
                                const inputValue = document.getElementsByName("recentSaleSearch")[0].value;
                                dispatch(showRecentSales(posID, warehouseID, onClose, dateFormat, dispatch, inputValue, recentSalesModalID, returnBaseDocument, theme));
                            }
                        }}/>
                    </div>
                </div>,
            id: recentSalesModalID,
            className: "Administration",
            onClose: onClose,
            canClose: true
        });

    }, function () {
        dispatch(setLoading(false));
        return {
            type: 'GET_RECENT_SALES_FAILED'
        }
    }, numberOrCustomer);
};

const voucherInput = (dispatch, warehouseID, posID) => {
    let voucherModalID = uuid.v4();

    let voucherTypes = AVMB.vouchers.filter(voucherType => {
        return window.AppConf.manualVoucherInput.indexOf(voucherType.id) !== -1;
    });
    return openModal({
        content:
            <Translate>{({ translate }) =>
                <VoucherInput
                    title={'Scan or enter voucher code'}
                    voucherTypes={voucherTypes}
                    translate={translate}
                    onDone={(voucherType, parameters) => {
                        console.log('Voucher input: ', voucherType, parameters);
                        voucherType.useAction(parameters, (code) => {
                            console.log('useAction code:', code);
                            dispatch(findProduct(code, warehouseID, posID));
                            dispatch(closeModal(voucherModalID));
                        }, () => {
                            dispatch(addErrorAlert('This is not a correct voucher!'));
                        });
                    }}
                />}
            </Translate>,
        id: voucherModalID,
        className: "Administration",
        onClose: () => {},
        canClose: true
    });
};

const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        rootReducer: state.rootReducer,
        dateFormat: state.rootReducer.erplyConf.dateformat,
        translate: getTranslate(state.localize),
        operationMode: state.rootReducer.operationMode,
        mode: state.rootReducer.mode,
        returnBaseDocument: state.rootReducer.returnBaseDocument,
        theme: state.rootReducer.theme,
        pluginAdminButtons: state.rootReducer.pluginAdminButtons,
        cashierMode: state.rootReducer.cashierMode,
        mixedTransaction: mixedTransactions()
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeQCO: () => {dispatch(showDialogue('Close the application', 'closeSCOMessage', 'I confirm', function () {
            dispatch(closeQCO());
        }, function () {

        }, 'Cancel'));},
        openAddItemView: (mode) => {
            if(mode !== 'scan'){
                dispatch(addErrorAlert('Only available in scan mode!'));
                return;
            }
            dispatch(changeAdministrationView('addItem'));
        },
        openAddNotesView: (mode) => {
            if(mode !== 'scan'){
                dispatch(addErrorAlert('Only available in scan mode!'));
                return;
            }
            dispatch(changeAdministrationView('addNotes'));
        },
        openCashInOutView: () => {dispatch(changeAdministrationView('cashInOut'));},
        saveSale: (productsInBasket, customer, pos, erplyConf, user) => {
            dispatch(saveSale(productsInBasket, customer, pos, erplyConf, user, function () {

            }));
        },
        closeDay: (posID, warehouseID, dayID, translate, theme) => {
            dispatch(getPointOfSaleDayTotals(posID, function (dayTotals) {
                let dayTotalsCash = dayTotals.filter(el => {return el.paymentType === "CASH"});
                let expectedAmount = dayTotalsCash.length > 0 ? dayTotalsCash[0].expectedAmount : 0;
                expectedAmount = displayPrice(expectedAmount);

                dispatch(showDialogue('Close day', translate('Cash expected') + ': ' + expectedAmount, 'End the day', function () {
                    dispatch(POSCloseDay(posID, dayID, expectedAmount, function () {
                        dispatch({
                            type: POS_DAY_CLOSE_SUCCESS
                        });
                        return showXReport(posID, warehouseID, function () {
                            dispatch(closeQCO());
                        }, theme);
                    }, function () {
                        return {
                            type: POS_DAY_CLOSE_FAILED
                        }
                    }));
                }, function () {}, 'Cancel'));

                return {
                    type: 'GET_POS_DAY_TOTALS'
                }
            }, function () {
                return {
                    type: POS_DAY_CLOSE_FAILED
                }
            }));
        },
        switchToReturnMode: (userID, posID, hasProductsInBasket, mixedTransaction) => {
            if(hasProductsInBasket && !mixedTransaction){
                dispatch(addErrorAlert('Mixed transactions are not allowed'));
                return;
            }
            dispatch(getUserRights(userID, posID, (records) => {
                let userRights = records[0];
                if( userRights.rightMakePOSReturnsWithoutReceipt == 1 && userRights.rightMakePOSRefunds == 1){
                    dispatch(changeTransactionMode('RETURN'));
                    return closeAdministration();
                }else{
                    return addErrorAlert('User has no right to perform this action!');
                }
            }, () => {
                return addErrorAlert('Could not get user rights!');
            }));
        },
        showRecentSales: (posID, warehouseID, onClose, dateFormat, returnBaseDocument, theme) => {
            dispatch(showRecentSales(posID, warehouseID, onClose, dateFormat, dispatch, undefined, undefined, returnBaseDocument, theme));
        },
        showXReport: (posID, warehouseID, onClose, theme) => {
            dispatch(showXReport(posID, warehouseID, onClose, theme));
            dispatch({
                type: 'SHOW_X_REPORT'
            });
        },
        voucherInput: (warehouseID, posID) => {
            dispatch(voucherInput(dispatch, warehouseID, posID));
        },
        openCashDrawer: () => {
            dispatch(openCashDrawer());
        },
        editQuickProducts: () => {
            dispatch(setEditQuickProducts(true));
            dispatch(closeAdministration());
        },
        openEditUIView: () => {
            dispatch(changeAdministrationView('editUI'));
        },
        changeMode: () => {
            dispatch(closeAdministration());
            let operationModeModalID = uuid.v4();
            dispatch(openModal({
                content:
                    <ChooseOperationMode
                        onFullMode={() => {
                            dispatch(initTransaction());
                            dispatch(closeModal(operationModeModalID));
                            dispatch(changeOperationMode('full'));
                        }}
                        onLiteMode={() => {
                            dispatch(initTransaction());
                            dispatch(closeModal(operationModeModalID));
                            dispatch(changeOperationMode('lite'));
                        }}
                        onCashierMode={() => {
                            dispatch(initTransaction());
                            dispatch(closeModal(operationModeModalID));
                            dispatch(changeOperationMode('cashier'));
                        }}
                    />,
                id: operationModeModalID,
                className: "Administration",
                onClose: function () {
                    //dispatch(closeQCO());
                },
                canClose: true
            }));
        }
    }
};

class Main extends Component {
    componentDidMount() {

    }

    render() {
        const that = this;

        let ThemeImageBox = ({theme, boxClassName, image, title, onClick}) => {
            if(theme === 'Erply'){
                let width = window.innerWidth < 1200 ? '33.33%' : '25%';
                return <ImageBoxErply
                    image={image}
                    title={title}
                    boxClassName={boxClassName}
                    width={width}
                    height={'160px'}
                    padding={'12px'}
                    onClick={onClick}
                />
            }else{
                return <ImageBox
                    image={image}
                    title={title}
                    width={'20%'}
                    height={'250px'}
                    onClick={onClick}
                />
            }
        }

        let voucherCodesButton;
        let editUIButton;
        if(this.props.theme === 'Decathlon'){
            voucherCodesButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={iconVoucher}
                    title={<Translate id={'Enter voucher codes'}/>}
                    onClick={e => {
                        that.props.voucherInput(
                            this.props.rootReducer.warehouseID,
                            this.props.rootReducer.posID);
                    }}
                />
            );
        }
        if(this.props.theme === 'Erply'){
            editUIButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={iconEditUIErply}
                    title={<Translate id={'Edit UI'}/>}
                    onClick={e => {
                        that.props.openEditUIView();
                    }}
                />
            );
        }

        let cashInOutButton;
        let cashDrawerButton;
        if(this.props.operationMode === 'full'){
            cashInOutButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={this.props.theme === 'Erply' ? iconCashInOutErply: iconCashInOut}
                    boxClassName={this.props.theme === 'Erply' ? 'greenButton' : undefined}
                    title={<Translate id={'Cash in/out'}/>}
                    onClick={e => {
                        that.props.openCashInOutView();
                    }}
                />
            );
            cashDrawerButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={this.props.theme === 'Erply' ? iconCashDrawerErply : iconCashDrawer}
                    boxClassName={this.props.theme === 'Erply' ? 'redButton' : undefined}
                    title={<Translate id={'Open cash drawer'}/>}
                    onClick={e => {
                        that.props.openCashDrawer();
                    }}
                />
            );
        }

        let editQuickProductsButton;
        if(window.AppConf.posSpecificQuickSelectProducts === true){
            editQuickProductsButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={this.props.theme === 'Erply' ? iconEditProductErply : iconEditProduct}
                    title={<Translate id={'Edit quick select products'}/>}
                    onClick={e => {
                        that.props.editQuickProducts();
                    }}
                />
            );
        }

        let header;
        let scrollBarStyle;
        if(this.props.theme === 'Erply'){
            scrollBarStyle = {
                position: "absolute",
                left: 50,
                right: 50,
                top: 100,
                height: 'calc(100% - 100px)'
            };
            header =
                <div style={{
                    backgroundColor: '#CCCCCC',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '65px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <span style={{
                        fontSize: '20px',
                        marginLeft: '20px'
                    }}>
                        <Translate id={"Administrator menu"}/>
                    </span>
                </div>;
        }else{
            scrollBarStyle = {
                position: "absolute",
                left: 0,
                right:0,
                top: 65,
                height: 'calc(100% - 65px)'
            };
            header =
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '65px',
                    backgroundColor: '#FFFFFF'
                }}>
                    <h1 className={"bigUpperCaseHeading"}><Translate id={"Administration menu"}/></h1>
                </div>;
        }

        return (
            <div>
                {header}
                <PerfectScrollbar style={scrollBarStyle}>
                <div>
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconArticleErply : iconArticle}
                        boxClassName={this.props.theme === 'Erply' ? 'greenButton' : undefined}
                        title={<Translate
                        id={'Add an item'}/>}
                        onClick={e => {
                            that.props.openAddItemView(this.props.mode);
                        }}
                    />
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconNotesErply : iconNotes}
                        title={<Translate
                        id={'Add sale notes'}/>}
                        onClick={e => {
                            that.props.openAddNotesView(this.props.mode);
                        }}
                    />
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconSaveSaleErply : iconSaveSale}
                        boxClassName={this.props.theme === 'Erply' ? 'lightBlueButton' : undefined}
                        title={<Translate id={'Save sale'}/>}
                        onClick={e => {
                            that.props.saveSale(
                                this.props.rootReducer.productsInBasket,
                                this.props.rootReducer.customer,
                                this.props.rootReducer.pos,
                                this.props.rootReducer.erplyConf,
                                this.props.rootReducer.user
                            );
                        }
                    }/>
                    { cashInOutButton }
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconReturnErply : iconReturn}
                        boxClassName={this.props.theme === 'Erply' ? 'darkYellowButton' : undefined}
                        title={<Translate id={'Return mode'}/>}
                        onClick={e => {
                            that.props.switchToReturnMode(
                                that.props.administration.user.userID,
                                that.props.rootReducer.posID,
                                this.props.rootReducer.productsInBasket.length > 0,
                                this.props.mixedTransaction
                            );
                        }}
                    />
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconRecentSalesErply : iconRecentSales}
                        title={<Translate id={'Recent sales'}/>}
                        onClick={e => {
                            that.props.showRecentSales(
                                this.props.rootReducer.posID,
                                this.props.rootReducer.warehouseID,
                                function () {},
                                this.props.dateFormat,
                                this.props.returnBaseDocument,
                                this.props.theme
                            );
                        }}
                    />
                    { voucherCodesButton }
                    { cashDrawerButton }
                    { editQuickProductsButton }
                    { editUIButton }
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconCloseQCOErply: iconCloseQCO}
                        title={<Translate id={'sign off'}/>}
                        onClick={e => {
                            that.props.closeQCO();
                        }}
                    />
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconReportErply : iconReport}
                        title={<Translate id={'X-report'}/>}
                        onClick={e => {
                            that.props.showXReport(
                                this.props.rootReducer.posID,
                                this.props.rootReducer.warehouseID,
                                function () {},
                                this.props.theme
                            );
                        }}
                    />
                    {
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={iconCashierMode}
                            title={<Translate id={'Change mode'}/>}
                            onClick={that.props.changeMode}
                        />
                    }
                    {this.props.pluginAdminButtons.map((button, index) => {
                        return <ThemeImageBox
                            key={index}
                            theme={this.props.theme}
                            image={button.image}
                            title={<Translate id={button.title}/>}
                            onClick={button.onClick}
                        />
                    })}
                    <ThemeImageBox
                        theme={this.props.theme}
                        image={this.props.theme === 'Erply' ? iconCloseDayErply : iconCloseDay}
                        boxClassName={this.props.theme === 'Erply' ? 'redButton' : undefined}
                        title={<Translate id={'close SCO'}/>}
                        onClick={e => {
                            that.props.closeDay(
                                this.props.rootReducer.posID,
                                this.props.rootReducer.warehouseID,
                                this.props.rootReducer.posDayID,
                                this.props.translate,
                                this.props.theme
                            );
                        }}
                    />
                </div>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Main));