import * as uuid from "uuid";

export const ED = {
    port: false,
    url: 'wss://localhost.erply.com',
    available: false,
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('ED: ');
        console.log.apply(this, newArgs);
    },
    init: (port) => {
        ED.port = port;
        ED.openWebSocket().then(() => {

        });
    },
    openWebSocket: function () {
        return new Promise(function (resolve, reject) {
            ED.websocket = new WebSocket(ED.url + ':' + ED.port);
            ED.websocket.onopen = function () {
                ED.log("websocket onopen");
                ED.available = true;
                resolve();
            };
            ED.websocket.onmessage = function (response) {
                ED.log("websocket: Message is received:", response.data);
                const data = JSON.parse(response.data);
                if(data.function === 'ScaleWeight'){
                    ED.onScaleWeight(data);
                }
            };
            ED.websocket.onclose = function () {
                ED.log("websocket onclose");
                delete ED.websocket;
                ED.available = false;
            }
        });
    },
    onScaleWeight: () => {},
    scaleWeight: (resolve) => {
        ED.log('scaleWeight');
        if(typeof ED.websocket === "undefined"){
            resolve({
                statusMessage: 'Scale integration is not running'
            });
            return;
        }
        const id = uuid.v4();
        ED.onScaleWeight = resolve;
        ED.websocket.send(JSON.stringify({
            function: "ScaleWeight",
            id
        }));
    }
};

window.ED = ED;