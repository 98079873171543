export default function () {
    this.CreditOrDebit = "";
    this.aid = "";
    this.applicationLabel = "";
    this.approvedAmount = "";
    this.authCode = "";
    this.cardHolder = "";
    this.cardNumber = "";
    this.cardType = "";
    this.cryptogram = "";
    this.cryptogramType = "";
    this.expirationDate = "";
    this.paidWithExternal = "";
    this.pinStatement = "";
    this.refNo = "";
    this.safNumber = "";
    this.signature = "";
    this.signatureIV = "";
    this.signatureNotRequired = "";
    this.sum = "";
    this.transactionId = "";
    this.handled = false;
}