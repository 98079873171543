import React, {Component} from 'react';
import "./main.css"
import LeftPanel from "./LeftPanel";
import RightPanel from "./RighPanel";
import ScannerDetect from "./ScannerDetect";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import Done from "./done/Done";
import ScreenSaver from "../screenSaver/ScreenSaver";
import RFID from "./rfid/RFID";
import {APP_MODE_DONE, APP_MODE_SCREEN_SAVER, APP_VIEW_APP} from "../redux/appConstants";
import Dialogue from "./Dialogue";
import Administration from "./administration/Administration";
import Peripherals from "./Peripherals";
import CallAssistance from "./administration/CallAssistance";
import ModalContainer from "./ModalContainer";

const mapStateToProps = state => {
    return {
        view: state.rootReducer.view,
        mode: state.rootReducer.mode,
        transactionMode: state.rootReducer.transactionMode,
        cashierMode: state.rootReducer.cashierMode,
        redirect: state.rootReducer.redirect,
        erplyConf: state.rootReducer.erplyConf,
        companyInfo: state.rootReducer.companyInfo,
        reasonCodes: state.rootReducer.reasonCodes,
        vatRates: state.rootReducer.vatRates,
        paymentTypes: state.payment.paymentTypes,
        pos: state.rootReducer.pos,
        posDayID: state.rootReducer.posDayID,
        currency: state.rootReducer.currency,
        employees: state.rootReducer.employees,
        uiCustomization: state.rootReducer.uiCustomization
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

class Main extends Component{
    render() {
        if(this.props.view !== APP_VIEW_APP){
            return <Redirect to={this.props.view}/>
        }
        let components;
        if(this.props.mode === APP_MODE_SCREEN_SAVER){
            components = (
                <div>
                    <ScreenSaver/>
                </div>
            );
        }else if(this.props.mode === APP_MODE_DONE){
            components = <Done/>;
        }else {
            components = (
                <div>
                    <LeftPanel/>
                    <RightPanel/>
                </div>
            );
        }
        let rfid;
        if(process.env.REACT_APP_ERPLY_MODE !== "1" && window.AppConf.enableRFID !== false){
            rfid = <RFID/>;
        }
        return (
            <div style={{
                height: '100%',
                backgroundColor: this.props.uiCustomization.colors.mainBackground
            }}>
                <Peripherals/>
                <Administration/>
                <CallAssistance/>
                <Dialogue/>
                <ModalContainer/>
                <ScannerDetect/>
                { rfid }
                { components }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
