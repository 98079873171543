export const timers = {
    timers: {},
    addTimeout: function(name, timeoutID){
        this.timers[name] = timeoutID;
    },
    getTimeoutID: function(name){
        return this.timers[name] || false;
    },
    setTimeout: function(name, handler, timeout, clearLast = false){
        let timeoutID = window.setTimeout(function(){
                handler();
            }, timeout * 1000);
        if(clearLast){
            this.removeTimeout(name);
        }
        this.addTimeout(name, timeoutID);

    },
    removeTimeout: function(name){
        let timeoutID = this.getTimeoutID(name);
        if(timeoutID !== false){
            clearTimeout(timeoutID);
            delete this.timers[name];
        }
    }
};