import transaction from "./transaction";

export default function (index, sum, status, type) {
    this.index = index;
    this.sum = sum;
    this.sumPaid = 0;
    this.cashPaid = 0;
    this.cashChange = 0;
    /**
     * started
     * success
     * failed
     * checked
     */
    this.status = status;
    this.receipts = {};
    this.transaction = new transaction();
    this.transactionId = "";
    this.type = type;
    this.serialNumber = "";
    this.documentNotes = "";
    this.title = type;
    this.currencyCode = null;

    this.attributes = [];
    this.addAttribute = (name,value,type='text') => {
        this.attributes.push({name,type,value});
    }

    this.documentAttributes = [];
    this.addDocumentAttribute = (name,value,type='text') => {
        this.documentAttributes.push({name,type,value});
    }
}