import React from "react";
import Button from "../buttons/Button";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../redux/actions";

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());
    }
    let enterDetailsButton = (
        <div onClick={e => {
            props.enterDetails();
        }}>
            <Button type="button" className={"largeButton blueButton"} name={<Translate id="Enter details"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
        </div>
    );
    let askForHelpButton = (
        <div onClick={e => {
            props.askForHelp();
        }}>
            <Button type="button" className={"largeButton grayButton"} name={<Translate id="Ask for assistance"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom grayFlenche"}/>
        </div>
    );

    return (
        <div style={{
            textAlign: "center",
            padding: "30px",
            paddingTop: "180px"
        }}>
            <span className={"boldText"}>
                <Translate id={"traceabilityMessage"}/>
            </span>
            <br/>
            <div style={{
                display: "block",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "400px"
            }}>
                { enterDetailsButton }
                <br/>
                <div style={{
                    "marginBottom": "50px"
                }}/>
                <div style={{
                    width: "280px",
                    margin: "0 auto"
                }}>
                    <div className={"diagonalLine"} style={{
                        float: "left",
                        marginTop: "38px"
                    }}/>
                    <span style={{
                        textTransform: "uppercase",
                        fontStyle: "italic",
                        color: "#0082C3",
                        fontSize: "20px"
                    }}>
                            <Translate id="or"/>
                        </span>
                    <div className={"diagonalLine"} style={{
                        float: "right",
                        marginTop: "10px"
                    }}/>
                </div>
                <div style={{
                    "marginBottom": "85px"
                }}/>
                { askForHelpButton }
            </div>
        </div>
    );
}
