import React, {useState} from 'react';
import {Translate} from "react-localize-redux";
import Keyboard from "../../keyboard/Keyboard";

export default function ({onDone}) {
    let [inputValue, changeInput] = useState('');

    return (
        <div style={{
            position: "absolute",
            left: 0,
            right:0,
            top: 0,
            bottom: 0
        }}>
            <h1 style={{marginLeft: '20px'}}><Translate id={'This action needs supervisor confirmation!'}/></h1>
            <div style={{
                width: "100%",
                borderTop: "1px solid gray",
                marginBottom: "10px"
            }}/>
            <input
                type={"password"}
                name={ 'pinInput' }
                className={"underlineInput"}
                autoComplete={'off'}
                placeholder={'PIN'}
                value={inputValue || ''}
                onChange={e => {
                    changeInput(e.target.value);
                }}
            />
            <div style={{
                "position": "absolute",
                "bottom": "0",
                "left": "0",
                "right": "0"
            }}>
                <Keyboard options={{
                    initialLayout: 'numbers',
                    /*onClick: (key) => {
                        let oldString = inputValue || '';
                        let newString = key === 'delete' ? oldString.slice(0, -1) : oldString + key;
                        changeInput(newString);
                    },*/
                    currentValue: inputValue,
                    updateValue: changeInput,
                    onDone: () => {
                        onDone(inputValue);
                    }
                }}/>
            </div>
        </div>
    );
}