import React, {useState} from 'react';
import {Translate} from "react-localize-redux";
import Keyboard from "../keyboard/Keyboard";

export default function ({voucherTypes, translate, onDone}) {
    if(voucherTypes.length === 0){
        return false;
    }
    let [selectedType, selectChange] = useState(voucherTypes[0]);
    let [inputValues, changeInput] = useState({});
    let [activeInput, changeActiveInput] = useState('');

    console.log('AVMB: inpuValues', inputValues, 'active: ', activeInput);

    let options = voucherTypes.map((type, index) => {
        return <option key={index} value={ type.id }>{ translate(type.name) }</option>;
    });

    let inputs = selectedType.inputFields.map((field, index) => {
        return (
            <input
                   key={index}
                   type={"text"}
                   name={ field }
                   className={"underlineInput"}
                   autoComplete={'off'}
                   placeholder={translate(field)}
                   value={inputValues[field] || ''}
                   onChange={e => {
                       let newState = {};
                       newState[field] = e.target.value;
                       changeInput(Object.assign({}, inputValues, newState));
                   }}
                   onClick={e => changeActiveInput(field)}
            />
        );
    });

    return (
        <div style={{
            position: "absolute",
            left: 0,
            right:0,
            top: 0,
            bottom: 0
        }}>
            <h1 style={{marginLeft: '20px'}}><Translate id={'Scan or enter voucher code'}/></h1>
            <div style={{
                width: "100%",
                borderTop: "1px solid gray",
                marginBottom: "10px"
            }}/>
            <div className={'voucherInputs'}>
                <select value={selectedType.id} onChange={e => {
                    let newVoucherType = voucherTypes.find(voucherType => voucherType.id === e.target.value);
                    selectChange(newVoucherType);
                    console.log('AVMB', newVoucherType);
                }} className={'fullWidth selectInput'}>
                    { options }
                </select>
                { inputs }
            </div>
            <div style={{
                "position": "absolute",
                "bottom": "0",
                "left": "0",
                "right": "0"
            }}>
                <Keyboard options={{
                    initialLayout: 'numbers',
                    onClick: (key) => {
                        let oldString = inputValues[activeInput] || '';
                        let newState = {};
                        newState[activeInput] = key === 'delete' ? oldString.slice(0, -1) : oldString + key;
                        changeInput(Object.assign({}, inputValues, newState));
                    },
                    // onChangeToLetters: () => {},
                    onDone: function () {
                        onDone(selectedType, inputValues);
                    }
                }}/>
            </div>
        </div>
    );
}