import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    FIND_PRODUCT,
    SET_USER,
    SET_POS_ID,
    SET_POS_DATA,
    SET_WAREHOUSE,
    SET_ERPLY_CONF,
    INIT_TRANSACTION,
    CHANGE_VIEW,
    CHANGE_MODE,
    SET_DEFAULT_CUSTOMER,
    SET_PAYMENT_MODE,
    CHANGE_TRANSACTION_SAVE_STATUS,
    INCREMENT_NEXT_INVOICE_NO,
    UPDATE_PRODUCTS,
    TRANSACTION_SUCCESS,
    SET_CUSTOMER,
    CLOSE_DIALOGUE,
    SHOW_DIALOGUE,
    OPEN_ADMINISTRATION_VIEW,
    CLOSE_ADMINISTRATION_VIEW,
    SET_EMPLOYEES,
    SET_ADMIN_USER,
    ADD_PERIPHERAL,
    UPDATE_PERIPHERAL,
    CHANGE_ADMINISTRATION_VIEW,
    SHOW_CALL_ASSISTANCE,
    CLOSE_CALL_ASSISTANCE,
    REMOVE_SECURITY_ITEMS,
    SET_CUSTOMER_AGE_VERIFIED,
    SHOW_RIGHT_PANEL_DIALOGUE,
    CLOSE_RIGHT_PANEL_DIALOGUE,
    ADD_UNKNOWN_RFID_PRODUCT,
    REMOVE_UNKNOWN_RFID_PRODUCT,
    UPDATE_PRODUCT,
    SET_REASON_CODES,
    ADD_ALERT,
    REMOVE_ALERT,
    CHANGE_TRANSACTION_MODE,
    ADD_PRODUCT_RETURN_REASON_ID,
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_POS_DAY_OPENED,
    SET_VAT_RATES,
    ADD_DOCUMENT_DISCOUNT,
    ADD_PRODUCT_DISCOUNT_APPROVAL,
    ADD_DOCUMENT_DISCOUNT_APPROVAL,
    REMOVE_PERIPHERAL,
    CLOSE_ALL_MODALS,
    SET_PLUGIN_URLS,
    SET_FISCAL_RECEIPT_REQUIRED,
    SET_COMPANY_INFO,
    SET_MIXED_TRANSACTION,
    SET_PLUGIN_REQUEST_PARAMETERS,
    SET_LOADING,
    SET_CUSTOMER_SELECT_LIST,
    SET_PRODUCT_SELECT_LIST,
    SET_PRODUCT_GROUPS,
    SET_PRODUCT_CATEGORIES,
    SET_QUICK_POS_PRODUCTS,
    UPDATE_PRODUCT_GROUP_PRODUCTS,
    SET_OPERATION_MODE,
    SET_DELAY_RECEIPT,
    CLOSE_ALL_DIALOGUES,
    SET_EDIT_QUICK_SELECT_PRODUCTS,
    ADD_QUICK_POS_PRODUCT,
    SET_QUICK_POS_PRODUCTS_ORDER,
    SET_UI_CUSTOMIZATION,
    SET_POS_UI_CUSTOMIZATION,
    SET_ERPLY_SERVICE_ENDPOINTS,
    SET_LAST_SCANNED_GIFT_CARD,
    ADD_BEFORE_COMPLETE_TRANSACTION_ACTION,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION,
    ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    ADD_RECEIPT_GIFT_CARD,
    UPDATE_PRODUCT_GIFT_CARD_NUMBER,
    ADD_PLUGIN_REQUEST_SALE_NOTES,
    REMOVE_PLUGIN_REQUEST_SALE_NOTE,
    SET_RETURN_BASE_DOCUMENT,
    SET_ADD_RECEIPT_TITLE,
    ADD_RECEIPT_FISCAL_INFO,
    REMOVE_DOCUMENT_DISCOUNT,
    UPDATE_USER_SESSION,
    REMOVE_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    ADD_PLUGIN_ADMIN_BUTTON,
    UPDATE_RETURN_BASE_DOCUMENT_PAYMENT,
    SET_PAYBACK_CUSTOMER,
    ADD_PAYBACK_COUPON,
    REMOVE_PAYBACK_COUPON,
    REMOVE_PAYBACK_CUSTOMER,
    SET_PAYBACK_INPUT_DONE,
    ADD_RECEIPT_EXTRA_LINE,
    REMOVE_RECEIPT_EXTRA_LINE,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE,
    SET_PAYBACK_REFERENCE_RECEIPT,
    ADD_MANUAL_PROMOTION_ID,
    REMOVE_MANUAL_PROMOTION_ID,
    ADD_CAMPAIGN_BY_NAME,
    SET_NEXT_SEQUENCE_NUMBER,
    REMOVE_CUSTOMER,
    SET_RIGHT_PANEL_FULLSCREEN
} from "../actionTypes";
import Customer from "../dto/customer"
import {reassignProduct} from "../dto/product";
import {getCurrencySign, getProductGroupByID} from "../selectors";
import {
    APP_DOCUMENT_SAVE_STATUS_START,
    APP_MODE_SCAN,
    APP_MODE_SCREEN_SAVER,
    APP_VIEW_LOGIN,
    PAYMENT_MODE_OPTIONS
} from "../appConstants";
import * as uuid from "uuid";

const initialState = {
    loading: false,
    loaderCount: 0,
    erplyConf: false,
    erplyServiceEndpoints: false,
    companyInfo: false,
    reasonCodes: false,
    vatRates: false,
    productGroups: false,
    productCategories: false,
    quickPosProducts: {
        data: {},
        order: [],
        saved: false
    },
    editQuickPosProducts: false,
    uiCustomization: {
        saved: false,
        basketHeader: {
            color: '#feea40'
        },
        screenSaver: {
            image: 'default',
            text: {}
        },
        fonts: {
            quickPosProducts:{
                size: '12px'
            }
        },
        statusBarSteps: {
            display: 1
        },
        colors: {
            mainBackground: '#FFF'
        }
    },
    uiCustomizationPOS: {
        saved: false,
        paymentMethods: {
            card: 1,
            giftCard: 1,
            cash: 1
        },
        shoppingBag: {
            display: 1,
            code: ''
        },
        printing: {
            printDefault: 1
        }
    },
    posID: 0,
    pos: false,
    posDayID: false,
    warehouse: false,
    user: false,
    productsInBasket: [],
    unknownRFIDProducts: [],
    currency: "",
    view: APP_VIEW_LOGIN,
    redirect: false,
    mode: APP_MODE_SCREEN_SAVER,
    paymentMode: PAYMENT_MODE_OPTIONS,
    operationMode: 'full',
    cashierMode: false,
    customer: false,
    customerSelectList: [],
    productSelectList: [],
    customerAgeVerified: -1,
    documentSaveStatus: APP_DOCUMENT_SAVE_STATUS_START,
    nextInvoiceNo: false,
    lastInvoice: false,
    currentLineNumber: 0,
    dialogues: [],
    modals: [],
    rightPanelDialogue: false,
    rightPanelFullScreen: false,
    leftPanelFullScreen: false,
    administration: false,
    callAssistance: false,
    employees: false,
    peripherals: [],
    alert: false,
    transactionMode: 'SALE',
    discount: {},
    mixedTransaction: window?.AppConf?.mixedTransaction ?? true,
    pluginRequestParameters: {},
    plugins: window?.AppConf?.plugins ?? [],
    delayReceipt: false,
    lastScannedGiftCard: false,
    beforeCompleteTransactionActions: [],
    receiptGiftCards: [],
    returnBaseDocument: false,
    addReceiptTitle: false,
    receiptFiscalInfo: false,
    receiptExtraLines: [],
    pluginAdminButtons: [],
    payback: {
        customer: false,
        coupons: [],
        inputDone: false
    },
    manualPromotionIDs: [],
    campaignsByName: {},
    nextSequenceNumber: false,
    theme: process.env.REACT_APP_ERPLY_MODE === "1" ? "Erply" : "Decathlon"
};

function rootReducer(state = initialState, action) {
    console.log('rootReducer: ' + action.type, state, action.payload);
    switch (action.type) {
        case SET_PLUGIN_URLS: {
            return Object.assign({}, state, {
                plugins: action.payload
            });
        }
        case SET_MIXED_TRANSACTION: {
            return Object.assign({}, state, {
                mixedTransaction: action.payload
            });
        }
        case SET_PLUGIN_REQUEST_PARAMETERS: {
            return Object.assign({}, state, {
                pluginRequestParameters: action.payload
            });
        }
        case ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES: {
            let pluginRequestParameters = Object.assign({}, state.pluginRequestParameters);
            if(typeof pluginRequestParameters.saveSalesDocument === "undefined"){
                pluginRequestParameters.saveSalesDocument = {};
            }
            if(typeof pluginRequestParameters.saveSalesDocument.attributes === "undefined"){
                pluginRequestParameters.saveSalesDocument.attributes = [];
            }
            for(let attribute of action.attributes){
                pluginRequestParameters.saveSalesDocument.attributes.push(attribute);
            }

            return Object.assign({}, state, {pluginRequestParameters});
        }
        case REMOVE_PLUGIN_REQUEST_SALE_ATTRIBUTES: {
            let pluginRequestParameters = Object.assign({}, state.pluginRequestParameters);
            if(typeof pluginRequestParameters.saveSalesDocument === "undefined") return state;
            if(typeof pluginRequestParameters.saveSalesDocument.attributes === "undefined") return state;
            pluginRequestParameters.saveSalesDocument.attributes = pluginRequestParameters.saveSalesDocument.attributes.filter((attribute) => {
                return action.attributes.indexOf(attribute.name) === -1;
            });
            return Object.assign({}, state, {pluginRequestParameters});
        }
        case ADD_PLUGIN_REQUEST_SALE_NOTES: {
            let pluginRequestParameters = Object.assign({}, state.pluginRequestParameters);
            if(typeof pluginRequestParameters.saveSalesDocument === "undefined"){
                pluginRequestParameters.saveSalesDocument = {};
            }
            if(typeof pluginRequestParameters.saveSalesDocument.notes === "undefined"){
                pluginRequestParameters.saveSalesDocument.notes = [];
            }
            for(let note of action.notes){
                pluginRequestParameters.saveSalesDocument.notes.push(note);
            }

            return Object.assign({}, state, {pluginRequestParameters});
        }
        case REMOVE_PLUGIN_REQUEST_SALE_NOTE: {
            let pluginRequestParameters = Object.assign({}, state.pluginRequestParameters);
            if(typeof pluginRequestParameters?.saveSalesDocument?.notes === "undefined"){
                return state;
            }
            pluginRequestParameters.saveSalesDocument.notes.splice(action.noteIndex, 1);
            return Object.assign({}, state, {pluginRequestParameters});
        }
        case SET_LOADING: {
            let nextLoaderCount = state.loaderCount + (action.payload ? 1 : -1);
            if(nextLoaderCount > 0){
                return Object.assign({}, state, {
                    loading: true,
                    loaderCount: nextLoaderCount
                });
            }else{
                return Object.assign({}, state, {
                    loading: action.payload,
                    loaderCount: 0
                });
            }
        }
        case ADD_PRODUCT: {
            let product = action.payload;
            if(product.getFlags === undefined){
                product = reassignProduct(action.payload);
            }
            product.lineNumber = ++state.currentLineNumber;
            const productsInBasket = state.productsInBasket.concat(action.payload);
            return Object.assign({}, state, {
                productsInBasket: productsInBasket
            });
        }
        case UPDATE_PRODUCTS: {
            console.log('UPDATE_PRODUCTS', action.payload);
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.slice().map((product, index) => {
                    if(typeof action.payload.rows[index] === 'undefined') return product;
                    if(product.getFlags === undefined){
                        product = reassignProduct(product);
                    }
                    let promotionProduct = action.payload.rows[index];
                    product.netPrice = promotionProduct.finalPrice;
                    product.vatPrice = promotionProduct.finalPriceWithVAT;
                    product.originalPrice = promotionProduct.originalPrice;
                    product.originalPriceWithVAT = promotionProduct.originalPriceWithVAT;
                    product.rowNetTotal = promotionProduct.rowNetTotal;
                    product.rowTotal = promotionProduct.rowTotal;
                    product.rowVAT = promotionProduct.rowVAT;
                    product.data.vatrateID = promotionProduct.vatrateID;
                    product.data.vatrate = promotionProduct.vatRate;
                    product.discount = promotionProduct.discount;

                    if(promotionProduct.manualDiscount !== 0){
                        product.manualDiscountPercentage = promotionProduct.manualDiscount;
                        product.manualDiscountFinalPrice = promotionProduct.manualDiscountPriceWithVAT * product.amount;
                        product.manualDiscountUnitPrice = promotionProduct.manualDiscountPriceWithVAT;
                        let originalRowTotal = product.getOriginalRowTotal();
                        product.manualDiscountTotal = product.getManualDiscountTotal(originalRowTotal);
                    }

                    /*promotionProduct.discount;
                    promotionProduct.manualDiscountPriceWithVAT;
                    promotionProduct.manualDiscountReasonCodeID;
                    promotionProduct.nonDiscountable;
                    promotionProduct.originalDiscount;
                    promotionProduct.promotionDiscount;
                    promotionProduct.promotionPrice;
                    promotionProduct.promotionPriceWithVAT;
                    promotionProduct.promotion;*/

                    product.promotionRules = Object.keys(promotionProduct).reduce((acc, key) => {
                        if(key.indexOf('promotionRule') !== -1){
                            acc.push({
                                name: key,
                                value: promotionProduct[key]
                            });
                        }
                        return acc;
                    }, []);

                    return product;
                })
            });
        }
        case UPDATE_PRODUCT: {
            console.log('updateProduct: ', action.payload);
            const id= action.payload.lineNumber;
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.map(product => {
                    if(product.getFlags === undefined){
                        product = reassignProduct(action.payload);
                    }
                    if(product.lineNumber === id){
                        return action.payload;
                    }else{
                        return product;
                    }
                })
            });
        }
        case REMOVE_PRODUCT: {
            let ids = [action.payload.lineNumber];
            if(action.payload._isContainer){
                ids.push(action.payload.containerForProductLine);
            }else if(action.payload.hasContainer){
                let containerProduct = state.productsInBasket.find(el => el.containerForProductLine === action.payload.lineNumber);
                if(typeof containerProduct !== "undefined"){
                    ids.push(containerProduct.lineNumber);
                }
            }
            const newState = Object.assign({}, state, {
                productsInBasket: state.productsInBasket.filter(product => {
                    return !ids.includes(product.lineNumber);
                })
            });

            console.log('Product removed', newState);
            return newState;
        }
        case ADD_PRODUCT_RETURN_REASON_ID: {
            const id= action.payload.lineNumber;
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.map(product => {
                    if(product.lineNumber === id){
                        product.returnReasonID = action.payload.returnReason.reasonID;
                        product.returnReason = action.payload.returnReason;
                        return product;
                    }else{
                        return product;
                    }
                })
            });
        }
        case UPDATE_PRODUCT_GIFT_CARD_NUMBER: {
            const id= action.payload.lineNumber;
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.map(product => {
                    if(product.lineNumber === id){
                        product.giftCardCode = action.payload.number;
                        product.name = product.name.substr(0,product.name.length - 16) + action.payload.number.substr(0, 12) + "----";
                        if(typeof product.data.RFIDTag !== "undefined"){
                            product.data.RFIDTag.SERIAL_NUMBER = action.payload.number;
                        }
                        return product;
                    }else{
                        return product;
                    }
                })
            });
        }
        case ADD_PRODUCT_DISCOUNT_APPROVAL: {
            const id= action.payload.lineNumber;
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.map(product => {
                    if(product.lineNumber === id){
                        product.discountApproval = action.payload.discountApproval;
                        return product;
                    }else{
                        return product;
                    }
                })
            });
        }
        case FIND_PRODUCT: {
            const code = action.payload;
            console.log('Finding product ', code);
            return state;
        }
        case SET_PRODUCT_SELECT_LIST: {
            return Object.assign({}, state, {
                productSelectList: action.payload
            });
        }
        case ADD_UNKNOWN_RFID_PRODUCT: {
            return Object.assign({}, state, {
                unknownRFIDProducts: state.unknownRFIDProducts.concat(action.payload)
            });
        }
        case REMOVE_UNKNOWN_RFID_PRODUCT: {
            return Object.assign({}, state, {
                unknownRFIDProducts: state.unknownRFIDProducts.filter(product => {
                    return product.GTIN !== action.payload.GTIN;
                })
            });
        }
        case REMOVE_SECURITY_ITEMS: {
            return state;
        }
        case SET_USER: {
            const user = action.payload;
            console.log('User set: ', user);
            return Object.assign({}, state, {
                user: user
            });
        }
        case UPDATE_USER_SESSION: {
            const sessionKey = action.sessionKey;
            console.log('Update session key: ', sessionKey);
            let user = state.user;
            user.sessionKey = sessionKey;
            return Object.assign({}, state, {
                user
            });
        }
        case SET_POS_ID: {
            console.log('POSID set: ', action);
            return Object.assign({}, state, {
                posID: action.payload
            });
        }
        case SET_POS_DATA: {
            console.log('POS Data set: ', action);
            const nextInvoiceNo = parseInt(action.payload.lastInvoiceNo) + 1;
            let pos = action.payload;
            pos.fiscalReceiptRequired = false;
            return Object.assign({}, state, {
                pos,
                nextInvoiceNo: nextInvoiceNo
            });
        }
        case SET_FISCAL_RECEIPT_REQUIRED: {
            let pos = state.pos;
            pos.fiscalReceiptRequired = action.payload;

            return Object.assign({}, state, {
                pos
            });
        }
        case SET_POS_DAY_OPENED: {
            return {
                ...state,
                posDayID: action.payload
            }
        }
        case SET_WAREHOUSE: {
            return Object.assign({}, state, {
                warehouse: action.payload
            });
        }
        case SET_ERPLY_CONF: {
            const currencySign = getCurrencySign(action.payload.default_currency);
            return Object.assign({}, state, {
                erplyConf: action.payload,
                currency: currencySign
            });
        }
        case SET_ERPLY_SERVICE_ENDPOINTS: {
            return Object.assign({}, state, {
                erplyServiceEndpoints: action.payload
            });
        }
        case SET_COMPANY_INFO: {

            return Object.assign({}, state, {
                companyInfo: action.payload,
            });
        }
        case SET_REASON_CODES: {
            return Object.assign({}, state, {
                reasonCodes: action.payload
            });
        }
        case SET_VAT_RATES: {
            return Object.assign({}, state, {
                vatRates: action.payload
            });
        }
        case SET_PRODUCT_GROUPS: {
            return Object.assign({}, state, {
                productGroups: action.payload
            });
        }
        case SET_PRODUCT_CATEGORIES: {
            return Object.assign({}, state, {
                productCategories: action.payload
            });
        }
        case UPDATE_PRODUCT_GROUP_PRODUCTS: {
            let productGroups = [...state.productGroups];
            let productGroup = getProductGroupByID(productGroups, action.productGroupID);
            productGroup.products = action.products;
            productGroup.productsPageNo = action.productsPageNo;
            return Object.assign({}, state, {productGroups});
        }
        case SET_QUICK_POS_PRODUCTS: {
            return Object.assign({}, state, {
                quickPosProducts: action.payload
            });
        }
        case SET_QUICK_POS_PRODUCTS_ORDER: {
            let quickPosProducts = Object.assign({}, state.quickPosProducts);
            quickPosProducts.order = action.payload;
            return Object.assign({}, state, {
                quickPosProducts
            });
        }
        case ADD_QUICK_POS_PRODUCT: {
            let product = action.payload;

            let quickPosProducts = Object.assign({}, state.quickPosProducts);
            if(state.quickPosProducts.order.length === 0){
                quickPosProducts.order = [product.productID];
                quickPosProducts.data[product.productID] = product;
            }else{
                quickPosProducts.order.push(product.productID);
                quickPosProducts.data[product.productID] = product;
            }
            return Object.assign({}, state, {
                quickPosProducts
            });
        }
        case SET_EDIT_QUICK_SELECT_PRODUCTS: {
            return Object.assign({}, state, {
                editQuickPosProducts: action.edit
            });
        }
        case SET_UI_CUSTOMIZATION: {
            return Object.assign({}, state, {
                uiCustomization: {
                    ...state.uiCustomization,
                    ...action.payload
                }
            });
        }
        case SET_POS_UI_CUSTOMIZATION: {
            return Object.assign({}, state, {
                uiCustomizationPOS: {
                    ...state.uiCustomizationPOS,
                    ...action.payload
                }
            });
        }
        case INIT_TRANSACTION: {
            return Object.assign({}, state, {
                productsInBasket: [],
                mode: APP_MODE_SCREEN_SAVER,
                transactionMode: 'SALE',
                customer: false,
                customerSelectList: [],
                productSelectList: [],
                customerAgeVerified: -1,
                paymentMode: PAYMENT_MODE_OPTIONS,
                documentSaveStatus: APP_DOCUMENT_SAVE_STATUS_START,
                currentLineNumber: 0,
                rightPanelDialogue: false,
                lastInvoice: false,
                discount: {},
                pluginRequestParameters: {},
                beforeCompleteTransactionActions: [],
                receiptGiftCards: [],
                returnBaseDocument: false,
                receiptFiscalInfo: false,
                receiptExtraLines: [],
                payback: {
                    customer: false,
                    coupons: [],
                    inputDone: false
                },
                manualPromotionIDs: [],
                nextSequenceNumber: false
            });
        }
        case TRANSACTION_SUCCESS: {
            return {
                ...state,
                lastInvoice: {
                    number: action.payload.invoiceNo,
                    id: action.payload.invoiceID,
                    receiptLink: action.payload.receiptLink
                }
            }

        }
        case CHANGE_TRANSACTION_SAVE_STATUS: {
            return Object.assign({}, state, {
                documentSaveStatus: action.payload
            });
        }
        case CHANGE_TRANSACTION_MODE: {
            return Object.assign({}, state, {
                transactionMode: action.payload
            });
        }
        case SET_NEXT_SEQUENCE_NUMBER: {
            return Object.assign({}, state, {
                nextSequenceNumber: action.payload
            });
        }
        case INCREMENT_NEXT_INVOICE_NO: {
            const nextInvoiceNo = state.nextInvoiceNo + 1;
            return Object.assign({}, state, {
                nextInvoiceNo: nextInvoiceNo
            });
        }
        case CHANGE_VIEW: {
            return Object.assign({}, state, {
                view: action.payload.view,
                redirect: true
            })
        }
        case CHANGE_MODE: {
            let leftPanelFullScreen = action.payload.mode === "scan" && state.cashierMode;
            return Object.assign({}, state, {
                mode: action.payload.mode,
                leftPanelFullScreen
            });
        }
        case SET_DEFAULT_CUSTOMER: {
            const customer = new Customer();
            customer.default = true;
            return Object.assign({}, state, {
                customer
            });
        }
        case SET_PAYMENT_MODE: {
            return Object.assign({}, state, {
                paymentMode: action.payload.mode
            });
        }
        case SET_OPERATION_MODE: {
            if(action.mode === 'cashier'){
                return Object.assign({}, state, {
                    operationMode: 'full',
                    cashierMode: true
                });
            }
            return Object.assign({}, state, {
                operationMode: action.mode,
                cashierMode: false
            });
        }
        case SET_CUSTOMER: {
            return Object.assign({}, state, {
                customer: action.payload
            });
        }
        case REMOVE_CUSTOMER: {
            if(state.mode === APP_MODE_SCAN || state.mode === APP_MODE_SCREEN_SAVER){
                return Object.assign({}, state, {
                    customer: false
                });
            }else{
                const customer = new Customer();
                customer.default = true;
                return Object.assign({}, state, {
                    customer
                });
            }
        }
        case SET_CUSTOMER_AGE_VERIFIED: {
            return Object.assign({}, state, {
                customerAgeVerified: action.payload
            });
        }
        case SET_CUSTOMER_SELECT_LIST: {
            return Object.assign({}, state, {
                customerSelectList: action.payload
            });
        }
        case SHOW_DIALOGUE: {
            if(typeof action.payload.id === "undefined"){
                action.payload.id = uuid.v4();
            }
            return {
                ...state,
                dialogues: state.dialogues.concat(action.payload)
            };
        }
        case CLOSE_DIALOGUE: {
            return {
                ...state,
                dialogues: state.dialogues.filter(item => item.id !== action.payload),
            };
        }
        case CLOSE_ALL_DIALOGUES: {
            return {
                ...state,
                dialogues: [],
            };
        }
        case OPEN_MODAL: {
            return {
                ...state,
                modals: state.modals.concat(action.payload)
            };
        }
        case CLOSE_MODAL: {
            return {
                ...state,
                modals: state.modals.filter(item => item.id !== action.payload),
            };
        }
        case CLOSE_ALL_MODALS: {
            return {
                ...state,
                modals: [],
            };
        }
        case SHOW_RIGHT_PANEL_DIALOGUE: {
            return Object.assign({}, state, {
                rightPanelDialogue: action.payload,
                leftPanelFullScreen: false
            });
        }
        case CLOSE_RIGHT_PANEL_DIALOGUE: {
            let leftPanelFullScreen = state.mode === APP_MODE_SCAN && state.cashierMode;
            return Object.assign({}, state, {
                rightPanelDialogue: false,
                leftPanelFullScreen
            });
        }
        case SET_RIGHT_PANEL_FULLSCREEN: {
            return Object.assign({}, state, {
                rightPanelFullScreen: action.payload
            });
        }
        case OPEN_ADMINISTRATION_VIEW: {
            let user = state.cashierMode ? state.user : state.administration !== false ? state.administration.user : false;
            let view = action.payload.view;
            if(view === "logon" && state.cashierMode){
                view = "main";
            }
            return Object.assign({}, state, {
                administration: {
                    view,
                    user: user,
                    canClose: action.payload.canClose,
                    onClose: action.payload.onClose
                }
            });
        }
        case CLOSE_ADMINISTRATION_VIEW: {
            return Object.assign({}, state, {
                administration: false
            });
        }
        case CHANGE_ADMINISTRATION_VIEW: {
            return Object.assign({}, state, {
                administration: {
                    view: action.payload,
                    user: state.administration.user,
                    canClose: state.administration.canClose,
                    onClose: state.administration.onClose
                }
            });
        }
        case SET_EMPLOYEES: {
            return Object.assign({}, state, {
                employees: action.payload
            });
        }
        case SET_ADMIN_USER: {
            let nextAdminView = state.administration.view === 'logon' ? 'main' : state.administration.view;
            return Object.assign({}, state, {
                administration: {
                    view: nextAdminView,
                    user: action.payload,
                    canClose: state.administration.canClose,
                    onClose: state.administration.onClose
                }
            });
        }
        case ADD_PERIPHERAL: {
            const peripherals = state.peripherals.slice();
            peripherals.push(action.payload);
            return Object.assign({}, state, {
                peripherals: peripherals
            });
        }
        case UPDATE_PERIPHERAL: {
            const updatePeripheral = action.payload;
            const peripherals = state.peripherals.map(function (peripheral) {
                return peripheral.name === updatePeripheral.name ? updatePeripheral : peripheral;
            });

            return Object.assign({}, state, {
                peripherals: peripherals
            });
        }
        case REMOVE_PERIPHERAL: {
            let peripherals = state.peripherals.filter((el) => {
                return el.name !== action.name;
            });
            return Object.assign({}, state, {
                peripherals: peripherals
            });
        }
        case "INIT_POS": {
            return initialState;
        }
        case SHOW_CALL_ASSISTANCE: {
            return Object.assign({}, state, {
                callAssistance: action.payload
            });
        }
        case CLOSE_CALL_ASSISTANCE: {
            return Object.assign({}, state, {
                callAssistance: false
            });
        }
        case ADD_ALERT: {
            return Object.assign({}, state, {
                alert: action.payload
            });
        }
        case REMOVE_ALERT: {
            return Object.assign({}, state, {
                alert: false
            });
        }
        case ADD_DOCUMENT_DISCOUNT: {
            let discount;
            if(action.sum !== ''){
                discount = Object.assign({},{documentDiscountPrice: action.sum});
            }else{
                discount = Object.assign({},{documentDiscountPercentage: action.percentage});
            }
            return Object.assign({}, state, {
                productsInBasket: state.productsInBasket.slice().map((product, index) => {
                    product.setManualDiscountPercentage(action.percentage);
                    if(typeof action.returnReason !== "undefined"){
                        if(product.amount < 0){
                            product.manualDiscountReasonCodeID = action.returnReason.reasonID;
                            product.manualDiscountReason = action.returnReason;
                        }else{
                            product.returnReasonID = action.returnReason.reasonID;
                            product.returnReason = action.returnReason;
                        }
                    }
                    return product;
                }),
                discount
            });
        }
        case REMOVE_DOCUMENT_DISCOUNT: {
            return Object.assign({}, state, {
                discount: {}
            });
        }
        case ADD_DOCUMENT_DISCOUNT_APPROVAL: {
            return Object.assign({}, state, {
                discount: Object.assign({}, state.discount, {documentDiscountApproval: action.payload.discountApproval})
            });
        }
        case SET_DELAY_RECEIPT: {
            return Object.assign({}, state, {
                delayReceipt: action.delayReceipt
            });
        }
        case SET_LAST_SCANNED_GIFT_CARD: {
            return Object.assign({}, state, {
                lastScannedGiftCard: action.code
            });
        }
        case ADD_RECEIPT_GIFT_CARD: {
            return {
                ...state,
                receiptGiftCards: state.receiptGiftCards.concat(action.giftCard)
            };
        }
        case ADD_BEFORE_COMPLETE_TRANSACTION_ACTION: {
            return {
                ...state,
                beforeCompleteTransactionActions: state.beforeCompleteTransactionActions.concat(action.action)
            };
        }
        case REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION: {
            return {
                ...state,
                beforeCompleteTransactionActions: state.beforeCompleteTransactionActions.filter(item => item.id !== action.id),
            };
        }
        case REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE: {
            return {
                ...state,
                beforeCompleteTransactionActions: state.beforeCompleteTransactionActions.filter(item => item.type !== action.actionType),
            };
        }
        case SET_ADD_RECEIPT_TITLE: {
            return {
                ...state,
                addReceiptTitle: action.addReceiptTitle,
            };
        }
        case ADD_RECEIPT_FISCAL_INFO: {
            return {
                ...state,
                receiptFiscalInfo: action.info
            };
        }
        case SET_RETURN_BASE_DOCUMENT: {
            return {
                ...state,
                returnBaseDocument: {
                    documentID: action.document.id,
                    document: action.document,
                    payments: action.payments
                },
            };
        }
        case UPDATE_RETURN_BASE_DOCUMENT_PAYMENT: {
            let payments = Array.from(state.returnBaseDocument.payments);
            let payment = action.payment;

            const index = payments.findIndex(object => {
                return object.referenceNumber === payment.referenceNumber;
            });

            if(index === -1) {
                return state;
            }

            payment = Object.assign(payments[index], payment);
            payments[index] = payment;
            return Object.assign({}, state, {
                returnBaseDocument: {
                    ...state.returnBaseDocument,
                    payments
                }
            });
        }
        case ADD_PLUGIN_ADMIN_BUTTON: {
            const buttons = state.pluginAdminButtons.slice();
            buttons.push(action.button);
            return Object.assign({}, state, {
                pluginAdminButtons: buttons
            });
        }
        case ADD_RECEIPT_EXTRA_LINE: {
            const lines = state.receiptExtraLines.slice();
            lines.push(action.line);
            return Object.assign({}, state, {
                receiptExtraLines: lines
            });
        }
        case REMOVE_RECEIPT_EXTRA_LINE: {
            return Object.assign({}, state, {
                receiptExtraLines: state.receiptExtraLines.filter(line => {
                    return line !== action.line;
                })
            });
        }
        case ADD_MANUAL_PROMOTION_ID: {
            const ids = state.manualPromotionIDs.slice();
            ids.push(action.id);
            return Object.assign({}, state, {
                manualPromotionIDs: ids
            });
        }
        case REMOVE_MANUAL_PROMOTION_ID: {
            return Object.assign({}, state, {
                manualPromotionIDs: state.manualPromotionIDs.filter(id => {
                    return id !== action.id;
                })
            });
        }
        case ADD_CAMPAIGN_BY_NAME: {
            return Object.assign({}, state, {
                campaignsByName: {
                    ...state.campaignsByName,
                    [action.payload.campaign.name]: action.payload.campaign
                }
            });
        }
        case SET_PAYBACK_INPUT_DONE: {
            return Object.assign({}, state, {
                payback: {
                    ...state.payback,
                    inputDone: action.inputDone
                }
            });
        }
        case SET_PAYBACK_REFERENCE_RECEIPT: {
            return Object.assign({}, state, {
                payback: {
                    ...state.payback,
                    referenceReceipt: {
                        number: action.payload.number,
                        products: action.payload.products,
                        transactionDateTime: action.payload.transactionDateTime
                    }
                }
            });
        }
        case SET_PAYBACK_CUSTOMER: {
            return Object.assign({}, state, {
                payback: {
                    ...state.payback,
                    customer: action.customer
                }
            });
        }
        case REMOVE_PAYBACK_CUSTOMER: {
            let customer = Object.assign({}, state.customer);
            if(customer?.localRequirements?.name === "PaybackAccount"){
                delete customer.localRequirements;
            }
            if(Object.values(customer).length === 0){
                customer = false;
            }
            return Object.assign({}, state, {
                payback: {
                    customer: false,
                    coupons: [],
                    inputDone: false
                },
                customer
            });
        }
        case ADD_PAYBACK_COUPON: {
            const coupons = state.payback.coupons === false ? [] : state.payback.coupons.slice();
            coupons.push(action.coupon);
            return Object.assign({}, state, {
                payback: {
                    ...state.payback,
                    coupons
                }
            });
        }
        case REMOVE_PAYBACK_COUPON: {
            return Object.assign({}, state, {
                payback: {
                    ...state.payback,
                    coupons: state.payback.coupons.filter(coupon => {
                        return coupon !== action.coupon;
                    }),
                    inputDone: false
                }
            });
        }
        default:
            return state;
    }
}

export default rootReducer;
