import React, {useState} from "react";
import {Translate} from "react-localize-redux";
import {validateEmail, validatePhone} from "../redux/dto/customer";
import Customer from "../redux/dto/customer";
import Button from "../buttons/Button";
import InputWithKeyboard from "../formElements/InputWithKeyboard";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../redux/actions";

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());
    }

    const [validationError, setValidationError] = useState(false);
    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <span className={'errorMessage'}>{validationError}</span>
        );
    }

    let cardCode = props.cardCode === true;
    let emailOnly = props.emailOnly === true;
    let title = props.title !== undefined ? props.title : <Translate id={"recallItemText"}/>;
    let inputTitle = cardCode? "enterCardCode" : emailOnly ? "enterEmail" : "enterEmailOrPhone";
    let keyboardLayout = cardCode ? "numbers" : "letters";
    let validationErrorTitle = emailOnly ? "emailValidationError" : "emailPhoneValidationError";
    let backButton;
    if(props.onBack !== undefined){
        backButton = (
            <div onClick={props.onBack} style={{marginTop: '10px', marginBottom: '10px'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                    arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    return (
        <div style={{
            padding: "10px",
            paddingTop: "180px"
        }}>
            <div className={"fullWidth centerText"}>
                <span className={"boldUppercaseText centerText"}>
                    { title }
                </span>
            </div>
            <br/>
            <InputWithKeyboard
                inputLabel={inputTitle}
                layout={keyboardLayout}
                onInputChange={(value) => {}}
                onDone={(value) => {
                    let customer = new Customer();
                    if(cardCode){
                        customer.card_number = value;
                        props.onDone(customer);
                    }else{
                        if(validateEmail(value)){
                            customer.email = value;
                            props.onDone(customer);
                        }else if(!emailOnly && validatePhone(value)){
                            customer.phone = value;
                            props.onDone(customer);
                        }else {
                            setValidationError(<Translate id={validationErrorTitle}/>)
                        }
                    }
                }}
            />

            { validationErrorDisplay }
            { backButton }
        </div>
    );
}
