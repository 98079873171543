import {API, AUTH_API, CAFA_API, CLOSE_QCO} from "../actionTypes";
import {accessDenied, apiError, apiStart, apiEnd, closeQCO, addErrorAlert} from "../actions";
import $ from "jquery"
import {getProductCategoryByID, getVatRateByID} from "../selectors";
import {getTranslate} from "react-localize-redux";
import CTR from "../../util/receiptTemplate";
import {APP_URL, DKT_SSO_URL, ERPLY_API_PARTNER_KEY} from "../appConstants";

// let retryCompleteTransaction = 1;

const apiMiddleware = (store) => next => action => {
    next(action);

    let dispatch = store.dispatch;
    let state = store.getState();
    let translate = getTranslate(state.localize);
    let decathlonSSO = window.AppConf?.decathlonSSO || false

    if (action.type === CAFA_API){
        cafaRequest(action.payload.method, action.payload.parameters, action.payload.onSuccess, action.payload.onFailure, store);
        return;
    }
    if (action.type === AUTH_API){
        authApiRequest(action.payload.method, action.payload.headers, action.payload.parameters, action.payload.onSuccess, action.payload.onFailure, store);
    }
    if (action.type === "CLOSE_QCO"){
        if(decathlonSSO){
            oAuthLogout(store, state);
        }
    }
    if (action.type !== API) return;


    const {
        data,
        onSuccess,
        onFailure,
        label,
        successActions
    } = action.payload;

    if (data.request === 'sendByEmail' && window.AppConf.printDecathlonReceipt){
        CTR.handleSendEmail(data, action.payload.document, state).then((response) => {
            dispatch(onSuccess(response));
        }, (errorMessage) => {
            dispatch(onFailure(errorMessage));
        });
        return;
    }else if(data.request === 'closeApp'){
        closeAppRequest(store);
        return;
    }else if(data.request === 'oAuthLogin'){
        oAuthLogin(store, data, onSuccess, onFailure);
        return;
    }else if(data.request === 'switchUser'){
        if(decathlonSSO){
            oAuthSwitchUser(store, data, onSuccess, onFailure);
            return;
        }
    }

    if (label) {
        dispatch(apiStart(label));
        /*if(label === 'completeTransaction'){
            if(retryCompleteTransaction < 3){
                retryCompleteTransaction++;
                dispatch(onFailure('testing retry complete transaction!'));
                return;
            }
        }*/
    }

    apiRequest(data, function (records) {
        dispatch(onSuccess(records));
        if(successActions){
            $(successActions).each(function (index, successAction) {
                dispatch(successAction(records));
            });
        }
    }, function (errorMessage, errorCode = 1000) {
        if(errorCode === 1054){
            dispatch(closeQCO());
            dispatch(addErrorAlert('Session has expired, please log in again!'));
        }else if(errorCode === 1065){
            setTimeout(() => {
                dispatch(closeQCO());
            }, 3000);
            dispatch(onFailure(errorMessage));
        }else if(onFailure !== undefined){
            dispatch(onFailure(errorMessage));
        }
    }, state, translate);

};

const apiRequest = function (parameters, onSuccess, onFail, state, translate) {
    const clientCode = parameters?.clientCode ?? state.rootReducer.user.clientCode;
    if (parameters.request === 'getProducts' && typeof parameters.searchNameIncrementally !== "undefined"){
        if(parameters.skipOfflineSearch !== true){
            console.log('ODS: getting from ODS');
            getProductFromOfflineDB(parameters, onSuccess, onFail, state, translate);
            return true;
        }
    }
    if(parameters.getAllPages){
        delete parameters.getAllPages;
        apiRequestAllPages(parameters, onSuccess, onFail, state, translate);
        return true;
    }

    if(window.AppConf?.decathlonSSO || window.AppConf?.decathlonQRCodeLogin){
        if(state.rootReducer.user !== false){
            parameters.sessionKey = state.rootReducer.user.sessionKey;
        }
    }

    let finalParams = {};
    let url = "";
    if(typeof window.AppConf?.ErplyAPI?.url !== "undefined" && window.AppConf.ErplyAPI.url.indexOf('localhost:') !== -1){
        finalParams = {
            erplyAPI: JSON.stringify({
                ...parameters,
                partnerKey: ERPLY_API_PARTNER_KEY
            })
        };
        url = APP_URL + 'erplyAPI';
    }else{
        if(Array.isArray(parameters)){
            finalParams = {
                requests: JSON.stringify(parameters)
            };
        }else{
            finalParams = {
                ...parameters
            };
        }
        finalParams.partnerKey = ERPLY_API_PARTNER_KEY;
        url = "https://" + clientCode + ".erply.com/api/";
    }

    if(state.rootReducer.user !== false){
        if(typeof finalParams.sessionKey === "undefined"){
            finalParams.sessionKey = state.rootReducer.user.sessionKey;
        }
        finalParams.clientCode = state.rootReducer.user.clientCode;
    }

    $.ajax({
        url,
        type: "POST",
        data: finalParams,
        timeout: 60000,
        success: function(response){
            let result;
            if(typeof response === 'object'){
                result = response;
            }else{
                try {
                    result = JSON.parse(response);
                }catch (e) {
                    console.log('Could not parse: ', response);
                    result = {
                        status: {
                            responseStatus: "error",
                            errorCode: 102
                        }
                    }
                }
            }
            if(result.status.responseStatus === 'ok'){
                let records = typeof result.records !== 'undefined' ? result.records : result.requests;
                onSuccess(records);
            }else{
                let errorMessage = errorCodes[result.status.errorCode] || 'No response';
                onFail(translate(errorMessage), result.status.errorCode);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('API request failed!', textStatus, errorThrown);
            onFail('No response from Erply API!', 1000);
        }
    });
};

const apiRequestAllPages = function (params, onSuccess, onFail, state, translate) {
    params['recordsOnPage'] = 100;
    if(params.request === "getProducts")
    {
        if(typeof params['getStockInfo'] == 'undefined') params['recordsOnPage'] = 1000;
    }
    params['pageNo'] = 1;
    const res = [];
    apiRequestAllPagesNext(params, onSuccess, onFail, state, translate, res);
};

const apiRequestAllPagesNext = function (params, onSuccess, onFail, state, translate, res) {
    apiRequest(params, function (records) {
        $(records).each(function (index, record) {
            res.push(record);
        });
        if (records.length < params['recordsOnPage']) {
            onSuccess(res);
        }else{
            params['pageNo'] += 1;
            apiRequestAllPagesNext(params, onSuccess, onFail, state, translate, res);
        }
    }, onFail, state, translate);
};

const getProductFromOfflineDB = function (parameters, onSuccess, onFail, state, translate) {
    let requests = [
        {name: 'getByCode', parameters: {code: parameters.searchNameIncrementally}},
        {name: 'getByCode2', parameters: {code2: parameters.searchNameIncrementally}}
    ];
    function getByCode () {
        if(requests.length === 0){
            parameters.skipOfflineSearch = true;
            apiRequest(parameters, onSuccess, onFail, state, translate);
            return true;
        }
        let request = requests.pop();
        offlineProductDatabaseRequest(request.name, request.parameters, function (data) {
            if(data.length === 0){
                getByCode();
            }else{
                onSuccess(data);
            }
        }, function () {
            parameters.skipOfflineSearch = true;
            apiRequest(parameters, onSuccess, onFail, state, translate);
        }, state);
    }

    getByCode();
};

const offlineProductDatabaseRequest = function (request, parameters, onSuccess, onFail, state) {
    console.log("Offline product database request", {request, parameters});
    if(window.AppConf.ODS === undefined || window.AppConf.ODS.url === undefined){
        onFail();
        return false;
    }
    $.ajax({
        url: window.AppConf.ODS.url + "/product/" + request,
        method: "GET",
        data: parameters,
        timeout: 500,
        success: function(data){
            console.log('ErplyDB.query Found from local db: ', data);
            if(data === null) data = [];

            data = data.filter((product) => {
                if(typeof product.status === "undefined") return true;
                return product.status !== 'ARCHIVED';
            });
            data.map(function (product) {
                if(typeof product.vatrateID === "undefined") product.vatrateID = product.vatRateID;
                let vatRate = getVatRateByID(state.rootReducer.vatRates, product.vatrateID);
                if(typeof product.price === "undefined"){
                    product.price = product.priceListPrice;
                }
                product.vatrate = vatRate.rate;

                if(typeof product.seriesName === "undefined" && typeof product.categoryID !== "undefined"){
                    const productCategory = getProductCategoryByID(state.rootReducer.productCategories, product.categoryID);
                    if(typeof productCategory !== "undefined"){
                        product.seriesName = productCategory.productCategoryName;
                    }
                }
                product.seriesID = product.categoryID;

                product.priceListPrice = product.price;
                product.priceListPriceWithVat = parseFloat((product.price * (1 + product.vatrate/100)).toFixed(4));
                return product;
            });
            onSuccess(data);
        },
        error: function(){
            onFail();
        }
    });
};

const cafaRequest = function (method, parameters, onSuccess, onFail, store) {
    let state = store.getState();
    let customerCode = state.rootReducer.user.clientCode;
    let headers = {
        clientCode: customerCode,
        sessionKey: state.rootReducer.user.sessionKey
    };

    let application = process.env.REACT_APP_ERPLY_MODE === "1" ? 'intral_sco' : 'decathlon_sco';
    if(parameters.level === 'Pos'){
        parameters.level_id = state.rootReducer.posID
    }
    let url = state.rootReducer.erplyServiceEndpoints.cafa.url + 'configuration';

    if(parameters.getAll){
        url += "/" + application;
    }else{
        parameters.application = application;
    }

    let options = {
        method,
        headers: headers
    };

    if(method === 'GET'){
        if(!parameters.getAll) {
            url += '?' + Object.keys(parameters).reduce((acc, key) => {
                if (acc !== '') {
                    acc += '&';
                }
                acc += key + '=' + encodeURIComponent(parameters[key]);
                return acc;
            }, '');
        }
    }else{
        options.body = JSON.stringify(parameters);
    }

    if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
        let proxyUrl = method === 'GET' ? 'proxy' : 'postProxy';
        url = APP_URL + proxyUrl + '?u=' + btoa(url);
    }

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        if(parameters.getAll){
            store.dispatch(onSuccess(response));
        }else{
            if(typeof response[0] !== "undefined"){
                store.dispatch(onSuccess(response[0].value));
            }else{
                store.dispatch(onSuccess(false));
            }
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(addErrorAlert('No response from CAFA API!'));
        onFail('No response from CAFA API!')
    });
};


const authApiRequest = function (method, headers, parameters, onSuccess, onFail, store) {
    let state = store.getState();
    headers.clientCode = state.rootReducer?.user?.clientCode || window.AppConf?.clientCode || false;
    let url = state.rootReducer?.erplyServiceEndpoints?.auth?.url || window.AppConf?.authApiUrl || false;
    if(headers.clientCode === false || url === false){
        onFail("Auth API not configured!");
        return;
    }

    url += 'v1/integrations/session';
    let options = {
        method,
        headers
    };

    if(method === 'GET'){
        url += '?' + Object.keys(parameters).reduce((acc, key) => {
            if(acc !== ''){
                acc += '&';
            }
            acc += key + '=' + encodeURIComponent(parameters[key]);
            return acc;
        }, '');
    }else{
        options.body = JSON.stringify(parameters);
    }

    if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
        let proxyUrl = method === 'GET' ? 'proxy' : 'postProxy';
        url = APP_URL + proxyUrl + '?u=' + btoa(url);
    }

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        if(response.status.responseStatus === 'error'){
            store.dispatch(onFail(response.status.errorDescription));
        }else{
            store.dispatch(onSuccess(response.data));
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(addErrorAlert('No response from AUTH API!'));
        onFail('No response from AUTH API!')
    });
};

const closeAppRequest = function (store) {
    fetch(APP_URL + 'exit').then((response) => {
        console.log('exit message', response);
        if(response.status !== 200){
            store.dispatch(addErrorAlert('Can\'t exit application!'));
        }
    }).catch((data) => {
        store.dispatch(addErrorAlert('Can\'t exit application!'));
    });
};

const oAuthLogin = function (store, data, onSuccess, onFailure) {
    fetch(DKT_SSO_URL + 'getToken?code=' + data.authCode + '&redirectUri=' + encodeURIComponent(window.location.origin)).then((response) => {
        response.json().then((response) => {
            console.log('oAuthLogin response', response);
            if(response.errorCode === 0){
                if(response.sessionInfo.signInFailed){
                    store.dispatch(onFailure(response.sessionInfo.message));
                    setTimeout(() => {
                        oAuthLogout(store, {
                            rootReducer: {
                                user:{
                                    oAuthToken: response.token
                                }
                            }
                        });
                    }, 3000);
                }else{
                    response.sessionInfo.oAuthToken = response.token;
                    store.dispatch(onSuccess(response.sessionInfo));
                }
            }else{
                store.dispatch(onFailure(response.errorMessage));
            }
        });
    }).catch((data) => {
        store.dispatch(onFailure('Can\'t login!'));
    });
}

const oAuthLogout = function (store, state) {
    let body = JSON.stringify(state.rootReducer.user.oAuthToken);
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body
    };
    console.log('oAuthLogout request', options);
    fetch(DKT_SSO_URL + 'revokeToken', options).then((response) => {
        response.json().then((response) => {
            console.log('oAuthLogout response', response);
            if(response.result){
                window.location.href = window.location.origin;
            }
        });
    }).catch((data) => {});
}

const oAuthSwitchUser = (store, data, onSuccess, onFailure) => {
    let state = store.getState();
    let customerCode = state.rootReducer.user.clientCode;
    let headers = {
        clientCode: customerCode,
        sessionKey: state.rootReducer.user.sessionKey
    };

    let options = {
        method: 'POST',
        headers
    };
    options.body = JSON.stringify({
        sessionLength: data.sessionLength,
        userPin: data.cardCode
    });

    let url = APP_URL + 'postProxy?u=' + btoa(state.rootReducer.erplyServiceEndpoints.auth.url + 'v1/switch-session');

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        console.log('switch-session', response);
        if(response.data !== null){
            let data = [
                {
                    userID: response.data.session.user.id,
                    employeeID: response.data.session.user.employeeId
                }
            ]
            store.dispatch(onSuccess(data));
        }else{
            store.dispatch(onFailure('Wrong PIN'));
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(onFailure('Can\'t verify user PIN)'));
    });
}

const errorCodes = {
    50: 'Invalid parameters format. Must be JSON string',
    101: 'API communication timed out, please try again',
    102: 'API communication error, please try again',
    103: 'API is not responding or invalid client code',
    1000: 'API is in maintenance, try again in a couple of minutes',
    1001: 'Access has not been set up for this account',
    1002: 'Number of allowed requests exceeded for this account',
    1003: 'Cannot access system',
    1004: 'API version number not specified',
    1005: 'Unknown function name',
    1006: 'Function not implemented yet',
    1007: 'Unknown format requested',
    1009: 'This API call requires authentication parameters, but none were found.',
    1010: 'Required parameters are missing',
    1011: 'Invalid classifier ID, there is no such item',
    1012: 'A parameter must have a unique value',
    1013: 'Inconsistent parameter set',
    1014: 'Incorrect data type or format',
    1015: 'Malformed request',
    1016: 'Invalid value',
    1017: 'Document has been confirmed and its contents and warehouse ID cannot be edited any more',
    1040: 'Invalid coupon identifier - such coupon has not been issued',
    1041: 'Invalid coupon identifier - this coupon has already been redeemed',
    1043: 'Employee already has an appointment on that time slot. Choose a different start and end time for appointment',
    1044: 'Default length for this service has not been defined in Erply backend - cannot suggest possible time slots',
    1050: 'Username/password missing',
    1051: 'Username or password incorrect',
    1052: 'User has been temporarily blocked because of unsuccessful login attempts',
    1053: 'Login has not been enabled for this user',
    1054: 'API session has expired. Call function verifyUser() again (with correct credentials) to receive a new session key',
    1055: 'Session not found',
    1057: 'Demo account has expired',
    1060: 'No viewing rights (in this module/for this item)',
    1061: 'No adding rights (in this module)',
    1062: 'No editing rights (in this module/for this item)',
    1063: 'No deleting rights (in this module/for this item).',
    1064: 'User does not have access to this location (store, warehouse).',
    1065: 'This user account does not have API access. (It may be limited to POS or Erply backend operations only.)',
    1066: 'This user does not have the right to manage specified user group. (Error may occur when attempting to create a new user, or modify an existing one.)',
    1082: 'E-mail sending has been incorrectly set up, review settings in ERPLY',
    1146: 'You do not have an employee record. Please ask a manager to create an employee record for you.',
    1147: 'You have already confirmed your compliance with the General Data Protection Regulation.',
    1148: 'You do not have access to customer data. Please contact your manager.',
    1149: 'Your account country is a non-EU country and the GDPR customer data processing log is not available.'
};

export default apiMiddleware;