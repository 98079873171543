import React from 'react';
import './button.css';

export default function ({className = '', image, title}) {
    return (
        <div className={"buttonContainer " + className} style={{
            overflow: 'hidden',
            boxShadow: '1px 3px 4px -1px #919191'
        }}>
            <div className={"blueBackground leftBox"}>
                <span style={{
                    display: 'inline-block',
                    height: '100%',
                    verticalAlign: 'middle',
                    marginLeft: '15px'
                }}/>
                <img src={image} style={{
                    width: "45px",
                    verticalAlign: 'middle'
                }} alt={"bank card"}/>
            </div>
            <div className={"flencheBackgroundLeft"}/>
            <span className={"uppercaseText"} style={{
                marginLeft: '20px'
            }}>
                { title }
            </span>
        </div>
    );
}