import React from 'react';
import './formElements.css';
import checkBoxChecked from './images/checkBoxChecked.png';
import checkBoxNotChecked from './images/checkBoxNotChecked.png';

export default function ({checked, name, onChange}) {
    let checkIcon;
    if(checked){
        checkIcon = <img src={checkBoxChecked} alt={'Checked'}/>;
    }else{
        checkIcon = <img src={checkBoxNotChecked} alt={'Not checked'}/>;
    }
    return (
        <div>
            <input type={'hidden'} value={checked} name={name}/>
            <div onClick={e => {
                onChange(!checked);
            }}>
                {checkIcon}
            </div>
        </div>
    );
}