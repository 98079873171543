import Decimal from "decimal.js";

export const ltrim = function(string, char) {
    let index = 0;
    while (string[index] === char){
        index++;
    }
    return string.substr(index);
};

export const isEmptyString = (string) => {
    return ltrim(string, " ") === "";
}

export function interpolate(template, variables, fallback) {
    return template.replace(/\${[^{]+}/g, (match) => {
        const path = match.slice(2, -1).trim();
        return getObjPath(path, variables, fallback);
    });
}

function getObjPath(path, obj, fallback = '') {
    return path.split('.').reduce((res, key) => res[key] || fallback, obj);
}

export const getAttributeValue = (object, attributeName) => {
    if(typeof object.attributes === "undefined") {
        return undefined;
    }
    let attribute = object.attributes.find(el => el.attributeName === attributeName || el.name === attributeName);
    return typeof attribute !== "undefined" ? attribute?.attributeValue || attribute.value : undefined;
};

export const getProductPackageWeight = (product) => {
    let attributeValue = getAttributeValue(product, 'packageWeight');
    if(typeof attributeValue === "undefined") {
        return undefined;
    }
    return attributeValue.replace(',', '.');
};

export const capitalizeFirstLetter = (s) => {
    return s.substr(0,1).toUpperCase() + s.slice(1);
};

export const round005 = (x, decimals = false) => {
    let isPositive = x >= 0;
    let value = Math.abs(x) * 100;
    let rounded = ((value % 5) >= 2.5 ? parseInt(value / 5) * 5 + 5 : parseInt(value / 5) * 5) / 100;
    if(!isPositive){
        rounded *= -1;
    }
    if(decimals !== false){
        return rounded.toFixed(decimals);
    }
    return parseFloat(rounded.toFixed(2));
};

export const numberIsOdd = (number) => {
    return number % 2 === 1;
}

export const displayPrice = (price) => {
    console.log('displayPrice', {price});
    const decimals = window?.AppConf?.priceDecimals ?? 2;
    return new Decimal(price).toFixed(decimals);
}

export const checkIfImageExists = (url) => {
    return new Promise(((resolve) => {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            resolve(true);
        } else {
            img.onload = () => {
                resolve(true);
            };

            img.onerror = () => {
                resolve(false);
            };
        }
    }));
}

export const imageFile = (str) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|ico|JPG|JPEG|PNG|GIF|ICO)$/);
    if (str == null) {
        return false;
    }
    return regex.test(str);
}