import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

export const ManagerAppContext = React.createContext(null);

export let sendStatusUpdate = (statusID) => {
    //console.log('sendStatusUpdate not registered yet!');
}

let isAvailable = true;
let starting = false;

async function createClient(options) {
    const { RSocketClient } = require('rsocket-core');
    const RSocketWebsocketClient = require('rsocket-websocket-client').default;
    const transportOptions = {
        url: `ws://${options.host}:${options.port}`,
        wsCreator: (url) => {
            let socket = new WebSocket(url);
            socket.onerror = (e) => {
                isAvailable = false;
            }
            socket.onclose = e => {
                isAvailable = false;
            }
            socket.onopen = e => {

            }
            return socket;
        }
    };
    const setup = {
        keepAlive: 86400000,
        lifetime: 86400000,
        dataMimeType: 'text/plain',
        metadataMimeType: 'text/plain'
    };
    const transport = new RSocketWebsocketClient(transportOptions);
    const client = new RSocketClient({ setup, transport });
    return client.connect();
}

async function run(clientCode,warehouseID,posID) {
    const { Flowable } = require('rsocket-flowable');
    const rsocket = await createClient({
        host: '127.0.0.1',
        port: 9898,
    });
    if(!isAvailable) return;

    rsocket.requestChannel(new Flowable(subscriber => {
        sendStatusUpdate = (statusID) => {
            subscriber.onNext({
                data: JSON.stringify({
                    request: 'updateStatus',
                    statusID
                }),
                metadata: JSON.stringify({ group: 1 })
            });
        }
        subscriber.onSubscribe({
            cancel: () => {},
            request: n => {
                subscriber.onNext({
                    data: JSON.stringify({
                        request: 'registerPOS',
                        posID,
                        account: clientCode,
                        warehouseID
                    }),
                    metadata: JSON.stringify({ group: 1 })
                });
                //subscriber.onComplete();
            },
            onError: err => {
                console.error(err);
                isAvailable = false;
            }
        })
    })).subscribe({
        onSubscribe: subscription => {
            //console.log(`Client is establishing Channel 1`);
            subscription.request(0x7fffffff);
        },
        onNext: response => {
            //console.log(`Channel 1: ${response.data}`);
        },
        onComplete: () => {
            //console.log(`Channel 1 received end of server stream`);
        },
        onError: err => {
            //console.error(err);
        }
    });
    return rsocket;
}

let socket;
let ws;

const ManagerAppProvider = ({children}) => {
    const dispatch = useDispatch();
    const rootReducer = useSelector(state => state.rootReducer);

    if (window?.AppConf?.useManagerApp === true &&
        !starting &&
        isAvailable &&
        rootReducer.posID !== 0 &&
        rootReducer.warehouse !== false &&
        (!socket || socket.availability() !== 1)
    ) {
        starting = true;
        run(rootReducer.user.clientCode, rootReducer.warehouse.warehouseID, rootReducer.posID).then((newSocket) => {
            socket = newSocket;
            starting = false;
        }, () => {
            starting = false;
            setTimeout(() => {
                isAvailable = true;
            }, 60000)
        });
        ws = {
            sendStatusUpdate
        }
    }

    return (
        <ManagerAppContext.Provider value={ws}>
            {children}
        </ManagerAppContext.Provider>
    )
}

export default ManagerAppProvider;