import React, {useState} from "react";
import {getISO6392BFromISO6391} from "../../redux/selectors";
import { Responsive, WidthProvider } from 'react-grid-layout';
import "react-grid-layout/css/styles.css";
import ImageBoxErply from "../../buttons/ImageBoxErply";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function ({
    setProduct,
    products,
    productsOnLine,
    containerStyle = {},
    language,
    editable=false,
    onLayoutChange=()=>{},
    removeProduct=()=>{},
    fontSize='12px'
}) {

    const [breakPoint, onBreakPointChange] = useState('sm');

    const getProductName = (product) => {
        let lang = getISO6392BFromISO6391(language);
        if(lang === false){
            return product.name;
        }
        lang = lang.toUpperCase();
        return product['name' + lang];
    };

    const layout = (columns) => products.reduce((acc, el, index) => {
        acc.push({
            i: index.toString(),
            x: index % columns,
            y: Math.floor(index/columns),
            w: 1,
            h: 1,
            static: !editable,
            isBounded: true,
            isResizable: false
        });
        return acc;
    }, []);
    const cols = {lg: 12, md: 10, sm: productsOnLine, xs: 4, xxs: 2};

    return (
        <div style={{
            clear: 'both',
            margin: '5px'
        }}>
            <ResponsiveGridLayout
                style={containerStyle}
                className="layout"
                layouts={{lg: layout(12), md: layout(10), sm: layout(productsOnLine), xs: layout(4), xxs: layout(2)}}
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={cols}
                onLayoutChange={(layout) => {
                    let productsInCol = cols[breakPoint];
                    layout.sort((a,b) => {
                        return (a.x + productsInCol * a.y) > (b.x + productsInCol * b.y) ? 1 : -1;
                    });

                    let order = layout.reduce((acc, el) => {
                        acc.push(products[el.i].productID);
                        return acc;
                    }, []);

                    /*let order = [];

                    let lastEl = layout[layout.length - 1];
                    for(let i = 0; i <= lastEl.y; i++){
                        for(let j = 0; j < productsInCol; j++) {
                            console.log('layout: ', i, j);
                            let layoutItem = layout.find(el => el.x === j && el.y === i);
                            if(typeof layoutItem !== "undefined"){
                                order.push(products[layoutItem.i].productID);
                            }else{
                                order.push('');
                            }
                        }
                    }*/

                    onLayoutChange(order);
                }}
                onBreakpointChange={breakPoint => {
                    onBreakPointChange(breakPoint);
                }}
                compactType={'horizontal'}
                draggableCancel={'.remove'}
            >
                {
                    products.map((product, index) => {
                        let displayImageAttribute = typeof product.attributes !== "undefined" ? product.attributes.find(el => el.attributeName === 'displayPictureSCO') : undefined;
                        let displayImage = typeof displayImageAttribute !== "undefined" ? displayImageAttribute.attributeValue === '1' : false;
                        let picture = displayImage && typeof product.images !== "undefined" ? product.images[0].thumbURL : false;
                        let boxStyle;
                        let textStyle;

                        if(picture !== false){
                            boxStyle = {
                                backgroundImage: 'url(' + picture + ')',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            };
                            textStyle = {
                                bottom: 0,
                                backgroundColor: 'rgb(255,255,255,0.8)',
                                borderBottomRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                padding: '4px',
                                boxSizing: 'border-box'
                            }
                        }else{
                            boxStyle = {
                                backgroundColor: '#E6E6E6'
                            };
                            textStyle = {
                                position: 'initial',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                overflow: 'hidden',
                            }
                        }
                        textStyle.fontSize = fontSize;
                        boxStyle.padding = 0;

                        let removeButton;

                        if(editable){
                            removeButton = (
                                <span
                                    className={"remove"}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        textAlign: 'center',
                                        fontSize: '20px',
                                        zIndex: 10,
                                        backgroundColor: '#0f82c1',
                                        borderRadius: '24px',
                                        padding: '10px',
                                        color: '#FFF',
                                        width: '24px',
                                        height: '24px',
                                        display: 'inline-block'
                                    }}
                                    onClick={ e => {
                                        removeProduct(product);
                                    }}
                                >
                                    <i className="icoFont">&#xeee1;</i>
                                </span>
                            );
                        }

                        let infoButton;
                        if(product.showInfoButton){
                            infoButton = (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: '4px',
                                        textAlign: 'center',
                                        fontSize: '28px',
                                        zIndex: 10,
                                        display: 'inline-block',
                                        cursor: 'pointer'
                                    }}
                                    onClick={ e => {
                                        product.onInfoButtonClick();
                                    }}
                                >
                                    <i className="icoFont">&#xef4e;</i>
                                </span>
                            );
                        }

                        return (
                            <div key={index.toString()} style={{padding:0, zIndex:9}}>
                                { removeButton }
                                { infoButton }
                                <ImageBoxErply
                                    boxStyle={boxStyle}
                                    boxClassName={'quickSelectButton'}
                                    textStyle={textStyle}
                                    title={getProductName(product)}
                                    height={150}
                                    padding={0}
                                    onClick={e => {
                                        if(!editable){
                                            setProduct(product);
                                        }
                                    }}
                                />
                            </div>
                        );
                    })
                }
            </ResponsiveGridLayout>
        </div>
    );
}