import {API, CUSTOMER_API, SET_EMPLOYEES} from "../actionTypes";
import { accessDenied, apiError, apiStart, apiEnd } from "../actions";
import $ from "jquery"

const customerAPI = store => next => action => {
    console.log('customerAPI next action:', action);
    next(action);

    let dispatch = store.dispatch;
    let state = store.getState();

    if (action.type !== CUSTOMER_API) return;

    const {
        request,
        data,
        onSuccess,
        onFailure,
        label
    } = action.payload;

    if (label) {
        dispatch(apiStart(label));
    }

    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        sendErplyRequest(request, data, state, dispatch, onSuccess, onFailure);
    }else{
        sendExternalRequest(request, data, state, dispatch, onSuccess, onFailure);
    }

};

const sendErplyRequest = (request, data, state, dispatch, onSuccess, onFailure) => {
    dispatch({
        type: API,
        payload: {
            data: {
                ...data,
                request
            },
            onSuccess,
            onFailure,
            label: request
        }
    });
};

const sendExternalRequest = (request, data, state, dispatch, onSuccess, onFailure) => {
    let countryCode = state.rootReducer.erplyConf.DKTCountryCode;

    let params = JSON.stringify(data);

    let requestData = {
        params,
        countryCode
    }
    const url = window.AppConf.CustomerAPI.url;
    const failMessage = 'Could not connect to customer database!';
    $.ajax({
        url: url + '/' + request,
        type: "GET",
        crossDomain: true,
        data: requestData,
        timeout: 10000,
        success: function(data){
            let result = JSON.parse(data);
            if(result.status.responseStatus === 'ok' && result.data.customers !== null){
                console.log('CAP: successful API request!', result);
                dispatch(onSuccess(result.data.customers));
            }else{
                console.log('CAP: Customer API request failed!', result);
                dispatch(onFailure(result));
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('CAP: Customer API request failed!', failMessage, textStatus);
            dispatch(onFailure({
                failMessage: failMessage,
                textStatus: textStatus,
                errorThrown: errorThrown
            }));
            dispatch(apiError(failMessage, false));
        }
    });
};

export default customerAPI;
