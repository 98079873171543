import {
    CASH_IN_OUT_SUCCESS, CLOSE_QCO,
    EPSI, EPSI_REQUEST, OPEN_CASH_DRAWER,
    OPEN_POS_OPEN_DAY_VIEW,
    POS_DAY_CLOSE_SUCCESS, PRINT_HTML,
    PRINT_RECEIPT,
    SET_USER
} from "../actionTypes";
import $ from "jquery"
import {getPeripheral, getProductsTotalSum} from "../selectors";
import {
    addErrorAlert,
    addPeripheral,
    findCustomerByRegistryCode, removePeripheral,
    updatePeripheral
} from "../actions";
import Peripheral from "../dto/peripheral";
import {INPUT_PAYMENT_STARTED} from "../payment/actionTypes";
import {getTranslate} from "react-localize-redux";
import CTR from "../../util/receiptTemplate";
import {APP_URL} from "../appConstants";
//import teleprint from "teleprint"

function getPaymentProvider() {
    return '';
}

const nl2br = function(e, t) {
    return (e + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + (t || void 0 === t ? "<br />" : "<br>") + "$2")
};

const toQueryString = function(obj) {
    var parts = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
};

const Timers = {
    timers: [],

    /**
     * setTimeout function with additional "name" parameter, so we could identify what for the timer is set
     *
     * @param name
     * @param callback
     * @param timestamp
     * @returns {String} name of the timer
     */

    setTimeout: function(name, callback, timestamp) {
        var timer = {
            name: name,
            repeatTime: timestamp,
            isInterval: false
        };

        var self = this;
        var timerID = setTimeout(function() {
            self.timers.splice( $.inArray(timer, self.timers), 1);
            callback();
        }, timestamp);

        $.extend(timer, {
            systemTimerID: timerID
        });
        this.timers.push( timer );
        return name;
    },

    /**
     * setTimeout function with additional "name" parameter, so we could identify what for the timer is set
     *
     * @param name
     * @param callback
     * @param timestamp
     * @returns {String} name of the timer
     */

    setInterval: function(name, callback, timestamp) {
        var timer = {
            name: name,
            repeatTime: timestamp,
            isInterval: true
        };

        var timerID = setInterval(function() {
            callback();
        }, timestamp);

        $.extend(timer, {
            systemTimerID: timerID
        });
        this.timers.push( timer );
        return name;
    },

    /**
     * Clear all the timers
     */

    clearAllTimers: function() {
        var timers_length = this.timers.length;

        while( timers_length-- ) {
            var timer = this.timers[timers_length];
            console.log(timer.name +" - "+timer.isInterval + " : " + timers_length);

            if ( timer.isInterval ) {
                clearInterval(timer.systemTimerID);
            } else {
                clearTimeout(timer.systemTimerID);
            }

            this.timers.splice( timers_length, 1);
            console.log("Cleared timer: " + timer.name + " with ID: " + timer.systemTimerID);
        }
    },

    /**
     * Clear timer by name
     *
     * @param name
     */

    clearTimer: function( name ) {
        var timers_length = this.timers.length;

        while( timers_length-- ) {
            var timer = this.timers[timers_length];
            if ( timer.name === name ) {
                if ( timer.isInterval ) {
                    clearInterval(timer.systemTimerID)
                } else {
                    clearTimeout(timer.systemTimerID);
                }
                this.timers.splice( timers_length, 1);
                console.log("Cleared timer: " + timer.name + " with ID: " + timer.systemTimerID);
            }
        }
    },

    /**
     * Check if timer with name exists or not
     *
     * @param name
     * @returns {boolean}
     */

    hasTimer: function( name ) {
        var timerExists = false;
        $.each(this.timers, function(index, timer) {
            if ( timer.name === name ) {
                timerExists = true;
                return false;
            }
        });
        return timerExists;
    }
};

/**
 * EPSI communication implementation
 * @class ErplyEPSI
 * @public
 * @static
 */

// jscs: disable
// jshint ignore: start


var ErplyEPSI = {
    localServerUp: false,
    message_broker_id: '',
    server_version: 'unknown',
    post_supported: false,
    websocket: null,
    requests_queue: [],
    currentConfiguration: {},
    downloadURL: 'https://app.erply.com/epsi/setup.exe', //FIXME This is a fallback URL for now, will be removed in the future
    appletURL: 'http://app.erply.com/epsi/applet/EPSI.jnlp', //FIXME This is a fallback URL for now, will be removed in the future
    initPromise: null, // BPOS-937 only for EPSI v2/3
    messageMiddlewares: [],

    /**
     * Initialize EPSI, detect EPSI type or if failes setup an poller every 5 seconds
     */
    setupInitPromise: function() {
        if (this.initPromise == null || this.initPromise.state() === 'resolved') {
            this.initPromise = $.Deferred();
        }
        return this.initPromise;
    },

    init: function(onConnectionLost) {
        var promise = this.setupInitPromise();
        // Timers.clearTimer('epsi-detection-retry');

        if (!ErplyEPSI.isUp()) {
            this.detectEPSI().done(function( type ) {
                console.log("EPSI detected type: " + type);
                switch(type) {
                    case 'standalone':
                        this.openWebSocket(onConnectionLost); //jEPSI detected, open WebSocket
                        break;
                    case 'standalone-legacy':
                        this.isAlive(); //Legacy EPSI detected, setup connection poller every 60 seconds
                        break;
                    case 'applet':
                        this.applet.init(); //Applet detected, try to load applet
                        break;
                    default: {

                    }
                }
            }.bind(this)).fail(function() {
                promise.reject("EPSI detect failed");
                console.log("EPSI detect failed");
                // Timers.setTimeout( 'epsi-detection-retry', this.init.bind(this), 1000 * 5 );
            }.bind(this));
        }else{
            onConnectionLost();
        }
        return promise;
    },

    whenConnected: function() {
        return $.when(this.setupInitPromise());
    },

    /**
     * Get WebSocket EPSI host
     * @return {String}
     */

    getWebSocketHost: function() {
        var host = 'localhost.erply.com';
        var protocol = document.location.protocol === 'https:' ? 'wss://' : 'ws://';
        return protocol + host + ':5656'
    },

    /**
     * Legacy EPSI poller method
     */

    isAlive: function() {
        ErplyEPSI.connectLegacyEPSI().always(function() {
            Timers.setTimeout( 'epsi-is-alive', ErplyEPSI.isAlive, 1000 * 60 );
        }.bind(this));
    },

    /**
     * Try to detect EPSI
     *
     * First WebSocket standalone (jEPSI) is tried, if that fails then IF applet is enabled (backward compatibility),
     * applet is initialized OR if NO applet, then legacy EPSI connection is tested.
     *
     * If no applet and legacy EPSI does not respond, promise is rejected, else promise is always resolved with detected
     * epsi type.
     *
     * List of types: standalone, standalone-legacy, applet
     *
     * @returns Promise
     */

    detectEPSI: function() {
        var promise = $.Deferred();
        var socket = new WebSocket(this.getWebSocketHost());

        socket.onopen = function() {
            socket.onclose = function() {
                promise.resolve('standalone');
            };
            socket.close();
        };

        socket.onclose = function(event) {
            console.log('EPSI socket closed!');
            promise.reject();
        }.bind(this);

        socket.onerror = function (event) {
            console.log('EPSI socket error', event);
        };
        return promise;
    },

    getName: function() {
        if ( this.isUp() ) {
            return this.websocket ? 'jEPSI' : 'EPSI';
        }
        return null;
    },

    unvalidateRequests: function( request_type ) {
        var len = ErplyEPSI.requests_queue.length;
        while( len-- ) {
            var request = ErplyEPSI.requests_queue[len];
            if ( request && request_type == request.request ) {
                //Simply remove request from the queue
                ErplyEPSI.requests_queue.splice( len, 1 );
            }
        }
    },

    retryConnection: function( promise, retryNo ) {
        if ( !promise ) {
            promise = $.Deferred();
        }

        if ( !retryNo ) {
            retryNo = 1;
        }

        var socket = new WebSocket(this.getWebSocketHost());
        socket.onopen = function() {
            socket.onclose = function() {
                promise.resolve('success');
            };
            socket.close();
        };

        socket.onclose = function(event) {
            if (event.code === 1006) {
                if (( retryNo + 1 ) <= 4) {
                    promise.notify(retryNo);
                    Timers.setTimeout('epsi-connection-retry', function () {
                        ErplyEPSI.retryConnection(promise, retryNo + 1);
                    }, 3000);
                } else {
                    promise.reject('fail');
                }
            }
        };

        return promise;
    },

    openWebSocket: function(onConnectionLost) {
        console.log("Opening socket");
        // Let us open a web socket
        ErplyEPSI.websocket = new WebSocket(this.getWebSocketHost());
        ErplyEPSI.websocket.onopen = function() {
            console.log('epsi: websocket opened');
            // Web Socket is connected
            var params = {
                request: 'getPermissions'
            };
            // Event listener returns EPSI running true

            ErplyEPSI.request( params ).done( function( response ) {
                ErplyEPSI.server_version = response.server_version;
                if ( response.permission_to_access_filesystem == 0 ) {
                    // TODO: handle no access to filesystem error!
                    /*ERPLY.Confirm({
                        'title': __t( 'EDI status' ),
                        'body': __t( 'External Device Integrations has no access to filesystem, configuration cannot be stored.' ),
                        'buttons': [ 'yes' ]
                    });*/
                }
            });

            //Fetch configuration too
            ErplyEPSI.updateCurrentConfiguration().then(function() {
                console.log('epsi: initPromise resolve');
                ErplyEPSI.initPromise.resolve();
            });
            ErplyEPSI.localServerUp = true;

            // Check message broker
            ErplyEPSI.getKeyValue('enable_message_broker').done( function( response ) {
                if (response.enable_message_broker && response.enable_message_broker == true) {
                    ErplyEPSI.registerMessageBrokerChannel(function(response) {
                        if (response.errorCode == 0) {
                            ErplyEPSI.message_broker_id = response.id;
                        }
                    });
                }
            });

        };

        ErplyEPSI.websocket.onmessage = function (evt) {
            console.log("epsi: Message is received:");
            console.log( evt.data );
            var data;
            try {
                data = JSON.parse( evt.data );
            } catch(e) {
                console.log( 'EPSI WebSocket response JSON parse error ' + evt.data );
            }

            //If we have successfully parsed JSON, continue here
            if ( data ) {
                // BPOS-937
                ErplyEPSI.dispatchToMiddleware(data);

                if(data.id === 'readidcard' && data.result === 'status'){
                    ErplyEPSI.handleReadIDCard(data);
                    return;
                }

                var request = ErplyEPSI.findMatchingRequest( data );
                if ( request ) {
                    if ( data.result !== 'status' && data.result !== 'notice' ) {
                        if ( data.result == 'request') {
                            $.each(request.events, function (index, event) {
                                if (event.name === data.type) {
                                    event.callback(function( responseText ) {
                                        return ErplyEPSI.request({
                                            id: request.id,
                                            request: data.type,
                                            textResponse: responseText,
                                            noQueue: true
                                        });
                                    }, data);
                                }
                            });
                            return;
                        }

                        //Remove request from the array right away
                        ErplyEPSI.requests_queue.splice( request.arrayIndex, 1 );

                        //Decide promise status either resolve or reject
                        if ( data.result == 'ok' ) {
                            request.promise.resolve( data );
                        } else {
                            request.promise.reject( data );
                        }
                    } else {
                        if (data.result === 'status') {
                            //Status response for request, notify promise progress
                            data.textResponse = nl2br( data.textResponse );
                            request.promise.notify( data );
                        }
                    }
                }
            }
        };

        ErplyEPSI.websocket.onclose = function() {
            console.log('epsi: websocket closed');
            // websocket is closed.
            $.each( ErplyEPSI.requests_queue, function( index, request ) {
                var response = {
                    result: 'socket_closed',
                    // TODO: add translator
                    textResponse: /*__t*/( 'External Device Integrations has quit unexpectedly.')
                };
                request.promise.reject( response );
            });
            //Clear requests queue
            ErplyEPSI.requests_queue = [];

            //If server has been up
            if ( ErplyEPSI.localServerUp ) {
                var _alert;
                $.when( ErplyEPSI.retryConnection() ).fail( function() {
                    onConnectionLost();
                }).done(function() {
                    ErplyEPSI.openWebSocket();
                }).always( function() {
                    if ( _alert ) {
                        _alert.close();
                    }
                }).progress(function(retryNo) {
                    /*if ( !_alert ) {
                        _alert = ERPLY.Alert();
                    }

                    _alert.setMessage( __t( 'Reconnecting with External Device Integrations, retry {retryNo}').tprintf(retryNo) );*/
                });
            }

            ErplyEPSI.localServerUp = false;
        };
    },

    // BPOS-937
    registerMessageMiddleware: function(callback) {
        this.messageMiddlewares.push(callback);
    },

    // BPOS-937
    dispatchToMiddleware: function(message) {
        this.messageMiddlewares.forEach(function(callback) {
            callback(message);
        });
    },

    disconnect: function() {
        if ( ErplyEPSI.websocket ) {
            console.log("epsi: Disconnect socket");
            ErplyEPSI.localServerUp = false;
            ErplyEPSI.websocket.onclose = function() {}; //Disable onclose handler
            ErplyEPSI.websocket.close();
            ErplyEPSI.websocket = null;
        }
    },

    /**
     * Update EPSI current configuration from EPSI to local variable
     */

    updateCurrentConfiguration: function() {
        return ErplyEPSI.getConfParameters(function(response) {
            ErplyEPSI.currentConfiguration = response;
        });
    },

    /**
     * Print to secondary printer
     *
     * @param url
     * @param html
     * @param source
     *
     * @return {boolean}
     */

    printSecondary: function(url, html, source) {

        ErplyEPSI.getKeyValue('secondary_printer').done( function( response ) {
            if (!response.secondary_printer || response.secondary_printer == '') {
                return false;
            }

            var params = {
                request: 'print',
                printerName: response.secondary_printer
            }

            if (url != undefined) {
                $.extend( params, {
                    url: url
                });
            }

            if (html != undefined) {
                $.extend( params, {
                    html: html
                });
            }

            if (source != undefined) {
                $.extend( params, {
                    source: source
                });
            }

            ErplyEPSI.request( params, function() {
                return true;
            }, function() {
                return false;
            });

        });

    },

    /**
     * Get configuration value from EPSI current configuration, stored locally
     *
     * @param name
     * @returns {*}
     */

    getConfigurationValue: function( name ) {
        if ( this.currentConfiguration.hasOwnProperty( name ) ) {
            return this.currentConfiguration[name];
        }
        return null;
    },

    isDisplayConfigured: function() {
        //If legacy EPSI, always return true
        if ( !ErplyEPSI.websocket ) {
            return true;
        }
        return this.getConfigurationValue('customer_display_type') !== "";
    },

    isScaleConfigured: function() {
        //If legacy EPSI, always return true
        if ( !ErplyEPSI.websocket ) {
            return true;
        }
        return this.getConfigurationValue('scale_type') !== "";
    },

    isFiscalConfigured: function() {
        // if legacy v1 EPSI, return always false
        if (!ErplyEPSI.websocket) {
            return false;
        }
        return this.getConfigurationValue('fiscalisation_provider') !== "";
    },

    isIDCardEnabled: function() {
        var value = this.getConfigurationValue('personal_id_card_reader_implementation');
        return typeof value === 'string' && value !== '';
    },

    isMessageBrokerUp: function() {
        return (this.message_broker_id !== '');
    },

    findMatchingRequest: function( response ) {
        var request_from_queue;
        $.each( ErplyEPSI.requests_queue, function( index, request ) {
            if ( request.id == response.id ) {
                request_from_queue = request;
                request_from_queue.arrayIndex = index;
                return false;
            }
        });
        return request_from_queue;
    },

    request: function( params, success, fail ) {
        var promise = $.Deferred();
        if ( ErplyEPSI.websocket ) {

            //Clone original request parameters
            var request = $.extend({}, params);
            if ( success ) {
                promise.done( function( json_response ) {
                    success( json_response );
                });
            }

            if ( fail ) {
                promise.fail( function( json_response ) {
                    fail( json_response );
                });
            }

            // //If request has no ID, generate automatic ID for request tracking
            if ( !params.id ) {
                params.id = params.request + '-' + Date.now().toString() + Math.floor(Math.random()*(900)+100);
                request.id = params.id;
            }

            //Assign promise object
            request.promise = promise;
            request.events = [];

            //Add cancel request method to promise, so requests can be easily canceled
            promise.cancelRequest = function() {
                return ErplyEPSI.request({
                    request: 'cancel',
                    id: params.id
                });
            };

            //Add event listener for requests that require it
            promise.addRequestListener = function( event_name, callback ) {
                request.events.push({
                    name: event_name,
                    callback: callback
                });
                return this;
            };

            //Only send when socket is actually ready
            if ( ErplyEPSI.websocket.readyState == 1 ) {
                //Add to requests queue
                // TODO params.request == cancel for legacy vs v3.15
                if ((!params.noQueue && params.request !== 'cancel') || (params.request == 'cancel' && params.taskId)) {
                    ErplyEPSI.requests_queue.push(request);
                }

                //Remove local parameter used for request from EPSI
                delete params.noQueue;

                //Send request to EPSI
                ErplyEPSI.websocket.send( toQueryString( params ) );
            } else {
                promise.reject({
                    result: 'socket_opening'
                });
            }
            return promise;
        }

        var req_timeout = params.timeout ? params.timeout : 2000;
        delete params.timeout;

        var request_options = {
            type: 'GET',
            url: 'http://localhost.erply.com:5656/',
            timeout: req_timeout,
            data: params,
            jsonp: 'jsonCallback',
            dataType: 'jsonp',
            success: function( response ) {
                try {
                    if ( request_options.type == 'POST' ) {
                        response = JSON.parse( response );
                    }
                } catch( e ) {
                    console.log( "EPSI post response JSON parse failed, data: " + response );
                }

                if ( response ) {
                    if ( response.result == 'OK' ) {
                        if ( success ) {
                            success( response );
                        }
                        promise.resolve(response);
                    } else {
                        if ( fail ) {
                            fail( response );
                        }
                        promise.reject(response);
                    }
                }
            }
        };

        if ( this.post_supported ) {
            request_options.type = 'POST';
            delete request_options.jsonp;
            delete request_options.dataType;
        }

        $.ajax( request_options ).fail( function( jqXhr, textStatus ) {
            var context = {
                // TODO: add translations!
                result: textStatus == 'timeout' ? /*__t*/( 'Timeout') : textStatus,
                textResponse: textStatus == 'timeout' ? /*__t*/( 'Processing payment timed out') : /*__t*/( 'Processing payment failed')
            };
            if ( fail ) {
                fail(context);
            }
            promise.reject(context);
        });
        return promise;
    },

    saveConfParameters: function( configuration ) {
        var params = {
            request: 'saveConfParameters'
        }
        $.extend( params, configuration );
        var promise = $.Deferred();
        $.when( ErplyEPSI.request( params )).done(function( response ) {
            ErplyEPSI.updateCurrentConfiguration().done(function() {
                promise.resolve( response );
            });
        }).fail(promise.reject);
        return promise;
    },

    setConfParameters: function( configuration, success, fail ) {
        var params = {
            request: 'setConfParameters'
        };
        $.extend(params, configuration);
        return ErplyEPSI.request( params, success, fail );
    },

    registerMessageBrokerChannel: function( success, fail ) {
        var params = {
            request: 'messageBroker',
            command: 'register',
            channel: 'erply-local-customer-display'
        }
        return ErplyEPSI.request( params, success, fail );
    },

    publishMessageBrokerMessage: function( payloadData, success, fail ) {
        var params = {
            id: ErplyEPSI.message_broker_id,
            request: 'messageBroker',
            command: 'publish',
            channel: 'erply-local-customer-display',
            payload: JSON.stringify(payloadData)
        }
        return ErplyEPSI.request( params, success, fail );
    },

    getConfParameters: function( success, fail ) {
        var params = {
            request: 'getConfParameters',
            payment_service_provider: getPaymentProvider()
        }
        return ErplyEPSI.request( params, success, fail );
    },

    readIDCardOnce: function(parameters, success, fail) {
        var params = {
            request: 'readidcardonce'
        }
        $.extend(params, parameters);
        return ErplyEPSI.request(params, success, fail);
    },

    clearTaskByID: function(id) {
        ErplyEPSI.requests_queue = ErplyEPSI.requests_queue.filter(function(request) {
            return request.id !== id;
        });
    },

    getKeyValue: function(key, success, fail) {
        var params = {
            request: 'getKeyValue',
            key: key
        }
        return ErplyEPSI.request( params, success, fail );
    },

    saveKeyValue: function( key, value, success, fail ) {
        var params = {
            request: 'saveKeyValue',
            key: key,
            value: value
        }
        return ErplyEPSI.request( params, success, fail );
    },

    openDrawer: function() {
        var params = {
            request: 'opendrawer'
        }

        ErplyEPSI.request( params );
    },

    isPostSupported: function() {
        if ( ErplyEPSI.websocket ) {
            return true;
        }
        return this.post_supported;
    },

    /**
     * Get EPSI connection state
     *
     * @method isUp
     *
     * @author Raido Kuli <raido.kuli@erply.com>
     * @return {Boolean}
     */

    isUp: function() {
        return ErplyEPSI.localServerUp;
    },

    connectLegacyEPSI: function() {
        var promise = $.Deferred();
        var params = {
            request: 'ping'
        };

        ErplyEPSI.request( params, function( response ) {
            ErplyEPSI.localServerUp = true;
            ErplyEPSI.server_version = response.server_version;
            if ( response.supports_post ) {
                ErplyEPSI.post_supported = response.supports_post;
            }
            promise.resolve();
        }, function() {
            ErplyEPSI.localServerUp = false;
            promise.reject();
        });

        return promise;
    },

    printInvoice: function( url, source, success, fail ) {
        var params = {
            request: 'printInvoice',
            url: url
        }

        /*if ( TSPOS.Model.Config.getBoolean( 'touchpos_epsi_direct_print' ) ) {
            params.request = 'print';
        }*/

        if (source != undefined) {
            $.extend( params, {
                source: source
            });
        }

        ErplyEPSI.request( params, success, fail );
    },

    printHTML: function( html, source, success, fail ) {
        var params = {
            request: 'printInvoice',
            html: html
        }

        if (source != undefined) {
            $.extend( params, {
                source: source
            });
        }

        ErplyEPSI.request( params, success, fail );
    },

    saveFiscalData: function( data ) {
        var params = {
            request: 'saveFiscalData',
            cash_register_id: 'berlin'
        }
        $.extend( params, data );
        return ErplyEPSI.request( params );
    },

    cancel: function( epsiID ) {
        var params = {
            request: 'cancel',
            id: epsiID
        }
        return ErplyEPSI.request( params );
    },

    getVersion: function() {
        return ErplyEPSI.server_version;
    },

    sendMessage: function( lines, success, fail ) {
        var params = {
            clientdisplay: 1,
            line1: lines[0],
            line2: lines[1] ? lines[1] : ' '
        }

        if ( ErplyEPSI.websocket ) {
            $.extend( params, {
                request: 'clientDisplay'
            });
            delete params.clientdisplay;
        }

        ErplyEPSI.request( params, success, fail );
    },

    formatLine: function( firstPart, secondPart ) {
        if ( !secondPart ) {
            secondPart = '';
        }
        var msg = firstPart.substr(0, 20 - secondPart.length) + '' + secondPart;
        if ( msg.length <= 20 ) {
            var spaces = "";
            for (var s = 0; s < (20 - firstPart.length - secondPart.length); s++) {
                spaces += " ";
            }
            if ( secondPart.length > 0 && firstPart.length >= ( 20 - secondPart.length ) ) {
                spaces += " ";
            }
            msg = firstPart.substr(0, 20 - secondPart.length - spaces.length) + spaces + secondPart;
        }
        //If message second part is long enough, above if produces string longer than 20 characters, simply crop, we have nothing else todo
        if ( msg.length > 20 ) {
            console.error( 'EPSI formatLine: string too long: "'+msg+'"' + msg.length + 'chars, cropped to: "'+msg.substr(0, 20)+'"' );
            msg = msg.substr(0, 20);
        }
        return msg;
    },

    testPaymentTerminalConnectivity: function( success ) {
        var params = {
            request: 'payment',
            paymentServiceProvider: getPaymentProvider(),
            testConnection: 1,
            amount: 0
        };

        return ErplyEPSI.request( params, success );
    },

    closeBatch: function( params, success, fail ) {
        $.extend(params, {
            request: 'payment',
            paymentServiceProvider: getPaymentProvider(),
            closeBatch: 1
        });

        return ErplyEPSI.request( params, success, fail );
    },

    payment: function( params, success, fail ) {
        if ( !params ) {
            throw new Error( 'ErplyEPSI.payment: parameters not set' );
        }

        if ( ErplyEPSI.websocket ) {
            var originalTransactionType = params.transactionType;
            var transactionType = originalTransactionType;

            // This here is an ugly EPSI v1 > v2 > v3 conversion
            // Original EPSI V1 implementations used P V or R as transactionType which need to be translated for
            // EPSI v2, v3, translate only if "" or "P" etc.
            if (originalTransactionType.length <= 1) {
                transactionType = 'purchase'; //default is "purchase"

                switch (params.transactionType) {
                    case 'V':
                        transactionType = 'void';
                        break;
                    case 'R':
                        transactionType = 'return';
                        break;
                }
            }

            $.extend( params, {
                request: 'payment',
                amount: params.toBePaid,
                transactionType: transactionType
            });

            delete params.toBePaid;
        }

        return ErplyEPSI.request( params, success, fail );
    },

    scale: function( params, success, fail ) {
        if ( !params ) {
            throw new Error( 'ErplyEPSI.scale: parameters not set' );
        }

        return ErplyEPSI.request( params, success, fail );
    },

    directPrint: function(params, success, fail) {

        if ( !params ) {
            throw new Error( 'ErplyEPSI.directPrint: parameters not set' );
        }

        return ErplyEPSI.request( params, success, fail );

    },

    printLastCardReceipt: function(params, success, fail) {

        if ( !params ) {
            throw new Error( 'ErplyEPSI.paymentServiceProvider: parameters not set' );
        }

        ErplyEPSI.request( params, success, fail );

    },

    protocolProxy: function(params, success, fail) {
        ErplyEPSI.request( params, success, fail );
    },

    /**
     * @public
     * Return current OS
     * Method is not 100% accurate
     * @return {String}
     */
    getOS: function() {

        var userAgent = window.navigator.userAgent;
        var platform = window.navigator.platform;

        var macosPlatforms = [
            'Macintosh',
            'MacIntel',
            'MacPPC',
            'Mac68K'
        ];
        var windowsPlatforms = [
            'Win32',
            'Win64',
            'Windows',
            'WinCE'
        ];
        var iosPlatforms = [
            'iPhone',
            'iPad',
            'iPod'
        ];
        // Define default Windows OS
        var os = 'Windows';

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'macOS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }
        return os;
    },

    setDownloadURL: function(downloadURLs) {
        if (!downloadURLs) {
            return;
        }
        var os = this.getOS();
        var obj = downloadURLs.find(function(element) {
            return element.operatingSystem === os;
        });
        if (obj) {
            this.downloadURL = obj.url;
        }
    },
    getReceiptHTMLFromURL: function(url) {
        var t = $.Deferred()
            , n = new XMLHttpRequest;
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "proxy?u=" + btoa(url);
        }
        return n.onreadystatechange = function() {
            if (4 === n.readyState && (200 === n.status || 304 === n.status)) {
                var e = $.trim(n.responseText);
                t.resolve(e)
            }
        }
            ,
            n.open("GET", url),
            n.send(null),
            t
    },
    createReceiptHTMLObject: function(e) {
        var t = $("<html/>");
        t[0].appendChild(document.createElement("head"));
        t[0].appendChild(document.createElement("body"));
        for (var n = $(e), r = 0; r < 13; r++) {
            if ("STYLE" === n[r].nodeName) {
                n[r].appendChild(document.createTextNode("pre { font-family: Arial, sans-serif; font-size:12px; }"))
            }
            t.find("head")[0].appendChild(n[r])
        }
        for (var o = 13; o < n.length; o++)
            t.find("body")[0].appendChild(n[o]);
        return $.each(t.find(".cardPayment"), function(e, t) {
            0 != e && this.remove()
        }),
            t
    },
    handleReadIDCard: (data) => {}
};



const epsiMiddleware = (store) => next => action => {
    next(action);
    let state = store.getState();
    let translate = getTranslate(state.localize);
    if(action.type === SET_USER){
        const epsiPeripheral = new Peripheral('epsi');
        epsiPeripheral.setConnecting();
        store.dispatch(addPeripheral(epsiPeripheral));

        ErplyEPSI.init(function () {
            const peripheral = getPeripheral(store.getState().rootReducer.peripherals, 'epsi');
            peripheral.setConnectionFailed();
            store.dispatch(removePeripheral('epsi'));
        }).then(function () {
            const peripheral = getPeripheral(store.getState().rootReducer.peripherals, 'epsi');
            peripheral.setConnected();
            store.dispatch(updatePeripheral(peripheral));
            if(window.AppConf.useIDCardReader === true){
                ErplyEPSI.request({
                    request: 'readidcard',
                    id: 'readidcard'
                });
                ErplyEPSI.handleReadIDCard = (data) => {
                    store.dispatch(findCustomerByRegistryCode(data.data.idCode));
                }
            }
        }, function () {
            const peripheral = getPeripheral(store.getState().rootReducer.peripherals, 'epsi');
            peripheral.setConnectionFailed();
            store.dispatch(removePeripheral('epsi'));
        });
    }

    if(action.type === PRINT_RECEIPT){
        if(window.AppConf.printDecathlonReceipt){
            CTR.handlePrintReceipt(action, state).then((receiptHtml) => {
                /*teleprint({
                    template: {
                        html: receiptHtml
                    },
                    inherit: {
                        css: true
                    }
                });
                action.payload.onSuccess();*/
                if(ErplyEPSI.isUp()){
                    ErplyEPSI.printHTML(receiptHtml, null, function () {
                        action.payload.onSuccess();
                    }, function () {
                        store.dispatch(addErrorAlert('Printing failed!'));
                        action.payload.onFail();
                    });
                }else{
                    CTR.openBrowserPrint(receiptHtml);
                    action.payload.onSuccess();
                }
            }, (message) => {
                store.dispatch(addErrorAlert(message));
            });

            return true;
        }

        let receiptLink = action.payload.receiptLink;
        if(action.payload.giftReceipt){
            receiptLink += '&giftreceipt=1';
        }

        let printInvoice = function() {
            ErplyEPSI.printInvoice(receiptLink, null, function () {
                action.payload.onSuccess();
            }, function () {
                store.dispatch(addErrorAlert('Printing failed!'));
                action.payload.onFail();
            });
        };

        let printWithExtraInfo = (data) => {
            ErplyEPSI.getReceiptHTMLFromURL(receiptLink).done(function (receipt) {
                let receiptHtml = ErplyEPSI.createReceiptHTMLObject(receipt);

                data.map((el) => {
                    el.addInfo(receiptHtml);
                });

                let finalReceiptHtml = '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">' + receiptHtml[0].outerHTML;
                ErplyEPSI.printHTML(finalReceiptHtml, undefined, action.payload.onSuccess, function () {
                    store.dispatch(addErrorAlert('Printing failed!'));
                    action.payload.onFail();
                });
            });
        };

        let extraInfo = [];

        if(state.payment.allInOneReceipt === true){
            let clientReceipts = "";
            for(let payment of state.payment.payments){
                if(payment.type === 'CARD' && typeof payment.cardholderReceipt !== "undefined"){
                    clientReceipts += payment.cardholderReceipt + "<br>";
                }
            }
            if(clientReceipts !== ""){
                extraInfo.push({
                    addInfo: (receiptHtml) => {
                        receiptHtml.find(".infoAll").html(clientReceipts);
                    }
                });
            }
        }

        let duplicateLabel = action.payload.duplicate ? translate('DUPLICATE ') : '';
        if(state.rootReducer.addReceiptTitle){
            let totalSum = 0;
            if(action.payload.duplicate){
                totalSum = action.payload.document.total;
            }else{
                totalSum = parseFloat(getProductsTotalSum(state.rootReducer.productsInBasket));
            }
            let title = duplicateLabel + (totalSum < 0 ? translate('CREDIT RECEIPT') : (totalSum === 0 ? translate('RECEIPT') : translate('SALES RECEIPT')));
            if(action.payload.giftReceipt){
                title = translate('GIFT RECEIPT');
            }
            extraInfo.push({
                addInfo: receiptHtml => {
                    $('<span id="invoiceType">' + title + '</span><br><br>').insertBefore(receiptHtml.find('.invoiceTitle'));
                }
            });
        }

        if(state.rootReducer.receiptFiscalInfo !== false){
            extraInfo.push({
                addInfo: receiptHtml => {
                    $(receiptHtml).find('body').append(state.rootReducer.receiptFiscalInfo);
                }
            });
        }

        if(extraInfo.length > 0){
            printWithExtraInfo(extraInfo);
        }else{
            printInvoice();
        }
    }
    if(action.type === PRINT_HTML){
        ErplyEPSI.printHTML(action.payload.data, undefined, function (response) {
            action.payload.onSuccess(response);
        }, function (response) {
            action.payload.onFail(response);
        });
    }
    if(
        action.type === CASH_IN_OUT_SUCCESS ||
        action.type === POS_DAY_CLOSE_SUCCESS ||
        action.type === OPEN_POS_OPEN_DAY_VIEW ||
        action.type === OPEN_CASH_DRAWER
    ){
        ErplyEPSI.openDrawer();
    }
    if(action.type === INPUT_PAYMENT_STARTED){
        if(action.payload.type === 'CASH'){
            ErplyEPSI.openDrawer();
        }
    }
    if(action.type === CLOSE_QCO){
        ErplyEPSI.disconnect();
    }
    if(action.type === EPSI_REQUEST){
        console.log('EPSI_REQUEST', action.payload.params);
        ErplyEPSI.request(action.payload.params, action.payload.onSuccess, action.payload.onFail);
    }
    if (action.type !== EPSI) return true;


};

export default epsiMiddleware;