import React, {useRef, useState} from 'react';
import Keyboard from "../keyboard/Keyboard";
import "../main/main.css";
//import PerfectScrollbar from "react-perfect-scrollbar/lib/index";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import Select from 'react-select';
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import {useSelector} from "react-redux";
import {erplyDateFormatToMoment} from "../util/calendar";
import CheckBox from "../formElements/CheckBox";

export default function ({
    inputFields,
    title,
    translate,
    onDone,
    onChange = ()=>{},
    initialValues,
    afterInputFields = '',
    beforeInputFields = '',
    initialKeyboardLayout = 'letters',
    inputValuesDisplayRounding = false,
    labelStyle,
    inputStyle,
    inputClassName
}) {
    if(inputFields.length === 0){
        return false;
    }
    let [inputValues, changeInput] = useState(initialValues || {});
    let [activeInput, changeActiveInput] = useState(inputFields[0]);
    let [initialActiveInputSet, setInitialActiveInput] = useState(false);
    let [validationErrors, setValidationErrors] = useState({});
    const dateFormat = useSelector(state => {
        return erplyDateFormatToMoment(state.rootReducer.erplyConf.dateformat);
    });

    const scrollbarRef = useRef(null);

    let inputs = inputFields.map((field, index) => {
        let labelEl;
        if(typeof field.name !== "undefined"){
            labelEl = (
                <span style={labelStyle}>
                    { translate(field.name) }
                </span>
            );
        }

        let ValidationError = ({errorText}) => <><span style={{color: "red"}}>{errorText}</span><br/></>;
        let fieldError = typeof validationErrors[field.id] === "undefined" ? "" : <ValidationError errorText={validationErrors[field.id]}/>

        if(field.type === 'select'){
            let selectOptions = field.options.map(el => {
                return {
                    value: el.value,
                    label: translate(el.name)
                };
            })
            let selectedValue = selectOptions.find(el => el.value === inputValues[field.id]);

            if(typeof selectedValue === "undefined"){
                selectedValue = selectOptions[0];
            }
            const selectRef = useRef(null);
            return (
                <label key={index}>
                    { field.labelContent || ''}
                    { labelEl }
                    <Select
                        ref={selectRef}
                        options={selectOptions}
                        style={{height: '50px', ...inputStyle}}
                        styles={{
                            control: styles => ({...styles, borderRadius: 0, height: "50px"}),
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    borderBottom: "1px solid grey"
                                };
                            },
                        }}
                        name={field.id}
                        value={selectedValue}
                        className={inputClassName || "underlineInput"}
                        isSearchable={false}
                        onChange={(option) => {
                            let newState = {};
                            newState[field.id] = option.value;
                            changeInput(Object.assign({}, inputValues, newState));
                            onChange(newState, activeInput.id, changeInput);
                        }}
                        onMenuOpen={() => {
                            scrollbarRef.current._ps.update();
                            setTimeout(() => {
                                if(selectRef.current.select.menuListRef !== null){
                                    selectRef.current.select.menuListRef.scrollIntoView();
                                }
                            },1);
                        }}
                    />
                </label>
            );
        }
        if(field.type === 'date'){
            let selectedDate;
            if(typeof inputValues[field.id] !== "undefined"){
                selectedDate = moment(inputValues[field.id], 'YYYY-MM-DD');
            }else{
                selectedDate = moment();
                changeInput(Object.assign({}, inputValues, {[field.id]: selectedDate.format('YYYY-MM-DD')}));
            }

            return (
                <label key={index}>
                    { field.labelContent || ''}
                    { labelEl }
                <DatePicker
                    style={{height: '50px', display: 'block', width: '100%'}}
                    TextFieldComponent={
                        ({ onClick, inputRef }) => {
                            return <input
                                style={{...componentStyles, ...inputStyle}}
                                className={inputClassName || "underlineInput"}
                                ref={inputRef}
                                value={selectedDate.format(dateFormat)}
                                onClick={onClick}
                                readOnly={true}
                            />}
                    }
                    className={inputClassName}
                    format={dateFormat}
                    onChange={(date) => {
                        let newState = {};
                        newState[field.id] = date.format('YYYY-MM-DD');
                        changeInput(Object.assign({}, inputValues, newState));
                        onChange(newState, activeInput.id, changeInput);
                    }}
                />
                </label>)
        }
        if(field.type === 'checkbox'){
            return (
                <label key={index}>
                    { field.labelContent || ''}
                    { labelEl }
                    <CheckBox
                        name={field.id}
                        onChange={checked => {
                            let newState = {};
                            newState[field.id] = checked;
                            changeInput(Object.assign({
                                [field.id]: checked
                            }, inputValues, newState));
                            onChange(newState, field.id, changeInput);
                        }}
                        checked={inputValues[field.id]}
                    />
                </label>
            );
        }
        if(field.id === activeInput.id){
            if(field.readOnly !== activeInput.readOnly){
                changeActiveInput(field);
            }
        }else if(field.active === true){
            if(!initialActiveInputSet){
                changeActiveInput(field);
                setInitialActiveInput(true);
            }
        }
        let displayValue = inputValues[field.id] || '';
        if(inputValuesDisplayRounding !== false){
            if(field.id !== activeInput.id){
                displayValue = parseFloat(displayValue).toFixed(inputValuesDisplayRounding);
                if(isNaN(displayValue)) displayValue = '';
            }
        }
        let readOnly = field.readOnly === true;
        let componentStyles = {};
        if(readOnly){
            componentStyles['backgroundColor'] = 'aliceblue';
        }
        return (
            <label key={index}>
                { field.labelContent || ''}
                { labelEl }
                <input
                    style={{...componentStyles, ...inputStyle}}
                    type={field.type === 'password' ? "password" : "text"}
                    name={ field.id }
                    className={inputClassName || "underlineInput"}
                    autoComplete={'off'}
                    value={displayValue}
                    onChange={e => {
                        if(readOnly) return false;

                        let newValue = e.target.value;
                        let newState = {};

                        if(field.isNumber){
                            newValue = newValue.replace(',', '.');
                            let lastChar = newValue.slice(-1);
                            if(lastChar === '.' && field?.positiveInt !== true){
                                if(newValue.split('.').length > 2){
                                    newValue = newValue.slice(0,-1);
                                }
                            }else if(isNaN(lastChar)){
                                newValue = newValue.slice(0,-1);
                            }
                        }

                        newState[field.id] = newValue;
                        changeInput(Object.assign({}, inputValues, newState));
                        onChange(newState, activeInput.id, changeInput);
                    }}
                    onClick={e => {
                        if(readOnly) return false;

                        changeActiveInput(field);
                        let clearState = {};
                        clearState[field.id] = '';
                        changeInput(Object.assign({}, inputValues, clearState));
                    }}
                    readOnly={readOnly}
                    autoFocus={field.active === true}
                />
                {fieldError}
            </label>
        );
    });

    let titleEl;
    if(typeof title !== "undefined"){
        titleEl = (
            <div>
                <h1 style={{marginLeft: '20px'}}>{ title }</h1>
                <div style={{
                    width: "100%",
                    borderTop: "1px solid gray",
                    marginBottom: "10px"
                }}/>
            </div>
        );
    }

    return (
        <div style={{
            position: "absolute",
            left: 0,
            right:0,
            top: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column'
        }}>
            { titleEl }
            <PerfectScrollbar
                style={{
                    flexGrow: 1,
                    width: '100%'
                }}
                ref={el => (scrollbarRef.current = el)}
            >
                <div className={'voucherInputs'}>
                    { beforeInputFields }
                    <div style={{marginBottom: '20px'}}/>
                    { inputs }
                    { typeof afterInputFields === "string" ? <div dangerouslySetInnerHTML={{__html: afterInputFields}}/> : afterInputFields }
                </div>
            </PerfectScrollbar>
            <div style={{
                /*"position": "absolute",
                "bottom": "0",
                "left": "0",
                "right": "0"*/
            }}>
                <Keyboard
                    options={{
                        initialLayout: initialKeyboardLayout,
                        layout: activeInput.isNumber ? 'numbers': undefined,
                        currentValue: inputValues[activeInput.id] || '',
                        updateValue: (value, key, currentValue) => {
                            if(activeInput.readOnly === true) return false;

                            let newInputValues = {};
                            if(activeInput.isNumber){
                                if(key !== 'delete' && key.length > 1){
                                    return false;
                                }
                                value = value.replace(',', '.');
                                let lastChar = value.slice(-1);
                                if(lastChar === '.' && activeInput?.positiveInt !== true){
                                    if(value.split('.').length > 2){
                                        value = value.slice(0,-1);
                                    }
                                }else if(isNaN(lastChar) && !(value === '-' && activeInput?.positiveInt !== true)){
                                    value = value.slice(0,-1);
                                }
                            }

                            newInputValues[activeInput.id] = value;
                            let newState = Object.assign({}, inputValues, newInputValues);
                            changeInput(newState);
                            onChange(newState, activeInput.id, changeInput);
                        },
                        onDone: function () {
                            inputFields.map(field => {
                               if(field.isNumber){
                                   if(typeof inputValues[field.id] !== "undefined" && inputValues[field.id] !== ''){
                                       inputValues[field.id] = parseFloat(inputValues[field.id]).toString();
                                   }
                               }
                               if(typeof inputValues[field.id] === "undefined"){
                                   let elementsByName = document.getElementsByName(field.id);
                                   if(elementsByName.length > 0){
                                       inputValues[field.id] = elementsByName[0].value;
                                   }
                               }
                            });
                            onDone(inputValues, activeInput.id, setValidationErrors);
                        }
                    }}
                />
            </div>
        </div>
    );
}